<template>
  <div class="relative">
    <div class="absolute w-full h-full z-50" v-if="isLoading">
      <div class="absolute m-auto left-0 right-0 top-0 bottom-0" style="width: 100px; height: 100px">
        <ProgressSpinner />
      </div>
    </div>

    <!-- Comments dialog -->
    <Dialog header="Комментарии" v-model:visible="displayComments" :modal="true">
      <div class="flex flex-col space-y-3">
        <div class="border-b pb-3" :key="comment.id" v-for="comment in commentsData">
          <div class="flex flex-row space-x-3">
            <div>
              <Avatar :label="comment.user.name.substr(0, 1)" size="large" shape="circle" v-if="!comment.user.avatar" />

              <div class="rounded-full border w-12 h-12 overflow-hidden" v-else>
                <img :src="baseURL + comment.user.avatar" class="object-cover w-full h-full" />
              </div>
            </div>

            <div class="flex flex-col">
              <div class="flex flex-row space-x-3 items-center">
                <strong>{{ comment.user.name }}</strong>
                <span class="text-sm">{{ moment(comment.created_at).format("DD.MM.Y в HH:mm") }}</span>

                <div>
                  <span class="text-sm font-medium"><i class="pi pi-star"></i> {{ comment.rate }}</span>
                </div>
              </div>
              <span>{{ comment.comment }}</span>

              <div v-if="comment.response" class="flex flex-col mt-3">
                <strong>Ваш ответ:</strong>
                <p class="text-sm">{{ comment.response.comment }}</p>

                <div
                  class="rounded bg-red-600 text-white px-2 py-1 w-max text-xs font-semibold cursor-pointer mt-1"
                  @click="deleteCommentResponse(comment)"
                >
                  Удалить мой ответ
                </div>
              </div>

              <div
                class="rounded bg-accent text-white px-2 py-1 w-min text-xs font-semibold cursor-pointer mt-1"
                v-if="!comment.is_responding && !comment.response"
                @click="comment.is_responding = true"
              >
                Ответить
              </div>

              <div class="flex flex-col" v-if="comment.is_responding">
                <Textarea v-model="comment.response_text" />

                <div class="flex flex-row space-x-2">
                  <div
                    class="rounded bg-red-600 text-white px-2 py-1 w-min text-xs font-semibold cursor-pointer mt-1"
                    @click="comment.is_responding = false"
                  >
                    Отмена
                  </div>
                  <div
                    class="rounded bg-green-600 text-white px-2 py-1 w-min text-xs font-semibold cursor-pointer mt-1"
                    @click="sendResponseToComment(comment)"
                  >
                    Отправить
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>

    <!-- List all workers -->
    <div
      class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8 filter transition duration-300 ease-linear"
      :class="{ blur: isLoading, 'blur-none': !isLoading }"
      v-if="!isEditorEnabled"
    >
      <div v-for="worker in workers" :key="worker.id">
        <div @click="loadWorkerDetails(worker.id)" class="shadow-lg rounded-lg border overflow-hidden">
          <div class="relative">
            <div style="height: 200px" class="overflow-hidden" v-if="worker.photo">
              <img :src="baseURL + worker.photo" class="object-cover object-center h-full w-full" />
            </div>
            <div style="height: 200px" class="flex w-full bg-blue-50" v-else>
              <i class="m-auto pi pi-camera text-blue-400 text-4xl"></i>
            </div>
            <div class="absolute bottom-2 right-2 left-2">
              <div class="text-right flex flex-row space-x-2 justify-end">
                <div
                  class="mt-1 font-semibold text-sm bg-accent text-white rounded-lg px-2 flex items-center space-x-1 pb-0.5 cursor-pointer"
                  v-if="worker.comments.length"
                  @click="
                    () => {
                      commentsData = worker.comments;
                      displayComments = true;
                    }
                  "
                >
                  <i class="pi pi-comment"></i>
                  <span class="pt-1.5">{{ worker.comments.length }}</span>
                </div>

                <div
                  class="mt-1 font-semibold text-sm rounded-lg px-2 flex items-center space-x-1 pb-0.5"
                  :class="{
                    'bg-yellow-400': worker.rate < 5 && worker.rate > 3,
                    'bg-green-400 text-white': worker.rate >= 5,
                    'bg-red-400 text-white': worker.rate < 3
                  }"
                  v-if="worker.rate"
                >
                  <i class="pi pi-star"></i>
                  <span class="pt-1.5">{{ worker.rate.toFixed(1) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="p-4 flex flex-row justify-between">
            <div class="flex flex-col">
              <strong>{{ worker.name }}</strong>
              <i class="text-sm">{{ worker.occupation }}</i>
            </div>
            <div v-if="1==0">
              <Button
                icon="pi pi-bars"
                class="p-button-rounded p-button-success"
                @click="toggleListMenu($event, worker.id)"
                aria-haspopup="true"
                aria-controls="overlay_tmenu"
              />
              <TieredMenu id="overlay_tmenu" ref="listItemMenu" :model="listItemActions" :popup="true" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="shadow-lg rounded-lg border overflow-hidden bg-blue-50 flex cursor-pointer hover:bg-blue-100"
        style="min-height:270px"
        @click="
          () => {
            isEditorEnabled = true;
            resetWorker();
          }
        "
      >
        <i class="pi pi-plus text-4xl m-auto text-blue-400"></i>
      </div>
    </div>

    <!-- Edit worker -->
    <div v-if="isEditorEnabled && worker">
      <div class="grid grid-cols-5 border-b pb-8">
        <div class="col-span-2 flex flex-col">
          <strong class="text-lg">Основная информация</strong>
          <p class="text-sm text-gray-600 w-2/3">Информация, доступная всем пользователям до совершения заказа</p>
        </div>
        <div class="col-span-3">
          <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
            <div>
              <strong class="text-gray-600 font-medium text-sm">Имя</strong>

              <div class="flex flex-col">
                <InputText
                  v-model="worker.name"
                  aria-describedby="username2-help"
                  class="p-inputtext-sm"
                  :class="{ 'p-invalid': worker.errors.name }"
                />
                <small v-if="worker.errors.name" id="username2-help" class="p-error">Username is not available.</small>
              </div>
            </div>
            <div>
              <strong class="text-gray-600 font-medium text-sm">E-mail</strong>

              <div class="flex flex-col">
                <InputText
                  v-model="worker.email"
                  aria-describedby="username2-help"
                  class="p-inputtext-sm"
                  :class="{ 'p-invalid': worker.errors.email }"
                />
                <small v-if="worker.errors.email" id="username2-help" class="p-error">Username is not available.</small>
              </div>
            </div>
            <div>
              <strong class="text-gray-600 font-medium text-sm">Телефон</strong>

              <div class="flex flex-col">
                <InputMask
                  v-model="worker.phone"
                  mask="+375 (99) 999-99-99"
                  aria-describedby="username2-help"
                  class="p-inputtext-sm"
                  :class="{ 'p-invalid': worker.errors.phone }"
                />
                <small v-if="worker.errors.phone" id="username2-help" class="p-error">Username is not available.</small>
              </div>
            </div>
            <div>
              <strong class="text-gray-600 font-medium text-sm">Должность</strong>

              <div class="flex flex-col">
                <InputText
                  v-model="worker.occupation"
                  aria-describedby="username2-help"
                  class="p-inputtext-sm"
                  :class="{ 'p-invalid': worker.errors.occupation }"
                />
                <small v-if="worker.errors.occupation" id="username2-help" class="p-error">Username is not available.</small>
              </div>
            </div>
            <div>
              <div class="flex justify-between">
                <strong class="text-gray-600 font-medium text-sm">Биография</strong>
                <span class="text-sm text-gray-400">не обязательно</span>
              </div>

              <div class="flex flex-col">
                <Textarea v-model="worker.bio" :autoResize="true" rows="3" cols="30" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-5 border-b py-8">
        <div class="col-span-2 flex flex-col">
          <strong class="text-lg">Фото</strong>
          <p class="text-sm text-gray-600 w-2/3">Фотография работника доступна всем пользователям</p>
        </div>
        <div class="col-span-3">
          <div class="px-4">
            <div>
              <FileUpload mode="basic" ref="photo" name="demo[]" />
            </div>
            <div class="grid autoGrid flex-wrap gap-4 existingPhotoContainer" v-if="1 == 2">
              <div v-for="photo in edit.photos" :key="photo.id" class="relative">
                <div v-if="photo.is_logo">
                  <img :src="baseURL + photo.photo" class="object-fit existingPhoto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-5 py-8">
        <div class="col-span-2 flex flex-col">
          <strong class="text-lg">Место работы</strong>
          <p class="text-sm text-gray-600 w-2/3">Выберите точки продаж, к которым относится этот работник</p>
        </div>
        <div class="col-span-3">
          <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
            <MultiSelect
              v-model="worker.places"
              :options="places"
              optionLabel="address_with_title"
              optionValue="id"
              placeholder="Выберите точки продаж"
              display="chip"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-row flex-row-reverse space-x-4 mb-16">
        <Button class="p-button-success ml-4" label="Сохранить" @click="store" />
        <Button class="p-button-secondary" label="Отменить" @click="isEditorEnabled = false" />
      </div>
    </div>
    <!-- End Edit worker -->
  </div>
</template>

<script>
import TieredMenu from "primevue/tieredmenu";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
// import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import InputMask from "primevue/inputmask";
import FileUpload from "primevue/fileupload";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import Avatar from "primevue/avatar";
import moment from "moment";

export default {
  components: {
    TieredMenu,
    Button,
    InputText,
    Textarea,
    // Dropdown,
    MultiSelect,
    InputMask,
    FileUpload,
    ProgressSpinner,
    Dialog,
    Avatar
  },
  data() {
    return {
      displayComments: false,
      commentsData: null,
      isLoading: true,
      isEditorEnabled: false,
      activeWorkerId: null,
      places: null,
      services: null,
      workers: [],
      worker: null,
      listItemActions: [
        {
          label: "Редактировать",
          icon: "pi pi-pencil",
          command: () => {
            this.loadWorkerDetails();
          }
        },
        {
          label: "Услуга",
          icon: "pi pi-plus",
          command: () => {
            this.$toast.add({ severity: "success", summary: "Update", detail: "Data Updated" });
          }
        },
        {
          label: "Удалить",
          icon: "pi pi-trash",
          command: () => {
            this.$toast.add({ severity: "error", summary: "Delete", detail: "Data Deleted" });
          }
        }
      ]
    };
  },
  mounted() {
    this.fetchWorkers();
    this.fetchPlaces();
  },
  methods: {
    moment(x) {
      return moment(x);
    },
    deleteCommentResponse(comment) {
      this.$http.delete("comment-response/" + comment.response.id).then(() => {
        delete comment.response;
      });
    },
    sendResponseToComment(comment) {
      this.$http.post("comment-response", { type: "worker", text: comment.response_text, id: comment.id }).then(response => {
        comment.response = response.data.comment;
        comment.is_responding = false;
      });
    },
    resetWorker() {
      this.worker = {
        errors: {
          name: false
        }
      };
    },
    store() {
      let form = new FormData();

      if (this.worker.id) {
        form.append("id", this.worker.id);
      }

      form.append("name", this.worker.name);
      form.append("occupation", this.worker.occupation);
      form.append("email", this.worker.email);
      form.append("phone", this.worker.phone);
      form.append("bio", this.worker.bio);
      form.append("place_id", 0);

      if (this.$refs.photo.files.length) {
        form.append("photo", this.$refs.photo.files[0]);
      }

      if (this.worker.places) {
        this.worker.places.map(place => {
          form.append("places[]", place);
        });
      }

      this.$http
        .post("organization/worker", form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.fetchWorkers();
          this.worker = null;
          this.isEditorEnabled = false;
        });
    },
    fetchPlaces() {
      this.$http.get("/entity-place/addressed").then(response => {
        this.places = response.data.addressed;
      });
    },
    loadWorkerDetails(id) {
      this.$http.get("organization/worker/" + id).then(response => {
        let data = response.data.worker;
        data.errors = {
          name: false,
          occupation: false,
          phone: false,
          email: false
        };
        data.places = data.places.map(place => {
          return place.address_id;
        });

        this.worker = data;
        this.isEditorEnabled = true;
      });
    },
    toggleListMenu(event, worker_id) {
      this.activeWorkerId = worker_id;
      this.$refs.listItemMenu.toggle(event);
    },
    fetchWorkers() {
      this.$http.get("organization/workers").then(response => {
        this.workers = response.data.workers;
        this.activeWorkerId = null;
        this.isLoading = false;
      });
    }
  },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(0, this.$http.defaults.baseURL.length - 3);
    }
  }
};
</script>

<style scoped>
:deep(.p-multiselect),
:deep(.p-chips-multiple-container),
:deep(.p-autocomplete-multiple-container) {
  border: 0;
}
</style>
