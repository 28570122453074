<template>
  <div>
    <DataTable
      :value="users"
      responsiveLayout="scroll"
    >
      <Column
        field="id"
        header="ID"
      ></Column>
      <Column
        field="name"
        header="Имя"
      ></Column>
      <Column
        field="created_at"
        header="Дата регистрации"
      ></Column>
      <Column
        field="is_vip"
        header="VIP"
      >
        <template #body="bodySlot">
          <Button
            v-if="bodySlot.data.is_vip"
            label="Убрать VIP"
            @click="setVipStatus(bodySlot.data.id)"
          />
          <Button
            v-else
            label="Сделать VIP"
            @click="setVipStatus(bodySlot.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

export default {
  data() {
    return {
      users: null,
    };
  },
  components: { DataTable, Column, Button },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      this.$http.get("admin/users").then((r) => {
        this.users = r.data.users;
      });
    },
    setVipStatus(userId) {
      this.$http.post("admin/user-vip", { user: userId }).then(() => {
        this.fetchUsers();
      });
    },
  },
};
</script>