<template>
  <div class="container content mx-auto text-left py-6 px-4 lg:px-0">
    <h1 class="text-4xl font-medium">Избранное</h1>

    <div class="mt-6 lg:mt-6">
      <div
        class="text-center space-y-5"
        v-if="!favorites"
      >
        <h2 class="text-2xl font-semibold">У вас пока нет избранного</h2>
        <p>Перейдите в каталог чтобы сохранить организацию в избранное</p>
        <Button
          label="Перейти в каталог"
          @click="$router.push('/')"
        />
      </div>

      <div v-else>
        <div
          class="flex flex-row space-x-3 col-span-4 my-6"
          v-if="1==2"
        >
          <div
            v-for="(tab, i) in categoryTabs"
            :key="i"
            class="rounded-full bg-gray-200 px-4 py-1 hover:bg-accent hover:text-white cursor-pointer"
            :class="{ 'bg-accent text-white': tab == activeTab }"
            @click="activeTab = tab"
          >
            {{ tab }}
          </div>
        </div>
        <div
          class="overflow-x-scroll lg:overflow-x-hidden my-6"
          v-if="1==2"
        >
          <div class="my-2 flex flex-row space-x-3">
            <Dropdown
              v-model="filters.filterCategory"
              :options="districtOptions"
              optionLabel="title"
              placeholder="Район"
              class="rounded-3xl px-2 outline-none"
            />

            <Dropdown
              v-model="filters.isOpen"
              :options="isOpenOptions"
              optionLabel="title"
              placeholder="Сейчас открыто"
              class="rounded-3xl px-2 outline-none"
            />

            <Dropdown
              v-model="filters.sorting"
              :options="sortingOptions"
              optionLabel="title"
              placeholder="Сортировка"
              class="rounded-3xl px-2 outline-none"
            />

            <Dropdown
              v-model="filters.sorting"
              optionLabel="title"
              placeholder="Категория"
              class="rounded-3xl px-2 outline-none"
            />

            <Dropdown
              v-model="filters.isClosest"
              :options="yesNoOptions"
              optionLabel="title"
              placeholder="Рядом со мной"
              class="rounded-3xl px-2 outline-none"
            />
          </div>
        </div>
        <div class="lg:grid grid-cols-4 grid-rows-2 gap-4">
          <div
            v-for="i in favorites"
            :key="i.id"
            class="relative"
          >
            <div
              class="absolute -right-4 -top-4 w-8 h-8 cursor-pointer border shadow-lg bg-white rounded-full z-10 flex items-center"
              @click="toggleFavorite(i.place.id)"
            >
              <i class="pi pi-times text-lg mx-auto text-gray-400 hover:text-gray-800"></i>
            </div>
            <ServiceCard :data="i.place" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../../views/ui/ServiceCard";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

export default {
  components: {
    Button,
    ServiceCard,
    Dropdown,
  },
  created() {
    this.fetchFavorites();
  },
  methods: {
    fetchFavorites() {
      this.$http.get("favorites").then((response) => {
        if (response.data.favorites) {
          if (response.data.favorites.length > 0) {
            this.favorites = response.data.favorites;
          } else {
            this.favorites = null;
          }
        }
      });
    },
    toggleFavorite(id) {
      this.$http
        .post("favorites", {
          place_id: id,
        })
        .then(() => {
          this.fetchFavorites();
        });
    },
  },
  data() {
    return {
      favorites: null,
      activeTab: "Еда и развлечения",
      categoryTabs: [
        "Еда и развлечения",
        "Красота",
        "Аренда недвижимости",
        "Медицина",
        "Автоуслуги",
      ],
      timeOptions: [
        { title: "Только в указанное время" },
        { title: "В любое время" },
      ],
      districtOptions: null,
      isOpenOptions: [
        { title: "Сейчас открыто" },
        { title: "Открыто или закрыто" },
      ],
      yesNoOptions: [{ title: "Да" }, { title: "Нет" }],
      sortingOptions: [
        { title: "Рейтинг по убыванию" },
        { title: "Рейтинг по возрастанию" },
        { title: "Цены по убыванию" },
        { title: "Цены по возрастанию" },
        { title: "Название заведения по возрастанию" },
        { title: "Название заведения по убыванию" },
      ],
      filters: {
        isSlotOnly: null,
        filterCategory: null,
        isOpen: null,
        sorting: null,
        isClosest: false,
      },
    };
  },
};
</script>
