<template>
  <div>
    <div v-for="(day, k) in days" :key="k" class="mt-5">
      <div class="flex space-x-4 items-center">
        <span class="uppercase text-gray-400 text-sm font-bold">{{ day.title }}</span>
        <span class="text-gray-400 text-sm">{{ day.date }}</span>
      </div>

      <div v-if="getDateEvents(day.date).length < 1" class="text-center text-gray-600">
        Нет записей
      </div>

      <div v-else>
        <div v-for="(event, i) in getDateEvents(day.date)" :key="i" class="mt-2">
          <div class="flex space-x-2">
            <div class="rounded-full w-4 h-4" :style="{ background: event.color }"></div>
            <div class="flex flex-col">
              <span class="text-gray-400 text-sm font-semibold">{{ event.full_time }}</span>
              <span class="text-white text-sm">{{ event.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

var moment = require("moment");
moment.locale("ru");

export default {
  props: ["date"],
  computed: {
    ...mapGetters(["events"]),

    days() {
      let days = [];

      for (let i = 0; i < 5; i++) {
        let title;
        let date = moment(this.date).add(i, "days");

        if (date.format("DD.MM.YYYY") == moment().format("DD.MM.YYYY")) {
          switch (i) {
            case 0:
              title = "Сегодня";
              break;
            case 1:
              title = "Завтра";
              break;
            default:
              title = date.format("dddd");
          }
        } else {
          title = date.format("dddd");
        }

        days.push({ title, date: date.format("DD.MM.YYYY") });
      }

      return days;
    }
  },
  methods: {
    getDateEvents(date) {
      let d = moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");

      return this.events.filter(e => {
        return e.date == d;
      });
    }
  },
  data() {
    return {
      start_date: moment()
      // days: [
      //   {
      //     title: "Сегодня",
      //     date: "02.09.2021",
      //     events: [
      //       { time: "11:00 - 12:00", title: "Стрижка", color: "#0086FF", type: 1, id: 1 },
      //       { time: "12:00 - 13:00", title: "Стрижка", color: "#0086FF", type: 1, id: 1 }
      //     ]
      //   },
      //   { title: "Завтра", date: "03.09.2021", events: [] },
      //   { title: "Суббота", date: "04.09.2021", events: [] },
      //   { title: "Воскресенье", date: "05.09.2021", events: [] },
      //   { title: "Понедельник", date: "06.09.2021", events: [] }
      // ]
    };
  }
};
</script>

<style type="scss" scoped></style>
