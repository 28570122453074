<template>
  <div class="container content mx-auto text-left py-6 px-4 lg:px-0">
    <ConfirmDialog></ConfirmDialog>
    <h1 class="text-3xl font-semibold">Бронирования</h1>

    <div class="mt-6">
      <div
        class="text-center space-y-5"
        v-if="orders.length < 1"
      >
        <h2 class="text-2xl font-semibold">У вас пока нет бронирований</h2>
        <p>Перейдите в каталог чтобы выбрать услугу и оформить бронирование</p>
        <Button
          label="Перейти в каталог"
          @click="$router.push('/')"
        />
      </div>

      <div v-else>
        <TabView v-model:activeIndex="activeTab">
          <TabPanel header="Актуальные">
            <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8">
              <div
                v-for="order in actualOrders"
                :key="order.id"
              >
                <div class="shadow-lg rounded-lg border overflow-hidden">
                  <div class="relative">
                    <div
                      style="height: 200px"
                      class="overflow-hidden"
                      v-if="order.service.photo"
                    >
                      <img
                        :src="baseURL + order.service.photo"
                        class="object-cover object-center h-full w-full"
                      />
                    </div>
                    <div
                      style="height: 200px"
                      class="flex w-full bg-blue-50"
                      v-else
                    >
                      <i class="m-auto pi pi-camera text-blue-400 text-4xl"></i>
                    </div>
                    <div class="absolute bottom-2 right-2 left-2 text-right space-x-1">
                      <span
                        class="mt-1 font-semibold text-xs bg-accent text-white rounded-lg px-2 pt-2 pb-1.5 cursor-pointer"
                        @click="changeDate"
                        v-if="!order.is_cancelled"
                      >Перенести</span>
                      <OverlayPanel ref="op">
                        <div class="flex flex-col">
                          <Calendar
                            v-model="newDate"
                            :inline="true"
                          />
                          <div class="flex flex-row space-x-2 mt-2">
                            <Button
                              label="Подтвердить"
                              @click="performDateChange(order.id)"
                            />
                          </div>
                        </div>
                      </OverlayPanel>

                      <span
                        class="mt-1 font-semibold text-xs bg-red-600 text-white rounded-lg px-2 pt-2 pb-1.5 cursor-pointer"
                        v-if="!order.is_cancelled"
                        @click="cancelBooking(order.id)"
                      >Отменить</span>
                      <span
                        class="mt-1 font-semibold text-xs bg-red-600 text-white rounded-lg px-2 pt-2 pb-1.5"
                        v-if="order.is_cancelled"
                      >Заказ отменен</span>
                      <span class="mt-1 font-semibold text-xs bg-yellow-400 rounded-lg px-2 pt-2 pb-1.5"> {{ order.service.price }} руб </span>
                    </div>
                  </div>
                  <div class="p-4 flex flex-row justify-between">
                    <div class="flex flex-col">
                      <strong>{{ order.service.title }}</strong>
                      <i>{{ order.geo.place.title }}</i>
                      <span
                        v-if="order.geo.clean_address"
                        class="text-sm"
                      >{{ order.geo.clean_address }}</span>

                      <div class="mt-4 flex flex-col space-y-1">
                        <span
                          v-if="order.date && order.start_time"
                          class="text-sm font-medium"
                        >{{ order.date }} в {{ order.start_time.substr(0,5) }}</span>
                        <span
                          v-if="order.worker"
                          class="text-sm"
                        >Мастер: {{ order.worker.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="Архив">
            <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8">
              <div
                v-for="order in archivedOrders"
                :key="order.id"
              >
                <div class="shadow-lg rounded-lg border overflow-hidden">
                  <div class="relative">
                    <div
                      style="height: 200px"
                      class="overflow-hidden"
                      v-if="order.service.photo"
                    >
                      <img
                        :src="baseURL + order.service.photo"
                        class="object-cover object-center h-full w-full"
                      />
                    </div>
                    <div
                      style="height: 200px"
                      class="flex w-full bg-blue-50"
                      v-else
                    >
                      <i class="m-auto pi pi-camera text-blue-400 text-4xl"></i>
                    </div>
                    <div class="absolute bottom-2 right-2 left-2 text-right space-x-1">
                      <span
                        class="mt-1 font-semibold text-xs bg-accent text-white rounded-lg px-2 pt-2 pb-1.5"
                        @click="repeatOrderToggle"
                      >Повторить</span>
                      <OverlayPanel ref="rp">
                        <div class="flex flex-col">
                          <Calendar
                            v-model="newDate"
                            :inline="true"
                          />
                          <div class="flex flex-row space-x-2 mt-2">
                            <Button
                              label="Подтвердить"
                              @click="performOrderRepeat(order.id)"
                            />
                          </div>
                        </div>
                      </OverlayPanel>

                      <span
                        class="mt-1 font-semibold text-xs bg-red-600 text-white rounded-lg px-2 pt-2 pb-1.5"
                        v-if="order.is_cancelled"
                      >Заказ отменен</span>
                      <span class="mt-1 font-semibold text-xs bg-yellow-400 rounded-lg px-2 pt-2 pb-1.5"> {{ order.service.price }} руб </span>
                    </div>
                  </div>
                  <div class="p-4 flex flex-row justify-between">
                    <div class="flex flex-col">
                      <strong>{{ order.service.title }}</strong>
                      <i>{{ order.geo.place.title }}</i>
                      <span
                        v-if="order.geo.clean_address"
                        class="text-sm"
                      >{{ order.geo.clean_address }}</span>

                      <div class="mt-4 flex flex-col space-y-1">
                        <span
                          v-if="order.date && order.start_time"
                          class="text-sm font-medium"
                        >{{ order.date }} в {{ order.start_time.substr(0,5) }}</span>
                        <span
                          v-if="order.worker"
                          class="text-sm"
                        >Мастер: {{ order.worker.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>

      </div>
    </div>
  </div>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import OverlayPanel from "primevue/overlaypanel";
import Calendar from "primevue/calendar";
import moment from "moment";

export default {
  components: {
    TabView,
    TabPanel,
    Button,
    ConfirmDialog,
    OverlayPanel,
    Calendar,
  },
  data() {
    return {
      orders: [],
      newDate: new Date(),
      activeTab: 0,
    };
  },
  methods: {
    repeatOrderToggle(event) {
      this.$refs.rp.toggle(event);
    },
    performOrderRepeat(id) {
      let date = moment(this.newDate).format("DD MMM YYYY");

      this.$confirm.require({
        message: "Вы действительно хотите записаться на " + date + "?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http
            .post("repeat-booking", {
              date: moment(this.newDate).format("YY-MM-DD"),
              id,
            })
            .then(() => {
              // this.$refs.op.toggle();
              this.fetchOrders();
              this.activeTab = 0;
            });
        },
        reject: () => {},
      });
    },
    changeDate(event) {
      this.$refs.op.toggle(event);
    },
    performDateChange(id) {
      let date = moment(this.newDate).format("DD MMM YYYY");

      this.$confirm.require({
        message: "Вы действительно хотите перенести запись на " + date + "?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http
            .post("change-booking", {
              date: moment(this.newDate).format("YY-MM-DD"),
              id,
            })
            .then(() => {
              // this.$refs.op.toggle();
              this.fetchOrders();
            });
        },
        reject: () => {},
      });
    },
    cancelBooking(id) {
      this.$confirm.require({
        message: "Вы действительно хотите отменить запись?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http.post("cancel-booking/" + id).then(() => {
            this.fetchOrders();
          });
        },
        reject: () => {},
      });
    },
    fetchOrders() {
      this.$http.get("my-orders").then((response) => {
        if (response.data.orders) {
          let orders = [];

          if (!response.data.orders) {
            return false;
          }

          response.data.orders.forEach((order) => {
            orders.push(...order.services);
          });

          this.orders = orders;
        }
      });
    },
  },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(
        0,
        this.$http.defaults.baseURL.length - 3
      );
    },
    actualOrders() {
      if (!this.orders) {
        return null;
      }

      let orders = this.orders.filter((order) => {
        let date = moment();
        if (date.isSameOrBefore(order.date) && order.is_cancelled == 0) {
          return true;
        }

        return false;
      });

      return orders;
    },
    archivedOrders() {
      if (!this.orders) {
        return null;
      }

      let orders = this.orders.filter((order) => {
        let date = moment();
        if (date.isAfter(order.date) || order.is_cancelled == 1) {
          return true;
        }

        return false;
      });

      return orders;
    },
  },
  created() {
    this.fetchOrders();
  },
};
</script>
