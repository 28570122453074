<template>
  <div class="searchresults">
    <Header />
    <CategorySearchResultsComponent :type="type" :id="id" />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import CategorySearchResultsComponent from "@/components/Search/CategorySearchResultsComponent.vue";
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    CategorySearchResultsComponent,
    Header,
    Footer
  },
  props: ["type", "id"]
};
</script>
