<template>
  <div class="content">
    <div class="container mx-auto flex flex-row justify-between">
      <div class="flex items-center">
        <span class="text-4xl">Наращивание ногтей</span>
        <p class="pl-10">
          Более 300 вариантов услуг
          <a href="#">в Минске</a>
        </p>
      </div>
      <div class="flex flex-row items-center cursor-pointer" @click="showMap">
        <p class="text-right">Показать карту</p>
        <img class="object-none pl-3" src="/img/location.png" />
      </div>
    </div>

    <div class="my-5"></div>

    <div
      class="container mx-auto grid gap-10"
      :class="{ 'grid-cols-2': isMapDisplayed }"
    >
      <div
        class="grid gap-x-6 gap-y-12 text-left"
        :class="{
          'grid-cols-2': isMapDisplayed,
          'grid-cols-4': !isMapDisplayed
        }"
      >
        <ServiceCard :data="i" v-for="i in places" :key="i" />
      </div>

      <div v-show="isMapDisplayed" id="map" style="max-height: 1100px"></div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../../views/ui/ServiceCard";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  components: {
    ServiceCard
  },
  methods: {
    showMap() {
      this.isMapDisplayed = !this.isMapDisplayed;

      if (this.isMapInitialized) {
        return false;
      }

      this.$nextTick(() => {
        mapboxgl.accessToken =
          "pk.eyJ1IjoicmVzZXJ2YXBwIiwiYSI6ImNrbmZ4MnVkZDI1a3cydm1ydnZjNzd3ejQifQ.GitchBaltaP1uyfk5TrTtQ";

        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/reservapp/cknfyrj9k494y17r0elagsflz"
        });

        map.addControl(new mapboxgl.NavigationControl());

        map.on("load", () => {
          this.isMapInitialized = true;
        });
      });
    }
  },
  data() {
    return {
      isMapDisplayed: false,
      isMapInitialized: false,
      places: [
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 2
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 1
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 1
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 3
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 2
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 1
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 1
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 2
        }
      ]
    };
  }
};
</script>
