<template>
  <div>
    <Header />

    <div class="bg-gray-50">
      <div class="container mx-auto px-4 py-16 text-left relative">
        <div class="flex">
          <div>
            <h2 class="text-5xl font-semibold">
              Когда
              <span class="bg-blue-100 text-accent px-2 py-2">стремиться к идеалу</span> —<br />
              это твой профиль
            </h2>
            <p class="w-4/6 pt-6">
              Мы всегда рады профессионалам, которые тоже любят вызовы, стараются делать больше, чем от них ждут, и думают о других: от пользователей
              до коллег.
            </p>
          </div>
          <div>
            <img src="img/mediator.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container mx-auto px-4 py-16 text-left relative">
        <h2 class="text-4xl font-semibold">Вакансии</h2>

        <div
          class="flex flex-col space-y-16 mt-12"
          v-if="!isDetailsDisplayed"
        >
          <div class="flex flex-col">
            <strong>Торговый представитель / менеджер по продажам</strong>
            <span class="mt-2">от 1 600 бел. руб. на руки</span>
            <span class="mt-1">Требуемый опыт работы: 1–3 года</span>
            <span class="mt-1">Полная занятость, полный день</span>
            <u
              class="text-accent mt-4 cursor-pointer"
              @click="isDetailsDisplayed = true"
            >Подробнее</u>
            <div
              class="rounded-3xl cursor-pointer bg-accent text-white text-center py-2 mt-8"
              style="width:250px"
            >Откликнуться на вакансию</div>
          </div>

          <div class="flex flex-col">
            <strong>Торговый представитель / менеджер по продажам</strong>
            <span class="mt-2">от 1 600 бел. руб. на руки</span>
            <span class="mt-1">Требуемый опыт работы: 1–3 года</span>
            <span class="mt-1">Полная занятость, полный день</span>
            <u
              class="text-accent mt-4 cursor-pointer"
              @click="isDetailsDisplayed = true"
            >Подробнее</u>
            <div
              class="rounded-3xl cursor-pointer bg-accent text-white text-center py-2 mt-8"
              style="width:250px"
            >Откликнуться на вакансию</div>
          </div>

          <div class="flex flex-col">
            <strong>Торговый представитель / менеджер по продажам</strong>
            <span class="mt-2">от 1 600 бел. руб. на руки</span>
            <span class="mt-1">Требуемый опыт работы: 1–3 года</span>
            <span class="mt-1">Полная занятость, полный день</span>
            <u
              class="text-accent mt-4 cursor-pointer"
              @click="isDetailsDisplayed = true"
            >Подробнее</u>
            <div
              class="rounded-3xl cursor-pointer bg-accent text-white text-center py-2 mt-8"
              style="width:250px"
            >Откликнуться на вакансию</div>
          </div>
        </div>

        <div
          class="flex flex-col mt-12"
          v-if="isDetailsDisplayed"
        >
          <strong>Торговый представитель / менеджер по продажам</strong>
          <span class="mt-2">от 1 600 бел. руб. на руки</span>
          <span class="mt-1">Требуемый опыт работы: 1–3 года</span>
          <span class="mt-1">Полная занятость, полный день</span>

          <strong class="mt-8 mb-2">Условия:</strong>
          <p class="leading-8">
            Официальное трудоустройство<br />
            Высокая оплата труда (оклад + % от продаж)<br />
            100% оплата топлива<br />
            Дополнительная мотивация за выполненную цель<br />
            График работы с 9.00 до 18.00 / 10:00 до 19:00<br />
            Широкие возможности профессионального и карьерного роста<br />
          </p>

          <strong class="mt-8 mb-2">Требования к кандидату:</strong>
          <p class="leading-8">
            Опыт работы в продажах и заключение договоров не менее 1-го года<br />
            Наличие личного авто и в/у (разъездной характер работы)<br />
            Желание работать и развиваться<br />
            Выполнение индивидуального плана по продажам<br />
            Презентабельный внешний вид (в компании регламентируется стиль одежды)<br />
            Пунктуальность, ответственность, исполнительность<br />
            Грамотная речь<br />
            Настойчивость<br />
            Умение убеждать<br />
            Желание работать и зарабатывать<br />
            Высокий уровень коммуникации<br />
          </p>

          <strong class="mt-8 mb-2">Ключевые навыки:</strong>
          <p class="leading-8">
            Заключение договоров<br />
            Пользователь ПК<br />
            Водительское удостоверение категории B<br />
            Умение работать в команде<br />
            Навыки работы с возражениями<br />
            Навыки продаж<br />
            Обучение и развитие<br />
            Опыт продаж<br />
            Навыки переговоров<br />
            Личный автомобиль<br />
            Водительские права категории В<br />
            Ведение переговоров<br />
            Проведение переговоров<br />
          </p>

          <div class="flex space-x-4">
            <div
              class="rounded-3xl cursor-pointer border-2 border-accent text-center pt-2 pb-1.5 mt-8"
              style="width:250px"
              @click="isDetailsDisplayed = false"
            >
              Назад
            </div>
            <div
              class="rounded-3xl cursor-pointer bg-accent text-white text-center pt-2 pb-1.5 mt-8"
              style="width:250px"
            >
              Откликнуться на вакансию
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isDetailsDisplayed: false,
    };
  },
};
</script>
