<template>
  <div>
    <TabView>
      <TabPanel header="Бронирования и визиты">
        <div
          class="my-4 lg:flex space-x-2 hidden"
          v-if="1==2"
        >
          <Dropdown
            v-model="selectedPlace"
            :options="places"
            optionLabel="title"
            placeholder="Точка продаж"
          />
          <Dropdown
            v-model="selectedService"
            :options="services"
            optionLabel="title"
            placeholder="Услуга"
          />
          <Calendar
            v-model="selectedDates"
            dateFormat="dd.mm.yy"
            selectionMode="range"
            :manualInput="false"
            placeholder="Диапазон дат"
          />
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              type="text"
              v-model="selectedClient"
              placeholder="Имя клиента"
            />
          </span>
        </div>

        <div v-if="orders">

          <DataTable
            :value="orders"
            v-model:expandedRows="expandedRows"
            dataKey="id"
            responsiveLayout="scroll"
            sortField="created_at"
            :sortOrder="-1"
          >
            <Column
              :expander="true"
              headerStyle="width: 3rem"
            />
            <Column
              field="created_at"
              header="Дата заказа"
              sortable
            >
              <template #body="slotProps">
                {{moment(slotProps.data.created_at).format('DD.MM.Y в H:m')}}
              </template>
            </Column>
            <Column
              field="user.name"
              header="Клиент"
              sortable
            ></Column>
            <Column
              field="phone"
              header="Телефон"
              sortable
            >
            </Column>
            <template #expansion="slotProps">
              <div class="orders-subtable">
                <strong class="block pb-2">Услуги в заказе</strong>
                <DataTable
                  :value="slotProps.data.services"
                  responsiveLayout="scroll"
                >
                  <Column
                    field="service.title"
                    header="Наименование"
                    sortable
                  ></Column>
                  <Column
                    field="date"
                    header="Дата"
                    sortable
                  ></Column>
                  <Column
                    field="start_time"
                    header="Время начала"
                    sortable
                  >
                  </Column>
                  <Column
                    field="end_time"
                    header="Время завершения"
                    sortable
                  >
                  </Column>
                  <Column
                    field="service.price"
                    header="Стоимость"
                    sortable
                  >
                  </Column>
                </DataTable>
              </div>
              <div
                class="orders-subtable mt-8"
                v-if="slotProps.data.gifts.length"
              >
                <strong class="block pb-2">Услуги в подарок</strong>
                <DataTable
                  :value="slotProps.data.gifts"
                  responsiveLayout="scroll"
                >
                  <Column
                    field="gift.title"
                    header="Наименование"
                    sortable
                  ></Column>
                  <Column
                    field="gift.price"
                    header="Стоимость"
                    sortable
                  >
                  </Column>
                </DataTable>
              </div>
            </template>
          </DataTable>

        </div>
        <div
          v-else
          class="mx-auto text-center"
        >
          <h1 class="pt-4 font-semibold text-2xl">Ничего не найдено</h1>
          <p>Результатов по выбранным критериям нет</p>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import moment from "moment";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  components: {
    DataTable,
    Column,
    Dropdown,
    Calendar,
    InputText,
    TabView,
    TabPanel,
  },
  data() {
    return {
      couponsOrders: null,
      orders: null,
      selectedPlace: null,
      selectedService: null,
      selectedDates: null,
      selectedClient: null,
      expandedRows: [],
    };
  },
  mounted() {
    this.fetchOrdersList();
  },
  methods: {
    moment(x) {
      return moment(x);
    },
    startChat(target_id) {
      this.$emit("startChat", target_id);
    },
    fetchOrdersList() {
      this.$http.get("organization/orders").then((response) => {
        this.orders = response.data.orders;
      });
    },
  },
};
</script>
