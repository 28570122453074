<template>
  <div class="grid grid-cols-2 gap-3 gap-y-5">
    <div class="flex flex-col">
      <label class="text-sm text-gray-500">Тип заведения</label>
      <Dropdown
        id="place_type_id"
        v-model="formData.place_type_id"
        v-if="placeTypesList"
        :options="placeTypesList"
        optionLabel="name"
        placeholder="Выберите тип заведения"
      />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-gray-500">Кухня</label>
      <MultiSelect
        v-if="kitchenTypesList"
        v-model="formData.kitchen_type_id"
        :options="kitchenTypesList"
        optionLabel="name"
        placeholder="Выберите тип кухни"
        display="chip"
      />
    </div>

    <!-- <div class="flex flex-col">
      <label class="text-sm text-gray-500">Район</label>
      <Dropdown v-model="formData.district_id" v-if="districtsList" :options="districtsList" optionLabel="name" placeholder="Район" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-gray-500">Адрес</label>
      <InputText type="text" v-model="formData.address" placeholder="Адрес" />
    </div> -->

    <div class="flex flex-col">
      <label class="text-sm text-gray-500">Контакты</label>
      <Textarea v-model="formData.contacts" placeholder="Контакты" rows="5" cols="30" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-gray-500">Описание</label>
      <Textarea v-model="formData.description" placeholder="Описание" rows="5" cols="30" />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-gray-500">Удобства и опции</label>
      <MultiSelect
        v-if="featuresList"
        v-model="formData.features"
        :options="featuresList"
        optionLabel="name"
        placeholder="Удобства и опции"
        display="chip"
      />
    </div>

    <div class="flex flex-col">
      <label class="text-sm text-gray-500">Ценовая категория</label>
      <Dropdown v-model="formData.pricing" v-if="pricingList" :options="pricingList" optionLabel="name" placeholder="Ценовая категория" />
    </div>

    <div></div>

    <!-- <div class="flex flex-row-reverse">
      <Button class="p-button-success" @click="savePlace" label="Сохранить" />
      <Button
        v-if="!$props.data"
        class="p-button-text"
        @click="$emit('cancel')"
        label="Отмена"
      />
    </div> -->
  </div>
</template>

<script>
// import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
// import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";

export default {
  components: {
    // Button,
    Dropdown,
    Textarea,
    // InputText,
    MultiSelect
  },
  props: ["placeType", "data"],
  methods: {
    returnData() {
      this.formData.type = 1;
      this.formData.place_type = this.formData.place_type_id;

      this.$emit("success", { data: this.formData });
    },
    savePlace() {
      this.formData.type = 1;
      this.formData.place_type = this.formData.place_type_id;
      console.log(this.formData);
      this.$http
        .post("organization/place", this.formData)
        .then(response => {
          console.log(response.data);
          this.$emit("success");
        })
        .catch(e => {
          console.log(e.response.data);
        });
    }
  },
  created() {
    this.$http.get("lib/fk").then(response => {
      this.kitchenTypesList = response.data.library.map(i => {
        i.name = i.title;
        return i;
      });
    });
    this.$http.get("lib/fpt").then(response => {
      this.placeTypesList = response.data.library.map(i => {
        i.name = i.title;
        return i;
      });
    });
    this.$http.get("lib/fpf").then(response => {
      this.featuresList = response.data.library.map(i => {
        i.name = i.title;
        return i;
      });
    });
    this.$http.get("lib/districts").then(response => {
      this.districtsList = response.data.library.map(i => {
        i.name = i.title;
        return i;
      });
    });
  },
  mounted() {
    if (this.$props.data) {
      this.$http.get("organization/place/" + this.$props.data.category_id + "/" + this.$props.data.id).then(response => {
        let data = response.data.place;

        data.kitchen_type_id = data.kitchens.map(i => {
          i.name = i.title;
          return i;
        });

        data.place_type_id = data.place_type;
        data.place_type_id.name = data.place_type.title;
        data.district_id = data.district;

        if (data.pricing) {
          data.pricing = JSON.parse(data.pricing);
        }

        this.formData = data;
      });
    }
  },
  data() {
    return {
      formData: {
        place_type_id: null,
        kitchen_type_id: null
      },
      placeTypesList: [],
      kitchenTypesList: [],
      districtsList: [],
      featuresList: [],
      pricingList: [{ name: "Дёшево" }, { name: "Средне" }, { name: "Дорого" }]
    };
  }
};
</script>
