<template>
  <div class="searchresults">
    <Header />
    <TopSearchBar />
    <SearchResultsComponent />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SearchResultsComponent from '@/components/Search/SearchResultsComponent.vue'
import TopSearchBar from '@/views/ui/TopSearchBar.vue'
import Header from '@/views/ui/Header.vue'
import Footer from '@/views/ui/Footer.vue'

export default {
  components: {
    SearchResultsComponent,
    Header,
    TopSearchBar,
    Footer
  }
}
</script>
