<template>
  <div>
    <div class="relative">
      <div
        class="absolute w-full h-full z-50"
        v-if="isLoading"
      >
        <div
          class="absolute m-auto left-0 right-0 top-0 bottom-0"
          style="width: 100px; height: 100px"
        >
          <ProgressSpinner />
        </div>
      </div>

      <!-- Comments dialog -->
      <Dialog
        header="Комментарии"
        v-model:visible="displayComments"
        :modal="true"
      >
        <div class="flex flex-col space-y-3">
          <div
            class="border-b pb-3"
            :key="comment.id"
            v-for="comment in commentsData"
          >
            <div class="flex flex-row space-x-3">
              <div>
                <Avatar
                  :label="comment.user.name.substr(0, 1)"
                  size="large"
                  shape="circle"
                  v-if="!comment.user.avatar"
                />

                <div
                  class="rounded-full border w-12 h-12 overflow-hidden"
                  v-else
                >
                  <img
                    :src="baseURL + comment.user.avatar"
                    class="object-cover w-full h-full"
                  />
                </div>
              </div>

              <div class="flex flex-col">
                <div class="flex flex-row space-x-3 items-center">
                  <strong>{{ comment.user.name }}</strong>
                  <span class="text-sm">{{
                    moment(comment.created_at).format("DD.MM.Y в HH:mm")
                  }}</span>

                  <div>
                    <span class="text-sm font-medium"><i class="pi pi-star"></i> {{ comment.rate }}</span>
                  </div>
                </div>
                <span>{{ comment.comment }}</span>

                <div
                  v-if="comment.response"
                  class="flex flex-col mt-3"
                >
                  <strong>Ваш ответ:</strong>
                  <p class="text-sm">{{ comment.response.comment }}</p>

                  <div
                    class="rounded bg-red-600 text-white px-2 py-1 w-max text-xs font-semibold cursor-pointer mt-1"
                    @click="deleteCommentResponse(comment)"
                  >
                    Удалить мой ответ
                  </div>
                </div>

                <div
                  class="rounded bg-accent text-white px-2 py-1 w-min text-xs font-semibold cursor-pointer mt-1"
                  v-if="!comment.is_responding && !comment.response"
                  @click="comment.is_responding = true"
                >
                  Ответить
                </div>

                <div
                  class="flex flex-col"
                  v-if="comment.is_responding"
                >
                  <Textarea v-model="comment.response_text" />

                  <div class="flex flex-row space-x-2">
                    <div
                      class="rounded bg-red-600 text-white px-2 py-1 w-min text-xs font-semibold cursor-pointer mt-1"
                      @click="comment.is_responding = false"
                    >
                      Отмена
                    </div>
                    <div
                      class="rounded bg-green-600 text-white px-2 py-1 w-min text-xs font-semibold cursor-pointer mt-1"
                      @click="sendResponseToComment(comment)"
                    >
                      Отправить
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <!-- List all places -->
      <div
        class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8 filter transition duration-300 ease-linear"
        :class="{ blur: isLoading, 'blur-none': !isLoading }"
        v-if="!isEditorEnabled"
      >
        <div
          v-for="place in places"
          :key="place.id"
        >
          <div @click="loadPlaceDetails(place.id)" class="shadow-lg rounded-lg border overflow-hidden">
            <div class="relative">
              <div
                style="height: 200px"
                class="overflow-hidden"
                v-if="place.logo"
              >
                <img
                  :src="baseURL + place.logo.photo"
                  class="object-cover object-center h-full w-full"
                />
              </div>
              <div
                style="height: 200px"
                class="flex w-full bg-blue-50"
                v-else
              >
                <i class="m-auto pi pi-camera text-blue-400 text-4xl"></i>
              </div>
              <div class="absolute bottom-2 right-2 left-2 text-right">

              </div>
              <div class="absolute bottom-2 right-2 left-2">
                <div class="text-right flex flex-row space-x-2 justify-end">
                  <div class="mt-1 font-semibold text-sm bg-yellow-400 rounded-lg px-2 flex items-center space-x-1">
                    <i class="pi pi-eye"></i> <span class="pt-1">{{ place.views ?? '0' }}</span>
                  </div>
                  <div
                    class="mt-1 font-semibold text-sm bg-accent text-white rounded-lg px-2 flex items-center space-x-1 pb-0.5 cursor-pointer"
                    v-if="place.comments.length"
                    @click="
                      () => {
                        commentsData = place.comments;
                        displayComments = true;
                      }
                    "
                  >
                    <i class="pi pi-comment"></i>
                    <span class="pt-1.5">{{ place.comments.length }}</span>
                  </div>

                  <div
                    class="mt-1 font-semibold text-sm rounded-lg px-2 flex items-center space-x-1 pb-0.5"
                    :class="{
                      'bg-yellow-400': place.rate < 5 && place.rate > 3,
                      'bg-green-400 text-white': place.rate >= 5,
                      'bg-red-400 text-white': place.rate < 3,
                    }"
                    v-if="place.rate"
                  >
                    <i class="pi pi-star"></i>
                    <span class="pt-1.5">{{ place.rate.toFixed(1) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4 flex flex-row justify-between">
              <div class="flex flex-col">
                <strong>{{ place.title }}</strong>
                <i class="text-sm">{{ place.short_description }}</i>
              </div>
              <div v-if="1 == 0">
                <Button
                  icon="pi pi-bars"
                  class="p-button-rounded p-button-success"
                  @click="toggleListMenu($event, place.id)"
                  aria-haspopup="true"
                  aria-controls="overlay_tmenu"
                />
                <TieredMenu
                  id="overlay_tmenu"
                  ref="listItemMenu"
                  :model="listItemActions"
                  :popup="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="shadow-lg rounded-lg border overflow-hidden bg-blue-50 flex cursor-pointer hover:bg-blue-100"
          style="min-height: 270px"
          @click="
            () => {
              isEditorEnabled = true;
              resetEditForm();
            }
          "
        >
          <i class="pi pi-plus text-4xl m-auto text-blue-400"></i>
        </div>
      </div>

      <!-- Edit place -->
      <div v-if="isEditorEnabled && edit">
        <div class="grid grid-cols-5 border-b pb-8">
          <div class="col-span-2 flex flex-col">
            <strong class="text-lg">Основная информация</strong>
            <p class="text-sm text-gray-600 w-2/3">
              Информация, доступная всем пользователям до совершения заказа
            </p>
          </div>
          <div class="col-span-3">
            <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
              <div>
                <strong class="text-gray-600 font-medium text-sm">Категория</strong>

                <div class="flex flex-col">
                  <Dropdown
                    class="p-inputtext-sm"
                    v-model="edit.category_id"
                    :options="categories"
                    optionLabel="title"
                    optionValue="id"
                    placeholder="Выберите категорию"
                  />
                </div>
              </div>
              <div>
                <strong class="text-gray-600 font-medium text-sm">Название</strong>

                <div class="flex flex-col">
                  <InputText
                    v-model="edit.title"
                    aria-describedby="username2-help"
                    class="p-inputtext-sm"
                    :class="{ 'p-invalid': edit.errors.title }"
                  />
                  <small
                    v-if="edit.errors.title"
                    id="username2-help"
                    class="p-error"
                  >Username is not available.</small>
                </div>
              </div>
              <div>
                <div class="flex justify-between">
                  <strong class="text-gray-600 font-medium text-sm">Краткое описание</strong>
                  <span class="text-sm text-gray-400">3-5 слов</span>
                </div>

                <div class="flex flex-col">
                  <InputText
                    v-model="edit.short_description"
                    aria-describedby="username2-help"
                    class="p-inputtext-sm"
                    :class="{ 'p-invalid': edit.errors.short_description }"
                  />
                  <small
                    v-if="edit.errors.short_description"
                    id="username2-help"
                    class="p-error"
                  >Username is not available.</small>
                </div>
              </div>
              <div>
                <div class="flex justify-between">
                  <strong class="text-gray-600 font-medium text-sm">Полное описание</strong>
                  <span class="text-sm text-gray-400">не обязательно</span>
                </div>

                <div class="flex flex-col">
                  <Textarea
                    class="text-sm"
                    v-model="edit.description"
                    :autoResize="true"
                    rows="3"
                    cols="30"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 border-b py-8">
          <div class="col-span-2 flex flex-col">
            <strong class="text-lg">Адреса</strong>
            <p class="text-sm text-gray-600 w-2/3">
              Добавьте адреса вашего заведения. Введите адрес, нажмите на него в
              появившемся списке. Повторите, если у вас несколько адресов
            </p>
          </div>
          <div class="col-span-3">
            <div class="border rounded-lg shadow-lg p-4">
              <div class="flex flex-row w-full">
                <AutoComplete
                  :multiple="true"
                  v-model="edit.geo"
                  :suggestions="addressResults"
                  @complete="searchAddressInput($event)"
                  field="place_name"
                  type="text"
                  placeholder="Найти адрес"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 border-b py-8">
          <div class="col-span-2 flex flex-col">
            <strong class="text-lg">Социальные сети</strong>
            <p class="text-sm text-gray-600 w-2/3">
              Добавьте ссылки на свои страницы в социальных сетях. Вставьте
              ссылку в поле и нажмите Enter. Повторите, если ссылок несколько
            </p>
          </div>
          <div class="col-span-3">
            <div class="border rounded-lg shadow-lg p-4">
              <div class="flex flex-row w-full">
                <Chips
                  v-model="edit.social"
                  class="w-full"
                  placeholder="Введите ссылку"
                >
                  <template #chip="slotProps">
                    <div>
                      <div class="text-sm">
                        {{ socialType(slotProps.value)
                        }}<span class="font-semibold">{{
                          socialIDValue(slotProps.value)
                        }}</span>
                      </div>
                    </div>
                  </template>
                </Chips>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 border-b py-8">
          <div class="col-span-2 flex flex-col">
            <strong class="text-lg">Заглавное фото</strong>
            <p class="text-sm text-gray-600 w-2/3">
              Добавьте заглавное фото для карточки заведения. Существующее фото
              можно только обновить.
              <br />
              <br />
              <i class="text-sm">Это фото создаёт у пользователей первое впечатление. Выберите
                самое качественное и привлекательное фото</i>
            </p>
          </div>
          <div class="col-span-3">
            <div class="px-4">
              <div>
                <FileUpload
                  mode="basic"
                  ref="logo"
                  name="demo[]"
                />
              </div>
              <div class="grid autoGrid flex-wrap gap-4 existingPhotoContainer">
                <img
                  :src="baseURL + edit.logo.photo"
                  class="object-fit existingPhoto"
                  v-if="edit.logo"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 border-b py-8">
          <div class="col-span-2 flex flex-col">
            <strong class="text-lg">Фото в галерею</strong>
            <p class="text-sm text-gray-600 w-2/3">
              Добавьте как минимум 5 фото в галерею. Галерея доступна всем
              пользователям на странице заведения
            </p>
          </div>
          <div class="col-span-3">
            <div class="px-4">
              <div class="flex flex-col">
                <FileUpload
                  name="demo[]"
                  ref="gallery"
                  url="./upload"
                  :multiple="true"
                />
              </div>
              <div
                v-if="edit.gallery"
                class="pt-4 flex flex-col"
              >
                <strong>Загруженные фото</strong>
                <i>Удаление отменить нельзя</i>
                <div class="grid autoGrid flex-wrap gap-4 existingPhotoContainer">
                  <div
                    v-for="photo in edit.gallery"
                    :key="photo.id"
                    class="relative"
                  >
                    <div v-if="!photo.is_logo">
                      <img
                        :src="baseURL + photo.photo"
                        class="object-fit existingPhoto"
                      />
                      <button
                        class="p-button p-component p-button-icon-only"
                        type="button"
                        @click="deletePhoto(photo.id)"
                      >
                        <span class="pi pi-times p-button-icon"></span><span class="p-button-label">&nbsp;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 border-b py-8">
          <div class="col-span-2 flex flex-col">
            <strong class="text-lg">График работы</strong>
            <p class="text-sm text-gray-600 w-2/3">
              Укажите свой обычный график работы. Здесь не учитываются
              праздничные дни
            </p>
          </div>
          <div class="col-span-3">
            <div class="px-4 flex flex-col flex-wrap space-4 space-y-4">
              <div class="flex flex-row items-center space-x-4 border-b pb-4">
                <Checkbox
                  v-model="toggleAllDays"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 text-sm"
                  style="width: 130px"
                >
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="allDaysStart"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="allDaysEnd"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
              <div
                class="flex flex-row items-center space-x-4"
                :class="{ 'opacity-40': !edit.schedule.monday_open }"
              >
                <Checkbox
                  v-model="edit.schedule.monday_open"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 font-medium text-sm"
                  :class="{ 'text-gray-400': !edit.schedule.monday_open }"
                  style="width: 130px"
                >
                  Понедельник
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="edit.schedule.monday_start"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="edit.schedule.monday_end"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
              <div
                class="flex flex-row items-center space-x-4"
                :class="{ 'opacity-40': !edit.schedule.tuesday_open }"
              >
                <Checkbox
                  v-model="edit.schedule.tuesday_open"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 font-medium text-sm"
                  :class="{ 'text-gray-400': !edit.schedule.tuesday_open }"
                  style="width: 130px"
                >
                  Вторник
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="edit.schedule.tuesday_start"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="edit.schedule.tuesday_end"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
              <div
                class="flex flex-row items-center space-x-4"
                :class="{ 'opacity-40': !edit.schedule.wednesday_open }"
              >
                <Checkbox
                  v-model="edit.schedule.wednesday_open"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 font-medium text-sm"
                  :class="{ 'text-gray-400': !edit.schedule.wednesday_open }"
                  style="width: 130px"
                >
                  Среда
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="edit.schedule.wednesday_start"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="edit.schedule.wednesday_end"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
              <div
                class="flex flex-row items-center space-x-4"
                :class="{ 'opacity-40': !edit.schedule.thursday_open }"
              >
                <Checkbox
                  v-model="edit.schedule.thursday_open"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 font-medium text-sm"
                  :class="{ 'text-gray-400': !edit.schedule.thursday_open }"
                  style="width: 130px"
                >
                  Четверг
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="edit.schedule.thursday_start"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="edit.schedule.thursday_end"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
              <div
                class="flex flex-row items-center space-x-4"
                :class="{ 'opacity-40': !edit.schedule.friday_open }"
              >
                <Checkbox
                  v-model="edit.schedule.friday_open"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 font-medium text-sm"
                  :class="{ 'text-gray-400': !edit.schedule.friday_open }"
                  style="width: 130px"
                >
                  Пятница
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="edit.schedule.friday_start"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="edit.schedule.friday_end"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
              <div
                class="flex flex-row items-center space-x-4"
                :class="{ 'opacity-40': !edit.schedule.saturday_open }"
              >
                <Checkbox
                  v-model="edit.schedule.saturday_open"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 font-medium text-sm"
                  :class="{ 'text-gray-400': !edit.schedule.saturday_open }"
                  style="width: 130px"
                >
                  Суббота
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="edit.schedule.saturday_start"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="edit.schedule.saturday_end"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
              <div
                class="flex flex-row items-center space-x-4"
                :class="{ 'opacity-40': !edit.schedule.sunday_open }"
              >
                <Checkbox
                  v-model="edit.schedule.sunday_open"
                  :binary="true"
                  class="mb-0.5"
                />

                <span
                  class="col-span-2 pt-0.5 font-medium text-sm"
                  :class="{ 'text-gray-400': !edit.schedule.sunday_open }"
                  style="width: 130px"
                >
                  Воскресенье
                </span>

                <div class="flex space-x-2 items-center">
                  <Calendar
                    v-model="edit.schedule.sunday_start"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                  <span>-</span>
                  <Calendar
                    v-model="edit.schedule.sunday_end"
                    :showTime="true"
                    :timeOnly="true"
                    class="w-14"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-5 py-8">
          <div class="col-span-2 flex flex-col">
            <strong class="text-lg">Удобства</strong>
            <p class="text-sm text-gray-600 w-2/3">
              Выберите всё то, что относится к вашей точке продаж. Заполнение
              этой графы опционально
            </p>
          </div>
          <div class="col-span-3">
            <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
              <MultiSelect
                v-model="edit.features"
                :options="features"
                optionLabel="title"
                optionValue="id"
                placeholder="Выберите удобства"
                display="chip"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-row flex-row-reverse space-x-4 mb-16">
          <Button
            class="p-button-success ml-4"
            label="Сохранить"
            @click="store"
          />
          <Button
            class="p-button-secondary"
            label="Отменить"
            @click="isEditorEnabled = false"
          />
        </div>
      </div>
      <!-- End edit place -->
    </div>
  </div>
</template>

<script>
import TieredMenu from "primevue/tieredmenu";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import MultiSelect from "primevue/multiselect";
import Chips from "primevue/chips";
import AutoComplete from "primevue/autocomplete";
import moment from "moment";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import Avatar from "primevue/avatar";

// import mapboxgl from "mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";
//
// let center = [27.559025, 53.900562];
//
// var map, marker;

export default {
  components: {
    TieredMenu,
    Button,
    InputText,
    Textarea,
    Dropdown,
    FileUpload,
    Calendar,
    Checkbox,
    MultiSelect,
    Chips,
    AutoComplete,
    ProgressSpinner,
    Dialog,
    Avatar,
  },
  data() {
    return {
      isLoading: true,
      activePlaceId: null,
      displayComments: false,
      commentsData: null,
      isEditorEnabled: false,
      places: [],
      edit: {
        title: "",
        category_id: null,
        short_description: "",
        description: "",
        features: [],
        geo: null,
        schedule: {
          monday_open: true,
          monday_start: moment().hours(9).minutes(0).toDate(),
          monday_end: moment().hours(18).minutes(0).toDate(),
          tuesday_open: true,
          tuesday_start: moment().hours(9).minutes(0).toDate(),
          tuesday_end: moment().hours(18).minutes(0).toDate(),
          wednesday_open: true,
          wednesday_start: moment().hours(9).minutes(0).toDate(),
          wednesday_end: moment().hours(18).minutes(0).toDate(),
          thursday_open: true,
          thursday_start: moment().hours(9).minutes(0).toDate(),
          thursday_end: moment().hours(18).minutes(0).toDate(),
          friday_open: true,
          friday_start: moment().hours(9).minutes(0).toDate(),
          friday_end: moment().hours(18).minutes(0).toDate(),
          saturday_open: true,
          saturday_start: moment().hours(9).minutes(0).toDate(),
          saturday_end: moment().hours(18).minutes(0).toDate(),
          sunday_open: true,
          sunday_start: moment().hours(9).minutes(0).toDate(),
          sunday_end: moment().hours(18).minutes(0).toDate(),
        },
        errors: {
          title: false,
          short_description: false,
        },
      },
      temporaryFeature: null,
      features: null,
      toggleAllDays: true,
      allDaysStart: moment().hours(9).minutes(0).toDate(),
      allDaysEnd: moment().hours(18).minutes(0).toDate(),
      categories: [
        { id: 1, title: "Еда" },
        { id: 2, title: "Развлечения" },
        { id: 3, title: "Медицина" },
        { id: 4, title: "Красота" },
        { id: 5, title: "Автоуслуги" },
        { id: 6, title: "Аренда" },
        { id: 7, title: "Образование" },
      ],
      addressResults: [],
      listItemActions: [
        {
          label: "Редактировать",
          icon: "pi pi-pencil",
          command: () => {
            this.loadPlaceDetails();
          },
        },
        // {
        //   label: "Удалить",
        //   icon: "pi pi-trash",
        //   command: () => {
        //     this.$toast.add({
        //       severity: "error",
        //       summary: "Delete",
        //       detail: "Data Deleted",
        //     });
        //   },
        // },
      ],
    };
  },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(
        0,
        this.$http.defaults.baseURL.length - 3
      );
    },
  },
  mounted() {
    this.fetchPlaces();
    this.fetchFeatures();
  },
  watch: {
    toggleAllDays: function (v) {
      this.switchAllDays(v);
    },
    allDaysStart: function (v) {
      this.setAllDaysStart(v);
    },
    allDaysEnd: function (v) {
      this.setAllDaysEnd(v);
    },
  },
  methods: {
    moment(x) {
      return moment(x);
    },
    deleteCommentResponse(comment) {
      this.$http.delete("comment-response/" + comment.response.id).then(() => {
        delete comment.response;
      });
    },
    sendResponseToComment(comment) {
      this.$http
        .post("comment-response", {
          type: "place",
          text: comment.response_text,
          id: comment.id,
        })
        .then((response) => {
          comment.response = response.data.comment;
          comment.is_responding = false;
        });
    },
    store() {
      let self = this;

      this.$http
        .post("entity-place", {
          place_id: this.edit.id ? this.edit.id : null,
          category_id: this.edit.category_id,
          title: this.edit.title,
          short_description: this.edit.short_description,
          description: this.edit.description,
        })
        .then((response) => {
          if (typeof response.data.place !== "undefined") {
            let id = response.data.place.id;

            self.storeGeo(id);
            self.storeSchedule(id);
            self.storeSocials(id);
            self.storeFeatures(id);
            self.storePhotos(id);

            this.isEditorEnabled = false;
          }
        });
    },
    storePhotos(place_id) {
      let form = new FormData();

      form.append("place_id", place_id);

      if (this.$refs.gallery.files) {
        this.$refs.gallery.files.map((file) => {
          form.append("photo[]", file);
        });
      }

      if (this.$refs.logo) {
        this.$refs.logo.files.map((file) => {
          form.append("logo", file);
        });
      }

      this.$http
        .post("entity-place/photo", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.fetchPlaces();
        });
    },
    storeFeatures(place_id) {
      this.$http.post("entity-place/features", {
        place_id,
        features: this.edit.features,
      });
    },
    storeSocials(place_id) {
      if (!this.edit.social) {
        return false;
      }

      let socials = this.edit.social.map((s) => {
        let props = {};
        props.link = s;
        props.social = this.socialTechType(s);

        return props;
      });

      this.$http.post("entity-place/socials", {
        place_id,
        socials: JSON.stringify(socials),
      });
    },
    storeSchedule(place_id) {
      this.$http
        .post("entity-place/schedule", {
          place_id,
          monday_start: this.edit.schedule.monday_open
            ? this.edit.schedule.monday_start
            : null,
          monday_end: this.edit.schedule.monday_open
            ? this.edit.schedule.monday_end
            : null,
          tuesday_start: this.edit.schedule.tuesday_open
            ? this.edit.schedule.tuesday_start
            : null,
          tuesday_end: this.edit.schedule.tuesday_open
            ? this.edit.schedule.tuesday_end
            : null,
          wednesday_start: this.edit.schedule.wednesday_open
            ? this.edit.schedule.wednesday_start
            : null,
          wednesday_end: this.edit.schedule.wednesday_open
            ? this.edit.schedule.wednesday_end
            : null,
          thursday_start: this.edit.schedule.thursday_open
            ? this.edit.schedule.thursday_start
            : null,
          thursday_end: this.edit.schedule.thursday_open
            ? this.edit.schedule.thursday_end
            : null,
          friday_start: this.edit.schedule.friday_open
            ? this.edit.schedule.friday_start
            : null,
          friday_end: this.edit.schedule.friday_open
            ? this.edit.schedule.friday_end
            : null,
          saturday_start: this.edit.schedule.saturday_open
            ? this.edit.schedule.saturday_start
            : null,
          saturday_end: this.edit.schedule.saturday_open
            ? this.edit.schedule.saturday_end
            : null,
          sunday_start: this.edit.schedule.sunday_open
            ? this.edit.schedule.sunday_start
            : null,
          sunday_end: this.edit.schedule.sunday_open
            ? this.edit.schedule.sunday_end
            : null,
        })
        .then(() => {});
    },
    storeGeo(place_id) {
      if (!this.edit.geo) {
        return false;
      }

      let data = [];

      this.edit.geo.map((g) => {
        data.push({
          id: g.id ? g.id : null,
          address: g.place_name,
          district: g.context[1].text,
          lat: g.center[0],
          lng: g.center[1],
        });
      });

      this.$http.post("entity-place/geo", {
        place_id,
        geo: JSON.stringify(data),
      });
    },
    searchAddressInput(v) {
      this.searchAddress(v);
    },
    resetEditForm() {
      this.edit = {
        title: "",
        category_id: null,
        short_description: "",
        description: "",
        features: [],
        geo: null,
        schedule: {
          monday_open: true,
          monday_start: moment().hours(9).minutes(0).toDate(),
          monday_end: moment().hours(18).minutes(0).toDate(),
          tuesday_open: true,
          tuesday_start: moment().hours(9).minutes(0).toDate(),
          tuesday_end: moment().hours(18).minutes(0).toDate(),
          wednesday_open: true,
          wednesday_start: moment().hours(9).minutes(0).toDate(),
          wednesday_end: moment().hours(18).minutes(0).toDate(),
          thursday_open: true,
          thursday_start: moment().hours(9).minutes(0).toDate(),
          thursday_end: moment().hours(18).minutes(0).toDate(),
          friday_open: true,
          friday_start: moment().hours(9).minutes(0).toDate(),
          friday_end: moment().hours(18).minutes(0).toDate(),
          saturday_open: true,
          saturday_start: moment().hours(9).minutes(0).toDate(),
          saturday_end: moment().hours(18).minutes(0).toDate(),
          sunday_open: true,
          sunday_start: moment().hours(9).minutes(0).toDate(),
          sunday_end: moment().hours(18).minutes(0).toDate(),
        },
        errors: {
          title: false,
          short_description: false,
        },
      };
    },
    loadPlaceDetails(id) {
      this.$http.get("entity-place/" + id).then((response) => {
        let data = response.data.place;

        data.errors = {
          title: false,
          short_description: false,
        };

        if (data.social.length) {
          data.social = data.social.map((s) => {
            return s.link;
          });
        }

        if (data.features.length) {
          data.features = data.features.map((s) => {
            return s.feature_id;
          });
        }

        if (data.geo.length) {
          data.geo = data.geo.map((s) => {
            s.place_name = s.address;
            s.context = [{}, { text: s.district }];
            s.center = [s.lat, s.lng];

            return s;
          });
        }

        if (data.schedule) {
          if (data.schedule.monday_start && data.schedule.monday_end) {
            data.schedule.monday_start = moment(
              data.schedule.monday_start,
              "H:i"
            ).toDate();
            data.schedule.monday_end = moment(
              data.schedule.monday_end,
              "H:i"
            ).toDate();
            data.schedule.monday_open = true;
          } else {
            data.schedule.monday_open = false;
          }

          if (data.schedule.tuesday_start && data.schedule.tuesday_end) {
            data.schedule.tuesday_start = moment(
              data.schedule.tuesday_start,
              "H:i"
            ).toDate();
            data.schedule.tuesday_end = moment(
              data.schedule.tuesday_end,
              "H:i"
            ).toDate();
            data.schedule.tuesday_open = true;
          } else {
            data.schedule.tuesday_open = false;
          }

          if (data.schedule.wednesday_start && data.schedule.wednesday_end) {
            data.schedule.wednesday_start = moment(
              data.schedule.wednesday_start,
              "H:i"
            ).toDate();
            data.schedule.wednesday_end = moment(
              data.schedule.wednesday_end,
              "H:i"
            ).toDate();
            data.schedule.wednesday_open = true;
          } else {
            data.schedule.wednesday_open = false;
          }

          if (data.schedule.thursday_start && data.schedule.thursday_end) {
            data.schedule.thursday_start = moment(
              data.schedule.thursday_start,
              "H:i"
            ).toDate();
            data.schedule.thursday_end = moment(
              data.schedule.thursday_end,
              "H:i"
            ).toDate();
            data.schedule.thursday_open = true;
          } else {
            data.schedule.thursday_open = false;
          }

          if (data.schedule.friday_start && data.schedule.friday_end) {
            data.schedule.friday_start = moment(
              data.schedule.friday_start,
              "H:i"
            ).toDate();
            data.schedule.friday_end = moment(
              data.schedule.friday_end,
              "H:i"
            ).toDate();
            data.schedule.friday_open = true;
          } else {
            data.schedule.friday_open = false;
          }

          if (data.schedule.saturday_start && data.schedule.saturday_end) {
            data.schedule.saturday_start = moment(
              data.schedule.saturday_start,
              "H:i"
            ).toDate();
            data.schedule.saturday_end = moment(
              data.schedule.saturday_end,
              "H:i"
            ).toDate();
            data.schedule.saturday_open = true;
          } else {
            data.schedule.saturday_open = false;
          }

          if (data.schedule.sunday_start && data.schedule.sunday_end) {
            data.schedule.sunday_start = moment(
              data.schedule.sunday_start,
              "H:i"
            ).toDate();
            data.schedule.sunday_end = moment(
              data.schedule.sunday_end,
              "H:i"
            ).toDate();
            data.schedule.sunday_open = true;
          } else {
            data.schedule.sunday_open = false;
          }
        }

        this.edit = data;
        this.isEditorEnabled = true;
      });
    },
    socialIDValue(v) {
      let str = v.split("/");
      return str[str.length - 1];
    },
    socialTechType(v) {
      if (v.indexOf("vk.com") != -1) return "vk";
      if (v.indexOf("instagram.com") != -1) return "instagram";
      if (v.indexOf("meta.com") != -1) return "meta";
      if (v.indexOf("facebook.com") != -1) return "facebook";
      if (v.indexOf("twitter.com") != -1) return "twitter";
      if (v.indexOf("tiktok.com") != -1) return "tiktok";
      if (v.indexOf("ok.ru") != -1) return "ok";
      return "link";
    },
    socialType(v) {
      if (v.indexOf("vk.com") != -1) return "VK: ";
      if (v.indexOf("instagram.com") != -1) return "Instagram: ";
      if (v.indexOf("meta.com") != -1) return "Meta: ";
      if (v.indexOf("facebook.com") != -1) return "Facebook: ";
      if (v.indexOf("twitter.com") != -1) return "Twitter: ";
      if (v.indexOf("tiktok.com") != -1) return "TikTok: ";
      if (v.indexOf("ok.ru") != -1) return "OK: ";
      return "Ссылка: ";
    },
    switchAllDays(v) {
      if (v) {
        this.edit.schedule.monday_open = true;
        this.edit.schedule.tuesday_open = true;
        this.edit.schedule.wednesday_open = true;
        this.edit.schedule.thursday_open = true;
        this.edit.schedule.friday_open = true;
        this.edit.schedule.saturday_open = true;
        this.edit.schedule.sunday_open = true;
      } else {
        this.edit.schedule.monday_open = false;
        this.edit.schedule.tuesday_open = false;
        this.edit.schedule.wednesday_open = false;
        this.edit.schedule.thursday_open = false;
        this.edit.schedule.friday_open = false;
        this.edit.schedule.saturday_open = false;
        this.edit.schedule.sunday_open = false;
      }
    },
    setAllDaysStart(v) {
      this.edit.schedule.monday_start = v;
      this.edit.schedule.tuesday_start = v;
      this.edit.schedule.wednesday_start = v;
      this.edit.schedule.thursday_start = v;
      this.edit.schedule.friday_start = v;
      this.edit.schedule.saturday_start = v;
      this.edit.schedule.sunday_start = v;
    },
    setAllDaysEnd(v) {
      this.edit.schedule.monday_end = v;
      this.edit.schedule.tuesday_end = v;
      this.edit.schedule.wednesday_end = v;
      this.edit.schedule.thursday_end = v;
      this.edit.schedule.friday_end = v;
      this.edit.schedule.saturday_end = v;
      this.edit.schedule.sunday_end = v;
    },
    addFeatureToEditList(e) {
      this.edit.features.push(e.value);
    },
    fetchPlaces() {
      this.$http.get("/entity-place").then((response) => {
        this.places = response.data.places;
        this.activePlaceId = null;
        this.isLoading = false;
      });
    },
    fetchFeatures() {
      this.$http.get("/feature").then((response) => {
        this.features = response.data.features;
      });
    },
    getPlaceLogo(photos) {
      let logo = null;

      if (photos.length) {
        photos.map((photo) => {
          if (photo.is_logo) {
            logo = photo.photo;
          }
        });
      }

      return logo;
    },
    toggleListMenu(event, place_id) {
      this.activePlaceId = place_id;
      this.$refs.listItemMenu.toggle(event);
    },
    searchAddress(q) {
      let v = q.query;

      if (v.length < 4) {
        return false;
      }

      const mbtoken =
        "pk.eyJ1IjoicmVzZXJ2YXBwIiwiYSI6ImNrbmZ4MnVkZDI1a3cydm1ydnZjNzd3ejQifQ.GitchBaltaP1uyfk5TrTtQ";

      this.$http
        .get(
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            v +
            ".json?country=BY&language=ru&limit=10&types=address&access_token=" +
            mbtoken
        )
        .then((r) => {
          r.data.features.map((p) => {
            delete p.id;
            p.place_name = p.place_name.replace(", Белоруссия", "");
            return p;
          });

          this.addressResults = r.data.features;
        });
    },
    deletePhoto(id) {
      this.$http.delete("entity-place/photo/" + id).then(() => {
        this.edit.gallery = this.edit.gallery.filter((p) => {
          return p.id != id;
        });
      });
    },
  },
};
</script>

<style scoped>
.p-tabview .p-tabview-panels {
  padding: 0 !important;
}
#map {
  height: 400px;
}
:deep(.p-fileupload .p-fileupload-buttonbar) {
  border: 0;
  background: transparent;
  padding: 0;
}
:deep(.p-fileupload-content) {
  border: 0 !important;
}
:deep(.p-fileupload-row > div:nth-child(3)),
:deep(.p-fileupload-row > div:nth-child(2)) {
  display: none;
}
:deep(.p-progressbar),
:deep(.p-fileupload-buttonbar button) {
  display: none;
}
:deep(.p-fileupload-choose .p-button-label) {
  font-size: 0.85rem;
}
:deep(.p-fileupload-choose .pi.pi-plus),
:deep(.p-fileupload-choose .pi.pi-upload) {
  display: none;
}
:deep(.p-fileupload-content) {
  display: flex;
  padding: 0 !important;
}
:deep(.p-fileupload-row) {
  align-items: normal !important;
  position: relative;
}
:deep(.p-fileupload-files) {
  align-items: normal !important;
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  row-gap: 20px;
  padding-top: 1rem;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
:deep(.p-fileupload-row img) {
  object-fit: cover;
}
:deep(.p-fileupload-row img),
:deep(.p-fileupload-row > div) {
  width: 200px !important;
  height: 150px;
  padding: 0 !important;
}
:deep(.p-fileupload-row button) {
  background: #ccc;
  border: 0;
  border-radius: 128px;
  padding: 3px;
  width: 30px;
  height: 30px;
  position: relative;
  top: -15px;
  left: -15px;
}
:deep(.p-fileupload .p-fileupload-row > div:nth-child(4)) {
  width: 1px !important;
  position: absolute;
  left: 200px;
}
:deep(.p-fileupload .p-fileupload-row > div:nth-child(1)) {
  padding: 0;
}
:deep(.p-inplace-display) {
  background: #f5f5f5;
}
:deep(.p-calendar .p-inputtext) {
  background: #f5f5f5;
  border: 0;
  padding: 2px 6px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
:deep(.p-autocomplete-multiple-container),
:deep(.p-chips-multiple-container) {
  gap: 4px;
}
.existingPhotoContainer {
  position: relative;
}
.existingPhotoContainer button {
  background: #ccc;
  border: 0;
  border-radius: 128px;
  padding: 3px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  left: 185px;
}
.existingPhoto {
  width: 200px !important;
  height: 150px;
  padding: 0 !important;
}
.autoGrid {
  row-gap: 20px;
  padding-top: 1rem;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
:deep(.p-multiselect),
:deep(.p-chips-multiple-container),
:deep(.p-autocomplete-multiple-container) {
  border: 0;
}
</style>
