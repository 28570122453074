<template>
  <div class="flex -mt-20">
    <ManagerSideMenu />

    <div class="w-full text-left ml-0 lg:ml-280">
      <ManagerHeader title="Главная" />

      <div class="px-4 py-4 lg:px-12 lg:py-6">
        <ManagerMain />
      </div>
    </div>
  </div>
</template>

<script>
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import ManagerMain from "@/components/Manager/ManagerMain";

export default {
  components: { ManagerSideMenu, ManagerHeader, ManagerMain }
};
</script>
