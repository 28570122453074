<template>
  <div class="px-4 py-4 lg:px-12 lg:py-6 border-b border-gray-200 flex justify-between items-center">
    <h1 class="text-2xl lg:text-4xl font-semibold">{{ title }}</h1>

    <div class="text-right flex items-center space-x-4">
      <span class="lg:hidden" @click="$store.commit('isManagerMenuDisplayed', !$store.getters.isManagerMenuDisplayed)">
        <i
          class="pi pi-bars px-2 cursor-pointer"
        ></i>
      </span>

      <div
        class="flex items-center space-x-2 cursor-pointer"
        @click="toggleUserMenu"
      >
        <a href="#">{{ user.name }}</a>
        <div class="h-12 w-12 rounded-full bg-gray-400"></div>

        <Menu
          class="mt-3"
          ref="userMenu"
          :model="menu"
          v-if="menu.length > 0"
          :popup="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "primevue/menu";

export default {
  components: { Menu },
  props: ["title"],
  mounted() {
    let user = localStorage.getItem("user");

    if (user) {
      this.user = JSON.parse(user);
    }
  },
  data() {
    return {
      user: {
        name: "",
      },
      menu: [
        {
          label: "Аккаунт",
          items: [
            {
              label: "Выйти",
              icon: "pi pi-power-off",
              command: () => {
                this.signOut();
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleUserMenu(ev) {
      this.$refs.userMenu.toggle(ev);
    },
    signOut() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$refs.userMenu.hide();
      this.user = null;
      this.isUserAuthenticated = false;
      this.$http.defaults.headers.common["Authorization"] = null;
      this.$router.push("/");
    },
  },
};
</script>
