<template>
  <div>
    <div class="flex justify-between">
      <div class="flex flex-row items-center">
        <Avatar :label="content.user.name.substr(0, 1)" size="large" shape="circle" />

        <div class="flex flex-col pl-4 self-center items-start">
          <strong class="text-lg mt-2">
            {{ content.user.name }}
          </strong>

          <small class="text-gray-500">
            {{ moment(content.created_at).format("DD.MM.Y HH:mm") }}
          </small>
        </div>
      </div>

      <div class="flex flex-row items-center space-x-1">
        <img src="/img/star_1_med.png" v-for="star in rating" :key="star" class="object-none" />
      </div>
    </div>
    <p class="text-gray-500 text-sm pt-4 ml-16">
      {{ content.comment }}
    </p>
    <div class="text-gray-500 text-sm pt-4 ml-16" v-if="content.response">
      <strong>Ответ заведения:</strong>
      <p>{{ content.response.comment }}</p>
    </div>
  </div>
</template>

<script>
import Avatar from "primevue/avatar";

var moment = require("moment");

export default {
  props: ["content"],
  components: { Avatar },
  methods: {
    moment(v) {
      return new moment(v);
    }
  },
  computed: {
    rating() {
      let rate = [];
      let rating = this.$props.content.rate;

      if (rating > 5) {
        rating = 5;
      }
      if (rating < 1) {
        rating = 1;
      }

      for (let i = 0; i < rating; i++) {
        rate.push(1);
      }

      return rate;
    }
  }
};
</script>
