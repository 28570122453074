<template>
  <div class="flex -mt-20">
    <ManagerSideMenu />

    <div class="w-full text-left ml-0 lg:ml-280">
      <ManagerHeader title="Библиотека" />

      <div class="px-12 py-4 flex flex-col">
        <div
          class="flex"
          v-if="1==2"
        >
          <div class="rounded px-4 py-2 bg-accent hover:bg-accentDark cursor-pointer text-white text-center">
            Редактор категорий
          </div>
        </div>

        <TreeTable
          :value="nodes"
          class="p-treetable-sm"
          :filters="filters1"
          filterMode="lenient"
          v-if="nodes"
        >
          <template #header>
            <div class="flex flex-row space-x-3 w-full">
              <div class="p-text-right">
                <div class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    v-model="filters1['global']"
                    placeholder="Поиск"
                    size="50"
                  />
                </div>
              </div>
              <div class="flex items-center">
                <div
                  class="rounded px-4 bg-accent h-full flex items-center font-normal hover:bg-accentDark cursor-pointer text-white text-center"
                  @click="isCreatingParentCategory = true"
                >
                  <span>Создать категорию</span>
                </div>
              </div>
            </div>
          </template>
          <Column
            field="label"
            header="Имя категории"
            :expander="true"
            :sortable="true"
          ></Column>
          <Column bodyClass="text-right">
            <template #body="slotProps">
              <div class="flex justify-end">
                <Button
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-success"
                  @click="
                    () => {
                      isCreatingSubCategory = true;
                      editableCategory = slotProps.node;
                    }
                  "
                  style="margin-right: .5em"
                ></Button>

                <Button
                  type="button"
                  icon="pi pi-pencil"
                  class="p-button-warning"
                  @click="
                    () => {
                      isUpdatingCategory = true;
                      editableCategory = slotProps.node;
                    }
                  "
                  style="margin-right: .5em"
                ></Button>

                <Button
                  type="button"
                  icon="pi pi-times"
                  class="p-button-danger"
                  @click="deleteCategory(slotProps.node.id)"
                ></Button>
              </div>
            </template>
          </Column>
        </TreeTable>
      </div>

      <div
        class="px-4 py-4 lg:px-12 lg:py-6"
        v-if="libraries && !actingWithLibrary"
      >
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Группа
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Действия
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                      v-for="library in libraries"
                      :key="library.id"
                      @click="openLibrary(library)"
                      class="cursor-pointer"
                    >
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex">
                          <div class="text-sm font-medium text-gray-900">
                            {{ library.title }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a
                          href="#"
                          class="text-indigo-600 hover:text-indigo-900"
                        >Открыть</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="px-4 py-4 lg:px-12 lg:py-6"
        v-if="actingWithLibrary && libraryRecords"
      >
        <div class="flex pb-6 justify-between">
          <div class="flex space-x-6 items-center">
            <div
              class="flex space-x-2 cursor-pointer"
              @click="returnToList"
            >
              <img
                src="/img/arrow_back.png"
                class="object-none -mt-1"
              />
              <span class="text-accent">Назад</span>
            </div>

            <h2>{{ actingWithLibraryTitle }}</h2>
          </div>

          <div>
            <div
              class="rounded py-2 px-4 bg-accent text-white cursor-pointer"
              @click="openNewElementInput"
            >
              Добавить запись
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Значение
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Действия
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-show="isAddingNewElement">
                      <td
                        class="px-6 py-4 whitespace-nowrap"
                        colspan="2"
                      >
                        <div class="flex items-center space-x-6">
                          <input
                            ref="addNewElementField"
                            type="text"
                            v-model="newElementTitle"
                            v-on:keyup.enter="saveNewElement"
                            placeholder="Введите новый элемент"
                            class="w-full outline-none border-gray-200 border px-2 py-1"
                          />

                          <span
                            class="cursor-pointer text-indigo-600 text-sm hover:text-indigo-900"
                            @click="saveNewElement"
                          >
                            Добавить
                          </span>

                          <span
                            class="cursor-pointer text-indigo-600 text-sm hover:text-indigo-900"
                            @click="cancelAddingNewElement"
                          >
                            Отмена
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-for="record in libraryRecords"
                      :key="record.title"
                      class="cursor-pointer"
                    >
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex">
                          <div class="text-sm font-medium text-gray-900 w-full">
                            {{ record.title }}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <span
                          class="cursor-pointer text-indigo-600 text-sm hover:text-indigo-900"
                          @click="deleteLibraryElement(record.id)"
                        >
                          Удалить
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog v-model:visible="isCreatingParentCategory">
      <template #header>
        <h3>Создать родительскую категорию</h3>
      </template>

      <div class="w-full space-y-4">
        <label class="flex flex-col">
          <span>Название</span>
          <InputText v-model="newParentCategoryName" />
        </label>

        <label class="flex flex-col">
          <span>Раздел</span>
          <Dropdown
            v-model="selectedCategoryType"
            :options="categoryTypes"
            optionLabel="title"
            placeholder="Выберите раздел"
          />
        </label>
      </div>

      <template #footer>
        <Button
          label="Сохранить"
          @click="saveNewParentCategory"
          icon="pi pi-check"
          autofocus
        />
      </template>
    </Dialog>

    <Dialog v-model:visible="isUpdatingCategory">
      <template #header>
        <h3>Редактировать категорию</h3>
      </template>

      <div class="w-full space-y-4">
        <label class="flex flex-col">
          <span>Название</span>
          <InputText v-model="editableCategory.title" />
        </label>
      </div>

      <template #footer>
        <Button
          label="Сохранить"
          @click="updateCategory"
          icon="pi pi-check"
          autofocus
        />
      </template>
    </Dialog>

    <Dialog v-model:visible="isCreatingSubCategory">
      <template #header>
        <h3>Создать подкатегорию</h3>
      </template>

      <div class="w-full space-y-4">
        <label class="flex flex-col">
          <span>Название</span>
          <InputText v-model="newSubCategoryName" />
        </label>
      </div>

      <template #footer>
        <Button
          label="Сохранить"
          @click="createSubCategory"
          icon="pi pi-check"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import TreeTable from "primevue/treetable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";

export default {
  components: {
    ManagerSideMenu,
    ManagerHeader,
    TreeTable,
    Column,
    Button,
    InputText,
    Dialog,
    Dropdown,
  },
  mounted() {
    this.fetchLibrariesList();
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      this.$http.get("/categories").then((response) => {
        this.nodes = response.data.categories;
      });
    },
    fetchLibrariesList() {
      this.$http.get("/libs").then((response) => {
        this.libraries = response.data.libraries;
      });
    },
    openLibrary(library) {
      this.actingWithLibraryId = library.id;
      this.actingWithLibraryTitle = library.title;
      this.actingWithLibrary = true;
      this.loadLibraryRecords(library.id);
    },
    loadLibraryRecords(libraryId) {
      this.$http.get("/lib/" + libraryId).then((response) => {
        this.libraryRecords = response.data.library;
      });
    },
    cancelAddingNewElement() {
      this.isAddingNewElement = false;
      this.newElementTitle = "";
    },
    openNewElementInput() {
      this.isAddingNewElement = true;

      this.$nextTick(() => {
        this.$refs.addNewElementField.focus();
      });
    },
    saveNewElement() {
      this.$http
        .post("/lib", {
          type: this.actingWithLibraryId,
          title: this.newElementTitle,
        })
        .then(() => {
          this.cancelAddingNewElement();
          this.loadLibraryRecords(this.actingWithLibraryId);
        });
    },
    deleteLibraryElement(elementId) {
      this.$http
        .delete("/lib", {
          data: { type: this.actingWithLibraryId, id: elementId },
        })
        .then(() => {
          this.loadLibraryRecords(this.actingWithLibraryId);
        });
    },
    returnToList() {
      this.actingWithLibraryId = null;
      this.actingWithLibraryTitle = null;
      this.actingWithLibrary = false;
      this.libraryRecords = [];

      this.cancelAddingNewElement();
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;

        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    createSubCategory() {
      let name = this.newSubCategoryName;
      let parent_id = this.editableCategory.id;
      let type = this.editableCategory.primary_type;

      this.$http.post("/categories", { parent_id, name, type }).then(() => {
        this.fetchCategories();
        this.isCreatingSubCategory = false;
        this.newParentCategoryName = "";
        this.selectedCategoryType = null;
        this.editableCategory = null;
      });
    },
    saveNewParentCategory() {
      let name = this.newParentCategoryName;
      let type = this.selectedCategoryType.value;

      this.$http.post("/categories", { type, name }).then(() => {
        this.fetchCategories();
        this.isCreatingParentCategory = false;
        this.newParentCategoryName = "";
        this.selectedCategoryType = null;
        this.editableCategory = null;
      });
    },
    deleteCategory(id) {
      this.$http.delete("/category/" + id).then(() => {
        this.fetchCategories();
      });
    },
    updateCategory() {
      let name = this.editableCategory.title;
      let id = this.editableCategory.id;

      this.$http.put("/category/" + id, { name }).then(() => {
        this.fetchCategories();
        this.isUpdatingCategory = false;
        this.editableCategory = false;
      });
    },
  },
  data() {
    return {
      libraries: [],
      actingWithLibrary: false,
      actingWithLibraryId: null,
      actingWithLibraryTitle: null,
      libraryRecords: [],
      isAddingNewElement: false,
      newElementTitle: "",
      nodes: null,
      expandedKeys: {},
      categories: null,
      filters1: { name: "" },
      isUpdatingCategory: false,
      isCreatingParentCategory: false,
      isCreatingSubCategory: false,
      newSubCategoryName: "",
      newParentCategoryName: "",
      selectedCategoryType: null,
      editableCategory: null,
      categoryTypes: [
        { value: 1, title: "Еда и развлечения" },
        { value: 2, title: "Красота и здоровье" },
        { value: 3, title: "Аренда" },
        { value: 4, title: "Медицина" },
        { value: 5, title: "Автоуслуги" },
      ],
    };
  },
};
</script>
