<template>
  <div>
    <Header />

    <div class="container mx-auto">
      Test
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
