<template>
  <div class="flex -mt-20">
    <ManagerSideMenu />

    <div class="w-full text-left ml-0 lg:ml-280">
      <ManagerHeader title="Точки продаж" />

      <div class="px-4 py-4 lg:px-12 lg:py-6">
        <ManagerPlaceList />
      </div>
    </div>
  </div>
</template>

<script>
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import ManagerPlaceList from "@/components/Manager/ManagerPlaceList";

export default {
  components: {
    ManagerSideMenu,
    ManagerHeader,
    ManagerPlaceList
  },
  computed: {
    isViewingPlacesList() {
      if (!this.isCreatingNewPlace && !this.isEditingPlaceDetails) {
        return true;
      }

      return false;
    }
  },
  methods: {
    showCreatePlaceForm() {
      this.isCreatingNewPlace = true;
    },
    showPlaceEditingForm() {
      this.isEditingPlaceDetails = true;
    },
    returnToList() {
      this.isEditingPlaceDetails = false;
      this.isCreatingNewPlace = false;
    }
  },
  data() {
    return {
      isCreatingNewPlace: false,
      isEditingPlaceDetails: false
    };
  }
};
</script>
