<template>
  <div>
    <div class="flex justify-between">
      <div class="ml-8">
        <Dropdown
          placeholder="Работник"
          v-model="workerOrdering"
          :options="workers"
          optionLabel="name"
        />
      </div>

      <div class="flex items-center justify-center mr-8">
        <img
          class="cursor-pointer"
          src="/img/icon_left.png"
          @click="moveBack"
        />
        <span
          class="today-btn text-gray-900 text-sm bg-gray-100"
          @click="moveToday"
        >
          Сегодня
        </span>
        <img
          class="cursor-pointer"
          src="/img/icon_right.png"
          @click="moveForward"
        />
      </div>

      <div></div>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        class="grid grid-cols-7 mx-8 mt-4"
        v-if="dateList"
      >
        <div
          v-for="(date, k) in dateList"
          :key="k"
        >
          <div
            class="date-heading p-2 border-b"
            :class="{ 'border-r': k < 6, 'day-cell-dark': date.weekday == 6 || date.weekday == 0, 'day-cell-blue': date.isToday }"
          >
            <p class="uppercase text-xs">
              {{ date.weekday_name }}
            </p>
            <p class="text-2xl font-semibold">
              {{ date.month_day }}
            </p>
          </div>

          <div
            class="h-16 border-b relative flex day-cell"
            :class="{ 'border-r': k < 6, 'day-cell-dark': date.weekday == 6 || date.weekday == 0, 'day-cell-blue': date.isToday }"
            v-for="(hour, x) in hours"
            :key="x"
          >
            <div
              class="absolute text-sm text-gray-500"
              v-if="k == 0 || k == 6"
              :class="{ '-left-14': k == 0, 'text-right -right-14': k == 6 }"
            >
              {{ hour.title }}
            </div>

            <div
              v-if="events"
              class="flex-grow flex-shrink flex overflow-hidden"
            >
              <div
                class="event py-1 flex-grow flex-shrink truncate hover-focus"
                v-for="(event, ek) in getDateEvents(date, hour)"
                :key="ek"
                :class="{ 'event-blocked': event.isBlocked }"
                @click="showBaseEventEditor(event)"
              >
                <p class="text-xs">{{ event.full_time }}</p>
                <p
                  class="text-sm font-semibold"
                  v-if="!event.isBlocked"
                >{{ event.title }}</p>
                <p
                  class="text-xs"
                  v-if="event.worker"
                >{{ event.worker.name }}</p>
                <p
                  class="text-sm font-semibold"
                  v-else
                >Недоступно</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <Dialog
      header="Редактировать запись"
      v-model:visible="isEditing"
    >
      <div class="flex flex-col items-center">
        <date-picker
          v-model="editable.date"
          mode="date"
        />

        <div class="flex space-x-6">
          <div class="p-field flex flex-col">
            <label for="username1">Начало</label>
            <date-picker
              v-model="editable.start_time"
              is24hr
              mode="time"
              is-expanded
              :minute-increment="5"
            />
          </div>
          <div class="p-field flex flex-col">
            <label for="username1">Конец</label>
            <date-picker
              v-model="editable.end_time"
              is24hr
              mode="time"
              is-expanded
              :minute-increment="5"
            />
          </div>
        </div>

        <div class="flex flex-col w-full space-y-4 mt-4">
          <div class="p-field w-full">
            <label for="username1">Услуга (нельзя сменить)</label>
            <InputText
              class="w-full"
              v-model="editable.service.title"
              :disabled="true"
            />
          </div>
          <div class="p-field w-full">
            <label for="username1">Работник (нельзя сменить)</label>
            <InputText
              class="w-full"
              v-model="editable.worker.name"
              :disabled="true"
              placeholder="Выберите работника"
            />
          </div>
        </div>

        <div class="flex w-full mt-4">
          <Button
            label="Сохранить"
            :disabled="!editable.date || !editable.start_time || !editable.end_time ? 'disabled' : false"
            class="p-button-success"
            icon="pi pi-check"
            iconPos="right"
            @click="updateBooking"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DatePicker } from "v-calendar";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

var moment = require("moment");
moment.locale("ru");

export default {
  components: { DatePicker, Dialog, Button, InputText, Dropdown },
  computed: {
    ...mapGetters(["events"]),

    dateList() {
      let dateList = [];

      for (let i = 0; i < 7; i++) {
        let date = moment(this.date).add(i, "days");

        dateList.push({
          isToday: date.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"),
          date: date.toDate(),
          month_day: date.format("DD"),
          weekday_name: date.format("dddd"),
          weekday: date.format("d"),
          ymd: date.format("YYYY-MM-DD"),
        });
      }

      return dateList;
    },
  },
  mounted() {
    this.$http.get("organization/workers").then((r) => {
      this.workers = r.data.workers;
    });
  },
  methods: {
    updateBooking() {
      this.$http
        .put("organization/schedule/" + this.editable.obj.id, {
          date: this.editable.date,
          start_time: this.editable.start_time,
          end_time: this.editable.end_time,
        })
        .then(() => {
          this.$store.dispatch("fetchEvents");
          this.isEditing = false;
          this.editable = null;
        });
    },
    showBaseEventEditor(e) {
      this.isEditing = true;

      let time = e.full_time.split(" - ");
      e.start_time = moment(time[0], "H:mi").toDate();
      e.end_time = moment(time[1], "H:m").toDate();

      this.editable = e;
    },
    getDateEvents(date, hour) {
      return this.events.filter((e) => {
        if (this.workerOrdering) {
          if (e.worker && e.worker.id == this.workerOrdering.id) {
            return e.date == date.ymd && e.time == hour.time;
          }

          return false;
        }

        return e.date == date.ymd && e.time == hour.time;
      });
    },
    moveBack() {
      this.move(moment(this.date).subtract(7, "days"));
    },
    moveForward() {
      this.move(moment(this.date).add(7, "days"));
    },
    moveToday() {
      this.move(moment());
    },
    move(date) {
      this.date = date;
    },
  },
  data() {
    return {
      date: moment(),
      workers: [],
      workerOrdering: null,
      isEditing: false,
      editable: {
        service: null,
        worker: null,
      },
      hours: [
        { title: "06:00", time: "6" },
        { title: "07:00", time: "7" },
        { title: "08:00", time: "8" },
        { title: "09:00", time: "9" },
        { title: "10:00", time: "10" },
        { title: "11:00", time: "11" },
        { title: "12:00", time: "12" },
        { title: "13:00", time: "13" },
        { title: "14:00", time: "14" },
        { title: "15:00", time: "15" },
        { title: "16:00", time: "16" },
        { title: "17:00", time: "17" },
        { title: "18:00", time: "18" },
        { title: "19:00", time: "19" },
        { title: "20:00", time: "20" },
        { title: "21:00", time: "21" },
        { title: "22:00", time: "22" },
        { title: "23:00", time: "23" },
      ],
    };
  },
};
</script>

<style type="scss" scoped>
.day-cell-dark {
  background: #fafafa;
}
.day-cell-blue {
  background: #eff6ff !important;
}
.event {
  background: #e6f6fd;
  border-left: 4px solid rgba(14, 165, 233, 1);
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  color: rgba(3, 105, 161, 1);
  height: 100%;
}
.today-btn {
  height: 28px;
  line-height: 28px;
  padding: 0 12px;
  margin: 0 2px;
  cursor: pointer;
  display: block;
}
.hover-focus:hover {
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 1000;
}
.event-blocked {
  background: #ffd9d9;
  color: #a10303;
  border-color: #e90e0e;
}
:not(.def) .vc-container.vc-is-dark,
:not(.def) .vc-container {
  background: transparent;
  border: 0;
}
::v-deep .vc-weeks {
  margin-top: 24px;
}
::v-deep .vc-title {
  font-size: 22px;
  text-transform: capitalize;
}
::v-deep .vc-is-dark .vc-header,
::v-deep .vc-weeks,
::v-deep .vc-arrows-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
::v-deep .vc-time-date {
  display: none;
}
::v-deep .vc-container {
  border-radius: 3px;
}
::v-deep .vc-time-picker {
  height: 40px;
}
</style>
