<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
    <div class="shadow-lg rounded p-5 col-span-2">
      <h2 class="font-semibold text-lg">Последние продажи</h2>
      <small class="text-gray-400">Последние 7 дней</small>
      <p class="font-semibold text-2xl py-4">{{ total }} BYN</p>
      <Chart
        ref="chart"
        type="line"
        :data="basicData"
        :options="null"
      />
    </div>

    <div class="shadow-lg rounded p-5">
      <h2 class="font-semibold text-lg">Заказы на сегодня</h2>
      <small class="text-gray-400">Следующие 5 визитов</small>
      <div v-if="ordersForToday.length">
        <div
          class="flex flex-row space-x-4 pb-2 mt-4 border-b"
          v-for="i in ordersForToday"
          :key="i.id"
        >
          <div class="font-semibold">
            {{ moment().format("DD.MM") }}
          </div>
          <div class="w-full flex flex-row justify-between">
            <div class="flex flex-col">
              <div>
                {{ i.time }}
              </div>
              <div>
                {{ i.service.title }}
              </div>
              <div
                class="text-gray-400"
                v-if="1 == 2"
              >
                Без предварительной записи
              </div>
            </div>
            <div class="font-semibold text-lg">{{ i.service.price }} BYN</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="font-semibold pt-4"
      >
        На сегодня новых визитов нет
      </div>
    </div>

    <div class="shadow-lg rounded p-5">
      <h2 class="font-semibold text-lg">Популярность услуг</h2>
      <table class="table-auto w-full">
        <thead>
          <tr class="text-black border-b h-12">
            <td>Услуга</td>
            <td>Заказы</td>
          </tr>
        </thead>
        <tbody>
          <tr
            class="h-12 text-sm text-gray-600"
            v-for="(i) in popularServices"
            :key="i"
          >
            <td>{{ i.title }}</td>
            <td>{{ i.count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Chart from "primevue/chart";
import moment from "moment";

export default {
  components: { Chart },
  data() {
    return {
      total: 0,
      statFor7DaysRaw: [],
      popularServicesRaw: [],
      ordersForToday: [],
      basicData: {
        labels: [
          moment().subtract(6, "days").format("DD.MM"),
          moment().subtract(5, "days").format("DD.MM"),
          moment().subtract(4, "days").format("DD.MM"),
          moment().subtract(3, "days").format("DD.MM"),
          moment().subtract(2, "days").format("DD.MM"),
          "Вчера",
          "Сегодня",
        ],
        datasets: [
          {
            label: "Сумма продаж",
            data: null,
            fill: true,
            borderColor: "#42A5F5",
          },
        ],
      },
    };
  },
  created() {
    this.fetchStats();
  },
  computed: {
    popularServices() {
      return this.popularServicesRaw;
    },
  },
  methods: {
    moment() {
      return moment();
    },
    setStatFor7Days() {
      let calendar = [
        moment().subtract(6, "days").format("Y-M-D"),
        moment().subtract(5, "days").format("Y-M-D"),
        moment().subtract(4, "days").format("Y-M-D"),
        moment().subtract(3, "days").format("Y-M-D"),
        moment().subtract(2, "days").format("Y-M-D"),
        moment().subtract(1, "days").format("Y-M-D"),
        moment().format("Y-M-D"),
      ];
      let sums = [0, 0, 0, 0, 0, 0, 0];
      let total = 0;

      if (this.statFor7DaysRaw) {
        Object.keys(this.statFor7DaysRaw).map((i) => {
          let sum = 0;

          this.statFor7DaysRaw[i].forEach((x) => {
            sum += x.service.price;
          });

          calendar.forEach((v, k) => {
            if (moment(v).isSame(moment(i))) {
              sums[k] = sum;
            }
          });

          total += sum;
        });
      }

      this.total = total;
      return sums;
      // return this.statFor7DaysRaw;
    },
    fetchStats() {
      this.$http.get("organization/stat").then((response) => {
        this.ordersForToday = response.data.today;
        this.popularServicesRaw = response.data.grouppedServices;
        this.statFor7DaysRaw = response.data.last7Days;

        this.basicData.datasets[0].data = this.setStatFor7Days();
        this.$refs.chart.refresh();
      });
    },
  },
};
</script>
