<template>
  <div class="flex -mt-20">
    <ManagerSideMenu ref="sm" />

    <div class="w-full text-left ml-0 lg:ml-280" v-if="!isViewingClientDetails">
      <ManagerHeader title="Клиенты" />

      <div class="px-4 py-4 lg:px-12 lg:py-6">
        <div class="flex flex-row justify-between mb-4">
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText type="text" v-model="selectedClient" placeholder="Имя клиента" />
            </span>
          </div>
          <div>
            <Button label="Добавить клиента" @click="isCreatingNewClient = true" />
          </div>
        </div>

        <h2 class="text-lg font-semibold pb-2">Клиенты из заказов</h2>
        <DataTable :value="clients" class="p-0">
          <Column field="name" header="Имя">
            <template #body="slotProps">
              <Avatar shape="circle" :label="slotProps.data.name.substr(0, 1)" />
              <strong class="text-blue-500 cursor-pointer pl-2" @click="showClientDetails(slotProps.data)">{{ slotProps.data.name }}</strong>
              <i class="pi pi-comment cursor-pointer ml-2" @click="startChat(slotProps.data.id)"></i>
            </template>
          </Column>
          <Column field="email" header="Электронная почта"></Column>
          <Column field="phone" header="Номер телефона"></Column>
          <Column field="gender" header="Пол"></Column>
        </DataTable>

        <h2 class="text-lg font-semibold pb-2 mt-8">Клиенты, добавленные вручную</h2>
        <DataTable :value="customClients" class="p-0">
          <Column field="first_name" header="Имя">
            <template #body="slotProps">
              <Avatar shape="circle" :label="slotProps.data.first_name.substr(0, 1)" />
              <strong class="pl-2">{{ slotProps.data.first_name }}</strong>
            </template>
          </Column>
          <Column field="last_name" header="Фамилия"></Column>
          <Column field="email" header="Почта"></Column>
          <Column field="phone" header="Телефон"></Column>
          <Column field="phone_secondary" header="Телефон"></Column>
          <Column field="gender" header="Пол"></Column>
          <Column field="note" header="Примечания"></Column>
        </DataTable>
      </div>
    </div>

    <div class="w-full text-left" style="margin-left:280px;" v-else>
      <ManagerHeader :title="selectedClient.name" />

      <div class="px-12 py-6">
        <div class="flex flex-row justify-between mb-4">
          <div>
            <Button
              @click="
                isViewingClientDetails = false;
                selectedClient = null;
              "
              label="Назад"
              class="p-button-secondary p-button-text"
            />
          </div>
        </div>

        <div class="grid grid-cols-5 gap-4">
          <div class="col-span-2">
            <div class="p-8 shadow-lg items-center flex flex-col space-y-2">
              <Avatar shape="circle" :label="selectedClient.name.substr(0, 1)" size="xlarge" />

              <div class="font-semibold text-xl pb-2">
                {{ selectedClient.name }}
              </div>

              <div class="flex flex-row space-x-2">
                <Button
                  icon="pi pi-plus"
                  label="Заказ"
                  @click="$router.push({ path: '/manager/calendar', params: { user: selectedClient.id } })"
                  class="p-button-sm p-button-outlined p-button-secondary"
                />
                <Button
                  icon="pi pi-envelope"
                  label="Сообщение"
                  @click="startChat(selectedClient.id)"
                  class="p-button-sm p-button-outlined p-button-secondary"
                />
              </div>

              <span class="flex flex-col text-left w-full pt-8">
                <label class="text-sm text-gray-400">Эл. почта</label>
                <span>{{ selectedClient.email }}</span>
              </span>

              <span class="flex flex-col text-left w-full">
                <label class="text-sm text-gray-400">Пол</label>
                <span>{{ selectedClient.gender ? selectedClient.gender : "Не указан" }}</span>
              </span>
            </div>
          </div>
          <div class="col-span-3">
            <div class="p-8 shadow-lg">
              <div class="text-center">
                <span class="font-semibold">0 BYN</span>
                <p class="text-sm text-gray-600">Сумма всех успешно закрытых бронирований</p>
              </div>
              <div class="grid grid-cols-4 gap-2 text-center mt-6">
                <div>
                  <span class="font-semibold">3</span>
                  <p class="text-sm text-gray-600">Бронирования</p>
                </div>
                <div>
                  <span class="font-semibold">0</span>
                  <p class="text-sm text-gray-600">Выполнено</p>
                </div>
                <div>
                  <span class="font-semibold">2</span>
                  <p class="text-sm text-gray-600">Отменено</p>
                </div>
                <div>
                  <span class="font-semibold">1</span>
                  <p class="text-sm text-gray-600">Неявка</p>
                </div>
              </div>
            </div>

            <div class="p-8 shadow-lg mt-8">
              <TabView>
                <TabPanel header="Визиты">
                  <div class="flex flex-row space-x-4 pb-2 mt-4 border-b" v-for="i in [1, 2, 3, 4, 5]" :key="i">
                    <div class="font-semibold">
                      {{ moment().format("DD.MM") }}
                    </div>
                    <div class="w-full flex flex-row justify-between">
                      <div class="flex flex-col">
                        <div>
                          14:30
                        </div>
                        <div>
                          Маникюр
                        </div>
                        <div class="text-gray-400">
                          Без предварительной записи
                        </div>
                      </div>
                      <div class="font-semibold text-lg">
                        40 BYN
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="Заказы">
                  <div class="mt-4 text-center">
                    <h1 class="font-semibold text-xl">Заказов нет</h1>
                    <p class="text-gray-500 text-sm">Клиент пока не совершил ни одного заказа. Вы можете добавить заказ вручную</p>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Sidebar v-model:visible="isCreatingNewClient" position="full">
      <div class="container mx-auto">
        <ScrollPanel style="width: 100%; height: 98vh">
          <div class="grid grid-cols-2 gap-8">
            <div>
              <h1 class="font-semibold text-2xl">Информация о клиенте</h1>

              <div class="flex flex-col space-y-8 mt-4">
                <div class="flex flex-row space-x-8">
                  <div class="p-field flex flex-col w-full">
                    <label>Имя</label>
                    <InputText v-model="first_name" type="text" />
                  </div>
                  <div class="p-field flex flex-col w-full">
                    <label>Фамилия</label>
                    <InputText v-model="last_name" type="text" />
                  </div>
                </div>

                <div class="flex flex-row space-x-8">
                  <div class="p-field flex flex-col w-full">
                    <label>Мобильный телефон</label>
                    <InputMask v-model="phone" mask="+375 (99) 999-99-99" />
                  </div>
                  <div class="p-field flex flex-col w-full">
                    <label>Телефон</label>
                    <InputMask v-model="phone_secondary" mask="+375 (99) 999-99-99" />
                  </div>
                </div>

                <div class="p-field flex flex-col w-full">
                  <label>Электронная почта</label>
                  <InputText v-model="email" type="text" />
                </div>
              </div>
            </div>

            <div>
              <h1 class="font-semibold text-2xl">Дополнительная информация</h1>

              <div class="flex flex-col space-y-8 mt-4">
                <div class="flex flex-row space-x-8">
                  <div class="p-field flex flex-col w-full">
                    <label>Пол</label>
                    <Dropdown v-model="gender" :options="[{ title: 'Мужской' }, { title: 'Женский' }]" optionLabel="title" />
                  </div>
                  <div class="p-field flex flex-col w-full">
                    <label>Дата рождения</label>
                    <InputText v-model="birth_date" type="text" />
                  </div>
                </div>

                <div class="p-field flex flex-col w-full">
                  <label>Примечания (видны только вам)</label>
                  <InputText v-model="note" type="text" />
                </div>

                <div class="p-field flex flex-col w-full place-items-end">
                  <label>&nbsp;</label>
                  <Button @click="createClient" class="w-min" label="Сохранить" />
                </div>
              </div>
            </div>
          </div>
        </ScrollPanel>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import moment from "moment";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Avatar from "primevue/avatar";
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import InputText from "primevue/inputtext";
// import InputNumber from "primevue/inputnumber";
import InputMask from "primevue/inputmask";
// import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
// import Textarea from "primevue/textarea";
import ScrollPanel from "primevue/scrollpanel";

export default {
  components: {
    ManagerSideMenu,
    ManagerHeader,
    TabView,
    TabPanel,
    DataTable,
    Column,
    Button,
    Sidebar,
    InputText,
    Avatar,
    // InputNumber,
    InputMask,
    Dropdown,
    // InputSwitch,
    // Textarea,
    ScrollPanel
  },
  data() {
    return {
      isViewingClientDetails: false,
      selectedClient: null,
      clients: [],
      customClients: [],
      isCreatingNewClient: false,
      first_name: "",
      last_name: "",
      phone: "",
      phone_secondary: "",
      email: "",
      gender: "",
      birth_date: "",
      note: ""
    };
  },
  mounted() {
    this.fetchClients();
    this.getCustomClients();
  },
  methods: {
    moment() {
      return moment();
    },
    getCustomClients() {
      this.$http.get("organization/custom-clients").then(response => {
        this.customClients = response.data;
      });
    },
    createClient() {
      this.$http
        .post("organization/custom-client", {
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone,
          phone_secondary: this.phone_secondary,
          email: this.email,
          gender: this.gender,
          birth_date: this.birth_date,
          note: this.note
        })
        .then(() => {
          this.isCreatingNewClient = false;
          this.getCustomClients();
        });
    },
    showClientDetails(data) {
      this.isViewingClientDetails = true;
      this.selectedClient = data;
    },
    startChat(target_id) {
      this.$refs.sm.$refs.chat.createChat(target_id);
    },
    fetchClients() {
      this.$http.get("organization/clients").then(response => {
        this.clients = response.data.clients;
      });
    }
  }
};
</script>
