<template>
  <div class="container mx-auto py-6 px-4 lg:px-0">
    <div class="lg:grid grid-cols-5 gap-12">
      <div class="col-span-2">
        <div class="rounded-2xl py-6 shadow-md">
          <div
            alt="Загрузить фото"
            class="cursor-pointer rounded-full bg-gray-300 text-center mx-auto w-36 h-36 relative"
          >
            <div
              class="overflow-hidden w-36 h-36 absolute rounded-full"
              v-if="user.avatar"
            >
              <img
                class="h-full object-cover"
                :src="'https://api.reserv.by/' + user.avatar"
              />
            </div>
            <label for="fileUploadInput">
              <div class="flex items-center p-button-rounded absolute bottom-0 right-0 hover:bg-accent hover:text-white text-center
                cursor-pointer rounded-full bg-white border-gray-50 shadow-lg text-black">
                <i class="pi pi-camera text-lg p-1 px-2.5"></i>
              </div>
            </label>
            <input
              type="file"
              id="fileUploadInput"
              class="opacity-0"
              @change="uploadAvatar"
            />
          </div>

          <div class="w-full lg:w-5/6 mx-auto text-left space-y-6 mt-12 px-4 lg:px-0">
            <div class="p-field flex flex-col">
              <label
                for="name"
                class="ml-4 px-2 top-3 bg-white w-12 text-center relative text-gray-400"
              >Имя</label>
              <InputText
                v-model="user.name"
                class="rounded-full pl-6 py-3"
                id="name1"
                type="username"
              />
            </div>
            <div class="p-field flex flex-col">
              <label
                for="name"
                class="ml-4 px-2 top-3 bg-white w-22 text-left relative text-gray-400"
              >Телефон</label>
              <InputText
                v-model="user.phone"
                class="rounded-full pl-6 py-3"
                id="phone"
                type="phone"
              />
            </div>
            <div class="p-field flex flex-col">
              <label
                for="name"
                class="ml-4 px-2 top-3 bg-white w-18 text-left relative text-gray-400"
              >E-mail</label>
              <InputText
                v-model="user.email"
                class="rounded-full pl-6 py-3"
                id="email"
                type="email"
              />
            </div>
            <div class="p-field flex flex-col">
              <label
                for="name"
                class="z-10 ml-4 px-2 top-3 bg-white w-12 text-left relative text-gray-400"
              >Пол</label>
              <Dropdown
                v-model="user.gender"
                optionLabel="name"
                :options="genders"
                class="rounded-full pl-6 py-1"
                id="gender"
              />
            </div>

            <div class="pt-8">
              <Button
                class="w-full py-3 rounded-full font-semibold"
                label="Сохранить"
                @click="updateUser"
              />
              <Button
                label="Выйти из аккаунта"
                class="p-button-text w-full mt-4 font-semibold"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-3 text-left mt-6 lg:mt-0">
        <div>
          <h2 class="text-2xl font-semibold">Способы оплаты</h2>
          <div class="py-4 ">
            Безопасно сохраняйте данные вашей карты для оплаты услуг без лишних усилий.
          </div>

          <div>
            <span class="font-semibold">
              Мои карты
            </span>

            <div class="border-t border-b border-gray-300 mb-1 mt-2 py-1 w-full lg:w-1/2 flex items-center justify-between">
              <div class="font-semibold">
                ... 5514
              </div>

              <div class="flex space-x-2">
                <img
                  src="/img/mastercard_sm.png"
                  class="object-none"
                />

                <Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                />
              </div>
            </div>

            <Button
              label="Добавить карту"
              icon="pi pi-plus"
              class="p-button-sm mt-4"
            />
          </div>
        </div>

        <div class="mt-14">
          <h2 class="text-2xl font-semibold">Вход через соцсети</h2>
          <div class="py-4">
            Свяжите профиль в соц сетях для более быстрого доступа к вашей учетной записи.
          </div>

          <div class="flex w-full lg:w-1/2 items-center justify-between">
            <div class="flex space-x-8 font-semibold items-center">
              <img
                src="/img/google_sm.png"
                class="object-none"
              />
              <span>Google</span>
            </div>

            <Button
              label="Отвязать"
              class="p-button-text"
            />
          </div>
        </div>

        <div class="mt-14">
          <h2 class="text-2xl font-semibold">Сменить пароль</h2>
          <div class="py-4">
            Вы можете сменить свой пароль
          </div>

          <div class="flex w-full lg:w-1/2 items-center justify-between">
            <Button
              label="Сменить пароль"
              @click="isChangingPassword = true"
            />
          </div>
        </div>

        <div class="mt-14">
          <h2 class="text-2xl font-semibold">Уведомления</h2>
          <div class="py-4">
            Будьте в курсе последних новостей, получая уведомления и напоминания о визите.
          </div>

          <span class="font-semibold">Текстовые уведомления</span>

          <div class="w-full lg:w-1/2 flex mt-2">
            <span>
              Текстовые уведомления на вашу почту от поставщиков услуг
            </span>

            <InputSwitch v-model="textNotifications" />
          </div>
        </div>

        <Dialog
          header="Сменить пароль"
          v-model:visible="isChangingPassword"
        >
          <div class="flex flex-col space-y-4">
            <Password
              :feedback="false"
              placeholder="Новый пароль"
              v-model="newPass"
            />
            <Password
              :feedback="false"
              placeholder="Подтверждение пароля"
              v-model="newPassConfirm"
            />

            <div class="flex justify-between">
              <Button
                label="Отмена"
                class="p-button-text"
                @click="isChangingPassword = false"
              />
              <Button
                label="Сохранить"
                :disabled="!newPass || !newPassConfirm"
                @click="changePassword"
              />
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import Password from "primevue/password";

export default {
  components: {
    InputText,
    Button,
    InputSwitch,
    Dropdown,
    Dialog,
    Password,
  },
  data() {
    return {
      oldPass: null,
      newPass: null,
      newPassConfirm: null,
      isChangingPassword: false,
      textNotifications: true,
      user: {
        phone: "",
        email: "",
        name: "",
        gender: { name: "Не указан", value: null },
      },
      genders: [
        { name: "Не указан", value: null },
        { name: "Мужской", value: 1 },
        { name: "Женский", value: 0 },
      ],
    };
  },
  mounted() {
    this.$http.get("user").then((r) => {
      this.user = r.data;

      switch (this.user.gender) {
        case null:
          this.user.gender = { name: "Не указан", value: null };
          break;
        case 1:
          this.user.gender = { name: "Мужской", value: 1 };
          break;
        case 0:
          this.user.gender = { name: "Женский", value: 0 };
          break;
      }
    });
  },
  methods: {
    changePassword() {
      if (this.newPass != this.newPassConfirm) {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: "Подтверждение пароля не совпадает",
          life: 4000,
        });
        return false;
      }

      this.$http.put("user", { password: this.newPass }).then(() => {
        // console.log(r);
        this.$toast.add({
          severity: "success",
          summary: "Успешно",
          detail: "Новый пароль сохранён",
          life: 4000,
        });
      });
    },
    emitUploadAvatar() {
      console.log(this.$refs.fileUploadInput);
    },
    uploadAvatar(e) {
      let self = this;
      let formData = new FormData();
      formData.append("avatar", e.target.files[0]);

      this.$http
        .post("/user-avatar", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (r) {
          self.user = r.data.user;
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    updateUser() {
      this.$http
        .put("user", {
          name: this.user.name,
          phone: this.user.phone,
          email: this.user.email,
          gender: this.user.gender.value,
        })
        .then(() => {});
    },
  },
};
</script>

<style>
::v-deep .p-dropdown {
  background: transparent;
}
</style>
