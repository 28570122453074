<template>
  <div>
    <Toast />
    <a
      href="#"
      @click="showChatsList"
      v-if="showButton"
    > Чат <Badge
        v-if="unreads"
        :value="unreads"
      ></Badge> </a>
    <Sidebar
      v-model:visible="isChatDisplayed"
      position="right"
      :baseZIndex="10000"
      class="p-sidebar-lg overflow-hidden"
    >
      <div class="flex items-center space-x-3">
        <span
          class="text-accent cursor-pointer"
          v-if="isViewingChat"
          @click="backAndReload"
        >
          Назад
        </span>
        <h1 class="font-semibold text-2xl">Чат</h1>
      </div>

      <ScrollPanel
        style="width: 100%; height:90%"
        class="custombar1 mb-1"
        ref="messageContainer"
        v-if="isViewingChat > 0"
      >
        <div class="flex flex-col space-y-3 w-full h-full">
          <div
            class="flex items-start px-3 space-x-3"
            :class="{'border p-3 rounded-md bg-green-50': message.order_id}"
            v-for="message in messages"
            :key="message.id"
          >
            <div>
              <Avatar
                :label="message.sender.name.substr(0, 1)"
                shape="circle"
                size="large"
              />
            </div>
            <div class="flex flex-col">
              <div class="flex space-x-3">
                <strong
                  class="text-xs"
                  v-if="!message.order_id"
                >{{message.sender.id == user.id ? 'Вы' : message.sender.name}}</strong>
                <strong class="text-xs">{{toMoment(message.created_at)}}</strong>
              </div>
              <p class="break-word text-gray-700 text-sm pt-1">
                {{message.message}}
              </p>
              <strong
                class="cursor-pointer text-accent"
                @click="$router.push('/manager/sales')"
                v-if="message.order_id && message.sender.id != user.id"
              >Подробнее</strong>
            </div>
          </div>
        </div>
      </ScrollPanel>

      <div
        style="height:10%"
        v-if="isViewingChat"
      >
        <Textarea
          v-model="msg"
          rows="5"
          class="w-full mb-4"
          placeholder="Введите сообщение..."
          @keyup.enter.prevent="sendMessage"
        />
      </div>

      <ScrollPanel
        style="width: 100%; height:100%"
        class="custombar1 pb-3"
      >
        <div
          class="flex flex-col space-y-3"
          v-if="!isViewingChat"
        >
          <div
            v-for="chat in chats"
            :key="chat.id"
            class="border rounded-md w-full h-16 flex items-center px-3 space-x-3 cursor-pointer"
            @click="loadChatMessages(chat)"
          >
            <Avatar
              :label="chat.last_message.sender.name.substr(0, 1)"
              shape="circle"
              size="large"
            />
            <div class="flex flex-col">
              <strong class="text-xs">{{toMoment(chat.last_message.created_at)}}</strong>
              <p class="truncate text-gray-700 text-sm">
                {{chat.last_message.message}}
              </p>
            </div>
          </div>
        </div>
      </ScrollPanel>
    </Sidebar>
  </div>
</template>

<script>
import Badge from "primevue/badge";
import Sidebar from "primevue/sidebar";
import moment from "moment";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import Avatar from "primevue/avatar";
import ScrollPanel from "primevue/scrollpanel";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";

window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "55ebc9ecc1bb4c962ab4",
  authEndpoint: "https://api.reserv.by/broadcasting/auth",
  cluster: "eu",
  forceTLS: true,
  encrypted: true,
  auth: {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  },
});

export default {
  components: {
    Badge,
    Sidebar,
    Avatar,
    ScrollPanel,
    Textarea,
    Toast,
  },
  props: ["showButton"],
  beforeUnmount() {
    // clearInterval(this.interval);
    // this.interval = null;
    let userData = localStorage.getItem("user");

    if (userData) {
      userData = JSON.parse(userData);

      window.Echo.leave("prv." + userData.id);
    }
  },
  mounted() {
    let userData = localStorage.getItem("user");

    if (userData) {
      userData = JSON.parse(userData);
      this.user = userData;

      window.Echo.private("prv." + userData.id)
        .listen(".evt", (e) => {
          console.log(e);
        })
        .listen(".chat", (e) => {
          if (e.order_id) {
            this.$toast.add({
              severity: "success",
              summary: e.sender.name + " оформил новый заказ",
              detail: "Проверьте входящие сообщения в чате",
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "info",
              summary: e.sender.name + " отправил(-а) вам сообщение",
              detail: "Проверьте входящие сообщения в чате",
              life: 5000,
            });
          }

          if (!this.selectedChat) {
            return;
          }

          if (this.selectedChat.id == e.chat_id) {
            this.messages.push(e);
          }
        });
    }
  },
  watch: {
    isChatDisplayed: function (v) {
      if (v) {
        this.showChatsList();
      }
    },
  },
  methods: {
    backAndReload() {
      this.isViewingChat = false;
      this.messages = null;
      this.showChatsList();
    },
    showChatsList() {
      this.$http.get("chats").then((r) => {
        this.chats = r.data.chats;
      });
      this.isChatDisplayed = true;
    },
    toMoment(x) {
      return moment(x).format("DD MMM YYYY в HH:mm");
    },
    createChat(target_id) {
      this.isChatDisplayed = true;
      this.$http.post("chat", { target_id }).then((response) => {
        if (!response.data.is_found) {
          this.getChats();
        }

        this.selectChat(response.data.chat);
      });
    },
    getChats() {
      if (!localStorage.getItem("token") || !localStorage.getItem("user")) {
        return false;
      }

      this.$http.get("chats").then((response) => {
        let data = response.data;

        if (data.chats) {
          this.chats = data.chats;
          this.unreads = 0;

          data.chats.map((chat) => {
            if (chat.last_message.unreads) {
              this.unreads += chat.last_message.unreads;

              if (this.selectedChatId == chat.id) {
                this.loadChatMessages(this.selectedChat);
              }
            }
          });
        }
      });
    },
    loadMoreMessages(e) {
      let scroll = e.target.scrollTop;
      let scrollTarget = 200 * this.page;

      if (scroll >= scrollTarget && this.page < this.maxPage) {
        this.page++;
        this.loadChatMessages(this.selectedChat, this.page);
      }
    },
    sendMessage() {
      let msg = this.msg;
      let to;
      this.msg = null;

      if (this.selectedChat.sender_id == this.user.id) {
        to = this.selectedChat.receiver_id;
      } else {
        to = this.selectedChat.sender_id;
      }

      this.$http.post("message", { to: to, message: msg }).then((r) => {
        this.messages.push(r.data.message);
        // this.loadChatMessages(this.isViewingChat);
        // this.$refs.messageContainer.scrollTo(0, 0);
      });
    },
    selectChat(chat) {
      this.chatMessages = null;
      this.selectedChatId = chat.id;
      this.selectedChat = chat;
      this.page = 1;
      this.loadChatMessages(chat);
    },
    loadChatMessages(chat) {
      this.isViewingChat = chat.id;
      this.selectedChat = chat;

      this.$http.get("chat/" + chat.id + "/im").then((response) => {
        this.messages = response.data.messages;
      });
    },
  },
  data() {
    return {
      unreads: 0,
      interval: null,
      page: 1,
      maxPage: 1,
      message: null,
      unreadMessages: 15,
      isChatDisplayed: false,
      chatMessages: null,
      selectedChatId: null,
      selectedChat: null,
      chats: null,
      isViewingChat: false,
      messages: null,
      msg: "",
      user: null,
    };
  },
};
</script>

<style>
.scroll-chat-content {
  height: calc(100vh - 9rem);
}
*:enabled:focus {
  box-shadow: 0 0 0 transparent !important;
}
.p-sidebar-content {
  overflow: hidden;
  height: 100%;
}
</style>
