<template>
  <div class="w-full border-b-2 lg:h-20 shadow-sm bg-white relative z-10 flex">
    <div
      class="container mx-auto my-2 lg:my-0 px-4 lg:px-0 space-y-6 lg:space-y-0 lg:grid grid-cols-5 lg:content-center gap-x-6"
    >
      <div class="h-12 col-span-2 relative text-left">
        <label
          class="text-left lg:absolute -top-2.5 left-6 bg-white px-2 text-sm font-semibold z-50"
          >Что ищем</label
        >
        <span
          class="p-input-icon-left w-full lg:h-full lg:border-2 rounded-full outline-none"
        >
          <i class="pi pi-search" />
          <AutoComplete
            @keyup="debouncedPredictions"
            type="text"
            v-model="searchQuery"
            :suggestions="predictions"
            field="title"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder="Название услуги или заведения"
            class="lg:h-full w-full left-6 pr-10 rounded-full outline-none"
          />
        </span>

        <OverlayPanel
          ref="predictionsPanel"
          :showCloseIcon="true"
          :dismissable="false"
          class="mt-5"
          style="max-width: 80%; min-width: 50%"
        >
          <div
            class="flex flex-col space-y-3"
            v-if="!isSearching && searchResults != [] && searchResults"
          >
            <div
              v-for="category in searchResults"
              :key="category.id"
              class="flex flex-col space-y-3"
            >
              <strong class="text-2xl">{{ category.title }}</strong>
              <div class="grid grid-cols-2 gap-4">
                <div
                  class="cursor-pointer"
                  v-for="item in category.items"
                  :key="item.id"
                  @click="$router.push('/place/' + item.id)"
                >
                  <div class="flex flex-row items-center space-x-3">
                    <div class="w-20 h-20 rounded-lg overflow-hidden">
                      <img
                        :src="baseURL + item.place.logo"
                        class="object-none object-cover h-full"
                      />
                    </div>
                    <div>
                      <strong class="text-lg">{{ item.place.title }}</strong>
                      <p class="text-gray-500">{{ item.address }}</p>
                      <p class="text-gray-400">{{ item.place.slogan }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col space-y-2" v-if="isSearching">
            <Skeleton width="25rem" height="2rem" />

            <div class="flex flex-row space-x-4 items-center">
              <Skeleton width="4rem" height="4rem" />
              <Skeleton width="20rem" height="2rem" />
            </div>
          </div>

          <div class="flex" v-if="!isSearching && searchResults == []">
            <strong> Ничего не найдено </strong>
          </div>
        </OverlayPanel>
      </div>

      <div class="h-12 relative text-left flex flex-row items-center">
        <div class="flex flex-col w-full">
          <label
            class="lg:absolute -top-2 left-2 lg:left-6 bg-white px-2 text-sm font-semibold z-50"
            >Дата</label
          >
          <Calendar
            id="minmax"
            v-model="date"
            placeholder="2021-10-10"
            dateFormat="dd.mm.yy"
            :minDate="new Date()"
            :manualInput="false"
            class="pl-0 lg:pl-2 lg:h-full w-full lg:border-2 rounded-full outline-none py-0.5"
            autocomplete="off"
          />
        </div>

        <div class="lg:right-2 mt-1.5 lg:absolute">
          <InputSwitch v-model="isSearchDateUsed" />
        </div>
        <!-- <input class="lg:pl-6 lg:h-full w-full lg:border-2 rounded-full outline-none" type="text" placeholder="10.12.2021" /> -->
      </div>

      <div class="h-12 relative text-left flex flex-row items-center">
        <div class="flex flex-col w-full">
          <label
            class="lg:absolute -top-2 left-2 lg:left-6 bg-white px-2 text-sm font-semibold z-50"
            >Время</label
          >
          <Calendar
            id="time24"
            class="pl-0 lg:pl-2 lg:h-full w-full lg:border-2 rounded-full outline-none py-0.5"
            v-model="time"
            placeholder="10:00"
            :timeOnly="true"
            :showTime="true"
            :stepMinute="10"
            :showSeconds="false"
            autocomplete="off"
          />
        </div>

        <div class="lg:right-2 mt-1.5 lg:absolute">
          <InputSwitch v-model="isSearchTimeUsed" />
        </div>
        <!-- <input class="lg:pl-6 lg:h-full w-full lg:border-2 rounded-full outline-none" type="text" placeholder="18:30" /> -->
      </div>

      <div class="h-12 flex">
        <a
          @click.stop="search"
          class="btn btn-md w-full hover:bg-accentDark cursor-pointer px-2 py-2.5"
          >Найти</a
        >
      </div>

      <div class="h-12 text-center hidden lg:flex" v-if="1 == 2">
        <a
          @click.stop="findOnMap"
          class="w-full text-black self-center pt-1 cursor-pointer"
          href="#"
          >Показать на карте</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import InputSwitch from "primevue/inputswitch";
import OverlayPanel from "primevue/overlaypanel";
import Skeleton from "primevue/skeleton";
import Calendar from "primevue/calendar";
// import SearchBar from "./SearchBar";
// import InputText from "primevue/inputtext";
import { mapMutations } from "vuex";

var _ = require("lodash");

export default {
  components: { OverlayPanel, Skeleton, Calendar, InputSwitch, AutoComplete },
  props: ["query"],
  data() {
    return {
      gps: null,
      isSearchDateUsed: false,
      isSearchTimeUsed: false,
      isOnMap: false,
      date: new Date(),
      time: new Date(),
      searchQuery: null,
      isSearching: false,
      searchResults: null,
      querySearchResults: null,
      predictions: null,
    };
  },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(
        0,
        this.$http.defaults.baseURL.length - 3
      );
    },
  },
  created() {
    let self = this;
    this.debouncedSearch = _.debounce(function (e) {
      this.predictSearch(e);
    }, 500);
    this.debouncedPredictions = _.debounce(function () {
      self.fetchPredictions();
    }, 500);
  },
  mounted() {
    this.searchQuery = this.$props.query;

    if (this.$store.state.search) {
      let search = this.$store.state.search;

      this.searchQuery = search.q;
      this.date = search.date;
      this.time = search.time;
      this.isSearchDateUsed = search.is_date_only;
      this.isSearchTimeUsed = search.is_time_only;
    }
  },
  methods: {
    ...mapMutations(["search"]),
    fetchPredictions() {
      if (this.searchQuery.length < 2) {
        return false;
      }

      this.$http.get("predict?query=" + this.searchQuery).then((response) => {
        let data = [];
        let services = response.data.services ? response.data.services : null;
        let places = response.data.places ? response.data.places : null;

        if (services.length) {
          services = services.map((i) => {
            return { title: i };
          });

          data.push({
            label: "Услуги",
            items: services,
          });
        }

        if (places.length) {
          places = places.map((i) => {
            return { title: i };
          });

          data.push({
            label: "Места",
            items: places,
          });
        }

        this.predictions = data ? data : null;
      });
    },
    findOnMap() {
      this.isOnMap = true;
      this.search();
    },
    search() {
      let search = {
        q: this.searchQuery ? this.searchQuery : "",
        date: this.date,
        time: this.time,
        isOnMap: this.isOnMap,
        gps: this.gps,
      };

      this.$store.commit("search", search);

      this.$emit("triggerSearch");
      // this.$router.push("/find", { params: { query: this.searchQuery } });
    },
    togglePredictionsPanel(e) {
      if (!this.searchQuery) {
        return false;
      }

      if (this.searchQuery.length - 1 > 1) {
        this.$refs.predictionsPanel.show(e);
      } else {
        this.$refs.predictionsPanel.hide();
      }
    },
    predictSearch() {
      if (!this.searchQuery) {
        return false;
      }

      this.searchResults = null;
      this.querySearchResults = null;
      this.isSearching = true;

      if (this.searchQuery.length > 2) {
        this.handleSearch(this.searchQuery).then((data) => {
          this.isSearching = false;
          this.querySearchResults = data.items;

          this.assignFoundDataToCategories(data.items);
        });
      }
    },
    assignFoundDataToCategories(data) {
      let categories = [];

      if (data.places.length > 0) {
        data.places.forEach((item) => {
          let isHavingCategory = categories.filter((category) => {
            return category.title == item.place_type.title;
          });

          if (isHavingCategory.length < 1) {
            categories.push({ items: [], ...item.place_type });
          }

          categories.forEach((category) => {
            if (category.title == item.place_type.title) {
              if (category.items.indexOf(item) === -1) {
                category.items.push(item);
              }
            }
          });
        });
      }

      let serviceTypes = [];

      Object.keys(data).map((type) => {
        if (type == "places") {
          return false;
        }

        if (data[type].length > 0) {
          data[type].forEach((item) => {
            if (item) {
              if (item.place.services.length > 0) {
                item.place.services.forEach((service) => {
                  let hasServiceInList = serviceTypes.filter((service_type) => {
                    return service_type.title == service.service_type.title;
                  });

                  if (hasServiceInList.length == 0) {
                    if (
                      service.service_type.title
                        .toLowerCase()
                        .indexOf(this.searchQuery.toLowerCase()) !== -1
                    ) {
                      serviceTypes.push({
                        items: [],
                        ...service.service_type,
                      });
                    }
                  }

                  if (serviceTypes.length > 0) {
                    serviceTypes.forEach((st) => {
                      if (service.service_type.title == st.title) {
                        if (
                          st.title
                            .toLowerCase()
                            .indexOf(this.searchQuery.toLowerCase()) !== -1
                        ) {
                          st.items.push(item);
                        }
                      }
                    });
                  }
                });
              }
            }
          });
        }
      });

      this.searchResults = [...categories, ...serviceTypes];
    },
    handleSearch(q) {
      return this.$http.get("s?query=" + q).then((response) => {
        if (response.data) {
          return response.data;
        }

        return null;
      });
    },
  },
};
</script>

<style scoped>
::v-deep .p-calendar {
  /* padding: 0; */
  /* padding-left: 1.25rem; */
}
::v-deep .p-autocomplete-loader {
  top: 10px;
}
::v-deep .p-autocomplete-input {
  width: 100%;
}
::v-deep .p-inputtext {
  outline: none !important;
  border: 0;
}
::v-deep .p-calendar .p-inputtext {
  border: 0;
  background: transparent;
}
</style>
