<template>
  <div class="content">
    <TopSearchBar />
    <div class="mt-7 px-4 lg:px-0" v-if="category">
      <div class="container mx-auto flex flex-row justify-between">
        <div class="flex items-center">
          <span class="text-4xl" v-if="category.title">{{ category.title }}</span>
          <p class="hidden lg:block text-left" :class="{ 'pl-10': category.title }">
            Более 300 вариантов услуг
            <a href="#">в Минске</a>
          </p>
        </div>
        <div class="flex-row items-center cursor-pointer hidden lg:flex" @click="showMap">
          <p class="text-right">Показать карту</p>
          <img class="object-none pl-3" src="/img/location.png" />
        </div>
      </div>

      <div class="my-5"></div>

      <div class="container mx-auto grid gap-10" :class="{ 'grid-cols-2': isMapDisplayed }">
        <div
          class="lg:grid space-y-6 lg:space-y-0 gap-x-6 gap-y-12 text-left"
          v-if="places"
          :class="{
            'grid-cols-2': isMapDisplayed,
            'grid-cols-4': !isMapDisplayed
          }"
        >
          <ServiceCard :data="i" v-for="i in places" :key="i" />
        </div>

        <div v-show="isMapDisplayed" id="map" style="max-height: 1100px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TopSearchBar from "@/views/ui/TopSearchBar.vue";
import ServiceCard from "../../views/ui/ServiceCard";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  components: {
    ServiceCard,
    TopSearchBar
  },
  watch: {
    $route(to, from) {
      this.places = [];
      this.category = null;

      if (to.fullPath == from.fullPath) {
        this.fetchCategoryData();
        this.fetchCategoryPlaces();
      }
    }
  },
  props: ["type", "id"],
  mounted() {
    this.fetchCategoryData();
    this.fetchCategoryPlaces();
  },
  methods: {
    fetchCategoryPlaces() {
      if (this.$props.id) {
        this.$http.get("category/places/" + this.$props.type + "/" + this.$props.id).then(response => {
          if (response.data.places) {
            this.places = response.data.places;
          }
        });
      } else {
        this.$http.get("category/places-list/" + this.$props.type).then(response => {
          if (response.data.places) {
            this.places = response.data.places;
          }
        });
      }
    },
    fetchCategoryData() {
      if (this.$props.id) {
        this.$http.get("category/" + this.$props.type + "/" + this.$props.id).then(response => {
          if (response.data.category) {
            this.category = response.data.category;
          }
        });
      } else {
        this.$http.get("category/" + this.$props.type).then(response => {
          if (response.data.category) {
            this.category = response.data.category;
          }
        });
      }
    },
    showMap() {
      this.isMapDisplayed = !this.isMapDisplayed;

      if (this.isMapInitialized) {
        return false;
      }

      this.$nextTick(() => {
        mapboxgl.accessToken = "pk.eyJ1IjoicmVzZXJ2YXBwIiwiYSI6ImNrbmZ4MnVkZDI1a3cydm1ydnZjNzd3ejQifQ.GitchBaltaP1uyfk5TrTtQ";

        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/reservapp/cknfyrj9k494y17r0elagsflz"
        });

        map.addControl(new mapboxgl.NavigationControl());

        map.on("load", () => {
          this.isMapInitialized = true;
        });
      });
    }
  },
  data() {
    return {
      data: null,
      category: null,
      isMapDisplayed: false,
      isMapInitialized: false,
      places: []
    };
  }
};
</script>
