<template>
  <div class="flex -mt-20">
    <ManagerSideMenu />

    <div class="w-full text-left ml-0 lg:ml-280">
      <ManagerHeader title="Услуги и товары" />

      <div class="px-4 py-4 lg:px-12 lg:py-6 w-full relative">
        <div class="absolute w-full h-full z-50" v-if="isLoading">
          <div class="absolute m-auto left-0 right-0 top-0 bottom-0" style="width: 100px; height: 100px">
            <ProgressSpinner />
          </div>
        </div>

        <div class="flex flex-col w-full mx-auto filter transition duration-300 ease-linear" :class="{ blur: isLoading, 'blur-none': !isLoading }">
          <!-- List all services -->
          <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8" v-if="!isEditorEnabled">
            <div v-for="service in services" :key="service.id">
              <div @click="loadServiceDetails(service.id)" class="shadow-lg rounded-lg border overflow-hidden">
                <div class="relative">
                  <div style="height: 200px" class="overflow-hidden" v-if="service.photo">
                    <img :src="baseURL + service.photo" class="object-cover object-center h-full w-full" />
                  </div>
                  <div style="height: 200px" class="flex w-full bg-blue-50" v-else>
                    <i class="m-auto pi pi-camera text-blue-400 text-4xl"></i>
                  </div>
                  <div class="absolute bottom-2 right-2 left-2 text-right">
                    <span class="mt-1 font-semibold text-sm bg-yellow-400 rounded-lg px-2 pt-2 pb-1.5">
                      {{ service.price }}
                    </span>
                  </div>
                </div>
                <div class="p-4 flex flex-row justify-between">
                  <div class="flex flex-col">
                    <strong>{{ service.title }}</strong>
                    <i class="text-sm">{{ service.type.title }}</i>
                  </div>
                  <div v-if="1==0">
                    <Button
                      icon="pi pi-bars"
                      class="p-button-rounded p-button-success"
                      @click="toggleListMenu($event, service.id)"
                      aria-haspopup="true"
                      aria-controls="overlay_tmenu"
                    />
                    <TieredMenu id="overlay_tmenu" ref="listItemMenu" :model="listItemActions" :popup="true" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="shadow-lg rounded-lg border overflow-hidden bg-blue-50 flex cursor-pointer hover:bg-blue-100"
              style="min-height:270px"
              @click="
                () => {
                  isEditorEnabled = true;
                  /* resetEditForm(); */
                }
              "
            >
              <i class="pi pi-plus text-4xl m-auto text-blue-400"></i>
            </div>
          </div>

          <!-- Edit service -->
          <div v-if="isEditorEnabled && service">
            <div class="grid grid-cols-5 border-b pb-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Категория</strong>
                <p class="text-sm text-gray-600 w-2/3">
                  Выберите категорию, которая максимально соответствует услуге. От этого зависит качество поиска ваших услуг
                </p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <div>
                    <div class="flex flex-col">
                      <TreeSelect v-model="service.raw_type_id" @node-select="setTypeId" :options="serviceTypes" placeholder="Выберите тип услуги" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Основная информация</strong>
                <p class="text-sm text-gray-600 w-2/3">Информация, доступная всем пользователям до совершения заказа</p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <div>
                    <strong class="text-gray-600 font-medium text-sm">Название</strong>

                    <div class="flex flex-col">
                      <InputText
                        v-model="service.title"
                        aria-describedby="username2-help"
                        class="p-inputtext-sm"
                        :class="{ 'p-invalid': service.errors.title }"
                      />
                      <small v-if="service.errors.title" id="username2-help" class="p-error">Username is not available.</small>
                    </div>
                  </div>
                  <div>
                    <div class="flex justify-between">
                      <strong class="text-gray-600 font-medium text-sm">Описание</strong>
                      <span class="text-sm text-gray-400">желательно</span>
                    </div>

                    <div class="flex flex-col">
                      <Textarea class="text-sm" v-model="service.description" :autoResize="true" rows="3" cols="30" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Стоимость и длительность</strong>
                <p class="text-sm text-gray-600 w-2/3">
                  Укажите стоимость в белорусских рублях (BYN). Введите значение или удерживайте кнопку плюс или минус чтобы изменить значение
                </p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <div>
                    <strong class="text-gray-600 font-medium text-sm">Стоимость</strong>

                    <div class="flex flex-col">
                      <InputNumber
                        v-model="service.price"
                        mode="currency"
                        currency="BYN"
                        showButtons
                        buttonLayout="horizontal"
                        decrementButtonClass="p-button-danger"
                        incrementButtonClass="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        aria-describedby="username2-help"
                        class="p-inputtext-sm"
                        :step="0.01"
                        :min="0"
                        :class="{ 'p-invalid': service.errors.price }"
                      />
                      <small v-if="service.errors.price" id="username2-help" class="p-error">Username is not available.</small>
                    </div>
                  </div>

                  <div>
                    <strong class="text-gray-600 font-medium text-sm">Длительность</strong>

                    <div class="flex flex-col">
                      <InputNumber
                        v-model="service.duration"
                        suffix=" мин"
                        showButtons
                        buttonLayout="horizontal"
                        decrementButtonClass="p-button-danger"
                        incrementButtonClass="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        aria-describedby="username2-help"
                        class="p-inputtext-sm"
                        :step="5"
                        :min="5"
                        :class="{ 'p-invalid': service.errors.duration }"
                      />
                      <small v-if="service.errors.duration" id="username2-help" class="p-error">Username is not available.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Фото</strong>
                <p class="text-sm text-gray-600 w-2/3">Фотография услуги. Миниатюра показывается всем пользователям</p>
              </div>
              <div class="col-span-3">
                <div class="px-4">
                  <div>
                    <FileUpload mode="basic" ref="photo" name="demo[]" />
                  </div>
                  <div class="grid autoGrid flex-wrap gap-4 existingPhotoContainer" v-if="1 == 2">
                    <div v-for="photo in service.photos" :key="photo.id" class="relative">
                      <div v-if="photo.is_logo">
                        <img :src="baseURL + photo.photo" class="object-fit existingPhoto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Работники</strong>
                <p class="text-sm text-gray-600 w-2/3">Выберите работников, которые оказывают данную услугу</p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <MultiSelect
                    v-model="service.workers"
                    :options="workers"
                    optionLabel="worker_name"
                    optionValue="worker_id"
                    placeholder="Выберите работников"
                    display="chip"
                  />
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Точки продаж</strong>
                <p class="text-sm text-gray-600 w-2/3">Выберите точки продаж, к которым относится эта услуга</p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <MultiSelect
                    v-model="service.places"
                    :options="places"
                    optionLabel="address_with_title"
                    optionValue="id"
                    placeholder="Выберите точки продаж"
                    display="chip"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-row flex-row-reverse space-x-4 mb-16">
              <Button class="p-button-success ml-4" label="Сохранить" @click="store" />
              <Button class="p-button-secondary" label="Отменить" @click="isEditorEnabled = false" />
            </div>
          </div>
          <!-- End Edit worker -->
        </div>
      </div>
    </div>

    <ConfirmPopup></ConfirmPopup>
  </div>
</template>

<script>
import TieredMenu from "primevue/tieredmenu";
import Button from "primevue/button";
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import ConfirmPopup from "primevue/confirmpopup";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import MultiSelect from "primevue/multiselect";
import FileUpload from "primevue/fileupload";
import InputNumber from "primevue/inputnumber";
import TreeSelect from "primevue/treeselect";
import ProgressSpinner from "primevue/progressspinner";

export default {
  components: {
    ManagerSideMenu,
    ManagerHeader,
    ConfirmPopup,
    TieredMenu,
    Button,
    InputText,
    Textarea,
    MultiSelect,
    FileUpload,
    InputNumber,
    TreeSelect,
    ProgressSpinner
  },
  data() {
    return {
      isLoading: true,
      activeServiceId: null,
      serviceTypes: null,
      services: null,
      service: {
        errors: {
          title: false,
          price: false,
          duration: false
        }
      },
      places: null,
      workers: null,
      isEditorEnabled: false,
      listItemActions: [
        {
          label: "Редактировать",
          icon: "pi pi-pencil",
          command: () => {
            this.loadServiceDetails();
          }
        },
        {
          label: "Удалить",
          icon: "pi pi-trash",
          command: () => {
            this.$toast.add({ severity: "error", summary: "Delete", detail: "Data Deleted" });
          }
        }
      ]
    };
  },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(0, this.$http.defaults.baseURL.length - 3);
    }
  },
  mounted() {
    this.fetchServices();
    this.fetchPlaces();
    this.fetchWorkers();
    this.fetchServiceTypes();
  },
  methods: {
    setTypeId(node) {
      this.service.type_id = node.id;
    },
    store() {
      let form = new FormData();
      let route = "service";

      if (this.$refs.photo.files.length) {
        form.append("photo", this.$refs.photo.files[0]);
      }

      if (this.service.id) {
        form.append("_method", "PUT");
        route = "service/" + this.service.id;
      }

      Object.keys(this.service).map(k => {
        if (k == "places") {
          this.service[k].map(item => {
            form.append("places[]", item);
          });
        } else if (k == "photo") {
          //Ignore photo path
        } else if (k == "workers") {
          this.service[k].map(item => {
            form.append("workers[]", item);
          });
        } else {
          form.append(k, this.service[k]);
        }
      });

      this.$http
        .post(route, form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.fetchServices();
          this.isEditorEnabled = false;
          this.service = {};
          this.service.errors = {
            title: false,
            price: false,
            duration: false
          };
        });
    },
    fetchWorkers() {
      this.$http.get("organization/workers").then(response => {
        this.workers = response.data.workers;
      });
    },
    fetchPlaces() {
      this.$http.get("/entity-place/addressed").then(response => {
        this.places = response.data.addressed;
      });
    },
    loadServiceDetails(id) {
      this.$http.get("service/" + id).then(response => {
        let data = response.data.service;
        data.errors = {
          title: false
        };
        data.raw_type_id = {};
        data.raw_type_id[data.type_id] = true;

        if (data.workers) {
          data.workers = data.workers.map(worker => {
            return worker.worker_id;
          });
        }

        this.service = data;
        this.isEditorEnabled = true;
      });
    },
    toggleListMenu(event, service_id) {
      this.activeServiceId = service_id;
      this.$refs.listItemMenu.toggle(event);
    },
    fetchServices() {
      this.$http.get("service").then(response => {
        this.services = response.data.services;
        this.isLoading = false;
      });
    },
    fetchServiceTypes() {
      this.$http.get("service/types").then(response => {
        this.serviceTypes = response.data.types;
      });
    }
  }
};
</script>

<style scoped>
:deep(.p-multiselect),
:deep(.p-chips-multiple-container),
:deep(.p-treeselect),
:deep(.p-autocomplete-multiple-container) {
  border: 0;
}
</style>
