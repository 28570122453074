<template>
  <div>
    <Header />

    <div class="container mx-auto text-left px-4 md:px-0">
      <h1 class="py-24 text-4xl font-semibold">Контакты</h1>

      <div class="grid gap-x-12 grid-cols-1 md:grid-cols-5 pb-24">
        <div class="w-full md:col-span-2 flex flex-col space-y-14 items-start">
          <div class="flex flex-row">
            <img
              class="object-none object-top"
              src="img/location1.png"
            />
            <div class="flex flex-col ml-4 space-y-4">
              <strong class="text-2xl font-medium">ООО "АБТЕ"</strong>
              <span>г. Минск, пр-т название дом 1 офис 55</span>
            </div>
          </div>
          <div class="flex flex-row">
            <img
              class="object-none object-top"
              src="img/clock1.png"
            />
            <div class="flex flex-col ml-4 space-y-4">
              <strong class="text-2xl font-medium">График работы офиса</strong>
              <span>С 9:30 по 18:00 пн - пт</span>
              <span>Выходные сб - вс</span>
            </div>
          </div>
          <div class="flex flex-row">
            <img
              class="object-none object-top"
              src="img/microphone1.png"
            />
            <div class="flex flex-col ml-4 space-y-4">
              <strong class="text-2xl font-medium">Поддержка клиентов</strong>
              <a href="tel:+375295751800">+375 29 575-18-00</a>
              <a href="mailto:info@reserv.by">info@reserv.by</a>
            </div>
          </div>
          <div class="flex flex-row">
            <img
              class="object-none object-top"
              src="img/briefcase1.png"
            />
            <div class="flex flex-col ml-4 space-y-4">
              <strong class="text-2xl font-medium">Поддержка партнеров</strong>
              <a href="tel:+375295751800">+375 29 575-18-00</a>
              <a href="mailto:partner@reserv.by">partner@reserv.by</a>
            </div>
          </div>
        </div>
        <div class="col-span-3 mt-16 md:mt-0">
          <p class="font-semibold text-gray-500 text-2xl">Есть вопросы? Заполните форму и мы с вами свяжемся:</p>

          <div class="flex flex-col space-y-8 mt-12">
            <span class="relative">
              <label>Имя</label>
              <InputText
                type="text"
                v-model="name"
                class="w-full rounded-full"
              />
            </span>
            <span class="relative">
              <label>E-mail</label>
              <InputText
                type="text"
                v-model="email"
                class="w-full rounded-full"
              />
            </span>
            <span class="relative">
              <label>Телефон <span class="text-gray-400">(не обязательно)</span></label>
              <InputMask
                v-model="phone"
                mask="+375 (99) 999-99-99"
                class="w-full rounded-full"
              />
            </span>
            <span class="relative">
              <label for="inputtext2">Сообщение</label>
              <Textarea
                rows="5"
                cols="30"
                class="w-full rounded-3xl"
                v-model="text"
              />
            </span>

            <a
              href="#"
              class="font-semibold btn btn-md w-full lg:w-72"
              @click="sendFeedback"
            >Отправить</a>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import InputMask from "primevue/inputmask";

export default {
  components: {
    Header,
    Footer,
    InputText,
    Textarea,
    InputMask,
  },
  data() {
    return {
      name: "",
      email: "",
      text: "",
      phone: null,
    };
  },
  methods: {
    sendFeedback() {
      if (!this.name || !this.email || !this.text) {
        alert("Заполните обязательные поля");
        return;
      }

      this.$http.post("feedback", {
        name: this.name,
        email: this.email,
        text: this.text,
        phone: this.phone,
      });

      this.name = "";
      this.email = "";
      this.text = "";
      this.phone = null;
    },
  },
};
</script>

<style scoped>
::v-deep .p-inputtext {
  padding: 10px 14px;
}
label {
  position: absolute;
  background: #fff;
  padding: 0 8px;
  top: -12px;
  left: 20px;
  font-size: 14px;
  font-weight: 700;
}
</style>
