<template>
  <div>
    <Header />
    <UserFavorites />
    <Footer />
  </div>
</template>

<script>
import UserFavorites from "@/components/User/Favorites.vue";
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer,
    UserFavorites
  }
};
</script>
