<template>
  <div
    class="bg-gray-900 fixed h-full px-4 pt-8 text-left w-full lg:w-280 z-50"
    :class="{ 'hidden lg:block': !$store.getters.isManagerMenuDisplayed }"
  >
    <div
      v-if="!isCalendarVisible"
      class="flex space-x-4"
    >
      <a
        href="/"
        @click.prevent="$router.push('/')"
      >
        <img
          class="cursor-pointer object-fit w-24"
          src="/img/logo_primary.png"
        />
      </a>
      <span class="lg:hidden" @click="$store.commit('isManagerMenuDisplayed', !$store.getters.isManagerMenuDisplayed)">
        <i
          class="pi pi-times px-2 cursor-pointer text-white"
        ></i>
      </span>
    </div>
    <div
      v-else
      class="flex justify-between"
    >
      <div
        @click="isShowingCalendar = false"
        class="relative"
      >
        <img
          class="cursor-pointer"
          src="/img/menu_dark_burger.png"
        />
        <img
          class="cursor-pointer absolute top-2 left-2.5"
          src="/img/menu.png"
        />
      </div>
      <img
        @click="toggle"
        class="cursor-pointer"
        src="/img/add_entry.png"
      />
      <Menu
        ref="menu"
        :model="items"
        :popup="true"
      />

      <Dialog
        header="Добавить запись"
        v-model:visible="isAddingBooking"
      >
        <div class="flex flex-col items-center">
          <date-picker
            v-model="date"
            mode="date"
          />

          <div class="flex space-x-6">
            <div class="p-field flex flex-col">
              <label for="username1">Начало</label>
              <date-picker
                v-model="start_time"
                is24hr
                mode="time"
                is-expanded
                :minute-increment="5"
              />
            </div>
            <div class="p-field flex flex-col">
              <label for="username1">Конец</label>
              <date-picker
                v-model="end_time"
                is24hr
                mode="time"
                is-expanded
                :minute-increment="5"
              />
            </div>
          </div>

          <div class="flex flex-col w-full space-y-4 mt-4">
            <div class="p-field w-full">
              <label for="username1">Услуга</label>
              <Dropdown
                class="w-full"
                v-model="service"
                :options="services"
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                placeholder="Выберите услугу"
              />
            </div>
            <div class="p-field w-full">
              <label for="username1">Работник</label>
              <Dropdown
                class="w-full"
                v-model="worker"
                :options="workers"
                optionLabel="name"
                placeholder="Выберите работника"
              />
            </div>
          </div>

          <div class="flex w-full mt-4">
            <Button
              label="Сохранить"
              :disabled="!worker || !service || !date || !start_time || !end_time ? 'disabled' : false"
              class="p-button-success"
              icon="pi pi-check"
              iconPos="right"
              @click="createBooking"
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Добавить недоступное время"
        v-model:visible="isAddingBlockedTime"
      >
        <div class="flex flex-col items-center">
          <date-picker
            v-model="unav_date"
            mode="date"
          />

          <div class="flex space-x-6">
            <div class="p-field flex flex-col">
              <label for="username1">Начало</label>
              <date-picker
                v-model="unav_start_time"
                is24hr
                mode="time"
                is-expanded
                :minute-increment="5"
              />
            </div>
            <div class="p-field flex flex-col">
              <label for="username1">Конец</label>
              <date-picker
                v-model="unav_end_time"
                is24hr
                mode="time"
                is-expanded
                :minute-increment="5"
              />
            </div>
          </div>

          <div class="flex w-full mt-4">
            <Button
              label="Сохранить"
              @click="createBlockedBooking"
              class="p-button-success"
              icon="pi pi-check"
              iconPos="right"
            />
          </div>
        </div>
      </Dialog>
    </div>

    <nav
      class="pt-2 lg:pt-10 relative h-full w-full text-center lg:text-left"
      v-if="!isCalendarVisible"
    >
      <ul class="space-y-3 lg:space-y-6">
        <li>
          <router-link
            to="/manager"
            :class="{ 'font-bold': $route.name == 'Manager' }"
          >Главная</router-link>
        </li>
        <li v-if="isUserIsAdmin">
          <router-link
            to="/mgr/admin"
            :class="{ 'font-bold': $route.name == 'Admin' }"
          >Администрирование</router-link>
        </li>
        <li>
          <router-link
            to="/manager/calendar"
            :class="{ 'font-bold': $route.name == 'Calendar' }"
            @click="isShowingCalendar = true"
          >Календарь</router-link>
        </li>
        <li>
          <router-link
            to="/manager/sales"
            :class="{ 'font-bold': $route.name == 'Sales' }"
          >Продажи</router-link>
        </li>
        <li>
          <router-link
            to="/manager/coupons"
            :class="{ 'font-bold': $route.name == 'Coupons' }"
          >Акции</router-link>
        </li>
        <li>
          <router-link
            to="/manager/clients"
            :class="{ 'font-bold': $route.name == 'Organization Clients' }"
          >Клиенты</router-link>
        </li>
        <li>
          <router-link
            to="/manager/places"
            :class="{ 'font-bold': $route.name == 'Places' }"
          >Точки продаж</router-link>
        </li>
        <li>
          <router-link
            to="/manager/workers"
            :class="{ 'font-bold': $route.name == 'Workers' }"
          >Персонал</router-link>
        </li>
        <li>
          <router-link
            to="/manager/goods"
            :class="{ 'font-bold': $route.name == 'GoodsAndServices' }"
          >Услуги</router-link>
        </li>
        <li>
          <router-link
            to="/manager/food"
            :class="{ 'font-bold': $route.name == 'FoodMenu' }"
          >Меню</router-link>
        </li>
        <li v-if="1 == 2">Запасы товаров</li>
        <li v-if="1 == 2">Статистика</li>
        <li v-if="1 == 2">Настройки</li>
        <li>
          <router-link
            to="/manager/lib"
            :class="{ 'font-bold': $route.name == 'Library' }"
          >Библиотека</router-link>
        </li>
        <li>
          <router-link
            to="/manager/org"
            :class="{ 'font-bold': $route.name == 'Organization' }"
          >Организация</router-link>
        </li>
        <li v-if="1==2">
          <a
            href="#"
            @click="becomeVip"
            v-if="!vip"
          >Стать VIP</a>
          <span v-else>VIP активен</span>
          <!-- <span v-else>VIP до {{moment(vip.end_date).format('DD.MM.Y')}}</span> -->
        </li>
        <li class="absolute bottom-8 left-0 right-0 lg:bottom-16 flex flex-col space-y-3 lg:space-y-6">
          <Chat
            ref="chat"
            :showButton="true"
          />
          <router-link to="/manager/support">Служба поддержки</router-link>
        </li>
      </ul>
    </nav>

    <div v-else>
      <date-picker
        class="mt-4"
        v-model="calendarValue"
        :from-date="new Date()"
        :attributes="calendarAttributes"
        is-dark
        title-position="left"
        dayNamesShorter
      />
      <WeekEventScale :date="calendarValue" />
    </div>

    <Sidebar
      v-model:visible="isAddingNewBooking"
      position="full"
      class="def"
    >
      <div class="mx-auto container">
        <div class="grid grid-cols-2 w-2/3 shadow mx-auto p-4">
          <date-picker
            v-model="date"
            mode="date"
          />
          <div class="space-y-4">
            <div class="flex space-x-2">
              <div class="p-field">
                <label for="username1">Начало</label>
                <date-picker
                  v-model="start_time"
                  mode="time"
                  is24hr
                  :minute-increment="5"
                  is-expanded
                />
              </div>
              <div class="p-field w-full">
                <label for="username1">Услуга</label>
                <Dropdown
                  class="w-full"
                  v-model="selectedCity"
                  :options="cities"
                  optionLabel="name"
                  placeholder="Выберите услугу"
                />
              </div>
            </div>
            <div class="flex space-x-2">
              <div class="p-field">
                <label for="username1">Конец</label>
                <date-picker
                  v-model="end_time"
                  mode="time"
                  is24hr
                  :minute-increment="5"
                  is-expanded
                />
              </div>
              <div class="p-field w-full">
                <label for="username1">Работник</label>
                <Dropdown
                  class="w-full"
                  v-model="selectedCity"
                  :options="cities"
                  optionLabel="name"
                  placeholder="Выберите работника"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="w-2/3 mx-auto mt-8">
          <Textarea
            v-model="value"
            rows="5"
            cols="30"
            class="w-full"
          />
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
var moment = require("moment");
moment.locale("ru");

import Chat from "@/components/Manager/Chat/ChatComponent";
import { DatePicker } from "v-calendar";
import WeekEventScale from "@/components/Manager/SideMenu/WeekEventScale";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import Button from "primevue/button";

export default {
  components: {
    Chat,
    WeekEventScale,
    Menu,
    DatePicker,
    Dropdown,
    Sidebar,
    Textarea,
    Dialog,
    Button,
  },
  watch: {
    service: function (v) {
      this.worker = null;
      this.workers = [];
      this.$http
        .get("organization/workerByService?id=" + v.value)
        .then((response) => {
          this.workers = response.data;
        });
    },
  },
  data() {
    return {
      user: null,
      isMenuHidden: true,
      calendarValue: new Date(),
      service: null,
      services: [],
      worker: null,
      workers: [],
      isAddingBooking: false,
      isAddingBlockedTime: false,
      isAddingNewBooking: false,
      isShowingCalendar: true,
      start_time: new Date(),
      end_time: new Date(),
      unav_date: new Date(),
      unav_start_time: new Date(),
      unav_end_time: new Date(),
      vip: null,
      items: [
        {
          label: "Добавить",
          items: [
            {
              label: "Запись",
              command: () => {
                this.isAddingBooking = true;
                this.$store.dispatch("fetchEvents");
              },
            },
            {
              label: "Недоступное время",
              command: () => {
                this.isAddingBlockedTime = true;
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    moment(x) {
      return moment(x);
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    fetchVipStatus() {
      this.$http.get("vip-status").then((r) => {
        this.vip = r.data.vip;
      });
    },
    becomeVip() {
      this.$http.get("vip").then((r) => {
        this.vip = r.data.vip;
      });
    },
    createBooking() {
      this.$http
        .post("organization/schedule", {
          worker: this.worker,
          service: this.service,
          date: this.date,
          start_time: this.start_time,
          end_time: this.end_time,
        })
        .then(() => {
          this.$store.dispatch("fetchEvents");
          this.isAddingBooking = false;
        });
    },
    createBlockedBooking() {
      this.$http
        .post("organization/schedule-blocked", {
          date: this.unav_date,
          start_time: this.unav_start_time,
          end_time: this.unav_end_time,
        })
        .then(() => {
          this.$store.dispatch("fetchEvents");
          this.isAddingBlockedTime = false;
        });
    },
    fetchServices() {
      this.$http.get("organization/services").then((response) => {
        let data = response.data;
        let parsed = [];

        if (data.services) {
          Object.keys(data.services).map((type) => {
            if (data.services[type].length) {
              data.services[type] = data.services[type].map((item) => {
                item.label = item.title;
                item.value = item.id;
                return item;
              });

              let xtype;

              switch (type) {
                case "beauty":
                  xtype = "Красота";
                  break;
                case "food":
                  xtype = "Еда";
                  break;
                case "mechanical":
                  xtype = "Автоуслуги";
                  break;
                case "medical":
                  xtype = "Медицина";
                  break;
                case "realt":
                  xtype = "Аренда";
                  break;
              }

              parsed.push({
                label: xtype,
                items: data.services[type],
              });
            }
          });

          this.services = parsed;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["events"]),

    isUserIsAdmin() {
      if (this.user && this.user.is_admin) {
        return true;
      }

      return false;
    },

    calendarAttributes() {
      return [
        ...this.events.map((event) => ({
          dates: moment(event.date, "YYYY-MM-DD").toDate(),
          dot: true,
          popover: {
            label: event.title + " " + event.full_time,
          },
        })),
      ];
    },

    isCalendarPage() {
      return this.$router.currentRoute["_rawValue"].name == "Calendar";
    },
    isCalendarVisible() {
      return this.isShowingCalendar && this.isCalendarPage;
    },
  },
  mounted() {
    this.fetchServices();
    this.fetchVipStatus();

    this.$http.get("user").then((r) => {
      this.user = r.data;
    });
  },
};
</script>

<style type="scss" scoped>
li {
  color: #fff;
}
:not(.def) .vc-container.vc-is-dark,
:not(.def) .vc-container {
  background: transparent;
  border: 0;
}
::v-deep .vc-weeks {
  margin-top: 24px;
}
::v-deep .vc-title {
  font-size: 22px;
  text-transform: capitalize;
}
::v-deep .vc-is-dark .vc-header,
::v-deep .vc-weeks,
::v-deep .vc-arrows-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
::v-deep .vc-time-date {
  display: none;
}
::v-deep .vc-container {
  border-radius: 3px;
}
::v-deep .vc-time-picker {
  height: 40px;
}
</style>
