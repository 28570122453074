<template>
  <div>
    <Header />

    <div class="bg-bg-wider lg:text-left">
      <div class="container mx-auto py-24 text-white">
        <h1 class="text-4xl font-semibold">Условия для партнеров</h1>
      </div>
    </div>
    <div class="container mx-auto text-left px-4 lg:px-0">
      <div class="py-4 lg:py-16">
        <p class="font-semibold text-gray-500 text-2xl">Партнерская программа RESERV.BY</p>

        <div class="mt-8 md:hidden flex flex-col space-y-6">
          <div class="border rounded-xl">
            <div class="flex flex-row justify-between items-center px-4 pt-2">
              <h2 class="font-semibold" style="color:#0086FF">Бюджетный план️</h2>
              <span>⭐</span>
            </div>
            <div class="p-4 flex flex-col" v-if="selectedId != 1">
              <strong>30 Br + 1 Br / мес</strong>
              <span>за приведённого клиента</span>
            </div>
            <div class="px-4 py-4 space-y-5" v-if="selectedId == 1">
              <div>
                <div class="font-semibold" style="color:#0086FF">
                  1 месяц
                </div>
                <strong>30 Br + 1 Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  6 месяцев
                </div>
                <strong>153 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  1 год
                </div>
                <strong>270 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                Профессиональные фотографии организации + специалистов
              </div>

              <div>
                Профессиональная редакция информации о организации и их виде деятельности + биография их специалистов
              </div>

              <div>
                Размещение в приложении на поиск и бронирование
              </div>

              <div>
                Рейтинг, отзывы
              </div>
            </div>
            <div class="px-4 py-2 cursor-pointer border-t" @click="selectedId = selectedId > 0 ? (selectedId == 1 ? 0 : 1) : 1">
              <div class="flex space-x-2 items-center" v-if="selectedId != 1">
                <span class="text-gray-600 font-semibold">Подробнее</span>
                <img src="img/caret.png" class="object-none" />
              </div>
              <div class="flex space-x-2 items-center" v-if="selectedId == 1">
                <span class="text-gray-600 font-semibold">Скрыть подробности</span>
                <img src="img/caret_up.png" class="object-none" />
              </div>
            </div>
          </div>

          <div class="border rounded-xl">
            <div class="flex flex-row justify-between items-center px-4 pt-2">
              <h2 class="font-semibold" style="color:#0086FF">Стандартный план️</h2>
              <span>⭐⭐</span>
            </div>
            <div class="p-4 flex flex-col" v-if="selectedId != 2">
              <strong>100 Br + 1 Br / мес</strong>
              <span>за приведённого клиента</span>
            </div>
            <div class="px-4 py-4 space-y-5" v-if="selectedId == 2">
              <div>
                <div class="font-semibold" style="color:#0086FF">
                  1 месяц
                </div>
                <strong>100 Br + 1 Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  3 месяца
                </div>
                <strong>255 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  6 месяцев
                </div>
                <strong>480 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  1 год
                </div>
                <strong>840 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                Профессиональные фотографии организации + специалистов
              </div>

              <div>
                Профессиональная редакция информации о организации и их виде деятельности + биография их специалистов
              </div>

              <div>
                Размещение в приложении на поиск и бронирование
              </div>

              <div>
                Рейтинг, отзывы
              </div>

              <div>
                Реклама
              </div>

              <div>
                Акции, Скидки, Новинки
              </div>

              <div>
                Бронирование онлайн
              </div>

              <div>
                Сервис «Сберечь еду»
              </div>

              <div>
                Предзаказ где есть постоянное меню (стрит фуд), с оплатой онлайн через платформу
              </div>

              <div>
                Онлайн бронирование как столика, так и мероприятия
              </div>

              <div>
                Для кофейни - бренд кофе, эксклюзивные напитки. Данные отличия будут выведены отдельным фильтром.
              </div>

              <div>
                Анализ вашей деятельности
              </div>

              <div>
                Бизнес аналитика, анализ потребностей и требований клиента. Данные о качестве услуг, аудит продаж , финансовая оценка от
                сотрудничества, разработка и развитие рекламных акций
              </div>
            </div>
            <div class="px-4 py-2 cursor-pointer border-t" @click="selectedId = selectedId > 0 ? (selectedId == 2 ? 0 : 2) : 2">
              <div class="flex space-x-2 items-center" v-if="selectedId != 2">
                <span class="text-gray-600 font-semibold">Подробнее</span>
                <img src="img/caret.png" class="object-none" />
              </div>
              <div class="flex space-x-2 items-center" v-if="selectedId == 2">
                <span class="text-gray-600 font-semibold">Скрыть подробности</span>
                <img src="img/caret_up.png" class="object-none" />
              </div>
            </div>
          </div>

          <div class="border rounded-xl">
            <div class="flex flex-row justify-between items-center px-4 pt-2">
              <h2 class="font-semibold" style="color:#0086FF">Премиум план️</h2>
              <span>⭐⭐⭐</span>
            </div>
            <div class="p-4 flex flex-col" v-if="selectedId != 3">
              <strong>150 Br + 1 Br / мес</strong>
              <span>за приведённого клиента</span>
            </div>
            <div class="px-4 py-4 space-y-5" v-if="selectedId == 3">
              <div>
                <div class="font-semibold" style="color:#0086FF">
                  1 месяц
                </div>
                <strong>150 Br + 1 Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  3 месяца
                </div>
                <strong>382,5 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  6 месяцев
                </div>
                <strong>720 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                <div class="font-semibold" style="color:#0086FF">
                  1 год
                </div>
                <strong>1260 Br + 1Br</strong> за приведенного клиента
              </div>

              <div>
                Профессиональные фотографии организации + специалистов
              </div>

              <div>
                Профессиональная редакция информации о организации и их виде деятельности + биография их специалистов
              </div>

              <div>
                Размещение в приложении на поиск и бронирование
              </div>

              <div>
                Рейтинг, отзывы
              </div>

              <div>
                Реклама
              </div>

              <div>
                Акции, Скидки, Новинки
              </div>

              <div>
                Бронирование онлайн
              </div>

              <div>
                Сервис «Сберечь еду»
              </div>

              <div>
                Предзаказ где есть постоянное меню (стрит фуд), с оплатой онлайн через платформу
              </div>

              <div>
                Онлайн бронирование как столика, так и мероприятия
              </div>

              <div>
                Для кофейни - бренд кофе, эксклюзивные напитки. Данные отличия будут выведены отдельным фильтром.
              </div>

              <div>
                Анализ вашей деятельности
              </div>

              <div>
                Бизнес аналитика, анализ потребностей и требований клиента. Данные о качестве услуг, аудит продаж , финансовая оценка от
                сотрудничества, разработка и развитие рекламных акций
              </div>

              <div>
                Обзор/пост заведения в инстаграмм не менее 2х раз в месяц
              </div>

              <div>
                Видео обзор на главной странице
              </div>

              <div>
                Выделенный фон и нахождение в рекомендованных
              </div>
            </div>
            <div class="px-4 py-2 cursor-pointer border-t" @click="selectedId = selectedId > 0 ? (selectedId == 3 ? 0 : 3) : 3">
              <div class="flex space-x-2 items-center" v-if="selectedId != 3">
                <span class="text-gray-600 font-semibold">Подробнее</span>
                <img src="img/caret.png" class="object-none" />
              </div>
              <div class="flex space-x-2 items-center" v-if="selectedId == 3">
                <span class="text-gray-600 font-semibold">Скрыть подробности</span>
                <img src="img/caret_up.png" class="object-none" />
              </div>
            </div>
          </div>
        </div>

        <div class="hidden md:grid grid grid-cols-4 border rounded-xl text-center mt-8 text-xs lg:text-md">
          <div class="border-b border-r p-6"></div>
          <div class="border-b p-6 border-r font-semibold" style="color:#0086FF">
            ⭐ Бюджетный план️
          </div>
          <div class="border-b p-6 border-r font-semibold" style="color:#0086FF">
            ⭐️ ⭐ Стандартный план️
          </div>
          <div class="border-b p-6 font-semibold" style="color:#0086FF">
            ⭐️ ⭐️ ⭐️ Премиум план
          </div>

          <div class="border-b p-6 border-r font-semibold" style="color:#0086FF">
            1 месяц
          </div>
          <div class="border-b p-6 border-r text-center"><strong class="text-lg">30 Br + 1 Br</strong><br />за приведенного клиента</div>
          <div class="border-b p-6 border-r text-center"><strong class="text-lg">100 Br + 1 Br</strong><br />за приведенного клиента</div>
          <div class="border-b p-6 text-center"><strong class="text-lg">150 Br + 1 Br</strong><br />за приведенного клиента</div>

          <div class="border-b p-6 border-r font-semibold" style="color:#0086FF">
            3 месяца
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r text-center"><strong class="text-lg">255 Br + 1Br</strong><br />за приведенного клиента (скидка 15%)</div>
          <div class="border-b p-6 text-center"><strong class="text-lg">382,5 Br + 1Br</strong><br />за приведенного клиента (скидка 15%)</div>

          <div class="border-b p-6 border-r font-semibold" style="color:#0086FF">
            6 месяцев
          </div>
          <div class="border-b p-6 border-r text-center"><strong class="text-lg">153 Br + 1Br</strong><br />за приведенного клиента (скидка 15%)</div>
          <div class="border-b p-6 border-r text-center"><strong class="text-lg">480 Br + 1Br</strong><br />за приведенного клиента (скидка 20%)</div>
          <div class="border-b p-6 text-center"><strong class="text-lg">720 Br + 1Br</strong><br />за приведенного клиента (скидка 20%)</div>

          <div class="border-b p-6 border-r font-semibold" style="color:#0086FF">
            1 год
          </div>
          <div class="border-b p-6 border-r text-center"><strong class="text-lg">270 Br + 1Br</strong><br />за приведенного клиента (скидка 25%)</div>
          <div class="border-b p-6 border-r text-center"><strong class="text-lg">840 Br + 1Br</strong><br />за приведенного клиента (скидка 30%)</div>
          <div class="border-b p-6 text-center"><strong class="text-lg">1260 Br + 1Br</strong><br />за приведенного клиента (скидка 30%)</div>

          <div class="border-b p-6 border-r">
            Профессиональные фотографии организации + специалистов
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Профессиональная редакция информации о организации и их виде деятельности + биография их специалистов
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Размещение в приложении на поиск и бронирование
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Рейтинг, отзывы
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Реклама
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Акции, Скидки, Новинки
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Бронирование онлайн
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Сервис «Сберечь еду»
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Предзаказ где есть постоянное меню (стрит фуд), с оплатой онлайн через платформу
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Онлайн бронирование как столика, так и мероприятия
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Для кофейни - бренд кофе, эксклюзивные напитки. Данные отличия будут выведены отдельным фильтром.
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Анализ вашей деятельности
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Бизнес аналитика Анализ потребностей и требований клиента. Данные о качестве услуг, аудит продаж , финансовая оценка от сотрудничества,
            разработка и развитие рекламных акций
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Обзор/пост заведения в инстаграмм не менее 2х раз в месяц
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="border-b p-6 border-r">
            Видео обзор на главной странице
          </div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 border-r text-center"></div>
          <div class="border-b p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>

          <div class="p-6 border-r">
            Выделенный фон и нахождение в рекомендованных
          </div>
          <div class="p-6 border-r text-center"></div>
          <div class="p-6 border-r text-center"></div>
          <div class="p-6 flex">
            <img class="mx-auto object-none" src="img/checkbox-marked.png" />
          </div>
        </div>

        <div class="border rounded-xl relative p-4 mt-16">
          <span class="text-xl font-semibold absolute -top-4 left-4 bg-white px-2">Система скидок</span>
          <p class="pl-2 mt-2 text-gray-500">При наличии у одного Юридического лица, нескольких фактических объектов:</p>

          <div class="flex flex-col pl-2 mt-4 space-y-1">
            <div class="flex space-x-5">
              <div class="text-gray-400 font-semibold">больше <span class="text-blue-400">2х</span></div>
              <span class="font-semibold">5%</span>
            </div>
            <div class="flex space-x-5">
              <div class="text-gray-400 font-semibold">больше <span class="text-blue-400">3х</span></div>
              <span class="font-semibold">8%</span>
            </div>
            <div class="flex space-x-5">
              <div class="text-gray-400 font-semibold">больше <span class="text-blue-400">5-ти</span></div>
              <span class="font-semibold">12%</span>
            </div>
            <div class="flex space-x-5">
              <div class="text-gray-400 font-semibold">больше <span class="text-blue-400">10-ти</span></div>
              <span class="font-semibold">25%</span>
            </div>
          </div>
        </div>

        <div class="flex justify-between mt-16">
          <div class="flex w-full flex-col space-y-10">
            <div class="lg:w-3/4 relative">
              <h3 class="text-2xl font-semibold p-4 border rounded-2xl">Сервис «сберечь еду»</h3>
              <p class="px-5 bg-white">предзаказ с установленным заведением, % скидки (для общепита)</p>
            </div>
            <div class="lg:w-3/4 relative">
              <h3 class="text-2xl font-semibold p-4 border rounded-2xl">Ежедневник в личном кабинете</h3>
              <p class="px-5 bg-white">
                клиент онлайн видит свободное для записи для записи время (час/день/неделя/месяц/год)
              </p>
            </div>
            <div class="lg:w-3/4 relative">
              <h3 class="text-2xl font-semibold p-4 border rounded-2xl">Большой трафик</h3>
              <p class="px-5 bg-white">
                у нас много категорий: это значит, если клиент пришел в раздел авто услуг, он может увидеть акции общепита и остальных категорий
              </p>
            </div>
            <div class="lg:w-3/4 relative">
              <h3 class="text-2xl font-semibold p-4 border rounded-2xl">Онлайн-чат</h3>
              <p class="px-5 bg-white">клиент – заведение / заведение – клиент</p>
            </div>
            <div class="lg:w-3/4 relative">
              <h3 class="text-2xl font-semibold p-4 border rounded-2xl">Бизнес аналитика</h3>
              <p class="px-5 bg-white">
                анализ потребностей и требований клиента: данные о качестве услуг, аудит продаж, финансовая оценка от сотрудничества, разработка и
                развитие рекламных акций.
              </p>
            </div>
          </div>
          <img class="object-none hidden lg:block" src="img/conds.jpg" />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      selectedId: 0
    };
  }
};
</script>
