<template>
  <div class="flex -mt-20">
    <ManagerSideMenu />

    <div class="w-full text-left ml-0 lg:ml-280">
      <ManagerHeader title="Меню" />

      <div class="px-4 py-4 lg:px-12 lg:py-6 w-full relative">
        <div class="absolute w-full h-full z-50" v-if="isLoading">
          <div class="absolute m-auto left-0 right-0 top-0 bottom-0" style="width: 100px; height: 100px">
            <ProgressSpinner />
          </div>
        </div>

        <div class="flex flex-col w-full mx-auto filter transition duration-300 ease-linear" :class="{ blur: isLoading, 'blur-none': !isLoading }">
          <!-- List all services -->
          <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8" v-if="!isEditorEnabled">
            <div v-for="m in menus" :key="m.id">
              <div class="shadow-lg rounded-lg border overflow-hidden">
                <div class="relative">
                  <div style="height: 200px" class="overflow-hidden" v-if="m.photo">
                    <img :src="baseURL + m.photo" class="object-cover object-center h-full w-full" />
                  </div>
                  <div style="height: 200px" class="flex w-full bg-blue-50" v-else>
                    <i class="m-auto pi pi-camera text-blue-400 text-4xl"></i>
                  </div>
                  <div class="absolute bottom-2 right-2 left-2 text-right">
                    <span class="mt-1 font-semibold text-sm bg-yellow-400 rounded-lg px-2 pt-2 pb-1.5">
                      {{ m.price }}
                    </span>
                  </div>
                </div>
                <div class="p-4 flex flex-row justify-between">
                  <div class="flex flex-col">
                    <strong>{{ m.title }}</strong>
                    <i class="text-sm">{{ m.kitchen.title }}</i>
                  </div>
                  <div>
                    <Button
                      icon="pi pi-bars"
                      class="p-button-rounded p-button-success"
                      @click="toggleListMenu($event, m.id)"
                      aria-haspopup="true"
                      aria-controls="overlay_tmenu"
                    />
                    <TieredMenu id="overlay_tmenu" ref="listItemMenu" :model="listItemActions" :popup="true" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="shadow-lg rounded-lg border overflow-hidden bg-blue-50 flex cursor-pointer hover:bg-blue-100"
              style="min-height:270px"
              @click="
                () => {
                  isEditorEnabled = true;
                  /* resetEditForm(); */
                }
              "
            >
              <i class="pi pi-plus text-4xl m-auto text-blue-400"></i>
            </div>
          </div>

          <!-- Edit service -->
          <div v-if="isEditorEnabled && menu">
            <div class="grid grid-cols-5 border-b pb-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Кухня</strong>
                <p class="text-sm text-gray-600 w-2/3">
                  Выберите кухню, которая максимально соответствует блюду
                </p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <div>
                    <div class="flex flex-col">
                      <TreeSelect v-model="menu.kitchen_id" @node-select="setKitchenId" :options="kitchenTypes" placeholder="Выберите кухню" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Основная информация</strong>
                <p class="text-sm text-gray-600 w-2/3">Информация, доступная всем пользователям до совершения заказа</p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <div>
                    <strong class="text-gray-600 font-medium text-sm">Название</strong>

                    <div class="flex flex-col">
                      <InputText
                        v-model="menu.title"
                        aria-describedby="username2-help"
                        class="p-inputtext-sm"
                        :class="{ 'p-invalid': menu.errors.title }"
                      />
                      <small v-if="menu.errors.title" id="username2-help" class="p-error">Username is not available.</small>
                    </div>
                  </div>
                  <div>
                    <div class="flex justify-between">
                      <strong class="text-gray-600 font-medium text-sm">Описание</strong>
                      <span class="text-sm text-gray-400">желательно</span>
                    </div>

                    <div class="flex flex-col">
                      <Textarea class="text-sm" v-model="menu.description" :autoResize="true" rows="3" cols="30" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Стоимость</strong>
                <p class="text-sm text-gray-600 w-2/3">
                  Укажите стоимость в белорусских рублях (BYN). Введите значение или удерживайте кнопку плюс или минус чтобы изменить значение
                </p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <div>
                    <strong class="text-gray-600 font-medium text-sm">Стоимость</strong>

                    <div class="flex flex-col">
                      <InputNumber
                        v-model="menu.price"
                        mode="currency"
                        currency="BYN"
                        showButtons
                        buttonLayout="horizontal"
                        decrementButtonClass="p-button-danger"
                        incrementButtonClass="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        aria-describedby="username2-help"
                        class="p-inputtext-sm"
                        :step="0.01"
                        :min="0"
                        :class="{ 'p-invalid': menu.errors.price }"
                      />
                      <small v-if="menu.errors.price" id="username2-help" class="p-error">Username is not available.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Фото</strong>
                <p class="text-sm text-gray-600 w-2/3">Фотография блюда. Миниатюра показывается всем пользователям</p>
              </div>
              <div class="col-span-3">
                <div class="px-4">
                  <div>
                    <FileUpload mode="basic" ref="photo" name="demo[]" />
                  </div>
                  <div class="grid autoGrid flex-wrap gap-4 existingPhotoContainer" v-if="1 == 2">
                    <img :src="baseURL + menu.photo" class="object-fit existingPhoto" />
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 border-b py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Доставка</strong>
                <p class="text-sm text-gray-600 w-2/3">Доступно ли блюдо для доставки</p>
              </div>
              <div class="col-span-3">
                <div class="px-4">
                  <div>
                    <SelectButton
                      v-model="menu.is_deliverable"
                      optionLabel="name"
                      optionValue="value"
                      :options="[
                        { name: 'Недоступно', value: 0 },
                        { name: 'Доступно', value: 1 }
                      ]"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-5 py-8">
              <div class="col-span-2 flex flex-col">
                <strong class="text-lg">Точки продаж</strong>
                <p class="text-sm text-gray-600 w-2/3">Выберите точки продаж, к которым относится эта услуга</p>
              </div>
              <div class="col-span-3">
                <div class="border rounded-lg shadow-lg p-4 flex flex-col space-y-6">
                  <MultiSelect
                    v-model="menu.places"
                    :options="places"
                    optionLabel="address_with_title"
                    optionValue="id"
                    placeholder="Выберите точки продаж"
                    display="chip"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-row flex-row-reverse space-x-4 mb-16">
              <Button class="p-button-success ml-4" label="Сохранить" @click="store" />
              <Button class="p-button-secondary" label="Отменить" @click="isEditorEnabled = false" />
            </div>
          </div>
          <!-- End Edit worker -->
        </div>
      </div>
    </div>

    <ConfirmPopup></ConfirmPopup>
  </div>
</template>

<script>
import TieredMenu from "primevue/tieredmenu";
import Button from "primevue/button";
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import ConfirmPopup from "primevue/confirmpopup";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import MultiSelect from "primevue/multiselect";
import FileUpload from "primevue/fileupload";
import InputNumber from "primevue/inputnumber";
import TreeSelect from "primevue/treeselect";
import SelectButton from "primevue/selectbutton";
import ProgressSpinner from "primevue/progressspinner";

export default {
  components: {
    ManagerSideMenu,
    ManagerHeader,
    ConfirmPopup,
    TieredMenu,
    Button,
    InputText,
    Textarea,
    MultiSelect,
    FileUpload,
    InputNumber,
    TreeSelect,
    SelectButton,
    ProgressSpinner
  },
  data() {
    return {
      isLoading: true,
      activeServiceId: null,
      serviceTypes: null,
      menu: {
        is_deliverable: 1,
        kitchen_id: null,
        errors: {
          title: false,
          description: false
        }
      },
      menus: null,
      kitchenTypes: null,
      services: null,
      service: {
        errors: {
          title: false,
          price: false,
          duration: false
        }
      },
      places: null,
      workers: null,
      isEditorEnabled: false,
      kitchen_id: null,
      listItemActions: [
        {
          label: "Редактировать",
          icon: "pi pi-pencil",
          command: () => {
            this.loadMenuDetails();
          }
        },
        {
          label: "Удалить",
          icon: "pi pi-trash",
          command: () => {
            this.$toast.add({ severity: "error", summary: "Delete", detail: "Data Deleted" });
          }
        }
      ]
    };
  },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(0, this.$http.defaults.baseURL.length - 3);
    }
  },
  mounted() {
    this.fetchMenus();
    this.fetchPlaces();
    this.fetchKitchenTypes();
  },
  methods: {
    setKitchenId(node) {
      this.kitchen_id = node.key;
    },
    store() {
      let form = new FormData();
      let route = "entity-place/menu";

      if (this.$refs.photo.files.length) {
        form.append("photo", this.$refs.photo.files[0]);
      }

      Object.keys(this.menu).map(k => {
        if (k == "places") {
          this.menu[k].map(item => {
            form.append("addresses[]", item);
          });
        } else if (k == "photo" || k == "errors" || k == "kitchen" || k == "addresses") {
          //Ignore photo path
        } else if (k == "kitchen_id") {
          form.append("kitchen_id", this.kitchen_id);
        } else {
          form.append(k, this.menu[k]);
        }
      });

      this.$http
        .post(route, form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.fetchMenus();
          this.isEditorEnabled = false;
          this.menu = {};
          this.menu.errors = {
            title: false,
            description: false,
            price: false
          };
        });
    },
    fetchPlaces() {
      this.$http.get("/entity-place/addressed").then(response => {
        this.places = response.data.addressed;
      });
    },
    loadMenuDetails() {
      this.$http.get("entity-place/menu/" + this.activeServiceId).then(response => {
        let data = response.data.menu;
        data.errors = {
          title: false
        };

        let kitchen_id = data.kitchen_id;
        data.kitchen_id = {};
        data.kitchen_id[kitchen_id] = true;
        this.kitchen_id = kitchen_id;

        if (data.addresses) {
          data.places = data.addresses.map(addr => {
            return addr.id;
          });
        }

        this.menu = data;
        this.isEditorEnabled = true;
      });
    },
    toggleListMenu(event, service_id) {
      this.activeServiceId = service_id;
      this.$refs.listItemMenu.toggle(event);
    },
    fetchMenus() {
      this.$http.get("entity-place/menu").then(response => {
        this.menus = response.data.menus;
        this.isLoading = false;
      });
    },
    fetchKitchenTypes() {
      this.$http.get("kitchen").then(response => {
        this.kitchenTypes = response.data.kitchens;
      });
    }
  }
};
</script>

<style scoped>
:deep(.p-multiselect),
:deep(.p-chips-multiple-container),
:deep(.p-treeselect),
:deep(.p-autocomplete-multiple-container) {
  border: 0;
}
</style>
