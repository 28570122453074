<template>
  <div>
    <Food />
  </div>
</template>

<script>
import Food from "@/components/Manager/Business/CreateFoodPage.vue";

export default {
  components: {
    Food
  },
  mounted() {
    this.$http.get("business").then(response => {
      let data = response.data.business;
      console.log(data);
    });
  }
};
</script>
