<template>
  <div class="flex items-center">
    <div class="z-10 flex flex-row h-20 items-center border-b-2 justify-between lg:hidden absolute w-full bg-white mx-0 left-0 px-4 py-2">
      <div class="flex flex-col items-start mt-1">
        <strong>Что ищем</strong>
        <AutoComplete
          @keyup="debouncedPredictions"
          type="text"
          class="w-full border-0 rounded-none"
          v-model="searchQuery"
          :suggestions="predictions"
          field="title"
          optionGroupLabel="label"
          optionGroupChildren="items"
          placeholder="Название услуги"
        />
      </div>
      <div>
        <div class="flex">
          <div
            @click="isFiltersDisplayed = !isFiltersDisplayed"
            class="flex flex-row space-x-2 font-semibold p-2 px-4 cursor-pointer rounded-xl items-center"
          >
            <span>Фильтры</span>
            <img :src="'/img/filters.png'" />
          </div>
          <div
            @click="find"
            class="flex flex-row space-x-2 font-semibold bg-accent text-white p-2 px-2 sm:px-4 cursor-pointer rounded-xl items-center"
          >
            <span class="hidden sm:flex">Найти</span>
            <div class="pr-2 sm:pr-0">
              <i class="pi pi-search text-lg"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isFiltersDisplayed"
      class="absolute bg-white w-full px-4 m-0 left-0 z-10 border-b-2"
    >
      <div class="py-4 flex flex-row justify-between">
        <div class="font-bold text-xl">Фильтры</div>

        <div
          class="mt-0.5 text-accent"
          @click="isFiltersDisplayed = false"
        >Отмена</div>
      </div>
      <div class="justify-between flex flex-row border-t py-4">
        <strong class="mt-1">Дата</strong>
        <div>
          <Calendar
            id="minmax"
            v-model="date"
            :placeholder="moment().format('DD.MM.Y')"
            dateFormat="dd.mm.yy"
            :minDate="new Date()"
            :manualInput="false"
            autocomplete="off"
            @date-select="isSearchDateUsed = true"
          />
        </div>
      </div>
      <div class="justify-between flex flex-row border-t py-4">
        <strong class="mt-1">Время</strong>
        <Calendar
          id="time24"
          v-model="time"
          placeholder="00:00"
          :timeOnly="true"
          :showTime="true"
          :stepMinute="10"
          :showSeconds="false"
          autocomplete="off"
          @date-select="isSearchTimeUsed = true"
        />
      </div>
      <div class="justify-between flex flex-row border-t py-4">
        <strong class="mt-1">Рядом</strong>
        <InputSwitch
          @click="toggleSearchNearby"
          :modelValue="isSearchNearby"
        />
      </div>
      <div class="justify-between flex flex-row border-t py-4">
        <strong class="mt-1">Акции</strong>
        <InputSwitch
          @click="toggleSearchCoupons"
          :modelValue="isSearchCoupons"
        />
      </div>
      <div class="pb-4">
        <div
          @click="isFiltersDisplayed = false"
          class="space-x-2 text-center font-semibold bg-accent text-white p-2 px-4 cursor-pointer rounded-xl items-center"
        >
          Закрыть
        </div>
      </div>
    </div>

    <div
      class="hidden lg:flex w-full h-auto rounded-xl bg-white lg:rounded-full"
      :class="{ 'lg:h-auto': condensed, 'lg:h-20': !condensed }"
    >
      <div
        class="h-auto lg:h-full content-left lg:content-center py-2 lg:py-0 md:space-x-6 md:flex flex-col md:flex-row relative w-full"
        :class="{
        'justify-between px-4 lg:px-10': !condensed && !noPx,
        'px-4 lg:px-10 hidden': condensed && !noPx,
        'px-0': noPx,
      }"
      >
        <div class="flex flex-row flex-grow text-left items-center">
          <div class="flex flex-col w-full">
            <label class="text-black text-sm font-semibold">Что ищем</label>

            <span
              class="w-full block 2xl:w-1/2 input-wide"
              :class="{ 'px-2 border border-accent rounded-sm': condensed }"
            >
              <AutoComplete
                @keyup="debouncedPredictions"
                type="text"
                class="w-full border-0 rounded-none"
                v-model="searchQuery"
                :suggestions="predictions"
                field="title"
                optionGroupLabel="label"
                optionGroupChildren="items"
                placeholder="Название услуги или заведения"
              />
            </span>
          </div>
        </div>

        <div class="flex flex-row justify-between">
          <div class="flex flex-row">
            <div class="flex flex-row text-left items-center pr-2">
              <div class="flex flex-row items-center space-x-2">
                <div class="flex flex-col items-start w-22 cursor-pointer">
                  <span
                    class="text-black text-sm font-semibold"
                    :class="{ 'text-accent': isSearchDateUsed }"
                  >Дата</span>

                  <Calendar
                    id="minmax"
                    v-model="date"
                    :placeholder="moment().format('DD.MM.Y')"
                    dateFormat="dd.mm.yy"
                    :minDate="new Date()"
                    :manualInput="false"
                    autocomplete="off"
                    @date-select="isSearchDateUsed = true"
                  />
                </div>

                <i
                  class="pi pi-times cursor-pointer opacity-30 hover:opacity-100"
                  v-if="isSearchDateUsed"
                  @click="
              () => {
                isSearchDateUsed = false;
                date = null;
              }
            "
                ></i>
              </div>
            </div>

            <div class="flex flex-row text-left items-center md:px-4 lg:border-l lg:border-r border-dashed">
              <div class="flex flex-row items-center space-x-2">
                <div class="flex flex-col items-start w-12 cursor-pointer">
                  <span
                    class="text-black text-sm font-semibold"
                    :class="{ 'text-accent': isSearchTimeUsed }"
                  >Время</span>

                  <Calendar
                    id="time24"
                    v-model="time"
                    placeholder="00:00"
                    :timeOnly="true"
                    :showTime="true"
                    :stepMinute="10"
                    :showSeconds="false"
                    autocomplete="off"
                    @date-select="isSearchTimeUsed = true"
                  />
                </div>

                <i
                  class="pi pi-times cursor-pointer opacity-30 hover:opacity-100"
                  v-if="isSearchTimeUsed"
                  @click="
              () => {
                isSearchTimeUsed = false;
                time = null;
              }
            "
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="pr-5 flex flex-row text-left items-center lg:border-r border-dashed">
            <div
              class="flex flex-col items-center w-full justify-center"
              :class="{ 'text-accent': isSearchNearby }"
            >
              <!-- <i class="pi pi-compass"></i> -->

              <span class="text-sm font-semibold">Рядом</span>
              <!-- <span class="text-sm font-semibold underline" v-if="condensed">Рядом</span> -->

              <!-- <i class="pi pi-check" v-if="isSearchNearby"></i> -->
              <InputSwitch
                @click="toggleSearchNearby"
                :modelValue="isSearchNearby"
              />
            </div>
          </div>

          <div class="px-3 flex flex-row text-left items-center">
            <div
              class="flex flex-col items-center w-full justify-center"
              :class="{ 'text-accent': isSearchCoupons }"
            >
              <!-- <i class="pi pi-compass"></i> -->

              <span class="text-sm font-semibold">Акции</span>
              <!-- <span class="text-sm font-semibold underline" v-if="condensed">Рядом</span> -->

              <!-- <i class="pi pi-check" v-if="isSearchNearby"></i> -->
              <InputSwitch
                @click="toggleSearchCoupons"
                :modelValue="isSearchCoupons"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center md:justify-end">
          <div
            @click="find"
            class="flex flex-row space-x-2 font-semibold bg-accent text-white p-2 
          px-4 cursor-pointer rounded-xl items-center mt-4 md:mt-0 md:relative bottom-2 md:bottom-0"
          >
            <span>Найти</span>
            <i class="pi pi-search text-lg"></i>
          </div>
        </div>
      </div>

      <OverlayPanel
        ref="predictionsPanel"
        :showCloseIcon="true"
        :dismissable="false"
        class="mt-5 hidden lg:block"
        style="max-width: 80%; min-width: 50%"
      >
        <div
          class="flex flex-col space-y-3"
          v-if="!isSearching && searchResults != [] && searchResults"
        >
          <div
            v-for="category in searchResults"
            :key="category.id"
            class="flex flex-col space-y-3"
          >
            <strong class="text-2xl">{{ category.title }}</strong>
            <div class="grid grid-cols-2 gap-4">
              <div
                class="cursor-pointer"
                v-for="item in category.items"
                :key="item.id"
                @click="
                $router.push('/details/' + item.category_id + '/' + item.id)
              "
              >
                <div class="flex flex-row items-center space-x-3">
                  <div class="w-20 h-20 rounded-lg overflow-hidden">
                    <img
                      :src="baseURL + item.place.logo"
                      class="object-none object-cover h-full"
                    />
                  </div>
                  <div>
                    <strong class="text-lg">{{ item.place.title }}</strong>
                    <p class="text-gray-500">{{ item.address }}</p>
                    <p class="text-gray-400">{{ item.place.slogan }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col space-y-2"
          v-if="isSearching"
        >
          <Skeleton
            width="25rem"
            height="2rem"
          />

          <div class="flex flex-row space-x-4 items-center">
            <Skeleton
              width="4rem"
              height="4rem"
            />
            <Skeleton
              width="20rem"
              height="2rem"
            />
          </div>
        </div>

        <div
          class="flex"
          v-if="!isSearching && searchResults == []"
        >
          <strong> Ничего не найдено </strong>
        </div>
      </OverlayPanel>
    </div>
  </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import OverlayPanel from "primevue/overlaypanel";
import Calendar from "primevue/calendar";
import Skeleton from "primevue/skeleton";
import InputSwitch from "primevue/inputswitch";
// import Checkbox from "primevue/checkbox";
import moment from "moment";

var _ = require("lodash");

export default {
  props: ["condensed", "noPx"],
  components: {
    AutoComplete,
    OverlayPanel,
    Calendar,
    Skeleton,
    InputSwitch,
    // Checkbox
  },
  data() {
    return {
      isFiltersDisplayed: false,
      isSearchDateUsed: false,
      isSearchTimeUsed: false,
      isSearchNearby: false,
      isSearchCoupons: false,
      date: null,
      time: null,
      isSearching: false,
      isOnMap: false,
      searchQuery: null,
      predictions: null,
      gps: null,
    };
  },
  created() {
    let self = this;

    this.debouncedSearch = _.debounce(function (e) {
      this.predictSearch(e);
    }, 500);
    this.debouncedPredictions = _.debounce(function () {
      self.fetchPredictions();
    }, 500);

    let searchData = this.$store.state.search;

    if (searchData) {
      this.searchQuery = searchData.q;
      this.date = searchData.date;
      this.time = searchData.time;
      this.gps = searchData.gps;
      this.isSearchNearby = searchData.isSearchNearby;
      this.isSearchCoupons = searchData.isSearchCoupons;
    }
  },
  methods: {
    find() {
      let search = {
        q: this.searchQuery ? this.searchQuery : "",
        date: this.date,
        time: this.time,
        isOnMap: this.isOnMap,
        gps: this.gps,
        isSearchNearby: this.isSearchNearby,
        isSearchCoupons: this.isSearchCoupons,
      };
      this.$store.commit("search", search);

      if (this.$route.path !== "/find") {
        this.$router.push("/find");
      } else {
        this.$emit("hasSearchChanges");
      }
    },
    moment(x) {
      return moment(x);
    },
    toggleSearchNearby() {
      if (this.isSearchNearby) {
        this.isSearchNearby = false;
        this.gps = null;
        return false;
      }

      navigator.geolocation.getCurrentPosition(
        this.locationSuccess,
        this.locationFail,
        {
          maximumAge: 10000,
          timeout: 5000,
          enableHighAccuracy: true,
        }
      );
    },

    toggleSearchCoupons() {
      this.isSearchCoupons = !this.isSearchCoupons;
    },

    locationSuccess(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const accuracy = position.coords.accuracy;

      console.log(latitude, longitude, accuracy);
      this.gps = JSON.stringify({ lat: longitude, lng: latitude });
      this.isSearchNearby = true;
    },
    locationFail(err) {
      if (err.code == 1) {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: "Вы должны разрешить доступ к определению геолокации",
          life: 3000,
        });
        this.isSearchNearby = false;
        return false;
      }

      this.$toast.add({
        severity: "error",
        summary: "Ошибка",
        detail: "Не удалось получить геолокацию",
        life: 3000,
      });
      this.isSearchNearby = false;
    },

    fetchPredictions() {
      if (this.searchQuery.length < 2) {
        return false;
      }

      this.$http.get("predict?query=" + this.searchQuery).then((response) => {
        let data = [];
        let services = response.data.services ? response.data.services : null;
        let places = response.data.places ? response.data.places : null;

        if (services.length) {
          services = services.map((i) => {
            return { title: i.title };
          });

          data.push({
            label: "Услуги",
            items: services,
          });
        }

        if (places.length) {
          places = places.map((i) => {
            return { title: i.title };
          });

          data.push({
            label: "Места",
            items: places,
          });
        }

        this.predictions = data ? data : null;
      });
    },
    togglePredictionsPanel(e) {
      if (!this.searchQuery) {
        return false;
      }

      if (this.searchQuery.length - 1 > 1) {
        this.$refs.predictionsPanel.show(e);
      } else {
        this.$refs.predictionsPanel.hide();
      }
    },
    assignFoundDataToCategories(data) {
      let categories = [];

      if (data.places.length > 0) {
        data.places.forEach((item) => {
          let isHavingCategory = categories.filter((category) => {
            return category.title == item.place_type.title;
          });

          if (isHavingCategory.length < 1) {
            categories.push({ items: [], ...item.place_type });
          }

          categories.forEach((category) => {
            if (category.title == item.place_type.title) {
              if (category.items.indexOf(item) === -1) {
                category.items.push(item);
              }
            }
          });
        });
      }

      let serviceTypes = [];

      Object.keys(data).map((type) => {
        if (type == "places") {
          return false;
        }

        if (data[type].length > 0) {
          data[type].forEach((item) => {
            if (item) {
              if (item.place.services.length > 0) {
                item.place.services.forEach((service) => {
                  let hasServiceInList = serviceTypes.filter((service_type) => {
                    return service_type.title == service.service_type.title;
                  });

                  if (hasServiceInList.length == 0) {
                    if (
                      service.service_type.title
                        .toLowerCase()
                        .indexOf(this.searchQuery.toLowerCase()) !== -1
                    ) {
                      serviceTypes.push({
                        items: [],
                        ...service.service_type,
                      });
                    }
                  }

                  if (serviceTypes.length > 0) {
                    serviceTypes.forEach((st) => {
                      if (service.service_type.title == st.title) {
                        if (
                          st.title
                            .toLowerCase()
                            .indexOf(this.searchQuery.toLowerCase()) !== -1
                        ) {
                          st.items.push(item);
                        }
                      }
                    });
                  }
                });
              }
            }
          });
        }
      });

      this.searchResults = [...categories, ...serviceTypes];
    },
    predictSearch() {
      if (!this.searchQuery) {
        return false;
      }

      this.searchResults = null;
      this.querySearchResults = null;
      this.isSearching = true;

      if (this.searchQuery.length > 2) {
        this.handleSearch(this.searchQuery).then((data) => {
          this.isSearching = false;
          this.querySearchResults = data.items;

          this.assignFoundDataToCategories(data.items);
        });
      }
    },
    showPredictionsPanel(event) {
      this.$refs.predictionsPanel.show(event);
    },
    hidePredictionsPanel() {
      this.$refs.predictionsPanel.hide();
    },
  },
};
</script>

<style scoped>
::v-deep .p-autocomplete-loader {
  top: 10px;
}

::v-deep .p-inputtext {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding: 0;
  width: 100%;
}

::v-deep .p-calendar {
  margin-top: 0.15rem;
}

::v-deep .input-wide {
  width: 100%;
}

::v-deep .input-wide input {
  width: 100%;
}

::v-deep .input-wide div {
  width: 100%;
}
</style>
