import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import Main from "../views/Home.vue";
import SearchResults from "../views/Search/SearchResults.vue";
import CategorySearchResults from "../views/Search/CategorySearchResults.vue";
import SearchQuery from "../views/Search/SearchQuery.vue";
import PlaceDetails from "../views/Place/PlaceDetails.vue";
import ManagerMain from "../views/Manager/Main.vue";
import Library from "../views/Manager/Library.vue";
import GoodsAndServices from "../views/Manager/GoodsAndServices.vue";
import FoodMenu from "../views/Manager/FoodMenu.vue";
import Places from "../views/Manager/Places.vue";
import Organization from "../views/Manager/Organization.vue";
import Support from "../views/Manager/Support.vue";
import Calendar from "../views/Manager/Calendar.vue";
import Business from "../views/Manager/Business.vue";
import Workers from "../views/Manager/Workers.vue";
import Coupons from "../views/Manager/Coupons.vue";
import Sales from "../views/Manager/Sales.vue";
import OrganizationClients from "../views/Manager/OrganizationClients.vue";
import Admin from "../views/Admin/Admin.vue";

// User
import UserSettings from "../views/User/Settings.vue";
import UserChat from "../views/User/Chat.vue";
import UserCoupons from "../views/User/Coupons.vue";
import UserFavorites from "../views/User/Favorites.vue";
import UserBookings from "../views/User/Bookings.vue";

// Pages
import About from "../views/Pages/About";
import Jobs from "../views/Pages/Jobs";
import BookingConditions from "../views/Pages/BookingConditions";
import Conditions from "../views/Pages/Conditions";
import Contacts from "../views/Pages/Contacts";
import CreateBusinessAccount from "../views/Pages/CreateBusinessAccount";
import FAQ from "../views/Pages/FAQ";
import ForPartners from "../views/Pages/ForPartners";
import PrivacyPolicy from "../views/Pages/PrivacyPolicy";
import UserSupport from "../views/Pages/UserSupport";

// Order
import CreateOrder from "../views/Order/Create.vue";

const routes = [
  // Pages
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/job",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/booking-conditions",
    name: "BookingConditions",
    component: BookingConditions,
  },
  {
    path: "/conditions",
    name: "Conditions",
    component: Conditions,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/create-business",
    name: "CreateBusinessAccount",
    component: CreateBusinessAccount,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/for-partners",
    name: "ForPartners",
    component: ForPartners,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/support",
    name: "UserSupport",
    component: UserSupport,
  },
  // Base
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/search",
    name: "Search Results",
    component: SearchResults,
  },
  {
    path: "/find",
    name: "Query Search Results",
    component: SearchQuery,
  },
  {
    path: "/beauty",
    name: "Beauty Results",
    component: SearchQuery,
  },
  {
    path: "/medicine",
    name: "Medicine Results",
    component: SearchQuery,
  },
  {
    path: "/education",
    name: "Edu Results",
    component: SearchQuery,
  },
  {
    path: "/food",
    name: "Food Results",
    component: SearchQuery,
  },
  {
    path: "/event",
    name: "Event Results",
    component: SearchQuery,
  },
  {
    path: "/cars",
    name: "Cars Results",
    component: SearchQuery,
  },
  {
    path: "/realty",
    name: "Realty Results",
    component: SearchQuery,
  },
  {
    path: "/search/tagged/:type/:id",
    name: "Category Search Results",
    component: CategorySearchResults,
    props: true,
  },
  {
    path: "/search/tagged/:type",
    name: "Category Type Search Results",
    component: CategorySearchResults,
    props: true,
  },
  {
    path: "/details/:type/:id",
    name: "Place Details",
    component: PlaceDetails,
    props: true,
  },
  {
    path: "/place/:id",
    name: "Place Details",
    component: PlaceDetails,
    props: true,
  },
  {
    path: "/mgr/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/manager",
    name: "Manager",
    component: ManagerMain,
  },
  {
    path: "/manager/lib",
    name: "Library",
    component: Library,
  },
  {
    path: "/manager/goods",
    name: "GoodsAndServices",
    component: GoodsAndServices,
  },
  {
    path: "/manager/food",
    name: "FoodMenu",
    component: FoodMenu,
  },
  {
    path: "/manager/places",
    name: "Places",
    component: Places,
  },
  {
    path: "/manager/coupons",
    name: "Coupons",
    component: Coupons,
  },
  {
    path: "/manager/org",
    name: "Organization",
    component: Organization,
  },
  {
    path: "/manager/sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/manager/clients",
    name: "Organization Clients",
    component: OrganizationClients,
  },
  {
    path: "/manager/workers",
    name: "Workers",
    component: Workers,
  },
  {
    path: "/manager/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/manager/business",
    name: "Business",
    component: Business,
  },
  {
    path: "/manager/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/user/settings",
    name: "User Settings",
    component: UserSettings,
  },
  {
    path: "/user/favorites",
    name: "User Favorites",
    component: UserFavorites,
  },
  {
    path: "/user/chat",
    name: "User Chat",
    component: UserChat,
  },
  {
    path: "/user/coupons",
    name: "User Coupons",
    component: UserCoupons,
  },
  {
    path: "/user/bookings",
    name: "User Bookings",
    component: UserBookings,
  },
  {
    path: "/order",
    name: "Create Order",
    component: CreateOrder,
  },

  //Correct Links
  {
    path: "/:category_id/:name",
    name: "Slug Place Details",
    component: PlaceDetails,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.afterEach(() => {
  store.commit("isManagerMenuDisplayed", false);
});

export default router;
