import { createStore } from "vuex";
import axios from "axios";

var moment = require("moment");
moment.locale("ru");

export default createStore({
  state: {
    search: {
      date: null,
      time: null,
      is_slots_only: false,
      is_open: false,
      is_date_only: false,
      is_time_only: false,
      is_category_mode: false,
      is_nearby: false,
      gps: null,
      is_subcategory_mode: false,
      category_type: null,
      subcategory_type: null,
      isOnMap: false,
      q: null
    },
    events: [],
    isManagerMenuDisplayed: false
  },
  getters: {
    search: state => {
      return state.search;
    },
    events: state => {
      return state.events;
    },
    isManagerMenuDisplayed: state => {
      return state.isManagerMenuDisplayed;
    }
  },
  mutations: {
    search(state, v) {
      if (typeof v.q === "object") {
        v.q = v.q.title;
      }

      state.search = v;
    },
    events(state, v) {
      state.events = v;
    },
    isManagerMenuDisplayed(state, v) {
      state.isManagerMenuDisplayed = v;
    },
    setSearchToCategoryMode(state, v) {
      state.search.category_id = v;
    },
    setSearchToSubCategoryMode(state, v) {
      state.search.is_subcategory_mode = true;
      state.search.subcategory_type = v;
    }
  },
  actions: {
    fetchEvents(context, v = null) {
      let orders = [];

      axios.get("organization/orders?date=" + v).then(response => {
        if (response.data.orders) {
          let raw = response.data.orders;

          if (!raw) {
            return false;
          }

          raw.forEach(item => {
            if (item.services) {
            item.services.forEach(service => {
              orders.push({
                // date: moment(order.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
                date: service.date,
                time: service.start_time ? service.start_time.substr(0, 2) : "-",
                full_time: service.start_time.substr(0, 5) + ' - ' + service.end_time.substr(0, 5),
                title: service.service ? service.service.title : "Недоступно",
                color: "#0086FF",
                isBlocked: service.is_blocked,
                worker: service.worker,
                service: service.service,
                obj: service
              });
            })
          }
          })

          // Object.keys(raw).map(k => {
          //   console.log(raw[k])
          //   if (raw[k]) {
          //     raw[k].forEach(order => {
          //       order.service.forEach(service => {
          //         console.log(service)
          //         orders.push({
          //           // date: moment(order.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
          //           date: service.date,
          //           time: service.start_time ? service.start_time.substr(0, 2) : "-",
          //           full_time: service.start_time + ' - ' + service.end_time,
          //           title: service.service ? service.service.title : "Недоступно",
          //           color: "#0086FF",
          //           isBlocked: service.is_blocked,
          //           worker: service.worker,
          //           service: service.service,
          //           obj: service
          //         });
          //       })
          //     });
          //   }
          // });

          context.commit("events", orders);
        }
      });
    }
  },
  modules: {}
});
