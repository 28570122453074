<template>
  <div class="relative">
    <Header />

    <div class="container mx-auto text-left py-16 lg:px-0">
      <h1 class="text-5xl font-semibold">О нас</h1>

      <div class="md:grid md:grid-cols-2 grid-rows-2 mt-8">
        <div class="flex flex-col md:flex-row space-x-8">
          <div class="rounded-2xl p-4 md:p-0">
            <img
              style="width:520px"
              src="img/demo.jpg"
            />
          </div>
          <div class="flex flex-col my-4">
            <strong class="text-3xl font-medium">Абрамов С. А.</strong>
            <span class="py-1 text-lg text-gray-600">Генеральный директор</span>
            <p class="text-sm pb-4 mt-5 leading-6">
              Сергей - идейный вдохновитель компании и ее основатель. Окончил факультет предпринимателсьтва и управления в БГАТУ по специальности
              "Экономика и организация производства". Имеет успешный опыт ведения бизнеса в различных отраслях и сферах с 2005 года.
            </p>
            <i class="font-light text-sm">“sadhasjkfg sfsjd повы gdsfsj”</i>
          </div>
        </div>
        <div></div>
        <div></div>
        <div class="md:flex md:flex-row flex-row-reverse text-right space-x-4">
          <div class="rounded-2xl p-4 md:p-0">
            <img
              style="width:520px"
              src="img/demo.jpg"
            />
          </div>
          <div class="flex flex-col pr-8 my-4">
            <strong class="text-3xl font-medium">Тендера М.Н.</strong>
            <span class="py-1 text-lg text-gray-600">Операционный директор</span>
            <p class="text-sm pb-4 mt-5 leading-6">
              Максим – один из основателей компании. Опыт работы на руководящих должностях более 10 лет. Имеет высшее юридическое образование,
              специализация «Право». С 2011 по 2021 работал в сфере международных инвестиций в Молдове, России, Румынии, Украине и Беларуси.
            </p>
            <i class="font-light text-sm">"Маркетплейсов в сети интернет много, в тоже время для того, чтобы он стала любимым,
              он должен стать по-настоящему идеальным."</i>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-50">
      <div class="container mx-auto py-16 text-left relative">
        <div
          class="absolute"
          style="top:110px;left:-50px"
        >
          <img src="img/ornament.png" />
        </div>
        <h2 class="text-5xl font-semibold px-4 md:p-0">О компании</h2>
        <p class="md:w-2/6 pt-6 px-4">
          ООО "АБТЕ" основана в 2020 году. Наша деятельность сосредоточена на разработке и производстве технологических продуктов как для владельцев
          бизнеса, так и для обычных пользователей сети интернет.
        </p>
      </div>
    </div>

    <div class="">
      <div class="container mx-auto py-16 text-right relative flex flex-col items-end">
        <div
          class="absolute"
          style="top:110px;right:-40px"
        >
          <img src="img/ornament.png" />
        </div>
        <h2 class="text-5xl font-semibold px-4 md:p-0">О проекте</h2>

        <p class="md:w-2/6 px-4 pt-6">
          Мы разработали и теперь внедряем уникальную систему интернет-бронирования в сфере услуг и предпринимательства с помощью сайта и мобильного
          приложения <span class="text-accent font-medium">Reserv.by</span>
        </p>
        <div
          class="rounded-3xl cursor-pointer bg-accent text-white text-center py-2 mt-2 mr-4 md:mr-0"
          style="width:250px"
        >Скачать приложение</div>
      </div>
    </div>

    <div class="bg-gray-50">
      <div class="container mx-auto py-16 text-left relative">
        <div
          class="absolute"
          style="top:110px;left:-50px"
        >
          <img src="img/ornament.png" />
        </div>
        <h2 class="text-5xl font-semibold px-4 md:p-0">Наши цели</h2>
        <p class="md:w-2/6 px-4 md:p-0 pt-6">
          Ключевой миссией <span class="text-accent font-medium">Reserv.by</span> является объединение клиентов с предприятиями сферы обслуживания,
          путем бронирования и оказания услуг. Мы стремимся быть наиболее востребованныой площадки с точки зрения удобства и качества клиентского
          обслуживания.
        </p>
      </div>
    </div>

    <div
      class="bg-bg-wider lg:text-left"
      v-if="1 == 2"
    >
      <div class="container mx-auto py-24 text-white">
        <h1 class="text-4xl font-semibold">О проекте</h1>
      </div>
    </div>
    <div
      class="container mx-auto text-left px-4 lg:px-0"
      v-if="1 == 2"
    >
      <div class="py-4 lg:py-16">
        <p class="font-semibold text-gray-500 text-2xl">RESERV.BY — мост между клиентом и бизнесом</p>
        <p class="mt-6 w-full lg:w-1/2">Глобальная площадка по быстрому поиску, продвижению услуг и их бронирования в удобное для клиента время.</p>
        <img
          src="/img/about/tags.svg"
          class="w-full mt-4"
        />

        <p class="font-semibold text-gray-500 text-2xl mt-28">Сервисы, которые мы предоставляем:</p>
        <div class="lg:grid grid-cols-2 gap-6 mt-6">
          <div>
            <div
              class="border-b-2 py-5 font-semibold"
              style="border-color: #84B9E9"
            >
              бронирование онлайн
            </div>
            <div
              class="border-b-2 py-5 font-semibold"
              style="border-color: #84B9E9"
            >
              предзаказ услуг
            </div>
            <div
              class="border-b-2 py-5 font-semibold"
              style="border-color: #84B9E9"
            >
              оплата онлайн
            </div>
          </div>
          <div>
            <div
              class="border-b-2 py-5 font-semibold"
              style="border-color: #84B9E9"
            >
              онлайн консультации со специалистом
            </div>
            <div
              class="border-b-2 py-5 font-semibold"
              style="border-color: #84B9E9"
            >
              скидки, акции, новинки
            </div>
          </div>
        </div>

        <p class="font-semibold text-gray-500 text-2xl mt-28">Как это работает:</p>
        <img
          src="/img/about/flow.jpg"
          class="w-full mt-16"
        />

        <div class="lg:grid grid-cols-5 mt-24 gap-6">
          <div class="col-span-3 space-y-8">
            <div>
              <div class="border rounded-3xl">
                <h1 class="font-semibold text-2xl p-5">Уникальная для Беларуси поисковая система</h1>
              </div>
              <p class="bg-white relative -top-4 left-3 mr-8 pl-2">
                клиент выбирает необходимое ему время и день, система работает от времени на которое клиент хочет записаться
              </p>
            </div>
            <div>
              <div class="border rounded-3xl">
                <h1 class="font-semibold text-2xl p-5">Экономия на продвижении своего сайта</h1>
              </div>
              <p class="bg-white relative -top-4 left-3 mr-8 pl-2">
                мы решаем проблему скликиваний вашего сайта недобросовестными конкурентами
              </p>
            </div>
            <div>
              <div class="border rounded-3xl">
                <h1 class="font-semibold text-2xl p-5">Новый канал маркетинга</h1>
              </div>
              <p class="bg-white relative -top-4 left-3 mr-8 pl-2">
                бизнес может размещать как информацию о своем заведении, так и акции, скидки, новинки своего заведения
              </p>
            </div>
            <div>
              <div class="border rounded-3xl">
                <h1 class="font-semibold text-2xl p-5">Возможность стать лучше</h1>
              </div>
              <p class="bg-white relative -top-4 left-3 mr-8 pl-2">
                если Вы обновили команду специалистов, а до этого ваше заведение было с невысоким рейтингом, есть возможность его обнуления (не чаще
                чем раз в год)
              </p>
            </div>
            <div>
              <div class="border rounded-3xl">
                <h1 class="font-semibold text-2xl p-5">Онлайн оплата</h1>
              </div>
              <p class="bg-white relative -top-4 left-3 mr-8 pl-2">
                на предзаказы и на консультации со специалистами
              </p>
            </div>
          </div>
          <div class="col-span-2 hidden lg:block">
            <img
              src="/img/about/sales.jpg"
              class="w-full object-top"
            />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
