<template>
  <div class="flex -mt-20">
    <ManagerSideMenu ref="sm" />

    <div class="w-full text-left ml-0 lg:ml-280">
      <ManagerHeader title="Продажи" />

      <div class="px-4 py-4 lg:px-12 lg:py-6">
        <ManagerSales @startChat="startChat" />
      </div>
    </div>
  </div>
</template>

<script>
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import ManagerSales from "@/components/Manager/ManagerSales";

export default {
  components: { ManagerSideMenu, ManagerHeader, ManagerSales },
  methods: {
    startChat(target_id) {
      this.$refs.sm.$refs.chat.createChat(target_id);
    }
  }
};
</script>
