<template>
  <div
    @click="redirect"
    class="service-card cursor-pointer"
    v-if="data"
  >
    <div class="mb-1.5 title-image relative">
      <div
        v-if="data.hasCoupons"
        class="absolute rounded-lg -right-1 -top-1 bg-yellow-400 px-2 text-black font-medium pt-0.5"
      >Есть акция</div>
      <img
        class="w-full card-photo object-cover object-bottom rounded-lg"
        v-if="data.logo"
        :src="baseURL + data.logo.photo"
      />
      <div
        class="flex absolute bottom-0 right-0 bg-white px-2 items-center rounded-tl-md lg:hidden"
        v-if="data.geo"
      >
        <div class="rounded-full w-2 h-2 bg-blue-400 self-center mb-0 mr-1"></div>
        <p v-if="1 == 2">{{ data.geo.district }}</p>
      </div>
    </div>

    <p class="text-xl leading-5 font-semibold tracking-tight">{{ data.title }}</p>
    <p class="text-gray-500 mt-1.5 lg:block tracking-tight truncate">{{ data.short_description }}</p>

    <div
      class="text-gray-500 hidden lg:flex"
      v-if="1 == 2"
    >
      <div class="rounded-full w-2 h-2 bg-blue-400 self-center mb-0 mr-2"></div>
      <p class="font-medium tracking-tight">{{ data.geo.district }}</p>
    </div>

    <div class="flex items-center space-x-5 mt-1.5">
      <div class="flex flex-row space-x-5">
        <div
          class="text-gray-500"
          v-if="1 == 2"
        >
          {{ data.placeable.place_type.title }}
        </div>
        <div class="flex flex-row items-center font-semibold">
          <img
            class="object-none object-center mb-1"
            src="/img/star_1.png"
          />
          <span
            class="pl-1"
            v-if="data.rate"
          >{{ data.rate }}</span>
          <span
            class="pl-1 font-normal"
            v-else
          >нет</span>
        </div>
      </div>

      <div
        class="flex"
        v-if="1 == 2"
      >
        <span class="font-semibold">Цены</span>

        <div
          class="ml-1.5 self-center flex space-x-1"
          v-if="1 == 2"
        >
          <div
            class="rounded-full w-2.5 h-2.5"
            v-for="(idx, pos) in [1, 1, 1]"
            :class="{
              'bg-gray-300': pricing < pos + 1,
              'bg-yellow-400': pricing >= pos + 1
            }"
            :key="pos"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    placePhoto() {
      if (typeof this.$props.data !== "undefined") {
        return this.baseURL + this.$props.data.logo;
      }

      return null;
    },
    baseURL() {
      return this.$http.defaults.baseURL.substr(
        0,
        this.$http.defaults.baseURL.length - 3
      );
    },
    pricing() {
      let price;

      if (this.$props.data.placeable.pricing) {
        price = JSON.parse(this.$props.data.placeable.pricing).name;
      }

      let pricingRange = 2;

      switch (price) {
        case "Дёшево":
          pricingRange = 1;
          break;
        case "Средне":
          pricingRange = 2;
          break;
        case "Дорого":
          pricingRange = 3;
          break;
        default:
          pricingRange = 2;
      }

      return pricingRange;
    },
    generalRating() {
      let all = this.totalCommentsCount;
      let score = 0;

      if (!all) {
        return 0;
      }

      for (let r1 = 0; r1 < this.commentsCountByRate.rate1; r1++) {
        score = score + 1;
      }
      for (let r2 = 0; r2 < this.commentsCountByRate.rate2; r2++) {
        score = score + 2;
      }
      for (let r3 = 0; r3 < this.commentsCountByRate.rate3; r3++) {
        score = score + 3;
      }
      for (let r4 = 0; r4 < this.commentsCountByRate.rate4; r4++) {
        score = score + 4;
      }
      for (let r5 = 0; r5 < this.commentsCountByRate.rate5; r5++) {
        score = score + 5;
      }

      return (score / all).toFixed(1);
    },
  },
  methods: {
    redirect() {
      const pageCheck = this.$route.name !== 'Slug Place Details'

      if (pageCheck) {
        this.$router.push({
          name: 'Slug Place Details',
          params: { category_id: this.getCategorySlug(this.data.category_id), name: this.data.slug }
        })
      }
    },
    getCategorySlug(id) {
      let slug;

      switch (id) {
        case 5:
          slug = "cars";
          break;
        case 3:
          slug = "medicine";
          break;
        case 6:
          slug = "realty";
          break;
        case 7:
          slug = "education";
          break;
        case 1:
          slug = "food";
          break;
        case 2:
          slug = "event";
          break;
        case 4:
          slug = "beauty";
          break;
      }

      return slug;
    },
    fetchPlaceComments(id) {
      if (id) {
        this.$http.get("comments/" + id).then((response) => {
          if (response.data.count) {
            this.totalCommentsCount = response.data.comments.total;
            this.commentsCountByRate = response.data.count;
          }
        });
      }
    },
  },
  data() {
    return {
      totalCommentsCount: 0,
      commentsCountByRate: {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
      },
    };
  },
  // mounted() {
  //   if (typeof this.$props.data.place !== "undefined") {
  //     this.fetchPlaceComments(this.$props.data.place.id);
  //   }
  // }
};
</script>

<style scoped>
.service-card:hover .title-image::after {
  opacity: 0.2;
}
.title-image {
  position: relative;
}
.title-image::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  border-radius: 6px;
  transition: opacity 300ms ease 0s;
  top: 0;
  left: 0;
  opacity: 0;
}
</style>
