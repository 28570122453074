<template>
  <div class="flex -mt-20">
    <ManagerSideMenu />

    <div class="w-full text-left ml-0 lg:ml-280">
      <ManagerHeader title="Служба поддержки" />

      <div class="px-4 py-4 lg:px-12 lg:py-6">
        <ManagerSupport />
      </div>
    </div>
  </div>
</template>

<script>
import ManagerSideMenu from "@/components/Manager/ManagerSideMenu";
import ManagerHeader from "@/components/Manager/ManagerHeader";
import ManagerSupport from "@/components/Manager/ManagerSupport";

export default {
  components: { ManagerSideMenu, ManagerHeader, ManagerSupport }
};
</script>
