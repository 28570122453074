<template>
  <div class="container content mx-auto text-left py-6 px-4 lg:px-0">
    <h1 class="text-3xl font-semibold">Акции</h1>

    <div class="mt-6">
      <div class="grid grid-cols-4 gap-8 gap-y-12">
        <div class="flex flex-col" v-for="i in [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]" :key="i">
          <div class="rounded-lg bg-gray-200 h-44"></div>
          <span class="text-gray-600 mt-1.5">10.12.2020 - 09.01.2020</span>
          <strong class="text-lg font-semibold">10% скидка на маникюр</strong>
          <strong class="text-gray-500 mt-2">Название салона</strong>
          <p class="text-gray-500">Адрес заведения одна строка с про...</p>
        </div>
      </div>

      <div class="text-center space-y-5" v-if="1 == 2">
        <h2 class="text-2xl font-semibold">Нет доступных акций</h2>
        <p>Купоны начисляются за некоторые виды услуг</p>
        <Button label="Перейти в каталог" @click="$router.push('/search')" />
      </div>

      <div v-else></div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";

export default {
  components: {
    Button
  }
};
</script>
