<template>
  <div>
    <Header />

    <div class="bg-bg-wider lg:text-left px-4 md:px-0">
      <div class="container mx-auto py-24 text-white">
        <h1 class="text-4xl font-semibold">Политика конфиденциальности</h1>
        <h2>Какие персональные данные мы собираем и с какой целью</h2>
      </div>
    </div>
    <div class="container mx-auto text-left flex flex-col py-8 px-4 md:px-0">
      <strong>КОММЕНТАРИИ</strong>
      <p>
        Если посетитель оставляет комментарий на сайте, мы собираем данные указанные в форме комментария, а также IP адрес посетителя и данные
        user-agent браузера с целью определения спама.
      </p>
      <p class="pt-8">
        <strong>МЕДИАФАЙЛЫ</strong>
      </p>

      <p>
        Если вы зарегистрированный пользователь и загружаете фотографии на сайт, вам возможно следует избегать загрузки изображений с метаданными
        EXIF, так как они могут содержать данные вашего месторасположения по GPS. Посетители могут извлечь эту информацию скачав изображения с сайта.
      </p>
      <p class="pt-8">
        <strong>КУКИ</strong>
      </p>

      <p>
        Если вы оставляете комментарий на нашем сайте, вы можете включить сохранение вашего имени, адреса email и вебсайта в куки. Это делается для
        вашего удобства, чтобы не заполнять данные снова при повторном комментировании. Эти куки хранятся в течение одного года.
      </p>
      <p></p>
      <p>
        Если у вас есть учетная запись на сайте и вы войдете в неё, мы установим временный куки для определения поддержки куки вашим браузером, куки
        не содержит никакой личной информации и удаляется при закрытии вашего браузера.
      </p>
      <p></p>
      <p>
        При входе в учетную запись мы также устанавливаем несколько куки с данными входа и настройками экрана. Куки входа хранятся в течение двух
        дней, куки с настройками экрана – год. Если вы выберете возможность “Запомнить меня”, данные о входе будут сохраняться в течение двух недель.
        При выходе из учетной записи куки входа будут удалены.
      </p>
      <p></p>
      <p>
        При редактировании или публикации статьи в браузере будет сохранен дополнительный куки, он не содержит персональных данных и содержит только
        ID записи отредактированной вами, истекает через 1 день.
      </p>
      <p class="pt-8">
        <strong>ВСТРАИВАЕМОЕ СОДЕРЖИМОЕ ДРУГИХ ВЕБСАЙТОВ</strong>
      </p>

      <p>
        Статьи на этом сайте могут включать встраиваемое содержимое (например видео, изображения, статьи и др.), подобное содержимое ведет себя так
        же, как если бы посетитель зашел на другой сайт.
      </p>
      <p></p>
      <p>
        Эти сайты могут собирать данные о вас, использовать куки, внедрять дополнительное отслеживание третьей стороной и следить за вашим
        взаимодействием с внедренным содержимым, включая отслеживание взаимодействия, если у вас есть учетная запись и вы авторизовались на том сайте.
      </p>
      <p class="pt-8">
        <strong>КАК ДОЛГО МЫ ХРАНИМ ВАШИ ДАННЫЕ</strong>
      </p>

      <p>
        Если вы оставляете комментарий, то сам комментарий и его метаданные сохраняются неопределенно долго. Это делается для того, чтобы определять и
        одобрять последующие комментарии автоматически, вместо помещения их в очередь на одобрение.
      </p>
      <p></p>
      <p>
        Для пользователей с регистрацией на нашем сайте мы храним ту личную информацию, которую они указывают в своем профиле. Все пользователи могут
        видеть, редактировать или удалить свою информацию из профиля в любое время (кроме имени пользователя). Администрация вебсайта также может
        видеть и изменять эту информацию.
      </p>
      <p class="pt-8">
        <strong>КАКИЕ У ВАС ПРАВА НА ВАШИ ДАННЫЕ</strong>
      </p>

      <p>
        При наличии учетной записи на сайте или если вы оставляли комментарии, то вы можете запросить файл экспорта персональных данных, которые мы
        сохранили о вас, включая предоставленные вами данные. Вы также можете запросить удаление этих данных, это не включает данные, которые мы
        обязаны хранить в административных целях, по закону или целях безопасности.
      </p>
      <p class="pt-8">
        <strong>КУДА МЫ ОТПРАВЛЯЕМ ВАШИ ДАННЫЕ</strong>
      </p>

      <p>Комментарии пользователей могут проверяться автоматическим сервисом определения спама.</p>
      <p></p>

      <p class="pt-8"><strong>КОНТАКТНАЯ ИНФОРМАЦИЯ</strong></p>
      <p></p>
      <p>ООО "АБТЕ"</p>
      <p></p>
      <p>
        E-mail: <a class="text-blue-400" href="mailto:info@reserv.by">info@reserv.by</a> /
        <a class="text-blue-400" href="mailto:mail@reserv.by">mail@reserv.by</a>
      </p>
      <p></p>
      <p>
        Телефон: <a class="text-blue-400" href="tel:+375295751800">+375 (29) 575-18-00</a> /
        <a class="text-blue-400" href="tel:+375296558003">+375 (29) 655-80-03</a>
      </p>
      <p></p>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
