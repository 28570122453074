<template>
  <div class="home">
    <Header ref="header" />
    <Main @partnerSignUp="showPartnerSignUpForm" />
    <Footer @toggleCreateBusiness="showPartnerSignUpForm" />
  </div>
</template>

<script>
// @ is an alias to /src
import Main from "@/components/Main.vue";
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Main,
    Header,
    Footer,
  },
  methods: {
    showPartnerSignUpForm() {
      this.$refs.header.isCreatingPartnerAccount = true;
    },
  },
};
</script>
