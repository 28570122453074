<template>
  <div class="placedetails">
    <Header />
    <PlaceDetailsComponent
      :type="type"
      :id="id"
      :name="name"
      :category_id="category_id"
    />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import PlaceDetailsComponent from "@/components/Place/PlaceDetailsComponent.vue";
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    PlaceDetailsComponent,
    Header,
    Footer,
  },
  props: ["type", "id", "name", "category_id"],
};
</script>
