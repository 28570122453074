<template>
  <div style="background: linear-gradient(180deg, #F8F8F8 -45.04%, rgba(248, 248, 248, 0) 88.56%);">
    <Header ref="header" />

    <div class="container mx-auto px-4 lg:px-0">
      <div class="flex space-x-4 justify-between my-4 lg:my-16 py-10">
        <div class="py-16 flex flex-col">
          <h1 class="w-full text-left lg:pt-12 text-5xl font-semibold">
            <span class="text-white bg-accent pt-2 px-1">RESERV.BY</span> помогает найти клиентов во всех сферах услуг
          </h1>
          <p class="text-left w-full mt-4 leading-8">
            вне зависимости от размера и характера Вашего бизнеса. Выбирайте пакет услуг, наиболее подходящий для Ваших нужд и улучшите свои продажи
            через день.
          </p>
          <div
            class="rounded-3xl py-3 font-semibold text-white w-full lg:w-2/6 px-4 mt-4 shadow-lg cursor-pointer"
            style="background: #0086FF"
            @click="showPartnerSignUpForm"
          >
            Зарегистрироваться
          </div>
        </div>
        <div class="hidden lg:flex w-full">
          <img
            class="object-contain w-full"
            src="img/calendar.jpg"
          />
        </div>
      </div>

      <div class="bg-white flex flex-col lg:flex-row justify-between w-full lg:w-3/4 mx-auto lg:shadow-xl p-0 space-y-6
        lg:space-y-0 lg:p-12 rounded-lg mt-16 lg:mt-0">
        <div class="flex space-x-6">
          <img
            class="object-none"
            src="img/user.png"
          />
          <div class="flex flex-col text-left pt-1">
            <strong class="text-xl font-bold">25000+</strong>
            <p>Пользователей</p>
          </div>
        </div>
        <div class="flex space-x-6">
          <img
            class="object-none"
            src="img/locationx.png"
          />
          <div class="flex flex-col text-left pt-1">
            <strong class="text-xl font-bold">250000+</strong>
            <p>Посещений</p>
          </div>
        </div>
        <div class="flex space-x-6">
          <div
            class="rounded-full flex w-14 h-14"
            style="background-color:#E1F1FF"
          >
            <img
              class="object-none"
              src="img/people.png"
            />
          </div>
          <div class="flex flex-col text-left pt-1">
            <strong class="text-xl font-bold">2000+</strong>
            <p>Партнёров</p>
          </div>
        </div>
      </div>

      <div
        class="flex justify-between my-20"
        v-if="1 == 2"
      >
        <div class="w-2/3 hidden lg:block">
          <img src="img/illustration2.jpg" />
        </div>
        <div class="text-left">
          <h2 class="leading-8 text-3xl font-semibold">Увеличивайте объемы продаж, привлекая новых клиентов онлайн</h2>
          <p class="py-6 text-sm text-gray-600">Что получают партнёры</p>
          <ul class="flex flex-col space-y-4">
            <li class="flex"><img
                class="object-none pr-2"
                src="img/check-green.png"
              /> Повышаем оборот и прибыль</li>
            <li class="flex"><img
                class="object-none pr-2"
                src="img/check-green.png"
              /> Пополняем клиентскую базу</li>
            <li class="flex"><img
                class="object-none pr-2"
                src="img/check-green.png"
              /> Автоматизация бизнес-процессов</li>
            <li class="flex"><img
                class="object-none pr-2"
                src="img/check-green.png"
              /> Статистика</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mt-32 container mx-auto">
      <h2
        class="text-3xl font-semibold"
        style="color:#0B132A"
      >Как это работает</h2>
      <p class="pt-4">Ищи и находи то, что ищешь. Лучшие места здесь для вас.</p>

      <div class="grid grid-cols-1 gap-y-20 md:gap-y-0 md:grid-cols-3 gap-x-10 mt-28">
        <div class="flex flex-col">
          <img
            class="object-none object-top h-64"
            src="img/hiw1.png"
          />
          <span class="font-medium px-4 text-accent">Клиент выбирает время и район</span>
        </div>
        <div class="flex flex-col">
          <img
            class="object-none object-top h-64"
            src="img/hiw2.png"
          />
          <span class="font-medium px-4 text-accent">Платформа формирует подобранные заведения с рейтингом и стоимостью</span>
        </div>
        <div class="flex flex-col">
          <img
            class="object-none object-top h-64"
            src="img/hiw2.png"
          />
          <span class="font-medium px-4 text-accent">Клиент резервирует данное время и в течение 20 минут получает подтверждение</span>
        </div>
      </div>
    </div>

    <div class="mt-32 container mx-auto">
      <h2
        class="text-3xl font-semibold"
        style="color:#0B132A"
      >Выбери свой план</h2>
      <p class="pt-4">
        Давайте выберем пакет, который вам больше<br />
        всего подходит, и рассмотрим его.
      </p>

      <div class="grid grid-cols-3 mt-28">
        <div class="flex flex-col space-y-6 text-left">
          <div class="py-6">&nbsp;</div>
          <div class="py-6 text-accent font-semibold text-xl">
            3 месяца
          </div>
          <div class="py-6 text-accent font-semibold text-xl">
            6 месяцев
          </div>
          <div class="py-6 text-accent font-semibold text-xl">
            1 год
          </div>
        </div>
        <div
          class="flex flex-col space-y-6 text-center rounded-tl-2xl rounded-bl-2xl"
          style="background:#6C6C6C"
        >
          <div class="py-4 text-white font-semibold text-xl">
            <div class="rounded-full border-2 py-1 mx-4 font-normal text-lg flex text-center"><span class="mt-1 w-full">Стандартный пакет</span></div>
          </div>
          <div class="py-7 text-white font-semibold text-xl">
            Бесплатно
          </div>
          <div class="py-4 text-white font-semibold text-xl">
            Бесплатно
          </div>
          <div class="py-4 text-white font-semibold text-xl">
            Бесплатно
          </div>
        </div>
        <div
          class="flex flex-col space-y-6 text-center rounded-tr-2xl rounded-br-2xl text-black"
          style="background:#FFE819"
        >
          <div class="py-4 font-semibold text-xl">
            <div class="rounded-full border-2 border-black py-1 mx-4 font-normal text-lg flex text-center">
              <span class="mt-1 w-full">Всё включено</span>
            </div>
          </div>
          <div class="py-4 font-semibold">
            <strong class="text-xl">? Br</strong>
          </div>
          <div class="py-4 font-semibold">
            <strong class="text-xl">? Br</strong>
          </div>
          <div class="py-4 font-semibold">
            <strong class="text-xl">? Br</strong>
          </div>
        </div>
      </div>

      <div class="flex flex-col space-y-8 mt-8 pb-24">
        <div class="grid grid-cols-3 text-left">
          <div>Акции и скидки услуг*</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Бронирование услуг клиентами</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Видео-обзор услуг</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Интеграция с Instagram*</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Контент-менеджер персональной страницы</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Личный кабинет</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Модерация информации</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Онлайн календарь</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Онлайн-оплата услуг</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Онлайн-чат с клиентами</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Отзывы и оценки клиентов</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Реклама в Рекомендациях*</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Реклама в соц сетях*</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Уникальная поисковая система</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Фотосессия (помещение+ персонал)*</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Cтатистика акций и скидок</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Cтатистика посещений</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Cтатистика продаж</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Cтатистика услуг</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/no.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div>Push-уведомления</div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
          <div class="flex items-center"><img
              class="mx-auto"
              src="img/yes.png"
            /></div>
        </div>
        <div class="grid grid-cols-3 text-left">
          <div class="text-sm">* Указанные услуги могут быть<br />приобретены отдельно</div>
          <div class="flex items-center">
            <div
              class="border-accent cursor-pointer text-accent hover:bg-accent
            hover:text-white shadow-lg font-semibold px-10 py-2 mx-auto border-2 rounded-full"
              @click="showPartnerSignUpForm"
            >
              Выбрать
            </div>
          </div>
          <div class="flex items-center">
            <div class="border-accent cursor-pointer text-accent hover:bg-accent
              hover:text-white shadow-lg font-semibold px-10 py-2 mx-auto border-2 rounded-full">
              Временно недоступно
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style=""
      class="px-4 lg:px-0"
      v-if="1 == 2"
    >
      <div class="container mx-auto py-16">
        <h2 class="text-3xl font-semibold">Выбери свой план</h2>
        <p class="mt-4 text-sm">Давайте выберем пакет, который вам больше всего подходит, и рассмотрим его.</p>

        <div class="flex flex-col lg:grid grid-cols-3 gap-6 lg:mt-12">
          <div class="border rounded-xl p-2 mt-12 relative">
            <div
              class="rounded-xl py-12 flex flex-col"
              style="background: #454545"
            >
              <span class="border-2 rounded-2xl border-white text-white px-8 py-2 font-semibold w-3/4 mx-auto">
                Бюджетный план
              </span>
              <span class="font-semibold text-white pt-6 text-2xl">30BYN / мес</span>
            </div>

            <ul class="mt-4 p-4 flex flex-col space-y-4">
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Профессиональные фотографии организации + специалистов
              </li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Профессиональная редакция информации о организации и их виде деятельности +
                биография их специалистов
              </li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Размещение в приложении на поиск и бронирование
              </li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Рейтинг, отзывы</li>
            </ul>

            <div
              class="lg:absolute w-2/3 mx-auto cursor-pointer
            left-0 right-0 bottom-4 rounded-2xl border-blue-400 hover:bg-blue-400
            hover:text-white text-blue-400 font-semibold border-2 lg:mt-8 mx-8 py-2"
              @click="$router.push('/conditions')"
            >
              Выбрать
            </div>
          </div>

          <div class="border-2 shadow-lg border-blue-400 rounded-xl p-2 relative">
            <div
              class="rounded-xl py-16 flex flex-col relative"
              style="background: #FFE81A"
            >
              <span
                class="z-10 border-2 rounded-2xl text-white px-8 py-2 font-semibold w-3/4 mx-auto"
                style="border-color: #0B132A; color: #0B132A"
              >
                Премиум план
              </span>
              <span
                class="z-10 font-bold text-white pt-6 text-3xl"
                style="color:#0B132A"
              >150BYN / мес</span>
              <span class="z-10 absolute bottom-4 left-0 right-0">❤️ Рекомендуем </span>
            </div>

            <ul class="mt-4 p-4 flex flex-col space-y-4 mb-16">
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Размещение в приложении на поиск и бронирование
              </li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Рейтинг, отзывы</li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Профессиональные фотографии организации + специалистов
              </li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Профессиональная редакция информации о организации и их виде деятельности +
                биография их специалистов
              </li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Акции, Скидки, Новинки</li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Бронирование онлайн</li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Сервис «Сберечь еду»</li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Обзор/пост заведения в инстаграмм не менее 2х раз в месяц
              </li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Видео обзор на главной странице</li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Выделенный фон и нахождение в рекомендованных
              </li>
            </ul>

            <div
              class="absolute w-2/3 mx-auto cursor-pointer
            left-0 right-0 bottom-4 rounded-2xl border-blue-400 hover:bg-blue-400 hover:text-white text-blue-400
            font-semibold border-2 mt-8 mx-8 py-2"
              @click="$router.push('/conditions')"
            >
              Выбрать
            </div>
          </div>

          <div class="border rounded-xl p-2 lg:mt-12 relative">
            <div
              class="rounded-xl py-12 flex flex-col"
              style="background: #454545"
            >
              <span class="border-2 rounded-2xl border-white text-white px-8 py-2 font-semibold w-3/4 mx-auto">
                Стандартный план
              </span>
              <span class="font-semibold text-white pt-6 text-2xl">100BYN / мес</span>
            </div>

            <ul class="mt-4 p-4 flex flex-col space-y-4">
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Профессиональные фотографии организации + специалистов
              </li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Профессиональная редакция информации о организации и их виде деятельности +
                биография их специалистов
              </li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Акции, Скидки, Новинки</li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Бронирование онлайн</li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Сервис «Сберечь еду»</li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Предзаказ где есть постоянное меню (стрит фуд), с оплатой онлайн через платформу
              </li>
              <li class="flex text-sm text-left">
                <img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Онлайн бронирование как столика, так и мероприятия
              </li>
              <li class="flex text-sm text-left"><img
                  class="object-none pr-4"
                  src="img/check.png"
                /> Анализ вашей деятельности</li>
            </ul>

            <div
              class="lg:absolute w-2/3 mx-auto cursor-pointer
              left-0 right-0 bottom-4 rounded-2xl border-blue-400 hover:bg-blue-400 hover:text-white text-blue-400 font-semibold border-2 py-2"
              @click="$router.push('/conditions')"
            >
              Выбрать
            </div>
          </div>
        </div>

        <div class="mt-32 mx-auto">
          <h2 class="font-semibold text-3xl">Как это работает</h2>
          <p class="hidden lg:block text-sm pt-4">Ищи и находи то, что ищешь. Лучшие места здесь для вас.</p>

          <img
            src="/img/for-p.png"
            class="hidden lg:block"
          />

          <div class="lg:hidden flex flex-col lg:flex-row justify-between mx-auto mt-2 space-y-6 lg:space-y-0 lg:space-x-8">
            <div class="lg:mx-auto flex flex-col items-center rounded-xl p-2 py-2 w-full">
              <p class="text-gray-600 leading-2 text-sm pt-2">
                Клиент выбирает время и район
              </p>
            </div>
            <div class="lg:mx-auto flex flex-col items-center rounded-xl p-2 py-2 w-full">
              <p class="text-gray-600 leading-2 text-sm pt-2">
                Платформа формирует подобранные заведения с рейтингом и стоимостью
              </p>
            </div>
            <div class="lg:mx-auto flex flex-col items-center rounded-xl p-2 py-2 w-full">
              <p class="text-gray-600 leading-2 text-sm pt-2">
                Клиент резервирует данное время и в течение 20 минут получает подтверждение
              </p>
            </div>
          </div>
        </div>

        <div class="bg-white lg:flex lg:justify-between w-full lg:w-3/4 mx-auto shadow-xl py-3 lg:py-12 px-6 mt-16 rounded-lg items-center">
          <div class="lg:flex">
            <div class="flex flex-col text-left">
              <strong class="text-2xl lg:text-3xl">Уменьшение бюджета на рекламу</strong>
              <p class="text-sm pt-2">Ваше продвижение в сети интернет</p>
            </div>
          </div>
          <div class="lg:flex mt-4 lg:mt-0">
            <div
              class="rounded-xl py-3 h-12 font-semibold text-white px-8 shadow-lg cursor-pointer"
              style="background: #0086FF"
            >
              Регистрация
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  methods: {
    showPartnerSignUpForm() {
      this.$refs.header.isCreatingPartnerAccount = true;
    },
  },
};
</script>
