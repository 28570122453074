<template>
  <div class="flex flex-col w-2/4 space-y-4">
    <p class="text-sm">Введите ваше обращение, менеджер ответит при первой возможности</p>
    <Textarea></Textarea>
    <Button label="Отправить" />

    <p class="pt-12">Вы можете связаться с нами другими способами:</p>
    <a href="tel:+375295751800" class="font-medium">+375 29 575-18-00</a>
    <a href="mailto:partner@reserv.by" class="font-medium">partner@reserv.by</a>
  </div>
</template>

<script>
import Button from "primevue/button";
import Textarea from "primevue/textarea";

export default {
  components: { Button, Textarea },
  data() {
    return {};
  },
  methods: {}
};
</script>
