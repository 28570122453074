<template>
  <div class="placedetails">
    <Header />
    <CreateOrder />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import CreateOrder from "@/components/Order/CreateOrder.vue";
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    CreateOrder,
    Header,
    Footer
  }
};
</script>
