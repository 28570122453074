<template>
  <div>
    <div class="home">
      <div class="h-auto lg:h-screen-85 bg-header-bg-main bg-accent bg-cover bg-center">
        <div class="flex flex-col justify-between h-full">
          <div></div>

          <div>
            <div class="container mx-auto mb-12 w-full md:w-4/6 px-4 md:px-0 pb-24 lg:pb-0">
              <p class="search__title text-center hidden lg:block lg:text-left
              text-3xl mt-6 pb-6 lg:mt-0 lg:text-6xl lg:text-white font-medium tracking-tight">
                Мгновенный резерв услуг
              </p>

              <SearchBar id="sb" />
            </div>

            <div class="h-6 bg-white rounded-tl-3xl rounded-tr-3xl w-full"></div>
          </div>
        </div>
      </div>

      <div class="mt-5 container mx-auto text-left px-4" v-if="1==2">
        <h2 class="font-medium text-4xl text-center lg:text-left">Популярные категории</h2>

        <div class="flex flex-row overflow-x-scroll md:overflow-hidden md:flex-wrap items-end my-6">
          <div
            v-for="(category, i) in popularCategories"
            :key="i"
            :class="{
              'bg-accent text-white hover:bg-accentDark': activeCategoryId == category.id
            }"
            class="cursor-pointer hover:bg-gray-300 mr-3 rounded-3xl py-1.5 px-4 text-center bg-gray-200 text-xs lg:text-md font-medium"
            @click="selectPopularCategory(category)"
          >
            {{ category.title }}
          </div>
        </div>

        <div class="grid grid-cols-3 gap-x-3 lg:grid-cols-6 lg:gap-x-6">
          <div
            v-for="item in activeCategoryElements"
            :key="item.id"
            class="service-card cursor-pointer"
            @click="$router.push('/search/tagged/' + activeCategoryRealId + '/' + item.id)"
          >
            <div class="mb-2.5 title-image">
              <img
                class="w-full"
                src="/img/dev/1.jpg"
              />
            </div>

            <p class="text-sm font-medium">{{ item.title }}</p>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-8 container mx-auto text-left px-4">
        <h2 class="font-medium text-4xl text-center lg:text-left">Рекомендованные заведения</h2>

        <div class="flex flex-row overflow-x-scroll md:overflow-hidden md:flex-wrap items-end my-6">
          <div
            v-for="(category, i) in popularCategories"
            :key="i"
            :class="{
              'bg-accent text-white hover:bg-accentDark': activeCategoryId == category.id
            }"
            class="cursor-pointer hover:bg-gray-300 mr-3 rounded-3xl py-1.5 px-4 text-center bg-gray-200 text-xs lg:text-md font-medium"
            @click="selectPopularCategory(category)"
          >
            {{ category.title }}
          </div>
        </div>

        <div>
          <div
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
            v-if="activeCategoryId == 'best' && 1 == 2"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.best"
              :key="i.id"
            />
          </div>
          <div
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
            v-if="activeCategoryId == 'food'"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.food"
              :key="i.id"
            />
          </div>
          <div
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
            v-if="activeCategoryId == 'fun'"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.fun"
              :key="i.id"
            />
          </div>
          <div
            v-if="activeCategoryId == 'medical'"
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.medical"
              :key="i.id"
            />
          </div>
          <div
            v-if="activeCategoryId == 'beauty'"
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.beauty"
              :key="i.id"
            />
          </div>
          <div
            v-if="activeCategoryId == 'automechanical'"
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.mechanical"
              :key="i.id"
            />
          </div>
          <div
            v-if="activeCategoryId == 'rental'"
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.realty"
              :key="i.id"
            />
          </div>
          <div
            v-if="activeCategoryId == 'education'"
            class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-12"
          >
            <ServiceCard
              :data="i"
              v-for="i in recommendedPlaces.education"
              :key="i.id"
            />
          </div>
        </div>
      </div>

      <div class="container mx-auto relative mt-24">
        <div
          class="pt-20 pb-32 rounded-3xl"
          style="background:url('img/main1.jpg') no-repeat;background-size:cover;"
        >
          <div class="relative z-10 px-4 w-full md:w-3/6 text-left md:ml-12">
            <div
              style="background:#0086FF"
              class="text-3xl font-semibold text-white py-4 px-2"
            >Для пользователей</div>
            <div
              style="background:#FFC107"
              class="p-2"
            >
              <p>Вы хотите помыть автомобиль завтра в 11:30 на ближайшей автомойке и через час быть на коррекции бровей?</p>
              <p class="mt-2">
                А может быть, Вы хотите записаться сегодня к барберу по доступной цене в 14:00 и через два часа ждать в выбранном Вами кафе свою
                девушку?
              </p>
            </div>
            <div
              style="background:#FFF"
              class="p-2"
            >
              Начните искать и бронировать среди тысяч предложений нужные для Вас услуги в удобное для Вас время!
            </div>
          </div>
          <img
            src="img/play.png"
            class="absolute -left-12 my-auto"
            style="top:0;bottom:0;"
          />
        </div>
      </div>

      <div class="container mx-auto relative text-left my-28">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div class="flex flex-col">
            <h2 class="text-2xl text-center md:text-left font-normal">Мобильное приложение</h2>
            <p class="mt-6 text-center md:text-left">Находите услуги и организации среди тысячи предложений.
              <br />Записывайтесь к мастеру на нужное время.
            </p>
          </div>
          <div class="flex mx-auto flex-col lg:flex-row flex-row-reverse">
            <img
              class="object-none"
              src="img/as.jpg"
            />
            <img src="img/gp.jpg" />
          </div>
        </div>
      </div>

      <div class="container mx-auto relative my-24">
        <div
          class="pt-20 pb-24 rounded-3xl"
          style="background:url('img/handshake.jpg') no-repeat;background-size:cover;"
        >
          <div class="w-full px-4 relative z-10 md:w-3/6 text-left md:mr-12 md:float-right">
            <div
              style="background:#0086FF"
              class="text-3xl font-semibold text-white py-4 px-2"
            >Развивайте свой бизнес с нами!</div>
            <div
              style="background:#FFC107"
              class="p-2"
            >
              <p>Вы начинающий мастер по маникюру, опытный фитнес-тренер, а может вы владелец кафе, автомойки или медицинского центра?</p>
            </div>
            <div
              style="background:#FFF"
              class="p-2"
            >
              С нами Вас ждут увеличение продаж, вне зависимости от того в какой сфере деятельности Вы работаете
            </div>

            <div
              class="cursor-pointer rounded-3xl bg-accent text-white text-center py-2 font-semibold mt-8 float-right"
              style="width:240px"
              @click.prevent="$emit('partnerSignUp')"
            >
              Хочу стать партнёром
            </div>
          </div>
          <div class="clear-both"></div>
          <img
            src="img/play.png"
            class="absolute -left-12 my-auto"
            style="top:0;bottom:0;"
          />
        </div>
      </div>

      <div
        class="mt-32 container mx-auto grid grid-cols-4 gap-x-6 px-4"
        v-if="1 == 2"
      >
        <div class="flex flex-col text-left justify-between col-span-4 text-center lg:text-left lg:col-span-2">
          <p class="text-2xl lg:text-4xl font-semibold tracking-tight">
            Первый в Беларуси онлайн инструмент поиска услуг с возможностью записи
          </p>
          <p class="py-6 lg:py-0">
            Находите услуги и организации среди тысяч предложений. Записывайтесь к мастеру на нужное время.
          </p>
        </div>

        <div class="grid col-span-2 lg:col-span-1 gap-y-14 text-left">
          <div>
            <p class="text-center text-xl lg:text-left lg:text-3xl font-semibold tracking-tight">Более 150 000</p>
            <p class="pt-0 text-center lg:text-left lg:pt-4">мастеров и организаций</p>
          </div>

          <div>
            <p class="text-center text-xl lg:text-left lg:text-3xl font-semibold tracking-tight">Более 5 000</p>
            <p class="pt-0 text-center lg:text-left lg:pt-4">положительных отзывов</p>
          </div>
        </div>

        <div class="grid col-span-2 lg:col-span-1 gap-y-14 text-left">
          <div>
            <p class="text-center text-xl lg:text-left lg:text-3xl font-semibold tracking-tight">Более 1 000 000</p>
            <p class="pt-0 text-center lg:text-left lg:pt-4">бронирований</p>
          </div>

          <div>
            <p class="text-center text-xl lg:text-left lg:text-3xl font-semibold tracking-tight">Более 4 300</p>
            <p class="pt-0 text-center lg:text-left lg:pt-4">видов услуг</p>
          </div>
        </div>
      </div>

      <div
        class="mt-32 lg:my-32 container mx-auto text-left grid grid-cols-2 bg-prefooter-main
      bg-cover bg-center lg:rounded-3xl"
        v-if="1 == 2"
      >
        <div class="py-16 px-4 col-span-2 lg:col-span-1 lg:py-32 lg:pl-16">
          <p class="text-2xl lg:text-4xl font-bold tracking-tight">Развивайте свой бизнес с нами!</p>
          <p class="pt-4 text-lg lg:text-2xl font-semibold tracking-tight">
            Станьте партнером сервиса и находите новых клиентов
          </p>
          <p class="text-sm lg:text-md pt-4 pb-8">
            Мы создаем условия для развития и продвижения бизнеса как юридическим лицам, так и фрилансерам! Оформи месячную подписку или плати за
            каждого пришедшего с сайта клиента.
          </p>
          <a
            class="px-2 py-3 block text-md btn btn-md hover:bg-accentDark font-bold tracking-tight"
            href="#"
            @click.prevent="$emit('partnerSignUp')"
          >Стать партнёром сервиса</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../views/ui/ServiceCard";
import SearchBar from "../views/ui/SearchBar";
import { mapMutations } from "vuex";

export default {
  name: "Main",
  components: {
    ServiceCard,
    SearchBar,
  },
  props: {
    msg: String,
  },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(
        0,
        this.$http.defaults.baseURL.length - 3
      );
    },
    activeCategoryElements() {
      let res;

      switch (this.activeCategoryId) {
        // case "best":
        //   res = this.popularCategoriesElements.best;
        //   break;
        case "foodandfun":
          res = this.popularCategoriesElements.food;
          break;
        case "medical":
          res = this.popularCategoriesElements.medical;
          break;
        case "beauty":
          res = this.popularCategoriesElements.beauty;
          break;
        case "automechanical":
          res = this.popularCategoriesElements.mechanical;
          break;
        case "rental":
          res = this.popularCategoriesElements.rental;
          break;
      }

      return res;
    },
  },
  created() {
    this.fetchRecommendedPlaces();
    this.fetchPopularCategories();
  },
  methods: {
    ...mapMutations(["search"]),

    findOnMap() {
      this.isOnMap = true;
      this.find();
    },
    find() {
      let search = {
        q: this.searchQuery ? this.searchQuery : "",
        date: this.date,
        time: this.time,
        is_slots_only:
          this.isSearchDateUsed || this.isSearchTimeUsed ? true : false,
        is_date_only: this.isSearchDateUsed,
        is_time_only: this.isSearchTimeUsed,
        is_nearby: this.isSearchNearby,
        isOnMap: this.isOnMap,
      };
      this.$store.commit("search", search);
      this.$router.push("/find");
    },
    handleSearch(q) {
      return this.$http.get("s?query=" + q).then((response) => {
        if (response.data) {
          return response.data;
        }

        return null;
      });
    },
    selectPopularCategory(category) {
      this.activeCategoryId = category.id;
      this.activeCategoryRealId = category.realId;
    },
    fetchRecommendedPlaces() {
      this.$http.get("recommended").then((response) => {
        this.recommendedPlaces = response.data.places;
      });
    },
    fetchPopularCategories() {
      this.$http.get("place/popular/types").then((response) => {
        this.popularCategoriesElements = response.data.categories;
      });
    },
  },
  data() {
    return {
      isSearchDateUsed: false,
      isSearchTimeUsed: false,
      isSearchNearby: false,
      date: new Date(),
      time: new Date(
        new Date().getYear(),
        new Date().getMonth(),
        new Date().getDate(),
        0,
        0
      ),
      isSearching: false,
      isOnMap: false,
      searchResults: null,
      querySearchResults: null,
      searchQuery: null,
      activeCategoryId: "food",
      activeCategoryRealId: 1,
      popularCategoriesElements: [],
      popularCategories: [
        // { title: "Лучшее", id: "best", realId: 0 },
        { title: "Еда", id: "food", realId: 1 },
        { title: "Развлечения", id: "fun", realId: 2 },
        { title: "Красота", id: "beauty", realId: 4 },
        { title: "Жильё", id: "rental", realId: 5 },
        { title: "Медицина", id: "medical", realId: 3 },
        { title: "Автоуслуги", id: "automechanical", realId: 5 },
        { title: "Образование", id: "education", realId: 6 },
      ],
      recommendedPlaces: [],
      predictions: null,
    };
  },
};
</script>

<style scoped>
.bg-header-bg-main {
  background-repeat: no-repeat;
  /* height: 85vh; */
}
.service-card:hover .title-image::after {
  opacity: 0.2;
}
.title-image {
  position: relative;
}
.title-image::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  border-radius: 6px;
  transition: opacity 300ms ease 0s;
  top: 0;
  left: 0;
  opacity: 0;
}
::v-deep .p-autocomplete-loader {
  top: 10px;
}
::v-deep .p-autocomplete-input {
  width: 100%;
}

::v-deep .p-inputtext {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}
</style>
