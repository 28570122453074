<template>
  <div>
    <div
      class="flex flex-row justify-between mb-4"
      v-if="!isCreatingNewCoupon"
    >
      <div></div>
      <div>
        <Button
          label="Добавить акцию"
          @click="isCreatingNewCoupon = true"
        />
      </div>
    </div>

    <div
      class="grid grid-cols-1 2xl:grid-cols-2 gap-4"
      v-if="isCreatingNewCoupon"
    >
      <Panel
        header="Основные условия акции"
        :toggleable="true"
      >
        <div class="flex flex-row justify-between items-center">
          <div class="flex flex-col space-y-2">
            <div class="flex items-center field-checkbox">
              <Checkbox
                id="binary"
                v-model="coupon.is_promocode"
                :binary="true"
              />
              <label for="binary">Промокод</label>
            </div>
            <div class="flex items-center field-radiobutton">
              <RadioButton
                id="discount_type1"
                name="client_type"
                value="cash"
                v-model="coupon.discount_type"
              />
              <label for="discount_type1">Фиксированная сумма</label>
            </div>
            <div class="flex items-center field-radiobutton">
              <RadioButton
                id="discount_type2"
                name="client_type"
                value="percent"
                v-model="coupon.discount_type"
              />
              <label for="discount_type2">Процент от стоимости</label>
            </div>
            <div class="flex items-center field-radiobutton">
              <RadioButton
                id="discount_type3"
                name="client_type"
                value="gift"
                v-model="coupon.discount_type"
              />
              <label for="discount_type3">Услуга в подарок</label>
            </div>
          </div>

          <div class="flex flex-col">
            <div v-if="coupon.is_promocode">
              <div class="field flex flex-col items-end">
                <label for="username1">Промокод</label>
                <InputText
                  id="username1"
                  type="username"
                  v-model="coupon.promocode_value"
                />
              </div>
            </div>

            <div
              class="flex flex-col items-end"
              v-if="coupon.discount_type !== 'gift'"
            >
              <label
                for="client_type2"
                class="text-md"
              >Размер скидки</label>
              <InputNumber
                class="p-inputtext-sm"
                v-model="coupon.discount_value"
                :suffix="coupon.discount_type == 'cash' ? ' BYN' : '%'"
                showButtons
                :min="1"
                :max="coupon.discount_type == 'cash' ? 1000000 : 100"
              />
            </div>
            <div
              class="flex flex-col items-end"
              v-else
            >
              <label class="text-md">Услуга в подарок</label>
              <Dropdown
                v-model="coupon.gift_service"
                :options="availableServices"
                optionLabel="title"
                @change="coupon.selectedServices = null"
                placeholder="Выберите услугу"
              />
            </div>
          </div>
        </div>
      </Panel>

      <Panel
        header="Список услуг"
        :toggleable="true"
      >
        <div class="flex flex-col space-y-4">
          <MultiSelect
            v-model="coupon.selectedServices"
            :options="unusedServices"
            optionLabel="title"
            placeholder="Выберите услуги"
            display="chip"
          />

          <DataTable
            v-if="selectedServicesWithDiscounts && selectedServicesWithDiscounts.length"
            :value="selectedServicesWithDiscounts"
            stripedRows
            responsiveLayout="scroll"
          >
            <Column
              field="title"
              header="Наименование"
            ></Column>
            <Column
              field="price"
              header="Стоимость"
            ></Column>
            <Column
              field="discounted_price"
              header="Со скидкой"
            ></Column>
          </DataTable>
        </div>
      </Panel>

      <Panel
        header="Список точек продаж"
        :toggleable="true"
      >
        <div class="flex flex-col space-y-4">
          <MultiSelect
            v-model="coupon.selectedPlaces"
            :options="placesList"
            optionLabel="title"
            placeholder="Выберите места"
            display="chip"
          />

          <DataTable
            v-if="coupon.selectedPlaces && coupon.selectedPlaces.length"
            :value="coupon.selectedPlaces"
            stripedRows
            responsiveLayout="scroll"
          >
            <Column
              field="name"
              header="Наименование"
            ></Column>
            <Column
              field="address_string"
              header="Адрес"
            ></Column>
          </DataTable>
        </div>
      </Panel>

      <Panel
        header="Срок проведения акции"
        :toggleable="true"
      >
        <div class="flex">
          <div class="flex flex-col space-y-2">
            <div class="flex items-center field-radiobutton">
              <RadioButton
                id="timing_type1"
                name="client_type"
                value="timeless"
                v-model="coupon.timing_type"
              />
              <label for="timing_type1">Бессрочно</label>
            </div>
            <div class="flex items-center field-radiobutton">
              <div class="flex items-center">
                <RadioButton
                  id="timing_type2"
                  name="client_type"
                  value="fixedCount"
                  v-model="coupon.timing_type"
                />
                <label for="timing_type2">Не более</label>
              </div>
              <div class="ml-1">
                <InputNumber
                  class="p-inputtext-sm"
                  v-model="coupon.max_order_count"
                  suffix=" заказов"
                  showButtons
                  :min="1"
                />
              </div>
            </div>
            <div class="flex items-center field-radiobutton">
              <div class="flex items-center">
                <RadioButton
                  id="client_type2"
                  name="client_type"
                  value="range"
                  v-model="coupon.timing_type"
                />
                <label for="client_type2">В период с</label>
              </div>
              <div class="ml-1">
                <Calendar
                  class="p-inputtext-sm"
                  id="minmax1"
                  v-model="coupon.start_date"
                  :manualInput="false"
                  dateFormat="dd.mm.yy"
                />
              </div>
              <label for="client_type2">по</label>
              <div class="ml-1">
                <Calendar
                  class="p-inputtext-sm"
                  id="minmax2"
                  v-model="coupon.end_date"
                  :manualInput="false"
                  dateFormat="dd.mm.yy"
                />
              </div>
            </div>
            <div class="flex items-center field-radiobutton">
              <div class="flex items-center">
                <RadioButton
                  id="timing_type3"
                  name="client_type"
                  value="fixedCountWithDuration"
                  v-model="coupon.timing_type"
                />
                <label for="timing_type3">Не более</label>
              </div>
              <div class="ml-1">
                <InputNumber
                  class="p-inputtext-sm"
                  v-model="coupon.max_order_count"
                  suffix=" заказов"
                  showButtons
                  :min="1"
                />
              </div>
              <div class="flex items-center">
                <label for="client_type2">в период с</label>
              </div>
              <div class="ml-1">
                <Calendar
                  class="p-inputtext-sm"
                  id="minmax1"
                  v-model="coupon.start_date"
                  :manualInput="false"
                  dateFormat="dd.mm.yy"
                />
              </div>
              <label for="client_type2">по</label>
              <div class="ml-1">
                <Calendar
                  class="p-inputtext-sm"
                  id="minmax2"
                  v-model="coupon.end_date"
                  :manualInput="false"
                  dateFormat="dd.mm.yy"
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>

      <Panel
        header="Требования к клиентам"
        :toggleable="true"
      >
        <div class="flex">
          <div>
            <div class="flex items-center field-radiobutton">
              <RadioButton
                id="client_type1"
                name="client_type"
                value="any"
                v-model="coupon.client_type"
              />
              <label for="client_type1">Любой зарегистрированный пользователь</label>
            </div>
            <div class="flex items-center field-radiobutton">
              <div class="flex items-center">
                <RadioButton
                  id="client_type2"
                  name="client_type"
                  value="hasSomeOrders"
                  v-model="coupon.client_type"
                />
                <label for="client_type2">Любой, кто оставил у нас не менее</label>
              </div>
              <div class="ml-1">
                <InputNumber
                  class="p-inputtext-sm"
                  v-model="coupon.min_client_orders_count"
                  suffix=" заказов"
                  showButtons
                  :min="1"
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>

      <div class="flex space-x-4">
        <Button
          @click="cancelEditing"
          icon="pi pi-times"
          label="Вернуться назад"
        />
        <Button
          class="p-button-success"
          icon="pi pi-check"
          label="Всё верно, сохранить"
          @click="createCoupon"
        />
      </div>
    </div>

    <div
      class="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4"
      v-if="coupons.length && !isCreatingNewCoupon"
    >
      <div
        class="m-auto coupon-in-place rounded-lg cursor-pointer"
        :class="getGradType(coupon.discount_type, coupon.is_promocode)"
        v-for="(coupon, k) in coupons"
        :key="k"
        @click="editCoupon(coupon)"
      >
        <div class="font-semibold text-2xl">{{ coupon.discount_title }}</div>
        <ul class="flex flex-col space-y-2 mt-8 px-2">
          <li
            class="text-sm text-left border-b border-white border-opacity-10 pb-2"
            v-if="coupon.gift && coupon.discount_type == 'gift'"
          >
            <div class="text-sm">Подарок: {{ coupon.gift.title }}</div>
          </li>
          <li class="text-sm text-left border-b border-white border-opacity-10 py-2">{{coupon.affected_services_count}}</li>
          <li class="text-sm text-left border-b border-white border-opacity-10 py-2">{{coupon.affected_places_count}}</li>
          <li class="text-sm text-left border-b border-white border-opacity-10 py-2">{{coupon.timing_title}}</li>
          <li class="text-sm text-left py-2">{{coupon.client_type_title}}</li>
        </ul>

        <div
          class="bg-white text-black rounded-full w-8 h-8 items-center flex absolute -top-4 -right-4 border cursor-pointer"
          @click.prevent="removeCoupon(coupon.id)"
        >
          <i class="pi pi-times mx-auto"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import Panel from "primevue/panel";
import RadioButton from "primevue/radiobutton";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";

export default {
  components: {
    Button,
    Panel,
    RadioButton,
    InputNumber,
    MultiSelect,
    DataTable,
    Column,
    Dropdown,
    Calendar,
    Checkbox,
    InputText,
  },
  watch: {
    coupon: {
      handler(v) {
        if (v.discount_type == "percent" && v.discount_value > 100) {
          v.discount_value = 100;
        }
      },
      deep: true,
    },
  },
  methods: {
    cancelEditing() {
      this.isCreatingNewCoupon = false;
      this.isEditingCoupon = false;
      this.coupon = {
        client_type: "any",
        min_client_orders_count: 3,
        discount_type: "cash",
        discount_value: 1,
        selectedServices: null,
        gift_service: null,
        timing_type: "timeless",
        start_date: new Date(),
        end_date: new Date(),
        max_order_count: 10,
        selectedPlaces: null,
        is_promocode: false,
        promocode_value: null,
      };
      this.getCoupons();
    },
    editCoupon(coupon) {
      coupon.selectedPlaces = coupon.places;
      coupon.selectedServices = coupon.services;

      coupon.selectedServices.map((service) => {
        delete service.laravel_through_key;
        return service;
      });

      coupon.selectedPlaces.map((place) => {
        delete place.laravel_through_key;
        place.title = place.place.title + " (" + place.clean_address + ")";
        place.address_string = place.clean_address;
        place.name = place.place.title;
        delete place.place;
        return place;
      });

      if (coupon.gift) {
        coupon.gift.discounted_price = undefined;
        coupon.gift_service = coupon.gift;
      }

      this.coupon = coupon;
      this.isCreatingNewCoupon = true;
      this.isEditingCoupon = true;

      console.log(coupon);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getGradType(inp, promo = false) {
      let type;

      switch (inp) {
        case "gift":
          type = "gradient1";
          break;
        case "percent":
          type = "gradient2";
          break;
        case "cash":
          type = "gradient3";
          break;
        case 4:
          type = "gradient4";
          break;
        case 5:
          type = "gradient5";
          break;
      }

      if (promo) {
        type = "gradient5";
      }

      return type;
    },
    removeCoupon(id) {
      this.$http.delete("coupon-offer/" + id).then(() => {
        this.isCreatingNewCoupon = false;
        this.isEditingCoupon = false;
        this.coupon = {
          client_type: "any",
          min_client_orders_count: 3,
          discount_type: "cash",
          discount_value: 1,
          selectedServices: null,
          gift_service: null,
          timing_type: "timeless",
          start_date: new Date(),
          end_date: new Date(),
          max_order_count: 10,
          selectedPlaces: null,
          is_promocode: false,
          promocode_value: null,
        };
        this.getCoupons();
      });
    },
    getCoupons() {
      this.$http.get("coupons-offer").then((response) => {
        let coupons = response.data.coupons;

        if (!coupons) {
          return false;
        }

        coupons.map((c) => {
          let discount_title, client_type_title, timing_title;

          switch (c.discount_type) {
            case "gift":
              discount_title = "Услуга в подарок";
              break;
            case "percent":
              discount_title = "Минус " + c.discount_value + "% от стоимости";
              break;
            case "cash":
              discount_title =
                "Минус " + c.discount_value + " BYN от стоимости";
              break;
          }

          switch (c.timing_type) {
            case "timeless":
              timing_title = "Бессрочная акция";
              break;
            case "fixedCount":
              timing_title = "Лимит заказов: " + c.max_order_count;
              break;
            case "range":
              timing_title = "С " + c.start_date + " по " + c.end_date;
              break;
            case "fixedCountWithDuration":
              timing_title =
                "Лимит: " +
                c.max_order_count +
                " заказов c " +
                c.start_date +
                " по " +
                c.end_date;
              break;
          }

          if (c.is_promocode) {
            discount_title = c.promocode_value
              ? c.promocode_value.toUpperCase() + ": " + discount_title
              : "Промокод: " + discount_title;
          }

          switch (c.client_type) {
            case "any":
              client_type_title = "Любой клиент reserv.by";
              break;
            case "hasSomeOrders":
              client_type_title =
                "Клиент с " + c.min_client_orders_count + "+ заказов";
              break;
          }

          c.timing_title = timing_title;
          c.client_type_title = client_type_title;
          c.affected_services_count = "Эффект (услуги): " + c.services.length;
          c.affected_places_count = "Эффект (места): " + c.places.length;
          c.discount_title = discount_title;
          c.title = "test";
          console.log(c);
          return c;
        });

        this.coupons = coupons;
      });
    },
    getAvailableServices() {
      this.$http.get("service").then((response) => {
        this.available_services = response.data.services;
      });
    },
    getPlaces() {
      this.$http.get("entity-place").then((response) => {
        this.places = response.data.places;
      });
    },
    createCoupon() {
      if (this.coupon.gift_service) {
        this.coupon.gift_service = this.coupon.gift_service.id;
      }

      if (this.isEditingCoupon) {
        this.$http.put("coupon-offer", this.coupon).then((response) => {
          console.log(response);
          this.getCoupons();
          this.isCreatingNewCoupon = false;
          this.isEditingCoupon = false;

          this.coupon = {
            client_type: "any",
            min_client_orders_count: 3,
            discount_type: "cash",
            discount_value: 1,
            selectedServices: null,
            gift_service: null,
            timing_type: "timeless",
            start_date: new Date(),
            end_date: new Date(),
            max_order_count: 10,
            selectedPlaces: null,
            is_promocode: false,
            promocode_value: null,
          };
        });
        return false;
      }

      this.$http.post("coupon-offer", this.coupon).then((response) => {
        console.log(response);
        this.getCoupons();
        this.isCreatingNewCoupon = false;

        this.coupon = {
          client_type: "any",
          min_client_orders_count: 3,
          discount_type: "cash",
          discount_value: 1,
          selectedServices: null,
          gift_service: null,
          timing_type: "timeless",
          start_date: new Date(),
          end_date: new Date(),
          max_order_count: 10,
          selectedPlaces: null,
          is_promocode: false,
          promocode_value: null,
        };
      });
    },
    createCoupon1() {
      this.$http
        .post("organization/coupon", {
          title: this.title,
          price: this.price,
          r_price: this.r_price,
          due_to: this.due_to,
          sales_limit: this.sales_limit,
          services: this.services,
          coloring: this.gradType,
          comments: this.comments,
        })
        .then(() => {
          this.isCreatingNewCoupon = false;
        });
    },
  },
  data() {
    return {
      coupon: {
        client_type: "any",
        min_client_orders_count: 3,
        discount_type: "cash",
        discount_value: 1,
        selectedServices: null,
        gift_service: null,
        timing_type: "timeless",
        start_date: new Date(),
        end_date: new Date(),
        max_order_count: 10,
        selectedPlaces: null,
        is_promocode: false,
        promocode_value: null,
      },
      places: null,
      available_services: null,
      isSalesLimit: false,
      isEditingCoupon: false,
      title: "",
      price: 0,
      r_price: 0,
      due_to: null,
      sales_limit: 0,
      services: [],
      comments: "",
      gradType: 1,
      isCreatingNewCoupon: false,
      durations: [
        {
          title: "14 дней",
        },
        {
          title: "1 месяц",
        },
        {
          title: "3 месяца",
        },
        {
          title: "6 месяцев",
        },
        {
          title: "1 год",
        },
        {
          title: "Бессрочно",
        },
      ],
      salesLimitations: [
        {
          title: "10",
        },
        {
          title: "20",
        },
        {
          title: "30",
        },
        {
          title: "50",
        },
        {
          title: "100",
        },
        {
          title: "250",
        },
        {
          title: "500",
        },
        {
          title: "1000",
        },
      ],
      coupons: [],
    };
  },
  computed: {
    placesList() {
      if (!this.places) {
        return null;
      }

      let places = [];

      this.places.forEach((item) => {
        if (item.geo.length) {
          item.geo.forEach((geo) => {
            geo.name = item.title;
            geo.title = item.title + " (" + geo.clean_address + ")";
            geo.address_string = geo.clean_address;
            places.push(geo);
          });
        } else {
          item.name = item.title;
          item.address_string = "-";
          places.push(item);
        }
      });

      return places;
    },
    unusedServices() {
      if (!this.availableServices) {
        return null;
      }

      if (this.coupon.discount_type !== "gift") {
        return this.availableServices;
      }

      let services = this.availableServices.filter((item) => {
        return item.id !== this.coupon.gift_service.id;
      });

      return services;
    },
    availableServices() {
      if (!this.available_services) {
        return null;
      }

      let services = this.available_services.map((item) => {
        let price = item.price;
        let discounted;

        if (this.coupon.discount_type == "percent") {
          discounted = price - (price * this.coupon.discount_value) / 100;
          discounted = discounted.toFixed(2);
        } else if (this.coupon.discount_type == "cash") {
          discounted = price - this.coupon.discount_value;

          if (discounted < 0) {
            discounted = 0;
          }
        }

        item.discounted_price = discounted;

        return item;
      });

      return services;
    },
    selectedServicesWithDiscounts() {
      if (!this.coupon.selectedServices) {
        return null;
      }

      let services = this.coupon.selectedServices.map((item) => {
        let price = item.price;
        let discounted;

        if (this.coupon.discount_type == "percent") {
          discounted = price - (price * this.coupon.discount_value) / 100;
          discounted = discounted.toFixed(2);
        } else if (this.coupon.discount_type == "cash") {
          discounted = price - this.coupon.discount_value;

          if (discounted < 0) {
            discounted = 0;
          }
        }

        item.discounted_price = discounted;

        return item;
      });

      return services;
    },
    gradientType() {
      let type;

      switch (this.gradType) {
        case 1:
          type = "gradient1";
          break;
        case 2:
          type = "gradient2";
          break;
        case 3:
          type = "gradient3";
          break;
        case 4:
          type = "gradient4";
          break;
        case 5:
          type = "gradient5";
          break;
      }

      return type;
    },
  },
  mounted() {
    this.getCoupons();
    this.getAvailableServices();
    this.getPlaces();
  },
};
</script>

<style scoped>
label {
  font-weight: normal;
  padding-left: 4px;
  margin-top: 5px;
  cursor: pointer;
}

.color-item.active:after {
  content: " ";
  border: 2px solid #037aff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: block;
  top: -4px;
  position: relative;
  left: -4px;
}

.color-item {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
