<template>
  <header class="h-20 w-full bg-white border-b-2 fixed top-0 z-50">
    <div class="flex justify-between h-full container mx-auto px-4 md:px-0">
      <div class="flex flex-row w-full">
        <a href="/" @click.prevent="$router.push('/')" class="w-44 sm:w-44 flex">
          <img class="object-contain object-left cursor-pointer w-44 sm:w-44" src="/img/logo_primary.png" />
        </a>

        <SearchBar v-if="shouldDisplaySearchBar" condensed="true" />

        <nav v-if="!shouldDisplaySearchBar"
          class="xl:flex flex-col w-full xl:w-min xl:ml-8 h-full items-center xl:space-x-10
        text-sm place-content-start mt-20 xl:mt-0 xl:place-content-center absolute xl:relative xl:flex-row top-0 left-0 z-20 shadow-lg lg:shadow-none"
          :class="{ hidden: !isMenuDisplayed, flex: isMenuDisplayed }">
          <a class="w-full py-4 bg-white font-medium xl:w-min text-center xl:text-left" @click="searchInCategoryMode(4)"
            href="#">Красота</a>
          <a class="w-full py-4 bg-white font-medium whitespace-nowrap xl:w-min text-center xl:text-left"
            @click="searchInCategoryMode(1)" href="#">
            Еда
          </a>
          <a class="w-full py-4 bg-white font-medium whitespace-nowrap xl:w-min text-center xl:text-left"
            @click="searchInCategoryMode(2)" href="#">
            Развлечения
          </a>
          <a class="w-full py-4 bg-white font-medium xl:w-min text-center xl:text-left shadow-lg lg:shadow-none"
            @click="searchInCategoryMode(5)" href="#">
            Автоуслуги
          </a>
          <a class="w-full py-4 bg-white font-medium xl:w-min text-center xl:text-left" @click="searchInCategoryMode(3)"
            href="#">Медицина</a>
          <a class="w-full py-4 bg-white font-medium whitespace-nowrap xl:w-min text-center xl:text-left"
            @click="searchInCategoryMode(6)" href="#">
            Жильё
          </a>
          <a class="w-full py-4 bg-white font-medium whitespace-nowrap xl:w-min text-center xl:text-left"
            @click="searchInCategoryMode(7)" href="#">
            Образование
          </a>
          <a class="w-full py-4 bg-white block xl:hidden text-center font-semibold"
            @click="isCreatingPartnerAccount = true" href="#">Стать партнёром</a>
          <a class="w-full py-4 bg-white block xl:hidden text-center shadow-lg lg:shadow-none" @click="toggleAuthPanel"
            href="#">Вход и регистрация</a>
        </nav>
      </div>

      <div class="flex items-center">
        <span class="xl:hidden" @click="toggleMenu"><i class="pi pi-bars px-2 cursor-pointer"></i></span>

        <div v-if="!isUserAuthenticated" class="flex flex-row justify-end h-full items-center space-x-6">
          <a href="#" @click="isCreatingPartnerAccount = true"
            class="text-md text-accent whitespace-nowrap hidden ml-4 xl:ml-0 lg:block font-semibold">Стать партнёром</a>
          <a href="#" @click="toggleAuthPanel" class="px-4 btn py-2 hidden lg:block font-medium text-white">Войти</a>

          <OverlayPanel ref="authPanel" class="text-center w-full lg:w-auto">
            <div class="flex flex-col space-y-3">
              <span class="font-semibold text-xl">Вход</span>
              <div class="p-field text-left flex flex-col">
                <InputText placeholder="E-mail" type="text" v-model="login" id="username2"
                  aria-describedby="username2-help" :class="{ 'p-invalid': errors.email }" />
                <small id="username2-help" class="p-error" v-if="errors.email">Проверьте e-mail и пароль</small>
              </div>
              <div class="p-field text-left flex flex-col">
                <InputText placeholder="Пароль" type="password" v-model="password"
                  :class="{ 'p-invalid': errors.password }" />
                <small id="username2-help" class="p-error" v-if="errors.password">Неправильный пароль</small>
              </div>
              <a href="#" @click="signIn" class="btn py-2 text-white">Войти</a>
              <div class="flex flex-col text-sm space-y-2">
                <span class="text-accent cursor-pointer" @click="showSignUpForm">Зарегистрироваться</span>
                <span class="text-accent cursor-pointer" @click="showRestorationForm">Не помню пароль</span>
              </div>
            </div>
          </OverlayPanel>
        </div>

        <div v-else class="flex items-center">
          <div v-if="user" class="flex items-center space-x-2 cursor-pointer" @click="toggleUserMenu" aria-haspopup="true"
            aria-controls="overlay_menu">
            <Avatar :label="!user.avatar ? user.name.substr(0, 1) : null"
              :image="user.avatar ? 'https://api.reserv.by/' + user.avatar : null" size="large" shape="circle"
              v-badge.success />
          </div>

          <Menu class="mt-3" ref="userMenu" :model="menu" :popup="true">
            <template #item="{ item, props }">
              <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                  <span :class="item.icon" />
                  <span class="ml-2">{{ item.label }}</span>
                </a>
              </router-link>
              <a class="p-menuitem-link" href="#" v-else>
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </template>
          </Menu>
        </div>
      </div>
    </div>

    <Dialog header="Создайте свою учётную запись" v-model:visible="isCreatingAccount" position="topright">
      <div class="space-y-4">
        <div class="p-field flex flex-col">
          <label for="reg-username">Ваше имя</label>
          <InputText v-model="signUpForm.name" id="reg-username" type="username" aria-describedby="username-help"
            :class="{ 'p-invalid': signUpErrors.name }" />
          <small v-if="!signUpErrors.name" id="username-help">
            Используется для оформления заказов
          </small>
          <small v-else id="username-help">
            Поле должно быть заполнено
          </small>
        </div>

        <div class="p-field flex flex-col">
          <label for="reg-email">Электронная почта</label>
          <InputText v-model="signUpForm.email" id="reg-email" type="username" aria-describedby="email-help"
            :class="{ 'p-invalid': signUpErrors.email }" />
          <small v-if="!signUpErrors.email" id="email-help">
            Используется для входа в ваш аккаунт
          </small>
          <small v-if="signUpErrors.email && signUpForm.email" id="email-help">
            Введённый e-mail уже зарегистрирован
          </small>
          <small v-if="signUpErrors.email && !signUpForm.email" id="email-help">
            Поле должно быть заполнено
          </small>
        </div>

        <div class="p-field flex flex-col">
          <label for="reg-password">Пароль</label>
          <InputText v-model="signUpForm.password" id="reg-password" type="password" aria-describedby="password-help"
            :class="{ 'p-invalid': signUpErrors.password }" />
          <small v-if="!signUpErrors.password" id="password-help">
            Ваш надёжный пароль
          </small>
          <small v-if="signUpErrors.password && !signUpForm.password" id="password-help">
            Поле должно быть заполнено
          </small>
          <small v-if="signUpErrors.password && signUpForm.password" id="password-help">
            Пароль недостаточно сложный
          </small>
        </div>

        <div class="p-field-checkbox items-top flex space-x-3 mt-4">
          <Checkbox id="partnerConsent" name="partnerConsent" value="false" v-model="signUpForm.consent" />
          <label for="partnerConsent">
            Я соглашаюсь с
            <a href="#" @click="$router.push('/privacy-policy')" class="text-accent">политикой конфидециальности</a>,
            <br /><a href="#" @click="$router.push('/booking-conditions')" class="text-accent">
              условиями пользования веб-сайтом
            </a>
          </label>
        </div>
      </div>

      <template #footer>
        <Button label="Отмена" icon="pi pi-times" class="p-button-text outline-none" @click="isCreatingAccount = false" />
        <Button label="Зарегистрироваться" icon="pi pi-check" class="p-button-success outline-none"
          :disabled="signUpForm.consent ? false : 'disabled'" @click="handleRegister" autofocus />
      </template>
    </Dialog>

    <Dialog header="Восстановить доступ к аккаунту" v-model:visible="isRestoringAccount" position="topright">
      <div class="space-y-4">
        <div class="p-field flex flex-col">
          <label for="reg-email">Электронная почта</label>
          <InputText id="reg-email" type="username" aria-describedby="email-help" />
          <small id="email-help">
            Вам будут отправлены инструкции по восстановлению доступа
          </small>
        </div>
      </div>

      <template #footer>
        <Button label="Отмена" icon="pi pi-times" class="p-button-text outline-none"
          @click="isRestoringAccount = false" />
        <Button label="Восстановить" icon="pi pi-check" class="p-button-success outline-none" @click="handleRestore"
          autofocus />
      </template>
    </Dialog>

    <Dialog header="Создайте свою бесплатную учетную запись партнера" v-model:visible="isCreatingPartnerAccount"
      :modal="true">
      <div class="grid grid-cols-2 gap-4">
        <div class="p-field flex flex-col">
          <label>Имя</label>
          <InputText v-model="partnerSignUp.firstName" type="username" />
        </div>

        <div class="p-field flex flex-col">
          <label>Фамилия</label>
          <InputText v-model="partnerSignUp.lastName" type="username" />
        </div>

        <div class="p-field flex flex-col">
          <label>Номер телефона</label>
          <InputMask v-model="partnerSignUp.phone" mask="+375 (99) 999-99-99" type="username" />
        </div>

        <div class="p-field flex flex-col">
          <label>Электронная почта</label>
          <InputText v-model="partnerSignUp.email" type="username" />
        </div>

        <div class="p-field flex flex-col">
          <label>Пароль</label>
          <InputText v-model="partnerSignUp.password" type="password" />
        </div>

        <div class="p-field flex flex-col">
          <label>Вид деятельности</label>
          <Dropdown :options="activityTypes" optionLabel="title" v-model="partnerSignUp.businessType" type="username" />
        </div>
      </div>

      <div class="p-field-checkbox items-top flex space-x-3 mt-4">
        <Checkbox id="partnerConsent" name="partnerConsent" value="false" v-model="partnerSignUp.consent" />
        <label for="partnerConsent">
          Я соглашаюсь с
          <a href="#" class="text-accent">политикой конфидециальности</a>, <br /><a href="#" class="text-accent">
            условиями пользования веб-сайтом
          </a>
          и
          <a href="#" class="text-accent">
            условиями для партнеров
          </a>
        </label>
      </div>

      <template #footer>
        <Button label="Зарегистрироваться" class="p-button-success outline-none" style="width: 100%"
          @click="handleSignUpPartner" :disabled="partnerSignUp.consent ? false : true" />
      </template>
    </Dialog>

    <Dialog header="Данные о компании" v-model:visible="isFinalizingPartnerAccount" :modal="true">
      <div class="flex flex-col space-y-4">
        <div class="p-field flex flex-col">
          <label>Название компании</label>
          <InputText v-model="businessSignUp.title" type="username" />
        </div>

        <div class="p-field flex flex-col">
          <label>Количество сотрудников</label>
          <div class="grid grid-cols-4 gap-5">
            <div class="p-4 text-center cursor-pointer border-gray border rounded hover:bg-gray-100" :class="{
              'text-black font-semibold bg-gray-200': selectedWorkersCount == 0,
              'text-gray-400': selectedWorkersCount != 0
            }" @click="selectedWorkersCount = 0">
              Только я
            </div>
            <div class="p-4 text-center cursor-pointer border-gray border rounded hover:bg-gray-100" :class="{
              'text-black font-semibold bg-gray-200': selectedWorkersCount == 1,
              'text-gray-400': selectedWorkersCount != 1
            }" @click="selectedWorkersCount = 1">
              2-3
            </div>
            <div class="p-4 text-center cursor-pointer border-gray border rounded hover:bg-gray-100" :class="{
              'text-black font-semibold bg-gray-200': selectedWorkersCount == 2,
              'text-gray-400': selectedWorkersCount != 2
            }" @click="selectedWorkersCount = 2">
              4-10
            </div>
            <div class="p-4 text-center cursor-pointer border-gray border rounded hover:bg-gray-100" :class="{
              'text-black font-semibold bg-gray-200': selectedWorkersCount == 3,
              'text-gray-400': selectedWorkersCount != 3
            }" @click="selectedWorkersCount = 3">
              11+
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button label="Сохранить" class="p-button-success outline-none" style="width: 100%"
          @click="handleSignUpBusiness" />
      </template>
    </Dialog>

    <Chat ref="chat" :showButton="false" />
  </header>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Dropdown from "primevue/dropdown";
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Chat from "@/components/Manager/Chat/ChatComponent";
import SearchBar from "./SearchBar";

var bar;

export default {
  components: {
    OverlayPanel,
    InputText,
    InputMask,
    Dropdown,
    Avatar,
    Menu,
    Dialog,
    Button,
    Checkbox,
    Chat,
    SearchBar,
  },
  data() {
    return {
      shouldDisplaySearchBar: false,
      selectedWorkersCount: 0,
      partnerSignUp: {
        consent: false,
      },
      isMenuDisplayed: false,
      isFinalizingPartnerAccount: false,
      businessSignUp: { title: null, workersCount: 0 },
      isCreatingPartnerAccount: false,
      isRestoringAccount: false,
      isCreatingAccount: false,
      isUserAuthenticated: false,
      user: null,
      login: "",
      password: "",
      errors: {},
      signUpErrors: {},
      signUpForm: {
        email: "",
        password: "",
        name: "",
        consent: false,
      },
      menu: [],
      activityTypes: [
        { title: "Еда и развлечения" },
        { title: "Красота" },
        { title: "Аренда недвижимости" },
        { title: "Медицина" },
        { title: "Автоуслуги" },
      ],
    };
  },
  created() {
    this.checkAuth();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.shouldDisplaySearchBarFn);
  },
  mounted() {
    bar = document.getElementById("sb");
    if (bar) {
      window.addEventListener("scroll", this.shouldDisplaySearchBarFn);
    }
  },
  methods: {
    shouldDisplaySearchBarFn() {
      if (pageYOffset >= bar.offsetTop) {
        this.shouldDisplaySearchBar = true;
        return false;
      }

      this.shouldDisplaySearchBar = false;
    },
    searchInCategoryMode(category_id) {
      this.$store.commit("setSearchToCategoryMode", category_id);

      switch (category_id) {
        case 5:
          this.$router.push("/cars");
          break;
        case 3:
          this.$router.push("/medicine");
          break;
        case 6:
          this.$router.push("/realty");
          break;
        case 7:
          this.$router.push("/education");
          break;
        case 1:
          this.$router.push("/food");
          break;
        case 2:
          this.$router.push("/event");
          break;
        case 4:
          this.$router.push("/beauty");
          break;
        default:
          this.$router.push("/find");
      }

      this.$emit("searchQueryChange");
    },
    toggleMenu() {
      this.isMenuDisplayed = !this.isMenuDisplayed;
    },
    handleSignUpBusiness() {
      this.isCreatingPartnerAccount = false;
      this.isFinalizingPartnerAccount = false;
      this.$router.push("/manager/org");
    },
    handleSignUpPartner() {
      this.partnerSignUp.name =
        this.partnerSignUp.firstName + " " + this.partnerSignUp.lastName;
      this.partnerSignUp.password_confirmation = this.partnerSignUp.password;

      this.$http
        .post("register", this.partnerSignUp)
        .then((response) => {
          let token = "Bearer " + response.data.token;

          localStorage.setItem("token", token);
          this.$http.defaults.headers.common["Authorization"] = token;

          this.fetchUserInfo().then(() => {
            this.isUserAuthenticated = true;
          });

          this.isCreatingPartnerAccount = false;
          this.isFinalizingPartnerAccount = true;
        })
        .catch(() => { });
    },
    setPartnerMenu() {
      this.menu = [
        {
          label: 'Основное',
          items: [{
            label: "Мой кабинет",
            icon: "pi pi-calendar",
            route: "/manager",
          },
          {
            label: "Чат",
            icon: "pi pi-envelope",
            command: () => {
              this.$refs.chat.isChatDisplayed = true;
            },
          }]
        },
        {
          label: "Аккаунт",
          items: [
            {
              label: "Настройки",
              icon: "pi pi-cog",
              route: "/user/settings",
            },
            {
              label: "Выйти",
              icon: "pi pi-power-off",
              command: () => {
                this.signOut();
              },
            },
          ],
        },
      ];
    },
    setClientMenu() {
      this.menu = [
        {
          label: 'Основное',
          items: [
            {
              label: "Бронирования",
              icon: "pi pi-calendar",
              route: "/user/bookings",
            },
            {
              label: "Избранное",
              icon: "pi pi-heart",
              route: "/user/favorites",
            },
            {
              label: "Чат",
              icon: "pi pi-envelope",
              command: () => {
                this.$refs.chat.isChatDisplayed = true;
              },
            }
          ]
        },
        {
          label: "Аккаунт",
          items: [
            {
              label: "Настройки",
              icon: "pi pi-cog",
              route: "/user/settings",
            },
            {
              label: "Выйти",
              icon: "pi pi-power-off",
              command: () => {
                this.signOut();
              },
            },
          ],
        },
      ];
    },
    checkAuth() {
      if (localStorage.getItem("token") && localStorage.getItem("user")) {
        this.isUserAuthenticated = true;
      }

      this.fetchUserInfo().then(() => {
        if (this.user) {
          this.isUserAuthenticated = true;

          if (this.user.user_type == 0) {
            this.setClientMenu();
          }

          if (this.user.user_type > 0) {
            this.setPartnerMenu();
          }

          return false;
        }

        this.isUserAuthenticated = false;
      });
    },
    showSignUpForm() {
      this.isCreatingAccount = true;
      this.toggleAuthPanel();
    },
    showRestorationForm() {
      this.isRestoringAccount = true;
      this.toggleAuthPanel();
    },
    toggleUserMenu(ev) {
      this.$refs.userMenu.toggle(ev);
    },
    toggleAuthPanel(ev) {
      this.$refs.authPanel.toggle(ev);
    },
    handleRegister() {
      this.$http
        .post("/register", {
          email: this.signUpForm.email,
          password: this.signUpForm.password,
          password_confirmation: this.signUpForm.password,
          name: this.signUpForm.name,
        })
        .then((response) => {
          let token = "Bearer " + response.data.token;

          this.signUpErrors = {};

          localStorage.setItem("token", token);
          this.$http.defaults.headers.common["Authorization"] = token;

          this.fetchUserInfo().then(() => {
            this.isUserAuthenticated = true;
          });

          this.isCreatingAccount = false;
        })
        .catch((error) => {
          let errors = error.response.data.errors;

          this.signUpErrors = errors;
        });
    },
    signOut() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$refs.userMenu.hide();
      this.user = null;
      this.isUserAuthenticated = false;
      this.$http.defaults.headers.common["Authorization"] = null;
      this.$router.go();
    },
    signIn() {
      this.$http
        .post("/login", { email: this.login, password: this.password })
        .then((response) => {
          let token = "Bearer " + response.data.token;

          this.errors = {};

          localStorage.setItem("token", token);
          this.$http.defaults.headers.common["Authorization"] = token;

          this.fetchUserInfo().then(() => {
            this.isUserAuthenticated = true;
          });

          this.toggleAuthPanel();

          // document.location.reload();
          this.$router.go();
        })
        .catch((error) => {
          let errors = error.response.data.errors;

          this.errors = errors;
        });
    },
    fetchUserInfo() {
      return this.$http
        .get("/user")
        .then((response) => {
          this.user = response.data;
          localStorage.setItem("user", JSON.stringify(response.data));
        })
        .catch(() => {
          this.user = null;
        });
    },
  },
};
</script>
