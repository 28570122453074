<template>
  <footer class="bg-light h-auto lg:h-56 py-4 lg:py-10 px-4 lg:px-0">
    <div class="container h-full mx-auto">
      <div class="grid grid-rows-5 gap-y-6 text-center text-sm lg:text-md lg:text-left lg:grid-cols-5 text-left h-full">
        <div class="flex flex-col lg:justify-between">
          <img
            @click="$router.push('/')"
            class="object-none object-center lg:object-left"
            src="/img/logo_primary.png"
          />
          <!-- <p class="pt-0 lg:pt-10">ООО "АБТЕ"</p> -->
          <!-- <p>2021</p> -->
        </div>

        <div class="flex flex-col lg:justify-between text-left">
          <!-- <strong class="pb-0 lg:pb-2 text-xl">О проекте</strong> -->
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/about')"
            href="/about"
          >О проекте</a>
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/contacts')"
            href="/contacts"
            v-if="1==2"
          >Контакты</a>
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/contacts')"
            href="/contacts"
          >Связаться с нами</a>
        </div>

        <div class="flex flex-col lg:justify-between text-left">
          <!-- <strong class="pb-0 lg:mt-0 lg:pb-2 text-xl">Для бизнеса</strong> -->
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/for-partners')"
            href="/for-partners"
          >Партнёрам</a>
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/job')"
            href="/job"
          >Вакансии</a>
          <a
            class="text-md py-2 cursor-pointer"
            @click.prevent.stop="$emit('toggleCreateBusiness')"
          >Добавить компанию</a>
        </div>

        <div class="flex flex-col lg:justify-between text-left">
          <!-- <strong class="pb-0 lg:mt-0 lg:pb-2 text-xl">Юридическая информация</strong> -->
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/booking-conditions')"
            href="/booking-conditions"
          >Пользовательское соглашение (клиент)</a>
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/conditions')"
            href="/conditions"
            v-if="1==2"
          >Пользовательское соглашение (партнёр)</a>
          <a
            class="text-md py-2"
            @click.prevent.stop="$router.push('/privacy-policy')"
            href="/privacy-policy"
          >Политика конфиденциальности</a>
        </div>

        <div class="flex flex-col lg:justify-between relative lg:text-right text-center space-y-1.5">
          <a
            class="cnt"
            href="tel:+375295751800"
          ><strong class="text-xl">(29) 575-18-00</strong></a>
          <a
            class="cnt"
            href="mailto:info@reserv.by"
          ><strong class="text-xl">info@reserv.by</strong></a>
          <i></i>
          <i></i>

          <div class="flex space-x-3 mt-4 lg:mt-4 lg:justify-end justify-center">
            <div
              class="rounded flex"
              style="width:30px;height:30px;background:#000000"
            >
              <img
                class="object-none"
                src="/img/sn/tt.png"
              />
            </div>
            <div
              class="rounded flex"
              style="width:30px;height:30px;background:#784F99"
            >
              <img
                class="object-none"
                src="/img/sn/vb.png"
              />
            </div>
            <a
              href="https://wa.me/375296558003"
              target="_blank"
            >
              <div
                class="rounded flex"
                style="width:30px;height:30px;background:#00E676"
              >
                <img
                  class="object-none"
                  src="/img/sn/wa.png"
                />
              </div>
            </a>
            <a
              href="https://telegram.me/+375295751800"
              target="_blank"
            >
              <div
                class="rounded flex"
                style="width:30px;height:30px;background:#40B3E0"
              >
                <img
                  class="object-none"
                  src="/img/sn/tg.png"
                />
              </div>
            </a>
            <a
              href="https://instagram.com/reserv.by"
              target="_blank"
            >
              <div
                class="rounded flex"
                style="width:30px;height:30px;background:#CE2A7D"
              >
                <img
                  class="object-none"
                  src="/img/sn/ig.png"
                />
              </div>
            </a>
            <div
              class="rounded flex"
              style="width:30px;height:30px;background:#3B5998"
            >
              <img
                class="object-none"
                src="/img/sn/facebook.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
a {
  color: #696969;
}
.cnt {
  color: #2c3e50 !important;
}
</style>
