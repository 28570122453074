<template>
  <div>
    <Header />

    <div class="bg-bg-wider lg:text-left px-4 md:px-0">
      <div class="container mx-auto py-24 text-white">
        <h1 class="text-4xl font-semibold">Пользовательское соглашение</h1>
      </div>
    </div>
    <div class="container mx-auto text-left py-8 px-4 md:px-0">
      <p class="text-right">УТВЕРЖДЕНО<br />Приказ директора ООО “Абте”<br />№___ от ________________</p>

      <p class="pt-8">
        Настоящее Пользовательское соглашение (далее - Соглашение) регулирует порядок доступа и использования Пользователем (физическим, дееспособным
        лицом), принявшим условия настоящего Соглашения, сервисом rezerv.by, находящимся по адресу в сети Интернет:____________________________, либо
        используемого с помощью мобильных приложений, владельцем которого является Исполнитель - общество с ограниченной ответственностью “Абте”
        (зарегистрированное _______________________ , находящееся по адресу:______________________)
      </p>
      <p>&nbsp;</p>
      <p>1.Предмет соглашения</p>
      <p>
        1.1.Настоящее Соглашение регулирует порядок предоставления Пользователю программно-технических возможностей для установления контактов и
        совершения сделок между Пользователем и третьими лицами (Продавцами) с использованием разработанного с участием Исполнителя программного
        обеспечения, либо программного обеспечения третьих лиц через глобальную компьютерную сеть Интернет;
      </p>
      <p>
        1.2. Исполнитель, на основании настоящего Соглашения предоставляет Пользователю доступ к сервису поиска потенциальных продавцов, исполнителей
        и т.д. Rezerv.by, расположенном в сети Интернет по адресу:______________________________, а также доступ к использованию мобильного приложения
        rezrv.by, посредством установки через сервисы Google Play Store (_______________________), App Store (_________________________).
      </p>
      <p>1.3. В настоящем договоре используются следующие термины и определения:</p>
      <p>
        Программные продукты (вместе именуемые - «Программные продукты») - программные продукты Исполнителя, включая любой из следующих сайтов
        Исполнителя, расположенных по адресам: _____________________________________________ (далее - сайт), и их поддомены, другие сайты, на которых
        находятся Продукты Исполнителя и программный код, мобильное приложение rezerv.by, доступное для скачивания:_________________________________,
        _____________________________, иные программные продукты Исполнителя, используемые для оказания услуг по настоящему договору. Продавцы -
        потенциальные продавцы, лица оказывающие услуги, выполняющие работы, предоставляющие иные блага Пользователю, доступ к совершении сделок с
        которыми Пользователь получает через программные продукты Исполнителя. Исполнитель - общество с ограниченной ответственностью “Абте”,
        зарегистрированное ____________________________________, расположенное по адресу:________________________________________,
        эл.почта:____________________. Пользователь - физическое, дееспособное лицо, принявшее условия настоящего Соглашения, в порядке,
        предусмотренном Соглашением, либо фактически использующее Программные продукты Исполнителя, либо случайные посетители сайта rezerv.by, либо
        пользователи программных продуктов Исполнителя. Учетная запись (аккаунт) Пользователя - хранимая Исполнителем совокупность данных о
        Пользователе, необходимая для его опознавания (аутентификации) и предоставления доступа к его личным данным и настройкам, использования
        Программных продуктов Исполнителя, включая данные о поступившей оплате, иные данные, предоставленные Пользователем. Данные Пользователя -
        совокупность данных и/или сведений, изображений, сведений в иных формах, предоставленных Пользователем при создании учетной записи,
        используемых для идентификации Пользователя (логин и пароль), сведения, позволяющие совершить сделки с Потенциальными продавцами, иные
        сведения переданные Пользователем Исполнителю, размещенные в личном кабинете Пользователя, либо ставшие доступными Исполнителю через
        программные продукты, либо иным законным способом, включая сведения, не находящиеся в открытом доступе. Личный кабинет Пользователя - раздел
        сайта, который позволяет Пользователю получать доступ к учетной записи (аккаунту), данным о состоянии и статистической информации лицевого
        счета, деталям заказов и иной информации, посредством которого Пользователь передает данные Исполнителю, стороны осуществляют взаимоотношения
        по настоящему договору, совершают юридически значимые действия.
      </p>

      <p>
        1.4. Исполнитель не владеет, не создает, не продает, не перепродает, не контролирует, не предлагает, не предоставляет и не обеспечивает
        какие-либо товары, работы, услуги Продавцов, а также не управляет ими, не является организатором их деятельности. Пользователь принимает на
        себя все риски, связанные с возмездными отношениями с Продавцами. Продавцы несут единоличную и самостоятельную ответственность за свои товары,
        работы, услуги. Совершая сделки через Программные продукты Исполнителя, Пользователь вступает в договорные отношения непосредственно с
        Продавцами. Исполнитель не является стороной или иным участником каких-либо договорных отношений между Пользователем и Потенциальными
        продавцами и не выступает агентом чьей либо из сторон, не оказывает Пользователю никаких возмездных услуг в рамках настоящего Соглашения.
        Обязательства и ответственность Исполнителя ограничены предметом настоящего Соглашения.
      </p>
      <p>
        1.5. Продавцы, с которыми заключает договор Пользователь через Программные продукты Исполнителя, не являются работниками, агентами, участником
        совместного предприятия или партнером Исполнителя, а действуют исключительно от своего лица и в собственных интересах.
      </p>
      <p>
        1.6. Программные продукты могут содержать ссылки на сторонние сайты и ресурсы. Исполнитель не несет ответственности за доступность или
        точность сторонних сайтов и ресурсов, а также за контент, продукты или услуги сторонних сайтов и ресурсов, равно как и за контент, продукты
        или услуги (работы), выполняемые Продавцами.
      </p>
      <p>&nbsp;</p>
      <p>2. Порядок заключения, изменения и прекращения Соглашения</p>
      <p>
        2.1. Публикация (размещение) текста настоящего Соглашения на сайте является публичной офертой – предложением Исполнителя любому дееспособному
        лицу, достигшему 18-и летнего возвраста, отозвавшемуся на это предложение, заключить Соглашение на изложенных в нем условиях.
      </p>
      <p>
        2.2. Заключение настоящего Соглашения производится посредством принятия (акцепта) Пользователем его условий в целом. Акцепт условий договора
        признается полным и безоговорочным, не допускается частичное принятие условий договора Пользователем.
      </p>
      <p>
        2.3. Настоящее Соглашение вступает в силу с момента совершения Пользователем любого из следующих действий, подтверждающих факт принятия
        (акцепта) условий настоящего Соглашения:
      </p>
      <p>регистрации на сайтах Исполнителя, с созданием учетной записи (аккаунта);</p>
      <p>установки мобильных приложений (_________________________________________________) на устройства Пользователя;</p>
      <p>
        выражение согласия с условиями Соглашения на сайте либо в мобильных приложениях путем проставления визуальной отметки (“галочки” или иной),
        проставление согласия в размещенной на сайте (мобильном приложении) форме;
      </p>
      <p>внесения оплаты Продавцам через Программные продукты Исполнителя;</p>
      <p>
        Выполнение Пользователем любого из указанных действий означает принятие предложения Исполнителя заключить Соглашение, независимо от наличия в
        таких документах (информации) личной подписи, электронной цифровой подписи либо их эквивалентов, создания личного кабинета, установки
        мобильных приложений, совершения иных действий.
      </p>
      <p>
        2.4. Соглашение распространяет свое действие на любых лиц, в том числе случайных посетителей, совершивших любое активное действия в
        программных продуктах Исполнителя: переходы по ссылкам, открытие рекламных объявлений, просмотр предложений Продавцов и т.д., независимо от
        выполнения условий вступления в силу Соглашения, предусмотренных подпунктом 2.3 настоящего Соглашения.
      </p>
      <p>2.5. Соглашение считается заключенным в простой письменной форме.</p>
      <p>
        2.6. Действия Исполнителя на сайте, реализуемые в том числе через личный кабинет, либо иными способами, включая предложение Пользователю
        совершить определенные действия (выбрать тарифный план, изменить данные Пользователя и т.д.), ограничение доступа к личному кабинету, удаление
        аккаунта и т.д. считается предложением по согласованию, исполнению, изменению, прекращению действия условий настоящего договора либо самого
        договора, а их фактическое совершение Пользователем (подтверждение, согласие путем проставления визуальной отметки («галочки») в
        соответствующих формах, оплата услуг, счетов, заполнение форм, переходы по ссылкам, открытия страниц и т.д.) признаются согласием Пользователя
        на заключение, согласование, изменение, прекращение условий договора либо самого договора, иное изменение отношений Исполнителя и
        Пользователя, предусмотренное соответствующим предложением (действием) Исполнителя.
      </p>
      <p>
        2.7. Действия Пользователя в Программных продуктах Исполнителя: проставление “галочек”, переход по ссылкам, открытие разделов, страниц, иные
        действия, предусмотренные Программными продуктами, признаются действия по изменению, прекращение, согласованию условий Соглашения, договоров с
        Продавцами, согласие с предложением и т.д.
      </p>
      <p>2.8. Соглашение прекращает свое действие:</p>
      <p>по соглашению сторон;</p>
      <p>при одностороннем отказе стороны от исполнения договора (Соглашения), в случаях, предусмотренных договором и законодательством;</p>
      <p>при наступлении иных обстоятельств, предусмотренных договором.</p>
      <p>
        2.9. Односторонний отказ стороны от исполнения договора влечет досрочное прекращение действия договора (расторжение) во внесудебном порядке.
        Односторонним отказом стороны от исполнения настоящего договора (Соглашения) стороны считают следующие действия Сторон: удаление аккаунта
        Пользователем либо Исполнителем; уведомление любой стороны об одностороннем отказе от исполнения договора (Соглашения); В случаях
        одностороннего отказа от исполнения договора (Соглашения), договор считается расторгнутым: при удалении аккаунта - непосредственно после
        удаления аккаунта; при уведомлении об одностороннем отказе - в срок, указанный в таком уведомлении, если срок не указан - непосредственно
        после уведомления.
      </p>
      <p>
        Стороны пришли к соглашению, что любое сообщение содержащее указание на расторжение договора (Соглашения) , будет считаться сообщением об
        одностороннем отказе от исполнения настоящего договора (Соглашения) .
      </p>
      <p>
        2.10. Расторжение Соглашения влечет прекращение его действия и прекращение обязательств Сторон, за исключением обязательств Пользователя по
        оплате полученных товаров, работ, услуг, возмещению убытков, уплаты неустойки, если она предусмотрена Соглашение.
      </p>
      <p>
        2.11. Пользователь подтверждает: что обязуется соблюдать и ознакомлен со всеми Правилами работы Программных продуктов, пусть даже и не
        поименованных в настоящем Соглашении, с иными правилами, включая обязательные требования, установленные для Пользователя в стране его
        деятельности и применяемых в связи с использованием Программных продуктов;
      </p>
      <p>
        2.12. В связи с особенностями сети Интернет Исполнитель не может гарантировать постоянную и непрерывную доступность Программных продуктов.
        Исполнитель может ограничить доступность Программных продуктов их разделов или функций, если это будет необходимо для поддержания должной
        пропускной способности, безопасности или целостности серверов, а также для проведения технических мероприятий, обеспечивающих надлежащее или
        улучшенное функционирование Сайтов.
      </p>
      <p>&nbsp;</p>
      <p>3. Срок действия Соглашения</p>
      <p>
        3.1. Настоящее Соглашение действует в течение неопределенного срока, при условии функционирования и доступности Программных продуктов
        Исполнителя.
      </p>
      <p>&nbsp;</p>
      <p>4. Права и обязанности сторон. Запреты</p>
      <p>4.1. Исполнитель имеет право:</p>
      <p>запросить у Пользователя любые документы и сведения;</p>
      <p>в одностороннем порядке в любое время изменять любые условия настоящего Соглашения;</p>
      <p>использовать данные Пользователя в своих нуждах: для рекламных и прочих целей;</p>
      <p>
        отказать Пользователю в заключении и исполнении Соглашения, либо в одностороннем порядке отказаться от исполнения Соглашения, либо от
        исполнения отдельных обязательств, перенести срок исполнения обязательств, а равно как и в одностороннем порядке отказаться от исполнения
        настоящего Соглашения;
      </p>
      <p>
        в любое время изменять содержание Программных продуктов, любых их разделов, включая личный, удалять либо добавлять Продавцов, виды товаров,
        работ, услуг, не меняя общей направленности Программных продуктов;
      </p>
      <p>
        временно ограничить использование аккаунта и/или доступ к личному кабинету (приостановить оказание услуг) при неисполнении Пользователем
        платежным обязательств, включая внесения платы Продавцам, нарушении настоящего договора, несоблюдении гарантий предусмотренных настоящим
        Соглашением, а также в иных, в случаях, предусмотренных настоящим Соглашением - до устранения таких обстоятельств;
      </p>
      <p>
        отказаться от исполнения настоящего Соглашения в одностороннем порядке, путем удаления аккаунта Пользователя в случаях, предусмотренных
        Соглашением или законодательством, а также по требованию уполномоченных органов;
      </p>
      <p>
        размещать рекламу на сайтах, Программных продуктах, демонстрируемую Пользователю без его согласия, в том числе путем размещения баннеров,
        закрывающих содержимое экрана, любого содержания, не запрещенного законодательством, в любое время и в любом объеме, по усмотрению
        Исполнителя;
      </p>
      <p>
        проверять (модерировать) любые данные, предоставленные Пользователем, сообщения, переписку с третьими лицами через сайт, удалять любые данные
        оставленные Пользователем: в том числе оценки, комментарии, сообщения;
      </p>
      <p>в одностороннем порядке отказаться от исполнения настоящего Соглашения, в случаях, предусмотренных договором и законодательством;</p>
      <p>реализовывать иные права, предусмотренные договором.</p>

      <p>4.2. Пользователь имеет право:</p>
      <p>получать консультации по вопросам использования Программных продуктов , техническую поддержку от Исполнителя;</p>
      <p>реализовывать иные права, предусмотренные настоящим договором.</p>

      <p>4.3. Обязанности Пользователя:</p>
      <p>предоставлять полную и достоверную информацию о себе, включая личные данные;</p>
      <p>
        по требованию Исполнителя предоставлять документы, подтверждающие данные Пользователя, необходимые для его идентификации и т.д., в форме,
        определенной Исполнителем;
      </p>
      <p>контролировать направление Исполнителем уведомлений, сообщений, информации и т.д., обеспечить их своевременное и безусловное получение;</p>
      <p>обеспечивать выполнение своих обязанностей, соблюдение гарантий и запретов, предусмотренных Соглашением;</p>
      <p>своевременно и в полном объеме оплачивать товары, работы, услуги Продавцов;</p>
      <p>
        использовать для оплаты по настоящему договору только принадлежащие ему платежные средства, либо средства третьих лиц, попавшие к нему
        законным путем;
      </p>
      <p>обеспечивать сохранность учетных данных, не передавать учетные данные третьим лицам, не использовать учетные данные других лиц;</p>
      <p>не нарушать запреты для Пользователя, предусмотренные подпунктом 4.5 настоящего Соглашения;</p>
      <p>
        в пятидневный срок сообщать Исполнителю либо внести соответствующие изменения в данные в личном кабинете о каких-либо юридических или
        фактических изменении в статусе или деятельности Пользователя, которые имеют значение для Исполнителя или Продавцов или третьих лиц, в
        особенности информацию об изменении юридического или почтового адреса Пользователя.
      </p>
      <p>
        4.4. Предусмотренные Соглашением права и обязанности Пользователя не могут толковаться расширительно, в частности, Пользователь не вправе
        вести какую-либо деятельность от имени Исполнителя в качестве его уполномоченного представителя, не предусмотренную настоящим Соглашением.
      </p>
      <p>4.5. Пользователю запрещается:</p>
      <p>• создавать “клоны” аккаунта (новый аккаунт при блокировке предыдущего);</p>
      <p>• распространять недостоверную либо неподтвержденную информацию, клевету, в том числе и в виде оценок, комментариев, отзывов;</p>
      <p>
        • оскорблять, негативно отзываться о других Пользователях, Продавцах (за исключением подтвержденных и достоверных отзывов и комментариев);
      </p>
      <p>• употреблять в бранную лексику, использовать запрещенные выражения, обороты, символы и т.д.</p>
      <p>• осуществлять иные действия, запрещенные или ограниченные настоящим договором.</p>
      <p>&nbsp;</p>
      <p>5. Заключение и исполнение договоров с Продавцами</p>
      <p>
        5.1. Пользователь, используя программные продукты Исполнителя получает возможность заключать договоры (совершать сделки) с Продавцами,
        посредством использования Программных продуктов Исполнителя.
      </p>
      <p>
        5.2. Размещенные в Программных продуктах предложения Продавцов о совершении сделок (заключении договоров) считаются офертой Пользователю на
        заключение сделки с Продавцом на предложенных Продавцом условиях. Предложением Продавца (офертой) на совершение сделки (заключение договора)
        является любая информация, доступная Пользователю через Программные продукты Исполнителя, содержащая описание товаров, работ, услуг, их
        стоимость, иную информацию по усмотрению Продавца.
      </p>
      <p>
        5.3. Условия договора Пользователя с Продавцами, заключаемого путем акцепта Пользователем оферты Продавца определяются по соглашению между
        Пользователем и Продавцом и согласовываются через личный кабинет, иные разделы Программных продуктов Исполнителя, или в ином порядке по
        соглашению между Пользователем и Продавцом.
      </p>
      <p>
        5.4. В рамках настоящего соглашения, Исполнитель не оказывает Пользователю услуг, не выполняет работ, не реализует товаров, за исключением
        услуг, предусмотренных настоящим Соглашением.
      </p>
      <p>
        5.5. Аутентификация Пользователя путем доступа в личный кабинет с использованием логина и пароля или иных идентификаторов, признается
        Сторонами надлежащим подтверждением личности Пользователя при заключении и исполнении договора с Продавцами.
      </p>
      <p>
        5.6. Пользователь самостоятельно обеспечивает доступ к использованию Программных продуктов, путем использования соответствующих технических
        средств и несёт единоличную ответственность за оплату услуг третьих лиц (таких как услуги связи, Интернет и т.д.), необходимых для
        использования Программных продуктов. и Продавцов.
      </p>
      <p>&nbsp;</p>
      <p>6. Отзывы. Комментарии. Оценки</p>

      <p>
        6.1. Если это позволяет функционал Программных продуктов, Пользователь вправе оставлять комментарии, отзывы, оценки, иным образом
        демонстрировать оценку деятельности Продавцов.
      </p>
      <p>6.2. Пользователь обязуется давать оценки, оставлять отзывы, комментарии только в отношении Продавцов, с которыми взаимодействовал лично.</p>
      <p>
        6.3. При даче оценок, отзывов, комментариев Пользователь обязуется объективно и честно оценивать Продавцов, товары, работы, услуги,
        действовать от своего имени, не нарушать законодательство. Запрещаются оскорбительные оценки, комментарии, отзывы, содержащее ненадлежащую или
        недостоверную информацию.
      </p>
      <p>6.4. Исполнитель вправе по своему усмотрению удалить любой отзыв, оценку, комментарий.</p>
      <p>
        6.5. Пользователь обязан по требованию Исполнителя подтвердить объективность оценки, факт своего взаимодействия с Продавцом, иные сведения,
        запрошенные Исполнителем.
      </p>
      <p>6.6. Пользователь самостоятельно несет ответственность за соответствие отзывов, оценок, комментариев законодательству.</p>
      <p>&nbsp;</p>
      <p>7. Платежи</p>
      <p>
        7.1. Исполнитель не принимает от Пользователя никаких платежей в свой адрес. Все платежи, перечисляемые Пользователем с использованием
        Программных продуктов Исполнителя являются платежами в адрес Продавцов, иных третьих лиц.
      </p>
      <p>
        7.2. Оплата товаров, работ, услуг Продавцов осуществляется посредством использования в Программных продуктах платежных систем (сервисов) по
        усмотрению Исполнителя.
      </p>
      <p>
        7.3. Расчеты Пользователя с Продавцами осуществляются в следующем порядке: Продавец выставляет Пользователю счет на оплату товаров, работ,
        услуг, через Программные продукты Исполнителя, который Пользователь обязан оплатить. Оплата внесенная Пользователем через Программные продукты
        Исполнителя является надлежащим исполнением обязательств Пользователя перед Продавцом.
      </p>
      <p>
        7.4. Все перечисленные Пользователем в счет оплаты товаров, работ, услуг денежные средства зачисляются непосредственно Продавцам через
        платежные сервисы, платежные модули которых размещены в программных продуктах Исполнителя (BePaid либо иной сервис по выбору Исполнителя).
      </p>
      <p>
        7.5. Выставление счета на оплату осуществляется путем размещения сведений о цене товаров, работ, услуг в соответствующих разделах Программных
        продуктов и размещения в Программных продуктах либо направлении Пользователю ссылки на платежную систему, с помощью которой осуществляется
        оплата Продавцам.
      </p>
      <p>7.6. Продавцы вправе в любое время изменить стоимость услуг, (товаров, работ).</p>
      <p>7.7. Банковские и иные комиссии, комиссии, сборы платежных систем оплачивает Продавец.</p>
      <p>
        7.8. Платежи через Программные продукты обрабатывает компания - владелец платежной системы (Процессинговый центр), действующая на основании
        договора с Исполнителем. Все права и обязанности, возникающие в процессе расчётов, возникают непосредственно между Процессинговым центром и
        плательщиком (Исполнителем). Все возможные требования, связанные с возвратом денежных средств, их перечислением, зачислением Продавцам,
        Пользователь заявляет, соответственно, либо Процессинговому центру либо Продавцу.
      </p>
      <p>
        7.9. Исполнитель вправе отказать в оказании услуг, проведении платежа, если имеет основания полагать, что Пользователь использует для оплаты
        не принадлежащие ему платежные (денежные) средства, либо иным образом нарушает условия настоящего договора или требования законодательства.
      </p>
      <p>&nbsp;</p>
      <p>8. Отчетность. Документация по оказанию услуг</p>
      <p>
        8.1. Исполнитель вправе составить и подписать в одностороннем порядке всю необходимую бухгалтерскую, первичную учетную документацию, включая
        акты оказанных услуг, и прочую документацию, подтверждающую оказание услуг, в том числе в форме электронного документа.
      </p>
      <p>&nbsp;</p>
      <p>9.Доступ к аккаунту. Ограничение доступа</p>
      <p>
        9.1. Исполнитель вправе в одностороннем порядке ограничить доступ к аккаунту Пользователя либо личному кабинету, (в одностороннем порядке
        отказаться от оказания услуг), ограничить возможность совершения Пользователем определенных действий (комментировать, оценивать Потенциальных
        продавцов и т.д.) либо удалить аккаунт Пользователя (в одностороннем порядке отказаться от исполнения настоящего договора) :
      </p>
      <p>при неисполнении Пользователем своих обязательств по настоящему договору, несоблюдении гарантий, ином нарушении условий договора;</p>
      <p>при любом нарушении Пользователем условий настоящего договора;</p>
      <p>при нарушении Пользователем условий договора с Продавцом;</p>
      <p>в иных случаях, предусмотренными договором.</p>
      <p>
        9.2. Ограничение доступа к аккаунту действует до устранения обстоятельств, его вызвавших. Пользователь обязан предоставлять Исполнителю любые
        запрошенные им документы, сведения и т.д., подтверждающие устранение указанных обстоятельств.
      </p>
      <p>&nbsp;</p>
      <p>10. Разрешение споров Пользователя и Продавцов</p>
      <p>
        10.1. При возникновении споров между Пользователем и Продавцом, ответственным перед Пользователем является Продавец. Исполнитель не несет
        юридической ответственности при неисполнении Продавцами своих обязательств перед Пользователем.
      </p>
      <p>
        10.2. Пользователь вправе обратиться к Исполнителю за содействием в разрешении спора с Продавцом., в том числе используя возможности
        Программных продуктов. При рассмотрении споров Пользователя с Продавцами, Исполнитель не несет ответственности за действия Продавцов. Его
        участие в споре ограничивается действиями, предусмотренными пунктом 10.3 настоящего Соглашения.
      </p>
      <p>
        10.3. Исполнитель вправе, по своему усмотрению, рассмотрев обращение Пользователя, принять любую из следующих либо несколько мер реагирования:
      </p>
      <p>• оставить обращение без рассмотрения;</p>
      <p>• провести переговоры с Продавцом;</p>
      <p>• оказать содействие Пользователю, в том числе путем предоставления информации для разрешения спора;</p>
      <p>• принять к Продавцу меры, предусмотренные соглашением с Продавцом.</p>
      <p>&nbsp;</p>
      <p>11. Техническая поддержка</p>
      <p>
        11.1. Исполнитель оказывает Пользователю техническую поддержку, в порядке, предусмотренном информацией, размещенных в соответствующих разделах
        Программных продуктов.
      </p>
      <p>&nbsp;</p>
      <p>12. Гарантии. Запреты</p>
      <p>12.1. Пользователь гарантирует и обязуется:</p>
      <p>что им приняты все необходимые меры финансового контроля и меры по предотвращению легализации доходов, полученных преступным путем;</p>
      <p>соблюдать Общего регламента по защите персональных данных (General Data Protection Regulation, GDPR) (постановление Европейского союза);</p>
      <p>
        для осуществления платежей использует принадлежащие ему средства платежей (банковские карты и т.д.), либо попавшие к нему законным путем
        средства третьих лиц;
      </p>
      <p>
        Пользователь гарантирует что достиг совершеннолетия, обладает полной дееспособностью, либо что является законным представителем
        несовершеннолетнего и для Пользователя или его представителя отсутствуют любые запреты и/или ограничения для заключения настоящего договора и
        совершения сделок по исполнению договора.
      </p>
      <p>12.2. Пользователю запрещается:</p>
      <p>
        при удалении, временной блокировке аккаунта - создавать “клоны” (новый аккаунт, от имени Пользователя, или от имени другого лица, которым
        фактически управляет Пользователь);
      </p>
      <p>осуществлять свою деятельность с нарушением законодательства и положений настоящего договора.</p>
      <p>&nbsp;</p>
      <p>13. Информирование сторон</p>
      <p>
        13.1. Любое информирование по настоящему договору, включая сообщения об изменении условий договора, одностороннем отказе от его исполнения
        (расторжении) и т.д. осуществляется по выбору Исполнителя одним из следующих способов:
      </p>
      <p>путем направления информации на е-мейл Пользователя, указанный в данных Пользователя;</p>
      <p>
        путем направления информации через Программные продукты (личный кабинет, иные разделы), в том числе с использованием push-уведомлений, иных
        возможностей Программных продуктов;
      </p>
      <p>путем уведомления Пользователя любыми иными способами, предусмотренными настоящим соглашением или законодательством.</p>
      <p>
        13.2. Любая информация, направленная Исполнителем, считается полученной в день ее размещения, направления, демонстрации с использованием
        возможностей Программных продуктов. Пользователь обязан самостоятельно, не реже одного раза в сутки, проверять все возможные каналы получения
        информации от Исполнителя. При размещении (направлении) информации Исполнителем, Пользователь не вправе ссылаться на нее неполучение,
        отсутствие возможности ознакомиться с информацией, сведениями, уведомлениями, иные обстоятельства.
      </p>
      <p>&nbsp;</p>
      <p>14. Ответственность Сторон</p>
      <p>
        14.1. Исполнитель не отвечает за действия любых третьих лиц, Продавцов, с которыми Пользователь заключил договоры через Программные продукты.
        либо взаимодействовал иным образом.
      </p>
      <p>
        14.2. Исполнитель несет ответственность за вред, причиненный в результате использования Программных продуктов в пределах суммы эквивалентной
        50 (пятидесяти) белорусских рублей.
      </p>
      <p>
        14.3. Исполнитель, как лицо, не размещающее информацию, а предоставляющее возможность для ее размещения третьим лицам, не несет
        ответственности за размещение любыми третьими лицами, включая Пользователя, неправомерной информации. В таком случае, ответственность несет
        лицо, разместившее такую информацию.
      </p>
      <p>14.4. Пользователь самостоятельно несет ответственность за обеспечение сохранности учетных данных.</p>
      <p>&nbsp;</p>
      <p>15. Разрешение споров между Исполнителем и Пользователем</p>
      <p>
        15.1. Разрешение споров между Пользователем и Исполнителем осуществляется в соответствии с законодательством Республики Беларусь, судом по
        месту нахождения Исполнителя.
      </p>
      <p>&nbsp;</p>
      <p>16. Информация. Политика конфиденциальности</p>
      <p>
        16.1. Данные о Пользователе считаются переданными Исполнителю с момента их размещения в личном кабинете Пользователя, либо передаче
        Исполнителю любым иным способом.
      </p>
      <p>16.2. Исполнитель вправе распоряжаться и пользоваться данными о Пользователе (за исключением логинов и паролей) следующим образом:</p>
      <p>
        использовать для своих нужд, включая рекламу, информацию о Пользователе, его программах, оказанных им услугах, любую иную информацию о
        Пользователе, ставшую известной Исполнителю по настоящему договору, в том и после прекращения действия настоящего договора.
      </p>
      <p>
        Пользователь, заключая настоящий договор, согласен с тем, что Исполнитель имеет доступ к данным Пользователя, его переписке с третьими лицами,
        иным сведениям, ставшими ему известными в связи с заключением и исполнением настоящего договора.
      </p>
      <p>
        16.3. Пользователь дает согласие Исполнителю на сбор, использование и обработку персональных данных Пользователя, статистических сведений, для
        любых целей, не запрещенных законодательством, в том числе для рекламной деятельности, для передачи третьим лицам в целях демонстрации
        Пользователю рекламной информации, для дальнейшего аргументирования сотрудничества с Исполнителем.
      </p>
      <p>
        16.4. Требования о конфиденциальности не распространяются на случаи, когда стороны обязаны передать информацию, предоставить к ней доступ
        третьим в соответствии с законодательством, по требованию уполномоченных государственных органов, в иных случаях, когда такая передача
        (предоставление доступа) является обязательной для стороны.
      </p>
      <p>16.5. При прекращении действия Соглашения по любым основания Исполнитель вправе неограниченно использовать данные Пользователя.</p>
      <p>16.6. Исполнитель вправе хранить любые данные Пользователя у третьих лиц, в том числе путем размещения на серверах, иных местах хранения.</p>
      <p>&nbsp;</p>
      <p>17. Форс-мажор. Технические неисправности</p>
      <p>
        17.1. К форс-мажорным обстоятельствам стороны относят: технические и иные неисправности, произошедшие не по вине Исполнителя и/или не в
        результате его прямых действий, включая нарушение работы сайта по вине провайдера, разработчиков Программного обеспечения, в результате
        действий государственных органов, по иным причинам, в том числе, по неустановленным и связанным с особенностями работы сети Интернет.
      </p>
      <p>
        17.2. При наступлении форс-мажорных обстоятельств, Исполнитель освобождается от ответственности за неисполнение либо ненадлежащее исполнение
        обязательств по настоящему договору и получает право на одностороннее изменение срока исполнения своих обязательств на период действия
        форс-мажорных обстоятельств.
      </p>
      <p>&nbsp;</p>
      <p>18. Прекращение действия Соглашения</p>
      <p>
        18.1. Настоящее Соглашение прекращает свое действие по соглашению сторон, либо в результате отказа от исполнения настоящего Соглашения в
        одностороннем порядке.
      </p>
      <p>18.2. Пользователь вправе в любое время расторгнуть Соглашение путем удаления аккаунта.</p>
      <p>
        18.3. Исполнитель вправе в одностороннем порядке отказаться от исполнения настоящего Соглашения, при любом нарушении Пользователем условий
        Соглашения. Односторонний отказ от исполнения настоящего Соглашения, осуществляется путем удаления Исполнителем аккаунта Пользователя.
      </p>
      <p>&nbsp;</p>
      <p>19. Заключительные положения</p>
      <p>19.1 При толковании положений Соглашения приоритет имеет содержание договора на русском языке.</p>
      <p>
        19.2. Пользователь, заключив настоящее Соглашение, подтверждает, что ознакомлен и согласен со всеми положениями договора, включая стоимость
        услуг, что условия Соглашения приняты им полностью и безоговорочно, что Пользователь обладает необходимой для заключения настоящего договора,
        правоспособностью.
      </p>
      <p>
        19.3. В случае если какое-либо положение Соглашения признаётся недействительным, ничтожным или неисполнимым, все прочие положения Соглашения
        остаются действительными и подлежат исполнению в полном объёме.
      </p>
      <p>
        19.5. Исполнитель вправе переуступить свои права и обязанности по настоящему Соглашению третьим лицам, и Пользователь настоящим соглашается с
        такой переуступкой.
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
