<template>
  <div class="content">
    <div class="border-b">
      <div class="container mx-auto">
        <div class="pl-0">
          <SearchBar
            noPx="true"
            @hasSearchChanges="handleSearch"
          />
        </div>
      </div>
    </div>
    <TopSearchBar
      :query="query"
      @triggerSearch="updateSearchParams"
      v-if="1 == 2"
    />
    <div class="mt-7 mb-12 px-4 lg:px-0">
      <div
        class="container mx-auto flex flex-row justify-between"
        v-if="!isLoading"
      >
        <div class="flex flex-row items-center">
          <!-- <span class="text-2xl lg:text-4xl"
            >Поиск {{ filterCategory.id == 0 ? " в местах" : " в разделе " + filterCategory.title.toLowerCase() }}</span
          > -->
          <span class="text-2xl lg:text-4xl font-semibold">Найденные места</span>
          <p
            class="pl-10 hidden lg:block pt-1.5"
            v-if="placeList.length > 0"
          >
            {{
              placeList.length > 100
                ? "Более " + placeList.length
                : placeList.length
            }}
            вариантов услуг
            <a href="#">в Минске</a>
          </p>
        </div>
        <div
          class="hidden lg:flex flex-row items-center cursor-pointer"
          @click="showMap"
        >
          <p class="text-right">Показать карту</p>
          <img
            class="object-none pl-3"
            src="/img/location.png"
          />
        </div>
      </div>

      <div
        class="container mx-auto overflow-x-scroll lg:overflow-x-hidden my-4"
        v-if="!isLoading"
      >
        <div class="my-2 flex flex-row space-x-3 overflow-x-auto">
          <Dropdown
            v-model="filters.isSlotOnly"
            :options="timeOptions"
            optionLabel="title"
            placeholder="Только в указанное время"
            class="rounded-3xl px-2 outline-none"
          />

          <Dropdown
            v-model="filters.filterDistrict"
            :options="districtOptions"
            placeholder="Район"
            class="rounded-3xl px-2 outline-none"
          />

          <Dropdown
            v-model="filters.isOpen"
            :options="isOpenOptions"
            optionLabel="title"
            placeholder="Сейчас открыто"
            class="rounded-3xl px-2 outline-none"
          />

          <Dropdown
            v-model="filters.sorting"
            :options="sortingOptions"
            optionLabel="title"
            placeholder="Сортировка"
            class="rounded-3xl px-2 outline-none"
            @change="handleSearch()"
          />

          <MultiSelect
            v-if="comfortOptions"
            v-model="filters.comfort"
            :options="comfortOptions"
            optionLabel="title"
            placeholder="Особенности"
            class="rounded-3xl px-2 outline-none"
          />

          <MultiSelect
            v-if="kitchenOptions"
            v-model="filters.kitchen"
            :options="kitchenOptions"
            optionLabel="title"
            placeholder="Кухня"
            class="rounded-3xl px-2 outline-none"
          />
        </div>
      </div>

      <div
        class="container mx-auto lg:grid gap-10"
        :class="{ 'grid-cols-2': isMapDisplayed }"
      >
        <div
          class="lg:grid gap-x-6 gap-y-12 text-left grid-cols-4"
          v-if="!placeList"
        >
          <Skeleton
            height="16rem"
            v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
            :key="i"
          />
        </div>
        <div
          class="grid mt-6 lg:mt-0 gap-x-6 gap-y-12 text-left"
          v-if="placeList && !isLoading"
          :class="{
            'xs:grid-cols-1 sm:grid-cols-2': isMapDisplayed,
            'xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-4': !isMapDisplayed,
          }"
        >
          <ServiceCard
            :data="i"
            v-for="i in placeList"
            :key="i.id"
          />
        </div>
        <div v-if="!isLoading && !placeList.length">
          <strong class="text-lg">Ничего не найдено</strong>
          <p>Попробуйте изменить условия поиска</p>
        </div>

        <div
          v-show="isMapDisplayed"
          id="map"
          style="max-height: 1100px"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import TopSearchBar from "@/views/ui/TopSearchBar.vue";
import SearchBar from "@/views/ui/SearchBar.vue";
import ServiceCard from "../../views/ui/ServiceCard";
import Skeleton from "primevue/skeleton";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import moment from "moment";

var map;

export default {
  components: {
    ServiceCard,
    TopSearchBar,
    Dropdown,
    Skeleton,
    MultiSelect,
    SearchBar,
  },
  data() {
    return {
      isLoading: true,
      q: null,
      isMarkersAdded: false,
      filterDistrict: null,
      data: null,
      geoLocations: [],
      category: null,
      isMapDisplayed: false,
      isMapInitialized: false,
      viewableCategory: null,
      comfortOptions: null,
      kitchenOptions: null,
      resultsCategories: [
        { title: "Места", id: 0 },
        { title: "Еда и развлечения", id: 1 },
        { title: "Красота", id: 2 },
        { title: "Аренда", id: 3 },
        { title: "Медицина", id: 4 },
        { title: "Автоуслуги", id: 5 },
      ],
      filterCategory: { title: "Места", id: 0 },
      places: [],
      list: [],
      search: {
        places: [],
        beauty: [],
        food: [],
        realty: [],
        medical: [],
        mechanical: [],
      },
      timeOptions: [
        { title: "Только в указанное время" },
        { title: "В любое время" },
      ],
      districtOptions: null,
      isOpenOptions: [
        { title: "Сейчас открыто" },
        { title: "Открыто или закрыто" },
      ],
      sortingOptions: [
        { title: "Рейтинг по убыванию", id: 0 },
        { title: "Рейтинг по возрастанию", id: 1 },
        { title: "Цены по убыванию", id: 2 },
        { title: "Цены по возрастанию", id: 3 },
        { title: "Название заведения по возрастанию", id: 4 },
        { title: "Название заведения по убыванию", id: 5 },
      ],
      filters: {
        isSlotOnly: null,
        filterCategory: null,
        filterDistrict: null,
        isOpen: null,
        sorting: null,
        comfort: [],
        kitchen: [],
      },
    };
  },
  computed: {
    placeList() {
      if (!this.search) {
        return false;
      }

      return this.search;
    },
    placeList1() {
      if (!this.search) {
        return false;
      }

      let items = [];
      let comforted = [];
      let result = [];

      if (this.search.length) {
        this.search.forEach((item) => {
          items.push(item);
        });
      }

      items.forEach((item) => {
        let isItemAdded = false;

        if (this.filters.comfort.length) {
          this.filters.comfort.forEach((option) => {
            if (item.placeable.features && item.placeable.features.length) {
              item.placeable.features.forEach((ref) => {
                if (ref.title == option.title && !isItemAdded) {
                  comforted.push(item);
                  isItemAdded = true;
                }
              });
            }
          });
        } else {
          comforted.push(item);
        }
      });

      comforted.forEach((item) => {
        let isItemAdded = false;

        if (this.filters.kitchen.length) {
          this.filters.kitchen.forEach((option) => {
            if (item.placeable.kitchens && item.placeable.kitchens.length) {
              item.placeable.kitchens.forEach((ref) => {
                if (ref.title == option.title && !isItemAdded) {
                  result.push(item);
                  isItemAdded = true;
                }
              });
            }
          });
        } else {
          result.push(item);
        }
      });
      // console.log(result);
      // if (!this.filters.kitchen.length && !this.filters.comfort.length) {
      //   result.push(item);
      // }

      return result;
    },
  },
  props: ["query"],
  created() {
    this.handleSearch();
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoicmVzZXJ2YXBwIiwiYSI6ImNrbmZ4MnVkZDI1a3cydm1ydnZjNzd3ejQifQ.GitchBaltaP1uyfk5TrTtQ";

    this.$nextTick(() => {
      map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/reservapp/cknfyrj9k494y17r0elagsflz",
        center: [0, 0],
        zoom: 9,
      });

      map.addControl(new mapboxgl.NavigationControl());
      map.on("load", () => {
        this.isMapInitialized = true;
      });
    });
  },
  watch: {
    filters: {
      handler: function () {
        this.getDistrictsAndGeo();
        this.touchMapPoints();
      },
      deep: true,
    },
  },
  methods: {
    selectCategory(e) {
      this.filterCategory = e.value;
    },
    updateSearchParams() {
      this.handleSearch();
    },
    handleSearch() {
      this.filters.comfort = [];
      this.filters.kitchen = [];

      let query = { ...this.$store.state.search };
      query.date = query.date ? moment(query.date).format("YYYY-MM-DD") : null;
      query.time = query.time ? moment(query.time).format("HH:mm") : null;
      query.sorting = this.filters?.sorting?.id ?? 0;

      this.$http.post("search", { ...query }).then((response) => {
        if (response.data) {
          this.search = response.data.search;
          this.districtOptions = response.data.districts;

          if (this.search) {
            this.search.forEach((item) => {
              if (item.geo) {
                item.geo.forEach((geo) => {
                  this.geoLocations.push({
                    lat: geo.lng,
                    lng: geo.lat,
                    title: item.title,
                  });
                });
              }
            });
          }
          // Options for filtering
          // this.kitchenOptions = response.data.kitchen_options.length > 0 ? response.data.kitchen_options : null;
          // this.comfortOptions = response.data.comfort_options.length > 0 ? response.data.comfort_options : null;
          //
          // this.getDistrictsAndGeo();

          if (query.isOnMap) {
            this.showMap();
          }

          this.isLoading = false;
        }
      });
    },
    getDistrictsAndGeo() {
      // let districts = [];
      let geo = [];

      if (this.placeList) {
        // this.placeList.forEach(place => {
        //   // if (item.serviceable.places.length) {
        //   if (place.geo) {
        //     if (place.geo.lat && place.geo.lng) {
        //       if (!geo.find(i => i.lat == place.geo.lat && i.lng == place.geo.lng)) {
        //         geo.push({ lat: place.geo.lat, lng: place.geo.lng, title: place.title });
        //       }
        //     }
        //
        //     if (place.geo.district) {
        //       let obj = place.geo.district;
        //
        //       if (districts.indexOf(obj) === -1) {
        //         districts.push(obj);
        //       }
        //     }
        //   }
        //   // item.serviceable.places.forEach(place => {
        //   //
        //   // });
        //   // }
        // });
      }

      this.geoLocations = geo;
      // this.districtOptions = districts.map((d) => {
      //   return {
      //     title: d,
      //   };
      // });
    },
    fetchCategoryPlaces() {
      this.$http
        .get("category/places/" + this.$props.type + "/" + this.$props.id)
        .then((response) => {
          if (response.data.places) {
            this.places = response.data.places;
          }
        });
    },
    fetchCategoryData() {
      this.$http
        .get("category/" + this.$props.type + "/" + this.$props.id)
        .then((response) => {
          if (response.data.category) {
            this.category = response.data.category;
          }
        });
    },
    showMap() {
      this.isMapDisplayed = !this.isMapDisplayed;

      this.$nextTick(() => {
        map.resize();
      });

      this.touchMapPoints();
    },
    touchMapPoints() {
      let features = [];

      if (this.geoLocations && typeof map !== "undefined") {
        if (
          this.geoLocations.length &&
          this.geoLocations[0].lng &&
          this.geoLocations[0].lat
        ) {
          map.setCenter([this.geoLocations[0].lng, this.geoLocations[0].lat]);
        }

        this.geoLocations.forEach((geo) => {
          // let center = { lat: geo.lat, lng: geo.lng };

          features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [geo.lng, geo.lat],
            },
            properties: {
              title: geo.title,
            },
          });
        });
      }

      this.addMapMarkers(features);
    },
    addMapMarkers(features) {
      if (!map) {
        return false;
      }

      if (this.isMarkersAdded) {
        map.removeLayer("points");
        map.removeSource("points");
        map.removeImage("custom-marker");
        this.isMarkersAdded = false;
      }

      this.$nextTick(() => {
        map.loadImage(
          "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
          (error, image) => {
            if (error) throw error;
            map.addImage("custom-marker", image);
            // Add a GeoJSON source with 2 points
            map.addSource("points", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features,
              },
            });

            // Add a symbol layer
            map.addLayer({
              id: "points",
              type: "symbol",
              source: "points",
              layout: {
                "icon-image": "custom-marker",
                // get the title name from the source's "title" property
                "text-field": ["get", "title"],
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-offset": [0, 1.25],
                "text-anchor": "top",
              },
            });

            this.isMarkersAdded = true;
          }
        );
      });
    },
  },
};
</script>

<style scoped>
#map {
  width: 100% !important;
  height: 100%;
}
</style>
