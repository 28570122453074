<template>
  <div>
    <Header />
    <UserChat />
    <Footer />
  </div>
</template>

<script>
import UserChat from "@/components/User/Chat.vue";
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    Header,
    Footer,
    UserChat
  }
};
</script>
