<template>
  <div class="searchresults">
    <Header @searchQueryChange="emitSearch" />
    <CategorySearchByQuery
      :query="query"
      ref="querySearchComponent"
      v-if="!isRequestHalted"
    />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import CategorySearchByQuery from "@/components/Search/CategorySearchByQuery.vue";
import Header from "@/views/ui/Header.vue";
import Footer from "@/views/ui/Footer.vue";

export default {
  components: {
    CategorySearchByQuery,
    Header,
    Footer,
  },
  props: ["query"],
  data() {
    return {
      isRequestHalted: true,
    };
  },
  mounted() {
    this.isRequestHalted = true;
    const path = this.$router.currentRoute.value.path;

    switch (path) {
      case "/cars":
        this.$store.commit("setSearchToCategoryMode", 5);

        break;
      case "/medicine":
        this.$store.commit("setSearchToCategoryMode", 3);

        break;
      case "/education":
        this.$store.commit("setSearchToCategoryMode", 7);

        break;
      case "/food":
        this.$store.commit("setSearchToCategoryMode", 1);

        break;
      case "/event":
        this.$store.commit("setSearchToCategoryMode", 2);

        break;
      case "/beauty":
        this.$store.commit("setSearchToCategoryMode", 4);

        break;
      case "/realty":
        this.$store.commit("setSearchToCategoryMode", 6);

        break;
    }

    this.isRequestHalted = false;
  },
  methods: {
    emitSearch() {
      this.$refs.querySearchComponent.handleSearch();
    },
  },
};
</script>
