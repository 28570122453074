<template>
  <div
    class="mb-20 px-4 lg:px-0"
    v-if="data"
  >
    <div
      class="my-4 pt-4 text-left text-gray-500 container mx-auto"
      v-if="1 == 2"
    >
      <span
        class="cursor-pointer"
        @click="$router.push('/')"
      >Главная</span> -
      <span
        v-if="1 == 2"
        class="cursor-pointer"
        @click="$router.push('/search/tagged/' + data.category_id + '/')"
      >
        {{ data.title }}
      </span>
      {{ data.title }}
    </div>

    <div class="container flex mx-auto text-left justify-between pt-8">
      <div class="flex flex-row items-center">
        <div
          class="rounded-3xl bg-gray-600 text-white hidden lg:block px-3 h-6 font-medium tracking-tight"
          v-if="1 == 2"
        >
          {{ data.title }}
        </div>
        <h1 class="text-4xl font-semibold tracking-tight capitalize">{{ data.title }}</h1>

        <div
          class="ml-3 text-xl pi cursor-pointer"
          :class="{
            'hover:text-accent pi-heart': !isInFavorites,
            'text-accent pi-heart-fill': isInFavorites
          }"
          v-if="$http.defaults.headers.common['Authorization']"
          @click="toggleFavorited"
        ></div>
      </div>

      <div class="flex flex-row space-x-4">
        <div class="space-x-2 mb-1.5 hidden lg:flex">
          <img
            class="object-none"
            v-for="i in Math.floor(generalRating)"
            :key="i"
            src="/img/star_1.png"
          />
        </div>

        <div
          class="flex flex-row items-center space-x-2"
          v-if="filteredComments.length > 0"
        >
          <span class="text-2xl font-semibold">{{ generalRating }}</span>
          <span class="text-sm">({{ totalCommentsCount }})</span>
        </div>
        <div
          class="flex flex-row items-center space-x-4"
          v-else
        >
          <span class="text-2xl">Нет оценок</span>
        </div>
      </div>
    </div>

    <div class="mt-5 container mx-auto flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between">
      <div class="w-full space-y-3">
        <div
          class="flex flex-row items-center justify-between lg:justify-start"
          v-if="data.geo.length"
        >
          <div class="flex items-center">
            <img
              src="/img/location.png"
              class="object-none ml-1"
            />
            <div
              v-if="data.geo"
              class="flex flex-col text-left ml-4"
            >
              <span
                class="text-sm lg:text-md text-left"
                v-for="address in data.geo"
                :key="address.id"
              >{{
                address.clean_address ? address.clean_address : ""
              }}</span>
              <a
                href="#"
                class="text-sm lg:text-md mt-1 text-accent text-left"
                @click="toggleMap"
              >Показать на карте</a>
            </div>
          </div>
        </div>

        <div
          class="flex flex-row items-center justify-between lg:justify-start"
          v-if="worktime_today"
        >
          <div class="flex items-center">
            <img
              src="/img/clock.png"
              class="object-none"
            />
            <span class="text-sm lg:text-md ml-2.5 mt-1 text-left">{{ worktime_today }}</span>
          </div>
          <a
            href="#"
            class="text-sm lg:text-md ml-5 mt-1 text-accent text-right lg:text-left"
            @click="isTimelineDisplayed = true"
          >
            время работы
          </a>
        </div>
      </div>

      <div class="flex">
        <a
          class="btn btn-md py-3 px-4 font-semibold w-full lg:w-auto rounded-full self-center 
          hover:bg-accentDark cursor-pointer tracking-tight text-white"
          @click="isPreparingOrder = true"
        >
          Забронировать
        </a>
      </div>
    </div>

    <div
      class="w-full container mx-auto mt-8"
      v-if="isGalleryDisplayed"
    >
      <Galleria
        :value="data.gallery"
        :numVisible="5"
      >
        <template #item="slotProps">
          <img
            class="object-center object-cover"
            :src="baseURL + slotProps.item.photo"
          />
        </template>
        <template #thumbnail="slotProps">
          <div class="overflow-hidden w-12 h-12">
            <img
              class="h-full object-center object-cover"
              :src="baseURL + slotProps.item.photo"
            />
          </div>
        </template>
        <template #footer>
          <a
            href="#"
            @click.stop="isGalleryDisplayed = false"
            class="p-2 px-4 mt-4 rounded-3xl bg-gray-500 hover:bg-gray-700 bg-opacity-75 text-white text-md"
          >
            Закрыть
          </a>
        </template>
      </Galleria>
    </div>

    <div
      class="mt-8 container mx-auto grid grid-cols-4 grid-rows-2 gap-1 lg:gap-6 gallery cursor-pointer"
      style="max-height: 500px"
      @click.stop="isGalleryDisplayed = true"
      v-else
    >
      <div class="col-span-2 row-span-2 rounded-tl-3xl rounded-bl-3xl overflow-hidden title-image" v-if="data.gallery[0].photo">
        <img
          :src="baseURL + data.gallery[0].photo"
          class="object-cover w-full h-full"
        />
      </div>

      <div class="overflow-hidden title-image" v-if="data.gallery[1].photo">
        <img
          :src="baseURL + data.gallery[1].photo"
          class="object-cover w-full h-full"
        />
      </div>

      <div class="overflow-hidden rounded-tr-3xl title-image" v-if="data.gallery[2].photo">
        <img
          :src="baseURL + data.gallery[2].photo"
          class="object-cover w-full h-full"
        />
      </div>

      <div class="overflow-hidden title-image" v-if="data.gallery[3].photo">
        <img
          :src="baseURL + data.gallery[3].photo"
          class="object-cover w-full h-full"
        />
      </div>

      <div class="overflow-hidden rounded-br-3xl relative title-image" v-if="data.gallery[4].photo">
        <img
          :src="baseURL + data.gallery[4].photo"
          class="object-cover w-full h-full"
        />
        <a
          href="#"
          @click.stop="isGalleryDisplayed = true"
          class="absolute hidden md:block p-2 px-4 bottom-3 right-3 rounded-3xl bg-gray-500 hover:bg-gray-700 bg-opacity-75 text-white text-md"
        >
          Показать {{ data.gallery.length }} фото
        </a>
      </div>
    </div>

    <div class="container mx-auto">
      <Message
        v-for="order in recentOrders"
        :key="order.id"
        class="items-center"
      >Вы записаны на услугу <strong>"{{ order.service.title }}"</strong> {{ moment(order.date).format('DD.MM.YY') }}
        в {{ order.start_time.substr(0, 5) }}</Message>
    </div>

    <div class="mt-14 container mx-auto text-left">
      <h2 class="text-2xl lg:text-4xl font-semibold tracking-tight">О заведении</h2>

      <div class="lg:grid lg:grid-cols-3 mt-4">
        <div class="flex flex-row space-x-3 lg:space-x-0 lg:flex-col text-gray-700 lg:space-y-6 content-start items-start">
          <div
            class="flex cursor-pointer"
            @click="detailsSection = 1"
          >
            <i class="pi pi-book hidden lg:block text-xl"></i>
            <span
              class="lg:ml-4 self-end"
              :class="{ 'font-semibold': detailsSection == 1 }"
            >
              Описание
            </span>
          </div>

          <div
            class="flex cursor-pointer"
            @click="detailsSection = 2"
          >
            <i class="pi pi-plus-circle hidden lg:block text-xl"></i>
            <span
              class="lg:ml-4 self-end"
              :class="{ 'font-semibold': detailsSection == 2 }"
            >
              Удобства
            </span>
          </div>

          <div
            class="flex cursor-pointer"
            @click="detailsSection = 3"
            v-if="1 == 2"
          >
            <i class="pi pi-tags hidden lg:block text-xl"></i>
            <span
              class="lg:ml-4 self-end"
              :class="{ 'font-semibold': detailsSection == 3 }"
            >
              Кухня
            </span>
          </div>

          <div
            class="flex space-x-2 mt-4 text-2xl"
            v-if="data.social"
          >
            <div
              v-for="link in data.social"
              :key="link.id"
            >
              <a
                :href="(link.link.indexOf('https://') == -1 ? 'https://' : '') + link.link"
                target="_blank"
              >
                <FontAwesomeIcon :icon="[link.social != 'link' ? 'fab' : 'fas', link.social]" />
              </a>
            </div>
          </div>
        </div>

        <div class="flex flex-col col-span-2 mt-4 lg:mt-0">
          <div
            v-if="detailsSection == 1"
            class="flex flex-col"
          >
            <strong class="text-xl pb-2">Описание</strong>
            <p class="font-light leading-6">
              {{ data.description }}
            </p>
          </div>
          <div
            v-if="detailsSection == 2"
            class="space-y-2 flex flex-col"
          >
            <strong class="text-xl pb-2">Удобства и опции</strong>
            <div
              v-for="feature in data.features"
              :key="feature.id"
              class="space-y-2 flex flex-row"
            >
              <i class="pi pi-check pr-3 mt-0.5"></i> {{ feature.feature.title }}
            </div>
          </div>
          <div
            v-if="detailsSection == 3"
            class="space-y-2"
          >
            <div
              v-for="kitchen in data.kitchens"
              :key="kitchen.id"
              class="space-y-2 flex flex-row"
            >
              <i class="pi pi-check pr-3 mt-0.5"></i> {{ kitchen.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-14 container mx-auto text-left"
      v-if="data.category_id == 1"
    >
      <h2 class="text-2xl lg:text-4xl font-semibold tracking-tight">Меню</h2>

      <div class="lg:grid lg:grid-cols-3 mt-4">
        <div class="flex lg:flex-col text-gray-700 overflow-x-scroll lg:overflow-x-hidden">
          <div class="flex">
            <div
              class="px-2 lg:pl-4 lg:pr-8 py-2 items-center hover:bg-gray-100 cursor-pointer flex text-sm lg:text-lg"
              :class="{ 'bg-gray-200': activeKitchenType == false }"
              @click="makeActiveKitchenType()"
            >
              Показать все

              <div class="ml-4 font-semibold lg:font-normal lg:w-8 lg:h-8 text-center rounded-full lg:bg-gray-400 self-center leading-8.5">
                {{ data.menu.length }}
              </div>
            </div>
          </div>
          <div
            v-for="(type, k) in data.kitchen"
            :key="k"
            class="flex"
          >
            <div
              class="px-2 lg:pl-4 lg:pr-8 py-2 items-center flex hover:bg-gray-100 cursor-pointer text-sm lg:text-lg"
              :class="{ 'bg-gray-200': activeKitchenType == k }"
              @click="makeActiveKitchenType(k, type)"
            >
              {{ k }}

              <div class="ml-4 font-semibold lg:font-normal lg:w-8 lg:h-8 text-center rounded-full lg:bg-gray-400 self-center leading-8.5">
                {{ type.length }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-2">
          <div
            class="grid grid-cols-12 border-t py-4"
            v-for="i in filteredMenu"
            :key="i.id"
          >
            <div class="w-20 h-20 border rounded-full overflow-hidden hidden lg:block relative">
              <img
                :src="baseURL + i.photo"
                class="object-center object-cover h-full"
              />
            </div>

            <div class="col-span-12 lg:col-span-11 ml-4 mt-4">
              <div class="flex flex-row justify-between">
                <div class="flex flex-row items-center space-x-4">
                  <strong class="text-lg capitalize">{{ i.title }}</strong>
                </div>

                <div class="flex flex-col text-right relative transition-none">
                  <span class="text-right text-xl">{{ i.price }} руб</span>

                  <div class="flex flex-row items-center space-x-5 left-0 right-0 top-8 absolute justify-end">
                    <i
                      v-if="i.added_count"
                      class="pi cursor-pointer text-xl"
                      :class="{ 'text-green-600 pi-minus': i.is_added, 'text-accent pi-minus': !i.is_added }"
                      @click="
                        () => {
                          i.added_count--;
                          i.is_added = i.added_count ? true : false;
                        }
                      "
                    ></i>
                    <div
                      class="text-green-600 text-center font-semibold"
                      v-if="i.added_count"
                    >{{ i.added_count }}</div>
                    <i
                      v-if="i.is_deliverable"
                      class="pi cursor-pointer text-xl"
                      :class="{ 'text-green-600 pi-plus': i.is_added, 'text-accent pi-plus': !i.is_added }"
                      @click="
                        () => {
                          i.added_count ? i.added_count++ : (i.added_count = 1);
                          i.is_added = true;
                        }
                      "
                    ></i>
                  </div>
                </div>
              </div>

              <p
                class="lg:mt-0 text-gray-600 w-3/4 cursor-pointer font-light transition-all duration-300 ease-linear"
                :class="{ 'truncate hover:text-accent': !i.is_truncated }"
                @click="i.is_truncated = !i.is_truncated"
              >
                {{ i.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-12 container mx-auto text-left">
      <h2 class="text-2xl lg:text-4xl font-semibold tracking-tight">Услуги</h2>

      <div class="lg:grid lg:grid-cols-3 mt-4">
        <div class="flex lg:flex-col text-gray-700 overflow-x-scroll lg:overflow-x-hidden">
          <div class="flex">
            <div
              class="px-2 lg:pl-4 lg:pr-8 py-2 items-center hover:bg-gray-100 cursor-pointer flex text-sm lg:text-lg"
              :class="{ 'bg-gray-200': activeType == false }"
              @click="makeActiveType()"
            >
              Показать все

              <div class="ml-4 font-semibold lg:font-normal lg:w-8 lg:h-8 text-center rounded-full lg:bg-gray-400 self-center leading-8.5">
                {{ data.services.length }}
              </div>
            </div>
          </div>
          <div
            class="flex"
            v-for="type in serviceCategories"
            :key="type.title"
          >
            <div
              class="px-2 lg:pl-4 lg:pr-8 py-2 items-center flex hover:bg-gray-100 cursor-pointer text-sm lg:text-lg"
              :class="{ 'bg-gray-200': activeType == type.title }"
              @click="makeActiveType(type.title, type.items)"
            >
              {{ type.title }}

              <div class="ml-4 font-semibold lg:font-normal lg:w-8 lg:h-8 text-center rounded-full lg:bg-gray-400 self-center leading-8.5">
                {{ type.items.length }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-2">
          <div
            class="grid grid-cols-12 border-t py-4"
            v-for="i in filteredServices"
            :key="i.id"
          >
            <div class="w-20 h-20 border rounded-full overflow-hidden hidden lg:block relative">
              <img
                :src="baseURL + i.photo"
                class="object-center object-cover w-full h-full"
              />
            </div>

            <div class="col-span-12 lg:col-span-11 ml-4 mt-4">
              <div class="flex flex-row justify-between">
                <div class="flex flex-row items-center space-x-4">
                  <strong class="text-lg capitalize">{{ i.title }}</strong>
                  <span
                    class="text-sm text-gray-700"
                    v-if="i.duration"
                  >{{ i.duration }} мин</span>
                  <div
                    class="font-medium rounded-md bg-yellow-300 px-2"
                    v-if="i.discounted_price < i.price"
                  >Акция</div>

                  <div class="flex">
                    <div
                      class="font-medium rounded-md bg-accent px-2 text-white"
                      v-if="i.gift_service"
                    >+ {{i.gift_service.title}}</div>
                  </div>
                </div>

                <div class="flex flex-col text-right relative">
                  <div class="flex space-x-2">
                    <span
                      class="text-right text-xl"
                      :class="{'line-through text-gray-400 text-sm': i.discounted_price < i.price}"
                    >{{ i.price }} руб</span>
                    <span
                      class="text-right text-xl"
                      v-if="i.discounted_price < i.price"
                    >{{ i.discounted_price }} руб</span>
                  </div>
                  <i
                    class="pi absolute left-0 right-0 top-8 cursor-pointer text-xl"
                    :class="{ 'text-green-600 pi-check': i.is_added, 'text-accent pi-plus': !i.is_added }"
                    @click="i.is_added = !i.is_added"
                  ></i>
                </div>
              </div>

              <p
                class="lg:mt-0 text-gray-600 w-3/4 cursor-pointer font-light transition-all duration-300 ease-linear"
                :class="{ 'truncate hover:text-accent': !i.is_truncated }"
                @click="i.is_truncated = !i.is_truncated"
              >
                {{ i.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-20"
        v-if="workers && workers.length > 0"
      >
        <h2 class="text-2xl text-center lg:text-left lg:text-4xl font-semibold tracking-tight">Записаться к специалисту</h2>

        <div
          class="mt-10"
          v-if="workers"
        >
          <Carousel
            :value="workers"
            :numVisible="workers.length < 5 ? workers.length : 5"
            :numScroll="workers.length"
          >
            <template #item="slotProps">
              <div class="flex-col">
                <div
                  class="flex-col cursor-pointer"
                  @click="showWorkerRelatedServices(slotProps.data.id)"
                >
                  <div class="text-center mx-auto relative h-16 w-16">
                    <div>
                      <Avatar
                        :label="slotProps.data.name.substr(0, 1)"
                        size="xlarge"
                        v-if="!slotProps.data.photo"
                        shape="circle"
                      />
                      <Avatar
                        :image="baseURL + slotProps.data.photo"
                        size="xlarge"
                        class="rounded-full overflow-hidden"
                        v-else
                      />
                    </div>

                    <div
                      class="items-center flex flex-row absolute top-0 -right-10"
                      v-if="slotProps.data.rate"
                    >
                      <img
                        class="object-none mb-1"
                        src="/img/star_1.png"
                      />
                      <span class="text-sm pl-1">{{ parseFloat(slotProps.data.rate).toFixed(1) }}</span>
                    </div>
                  </div>

                  <div class="pt-2 text-lg text-center">
                    {{ slotProps.data.name }}
                  </div>
                </div>

                <div
                  class="pt-3 text-gray-400 text-center cursor-pointer"
                  @click="showWorkerDetailsModal({ worker: slotProps.data })"
                >
                  О специалисте
                </div>
              </div>
            </template>
          </Carousel>
        </div>
      </div>

      <div class="mt-20">
        <h2 class="text-2xl lg:text-4xl font-semibold tracking-tight">
          Отзывы
        </h2>

        <div
          class="lg:grid lg:grid-cols-3 gap-28"
          v-if="filteredComments.length > 0"
        >
          <div class="col-span-1">
            <div class="flex justify-between mt-5 mb-5 lg:mb-0">
              <div class="flex space-x-3">
                <div
                  class="object-none w-8 h-8"
                  v-for="s in Math.floor(generalRating)"
                  :key="s"
                >
                  <img
                    src="/img/star_2.png"
                    class="w-full h-full object-none"
                  />
                </div>
              </div>

              <div>
                <strong
                  class="text-4xl pl-2"
                  v-if="generalRating"
                >{{ generalRating }}</strong>
                <strong
                  class="text-4xl pl-2"
                  v-else
                >-</strong>

                <small class="self-end mb-1"> ({{ totalCommentsCount }}) </small>
              </div>
            </div>

            <div class="hidden lg:block">
              <div class="mt-6 mb-5">Фильтр</div>

              <div
                class="flex flex-row mb-6"
                v-for="fl in [5, 4, 3, 2, 1]"
                :key="fl"
              >
                <Checkbox
                  v-model="commentFilter[fl - 1].checked"
                  :binary="true"
                />

                <div class="flex space-x-1 ml-2">
                  <img
                    class="object-none"
                    :src="fl >= st ? '/img/star_1.png' : '/img/star_1_gray.png'"
                    v-for="st in [1, 2, 3, 4, 5]"
                    :key="st"
                  />
                </div>

                <div class="mt-1.5 mr-1 ml-12 rounded-lg w-full h-2 bg-gray-200">
                  <ProgressBar
                    :value="percentageRating(commentsCountByRate[commentFilter[fl - 1].rateId])"
                    :showValue="false"
                    class="rounded-lg h-2"
                  />
                </div>

                <small class="ml-2 pt-0.5">
                  {{ commentsCountByRate[commentFilter[fl - 1].rateId] }}
                </small>
              </div>
            </div>
          </div>

          <div class="col-span-2 space-y-4">
            <div
              v-for="comment in filteredComments"
              :key="comment.id"
              class="border-b border-gray-200 pb-4"
            >
              <Comment :content="comment" />
            </div>

            <div class="flex flex-row justify-between lg:justify-start space-x-4">
              <div
                class="border-gray-800 hover:bg-gray-200 border w-max text-center
                lg:py-4 lg:px-12 rounded-full text-sm lg:text-md px-4 py-2 cursor-pointer"
                @click="fetchMorePlaceComments(data.id)"
                v-if="currentLoadedCommentsPages <= totalCommentsPages"
              >
                Загрузить ещё
              </div>
              <div
                class="bg-accent hover:bg-accentDark border w-max text-white text-center
                lg:py-4 lg:px-12 rounded-full text-sm lg:text-md px-4 py-2 cursor-pointer"
                v-if="hasOrdersHere"
                @click="isPlaceCommentFormDisplayed = true"
              >
                Оставить отзыв
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="mt-4"
        >
          <p>
            Отзывов пока нет. Станьте первым, кто напишет отзыв об этом месте
          </p>
          <Button
            v-if="hasOrdersHere"
            label="Оставить отзыв"
            @click="isPlaceCommentFormDisplayed = true"
            class="mt-2"
          />
        </div>

        <div
          class="mt-12"
          v-if="data.nearby.length"
        >
          <h2 class="text-2xl lg:text-4xl font-semibold tracking-tight">
            Заведения рядом
          </h2>

          <div class="grid grid-cols-2 md:grid-cols-4 gap-6 mt-12">
            <ServiceCard
              :data="i"
              v-for="i in data.nearby"
              :key="i.id"
              @click="refetch(i.category_id, i.slug)"
            />
          </div>
        </div>
      </div>
    </div>

    <Dialog
      header="Оставить отзыв"
      v-model:visible="isPlaceCommentFormDisplayed"
      :modal="true"
    >
      <div class="flex flex-col space-y-3">
        <p>Поделитесь своим мнением о заведении. Расскажите о положительном или негативном опыте.<br />Поставьте заведению оценку</p>

        <div class="flex flex-row space-x-2 justify-between">
          <Rating
            v-model="placeComment.rate"
            :stars="5"
            :cancel="false"
          />
        </div>

        <Textarea
          v-model="placeComment.comment"
          rows="5"
          cols="30"
        />

        <Button
          label="Добавить"
          @click="addFeedback"
        />
      </div>
    </Dialog>

    <Dialog
      header="Адреса на карте"
      v-model:visible="isMapDisplayed"
      class="w-full lg:w-screen-50 h-full lg:h-screen-50"
      :modal="true"
    >
      <div id="map"></div>

      <template #footer>
        <Button
          label="Закрыть"
          icon="pi pi-times"
          @click="isMapDisplayed = false"
          class="p-button-text"
        />
      </template>
    </Dialog>

    <Dialog
      header="Оставить отзыв"
      v-model:visible="isLeavingWorkerComment"
      :modal="true"
    >
      <div class="flex flex-col space-y-3">
        <p>Поделитесь своим мнением о специалисте. Расскажите о положительном или негативном опыте.<br />Поставьте специалисту оценку</p>

        <div class="flex flex-row space-x-2 justify-between">
          <Rating
            v-model="workerComment.rate"
            :cancel="false"
          />
        </div>

        <Textarea
          v-model="workerComment.text"
          rows="5"
          cols="30"
        />

        <Button
          label="Добавить"
          @click="addWorkerFeedback"
        />
      </div>
    </Dialog>

    <Dialog
      v-if="selectedWorkerData"
      v-model:visible="isWorkerDetailsDisplayed"
      class="w-full lg:w-screen-50 h-full lg:h-screen-50"
      :closable="false"
      :modal="true"
    >
      <div>
        <div
          class="mt-3 text-center sm:mt-0 sm:text-left"
          v-if="selectedWorkerData"
        >
          <div class="lg:grid lg:grid-cols-5 gap-x-12 w-full overflow-hidden">
            <div class="col-span-2 items-center text-center">
              <div class="flex flex-row items-center">
                <div
                  class="rounded-full bg-gray-400 overflow-hidden w-40 h-40 mx-auto"
                  v-if="selectedWorkerData.worker.photo"
                >
                  <img
                    class="object-center object-cover h-full"
                    :src="baseURL + selectedWorkerData.worker.photo"
                  />
                </div>

                <Avatar
                  shape="circle"
                  size="xlarge"
                  :label="selectedWorkerData.worker.name.substr(0, 1)"
                  v-else
                />

                <div class="text-left flex flex-col pl-4">
                  <strong class="text-lg">
                    {{ selectedWorkerData.worker.name }}
                  </strong>
                  <span>{{ selectedWorkerData.worker.occupation }}</span>
                </div>
              </div>

              <div class="flex space-x-2 place-content-center mt-3 mx-auto">
                <img
                  src="/img/star_1_med.png"
                  v-for="star in 'x'.repeat(selectedWorkerData.worker.rate)"
                  :key="star"
                  class="object-none"
                />
                <strong
                  class="pt-1.5 text-lg"
                  v-if="selectedWorkerData.worker.rate"
                >{{
                  parseFloat(selectedWorkerData.worker.rate).toFixed(1)
                }}</strong>
              </div>

              <div
                v-if="selectedWorkerData.worker.bio"
                class="mt-2 italic break-words text-justify"
              >
                <div class="text-gray-500 leading-5 text-sm">
                  {{ selectedWorkerData.worker.bio }}
                </div>
              </div>
            </div>
            <div class="col-span-3 mt-4 lg:mt-0">
              <ScrollPanel style="width: 100%; height: 500px">
                <div class="space-y-4">
                  <div v-if="selectedWorkerData.worker.comments">
                    <div
                      v-for="comment in selectedWorkerData.worker.comments"
                      :key="comment.id"
                      class="border-b border-gray-200 py-4"
                    >
                      <!-- {{ comment.owner.name }} -->
                      <!-- {{ comment.text }} -->
                      <Comment :content="comment" />
                    </div>
                  </div>
                  <div
                    v-if="!selectedWorkerData.worker.comments"
                    class="text-center"
                  >
                    Отзывов пока нет
                  </div>
                </div>
              </ScrollPanel>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          label="Оставить отзыв"
          v-if="hasOrdersHere"
          @click="
            isWorkerDetailsDisplayed = false;
            isLeavingWorkerComment = true;
          "
          class="p-button-text"
        />
        <Button
          label="Закрыть"
          icon="pi pi-times"
          @click="
            isWorkerDetailsDisplayed = false;
            selectedWorkerData = null;
          "
          class="p-button-text"
        />
      </template>
    </Dialog>

    <Dialog
      header="Время работы"
      v-model:visible="isTimelineDisplayed"
      :modal="true"
    >
      <div
        class="flex space-x-2 text-center"
        v-if="data.schedule"
      >
        <div
          class="flex flex-col"
          v-for="day in workingDaysCounted"
          :key="day.id"
        >
          <div
            :class="{ 'bg-red-200': day.closed, 'bg-gray-200': !day.closed }"
            class="rounded-t-md font-semibold px-4 py-2"
          >
            {{ day.title }}
          </div>
          <div
            :class="{
              'bg-red-100 h-full justify-center': day.closed,
              'bg-gray-100': !day.closed
            }"
            class="rounded-b-md bg-gray-100 p-4 flex flex-col space-y-2"
          >
            <div
              v-show="day.closed"
              class="text-sm"
            >
              -
            </div>

            <div
              v-show="!day.closed"
              class="text-sm"
            >
              {{ day.opening }}
            </div>

            <div
              v-show="!day.closed"
              class="text-sm"
            >
              {{ day.closing }}
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          label="Закрыть"
          icon="pi pi-times"
          @click="isTimelineDisplayed = false"
          class="p-button-text"
        />
      </template>
    </Dialog>

    <Dialog
      header="Записаться к специалисту"
      v-model:visible="showWorkerRelatedServicePicker"
      :modal="true"
    >
      <div class="flex">
        <Dropdown
          class="w-full"
          v-model="selectedWorkerRelatedService"
          :options="workerRelatedServices"
          optionLabel="title"
          optionValue="id"
          placeholder="Выберите услугу"
        />
      </div>

      <template #footer>
        <Button
          label="Добавить"
          icon="pi pi-check"
          @click="
            () => {
              showWorkerRelatedServicePicker = false;
              addWorkerRelatedServiceToCart();
            }
          "
          class="p-button-text"
        />
        <Button
          label="Закрыть"
          icon="pi pi-times"
          @click="showWorkerRelatedServicePicker = false"
          class="p-button-text"
        />
      </template>
    </Dialog>

    <Sidebar
      v-model:visible="isPreparingOrder"
      position="full"
    >
      <div class="lg:grid lg:grid-cols-9 container mx-auto gap-12 gap-x-24">
        <div
          class="col-span-6 space-y-5"
          v-if="checkoutStep == 1"
        >
          <span class="text-gray-400">Шаг 1 из 2</span>

          <div v-if="!isSelectingWorker && !isSelectingDateAndTime && !isSelectingAddress">
            <h1 class="text-center lg:text-left text-3xl font-semibold">Настройте услуги</h1>

            <div
              v-for="service in selectedServices"
              :key="service.id"
            >
              <div class="flex flex-row justify-between items-center border-b py-5">
                <div class="flex flex-row items-center space-x-8">
                  <div class="flex flex-row space-x-3 items-center cursor-pointer">
                    <div class="border w-20 h-20 rounded-full overflow-hidden">
                      <img
                        :src="baseURL + service.photo"
                        class="object-cover object-center w-full h-full"
                      />
                    </div>
                    <div class="flex flex-col">
                      <div class="flex flex-row space-x-3 items-center">
                        <div class="font-semibold text-xl capitalize">{{ service.title }}</div>
                        <div
                          class="text-gray-600 text-sm hidden md:block"
                          v-if="service.duration"
                        >{{ service.duration }} мин.</div>
                      </div>
                      <div class="flex flex-col lg:flex-row text-sm items-left lg:items-center lg:space-x-4">
                        <div v-if="service.workers.length">
                          <a
                            href="#"
                            :class="{ 'text-accent': service.worker, 'text-red-600': !service.worker }"
                            @click="isSelectingWorker = service"
                          >{{ service.worker ? service.worker.worker_name : "Выбрать мастера" }}</a>
                        </div>
                        <div>
                          <a
                            href="#"
                            :class="{ 'text-accent': service.date && service.time, 'text-red-600': !service.date || !service.time }"
                            @click="isSelectingDateAndTime = service"
                          >{{ service.date }} {{ service.time }} {{ !service.date || !service.time ? "Выбрать дату и время" : "" }}</a>
                        </div>
                      </div>
                      <div class="flex flex-row text-sm items-center">
                        <div
                          v-if="service.addresses.length"
                          class="flex flex-col"
                        >
                          <a
                            href="#"
                            :class="{ 'text-accent': service.address, 'text-red-600': !service.address }"
                            @click="isSelectingAddress = service"
                            v-if="service.addresses.length > 1"
                          >{{ service.address ? service.address.clean_address : "Выбрать адрес" }}</a>
                          <span
                            href="#"
                            v-else
                          >{{ service.addresses[0].clean_address }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col text-right">
                  <div
                    class="text-xl font-semibold w-32 text-right"
                    v-if="service.discounted_price < service.price"
                  >{{ service.discounted_price }} руб</div>
                  <div
                    class="text-xl font-semibold w-32 text-right"
                    v-else
                  >{{ service.price }} руб</div>
                  <a
                    href="#"
                    class="text-accent"
                    @click="service.is_added = false"
                  >Удалить <span class="hidden lg:inline">услугу</span></a>
                </div>
              </div>
            </div>
            <div class="flex mt-8">
              <div
                class="rounded-full border mx-auto px-2 py-1 text-center items-center flex space-x-3 hover:bg-gray-300 cursor-pointer"
                @click="isPreparingOrder = false"
              >
                <i class="pi pi-plus mb-0.5"></i>
                <span>
                  Добавить услугу
                </span>
              </div>
            </div>
          </div>

          <div v-if="isSelectingWorker">
            <div
              v-for="worker in isSelectingWorker.workers"
              :key="worker.id"
            >
              <div class="flex flex-row justify-between items-center border-b py-5">
                <div class="flex flex-row items-center space-x-8">
                  <div
                    class="flex flex-row space-x-3 items-center cursor-pointer"
                    @click="
                      () => {
                        isSelectingWorker.worker = worker;
                        isSelectingWorker = false;
                      }
                    "
                  >
                    <div class="border w-20 h-20 rounded-full overflow-hidden">
                      <img
                        :src="baseURL + worker.worker.photo"
                        class="object-cover object-center w-full h-full"
                      />
                    </div>
                    <div class="flex flex-col">
                      <div
                        v-if="worker.worker.rate"
                        class="flex space-x-2 items-center"
                      >
                        <img
                          src="/img/star_1.png"
                          class="object-none mb-1.5"
                        />
                        <span>{{ worker.worker.rate }}</span>
                      </div>
                      <div class="font-semibold text-lg">
                        {{ worker.worker_name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="w-32 text-right cursor-pointer"
                  @click="showWorkerDetailsModal(worker)"
                >
                  О специалисте
                </div>
              </div>
            </div>
            <Button
              class="mt-6"
              label="Готово"
              @click="isSelectingWorker = false"
            />
          </div>

          <div v-if="isSelectingDateAndTime">
            <div>
              <Carousel
                :value="dates"
                :numVisible="7"
                :numScroll="7"
                indicatorsContentClass="hidden"
              >
                <template #item="slotProps">
                  <div
                    class="text-center shadow py-10 leading-10 mx-1 my-2 rounded-lg hover:bg-blue-50 flex flex-col cursor-pointer"
                    :class="{
                      'bg-blue-400 hover:bg-blue-200 text-white': isSelectingDateAndTime.date == slotProps.data.format('DD.MM.YYYY')
                    }"
                    @click="
                      () => {
                        isSelectingDateAndTime.date = slotProps.data.format('DD.MM.YYYY');
                        isSelectingDateAndTime.timeslots = generateTimeslots(slotProps.data.format('DD.MM.YYYY'), isSelectingDateAndTime.duration);
                      }
                    "
                  >
                    <span class="uppercase font-semibold text">{{ slotProps.data.format("ddd") }}</span>
                    <span class="font-semibold">{{ slotProps.data.format("DD.MM") }}</span>
                  </div>
                </template>
              </Carousel>

              <div
                v-if="isSelectingDateAndTime.timeslots"
                class="ml-16 flex flex-col space-y-2 mt-4 overflow-y-auto h-64 lg:h-96"
              >
                <div
                  v-for="s in isSelectingDateAndTime.timeslots"
                  :key="s"
                >
                  <div class="p-field-radiobutton flex flex-row items-center space-x-3">
                    <label
                      class="items-center flex gap-3 cursor-pointer"
                      @click="isSelectingDateAndTime.time = s.time"
                    >
                      <RadioButton
                        name="time"
                        :value="s.time"
                        v-model="isSelectingDateAndTime.time"
                      />
                      {{ s.time }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <Button
              class="mt-6"
              label="Готово"
              @click="isSelectingDateAndTime = false"
            />
          </div>

          <div v-if="isSelectingAddress">
            <div>
              <div
                v-if="isSelectingAddress.addresses"
                class="ml-16 flex flex-col space-y-2 mt-4"
              >
                <div
                  v-for="s in isSelectingAddress.addresses"
                  :key="s.id"
                >
                  <div class="p-field-radiobutton flex flex-row items-center space-x-3">
                    <label
                      class="items-center flex gap-3 cursor-pointer"
                      @click="isSelectingAddress.address = s"
                    >
                      <RadioButton
                        name="time"
                        :value="s"
                        v-model="isSelectingAddress.address"
                      />
                      {{ s.clean_address }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <Button
              class="mt-6"
              label="Готово"
              @click="isSelectingAddress = false"
            />
          </div>
        </div>

        <div
          class="col-span-6 space-y-5"
          v-if="checkoutStep == 2"
        >
          <span class="text-gray-400">Шаг 2 из 2</span>
          <h1 class="text-center lg:text-left text-3xl font-semibold">Укажите номер телефона</h1>

          <div>
            <p class="text-center lg:text-left font-semibold">
              Укажите ваш номер телефона, чтобы подтвердить бронирование.<br />Менеджер свяжется с вами по этому номеру или при помощи внутренних
              сообщений на сайте
            </p>
            <div class="relative">
              <span class="absolute top-6 left-4 px-2 bg-white text-sm font-semibold">Номер телефона</span>
              <InputMask
                class="mt-8 lg:mx-0 rounded-full py-3 px-2"
                v-model="phone"
                mask="+375 (99) 999-99-99"
                placeholder="+375 (29) 123-45-67"
              />
            </div>
          </div>
        </div>

        <!-- Side panel -->
        <div class="col-span-3 mt-4 lg:mt-0">
          <div class="shadow-md border p-4 rounded-lg">
            <div class="mx-auto w-40 h-40 overflow-hidden rounded-xl">
              <img
                class="h-full object-center object-cover"
                :src="baseURL + data.logo.photo"
              />
            </div>
            <div class="text-center font-semibold text-xl mt-3 capitalize">
              {{ data.title }}
            </div>
            <div class="text-center my-1 mb-4">
              {{ data.geo.address }}
            </div>

            <div
              class="flex flex-col space-y-3 border-t pt-4"
              v-if="checkedServices.length > 0"
            >
              <div
                v-for="s in checkedServices"
                :key="s.id"
                class="flex flex-row justify-between items-top border-b pb-4"
              >
                <div class="flex flex-col">
                  <div class="text-lg capitalize font-medium">
                    {{ s.title }}
                  </div>
                  <div
                    class="text-sm"
                    v-if="s.date && s.time"
                  >
                    {{ s.date }} в
                    {{ s.time }}
                  </div>
                  <div
                    class="text-sm"
                    v-if="s.worker"
                  >Мастер: {{ s.worker.worker_name }}</div>
                  <div
                    class="text-sm"
                    v-if="s.address"
                  >
                    {{ s.address.clean_address }}
                  </div>
                  <div
                    class="text-sm"
                    v-if="s.duration"
                  >Длительность: {{ s.duration }} мин.</div>
                  <div
                    class="text-sm font-medium mt-2"
                    :class="{'line-through text-gray-400': s.gift_declined}"
                    v-if="s.gift_service && 1==2"
                  >{{s.gift_service.title}} в подарок <span v-if="s.gift_service.duration"> - {{s.gift_service.duration}} мин.</span></div>
                  <span
                    class="text-xs text-accent cursor-pointer"
                    @click="s.gift_declined = true"
                    v-if="!s.gift_declined && s.gift_service && 1==2"
                  >Убрать услугу в подарок</span>
                  <span
                    class="text-xs text-accent cursor-pointer"
                    @click="s.gift_declined = false"
                    v-if="s.gift_declined && s.gift_service && 1==2"
                  >Вернуть услугу в подарок</span>
                </div>

                <div
                  class="font-semibold w-32 text-right"
                  v-if="s.discounted_price < s.price"
                >{{ s.discounted_price }} руб</div>
                <div
                  class="font-semibold w-32 text-right"
                  v-else
                >{{ s.price }} руб</div>
              </div>
            </div>

            <div
              class="flex flex-col space-y-3 pt-4"
              v-if="giftedServices.length"
            >
              <div
                v-for="s in giftedServices"
                :key="s.id"
                class="flex flex-row justify-between items-top border-b pb-4"
              >
                <div class="flex flex-col">
                  <div
                    class="text-lg capitalize font-medium"
                    :class="{'line-through text-gray-400': s.gift_declined}"
                  >
                    {{ s.title }}
                  </div>
                  <div
                    class="text-sm"
                    v-if="s.date && s.time"
                  >
                    {{ s.date }} в
                    {{ s.time }}
                  </div>
                  <div
                    class="text-sm"
                    v-if="s.worker"
                  >Мастер: {{ s.worker.worker_name }}</div>
                  <div
                    class="text-sm"
                    v-if="s.address"
                  >
                    {{ s.address.clean_address }}
                  </div>
                  <div
                    class="text-sm"
                    :class="{'line-through text-gray-400': s.gift_declined}"
                    v-if="s.duration"
                  >Длительность: {{ s.duration }} мин.</div>
                  <span
                    class="text-xs text-accent cursor-pointer"
                    @click="s.gift_declined = true"
                    v-if="!s.gift_declined"
                  >Убрать услугу</span>
                  <span
                    class="text-xs text-accent cursor-pointer"
                    @click="s.gift_declined = false"
                    v-if="s.gift_declined"
                  >Вернуть услугу</span>
                </div>

                <div
                  class="font-semibold w-32 text-right"
                  :class="{'line-through text-gray-400': s.gift_declined}"
                >Подарок</div>
              </div>

              <span class="italic text-xs">Уточняйте условия акций у менеджера</span>
            </div>

            <div
              class="flex flex-row justify-between items-center mt-2"
              v-if="checkedServices.length > 0"
            >
              <div class="text-xl font-semibold">Итог</div>
              <div class="font-semibold">{{ checkedServicesPrice }} руб</div>
            </div>
            <div
              class="flex flex-row justify-between items-center mt-2"
              v-if="total_discount > 0"
            >
              <div class="text-xl font-semibold">Скидка</div>
              <div class="font-semibold">{{ total_discount }} руб</div>
            </div>

            <div class="flex flex-col">
              <span
                class="text-xs text-accent text-center cursor-pointer"
                @click="promocodeForm = true"
                v-if="!promocodeForm"
              >У меня есть промокод</span>

              <div v-if="promocodeForm">
                <div class="col-12 md:col-4 w-full mt-1">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-ticket" />
                    </span>
                    <InputText
                      type="text"
                      class="uppercase"
                      v-model="promocode"
                    />
                    <Button
                      icon="pi pi-times"
                      class="p-button-danger"
                      @click="() => {promocode = ''; promocodeForm = false}"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 flex justify-around">
            <div class="flex flex-row space-x-2">
              <Button
                class="rounded-3xl font-semibold"
                label="Назад"
                @click="checkoutStep--"
                v-if="checkoutStep > 1"
              />
              <Button
                class="rounded-3xl font-semibold"
                v-if="checkoutStep < 2"
                label="Продолжить"
                @click="checkoutStep++"
              />
              <Button
                class="rounded-3xl font-semibold"
                v-else
                label="Оформить"
                @click="handleCheckout"
              />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>

    <Sidebar position="full">
      <div class="lg:grid lg:grid-cols-6 container mx-auto gap-12">
        <div
          class="col-span-4 space-y-5"
          v-if="checkoutStep == 1"
        >
          <span class="text-gray-400">Шаг 1 из 4</span>
          <h1 class="text-center lg:text-left text-3xl font-semibold">Выберите услугу</h1>

          <div
            class="flex flex-row justify-between items-center border-b pb-5"
            v-for="service in data.services"
            :key="service.id"
          >
            <div
              class="flex flex-row space-x-3 items-center cursor-pointer"
              @click="togglePickService(service)"
            >
              <div
                v-if="!service.isChecked"
                class="border w-12 h-12 rounded-full hover:bg-blue-50"
              ></div>
              <div
                v-else
                class="border w-12 h-12 rounded-full bg-blue-500 text-white flex items-center"
              >
                <i class="pi pi-check mx-auto"></i>
              </div>
              <div class="flex flex-col">
                <div class="font-semibold text-xl">{{ service.title }}</div>
              </div>
            </div>
            <div class="text-xl font-semibold w-32 text-right">{{ service.price }} руб</div>
          </div>
        </div>

        <div
          class="col-span-4 space-y-8"
          v-if="checkoutStep == 2"
        >
          <span class="text-gray-400">Шаг 2 из 4</span>
          <h1 class="text-center lg:text-left text-3xl font-semibold">Выберите специалиста</h1>

          <div
            v-for="service in checkedServices"
            :key="service.id"
          >
            <strong>{{ service.title }}</strong>

            <div
              class="flex flex-row justify-between items-center border-b py-3"
              v-for="worker in fetchWorkersForService(service)"
              :key="worker.id"
            >
              <div
                class="flex flex-row space-x-4 items-center cursor-pointer"
                @click="togglePickWorker(service.id, worker)"
              >
                <div
                  v-if="this.selectedWorkers[service.id] != worker.id"
                  class="border w-12 h-12 rounded-full hover:bg-blue-50"
                ></div>
                <div
                  v-else
                  class="border w-12 h-12 rounded-full bg-blue-500 text-white flex items-center"
                >
                  <i class="pi pi-check mx-auto"></i>
                </div>
                <div class="mx-auto w-24 h-24 overflow-hidden rounded-full">
                  <img
                    class="h-full object-center object-cover"
                    :src="baseURL + worker.photo"
                  />
                </div>
                <div class="flex flex-col">
                  <div class="flex space-x-2 items-center">
                    <img
                      src="/img/star_1.png"
                      class="object-none"
                    />
                    <div class="font-semibold text-xl">{{ worker.rate }}</div>
                  </div>
                  <div class="font-semibold text-xl">{{ worker.name }}</div>
                </div>
              </div>
              <div
                class="w-32 text-right cursor-pointer"
                @click="showWorkerDetailsModal(worker)"
              >
                О специалисте
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-span-4 space-y-8"
          v-if="checkoutStep == 3"
        >
          <span class="text-gray-400">Шаг 3 из 4</span>
          <h1 class="text-center lg:text-left text-3xl font-semibold">Выберите дату и время</h1>

          <div
            v-for="service in checkedServices"
            :key="service.id"
            class="border rounded-xl p-3"
          >
            <strong>{{ service.title }}</strong>

            <div>
              <Carousel
                :value="dates"
                :numVisible="7"
                :numScroll="7"
                indicatorsContentClass="hidden"
              >
                <template #item="slotProps">
                  <div
                    class="text-center shadow py-4 mx-1 my-2 rounded-lg hover:bg-blue-50 flex flex-col cursor-pointer"
                    :class="{
                      'bg-blue-400 hover:bg-blue-200 text-white': selectedServiceDate[service.id] == slotProps.data
                    }"
                    @click="selectedServiceDate[service.id] = slotProps.data"
                  >
                    <span class="uppercase font-semibold text-xs">{{ slotProps.data.format("ddd") }}</span>
                    <span class="font-semibold">{{ slotProps.data.format("DD.MM") }}</span>
                  </div>
                </template>
              </Carousel>

              <div
                class="flex flex-col px-4 mt-2"
                v-if="computeTimeslots(service)"
              >
                <div
                  v-for="(slot, idx) in computeTimeslots(service)"
                  :key="idx"
                  class="space-y-2 flex flex-col mb-2"
                >
                  <div class="p-field-radiobutton flex flex-row items-center space-x-3">
                    <label
                      class="items-center flex gap-3 cursor-pointer"
                      @click="selectedServiceSlots[service.id] = slot.id"
                    >
                      <RadioButton
                        name="time"
                        :value="slot.start_time.substr(0, 5)"
                        v-model="selectedServiceDates[service.id]"
                      />
                      {{ slot.start_time.substr(0, 5) }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-span-4 space-y-8"
          v-if="checkoutStep == 4"
        >
          <span class="text-gray-400">Шаг 4 из 4</span>
          <h1 class="text-center lg:text-left text-3xl font-semibold">Укажите номер телефона</h1>

          <div>
            <p class="text-center lg:text-left font-semibold">
              Укажите ваш номер телефона, чтобы подтвердить бронирование. Менеджер свяжется с вами по этому номеру или через внутренние сообщения на
              сайте
            </p>
            <InputMask
              class="mt-8 w-full lg:mx-0"
              v-model="phone"
              mask="+375 (99) 999-99-99"
            />
          </div>
        </div>

        <div class="col-span-2 mt-4 lg:mt-0">
          <div class="shadow-lg border p-4 rounded-lg">
            <div class="mx-auto w-32 h-32 overflow-hidden rounded-xl">
              <img
                class="h-full object-center object-cover"
                :src="baseURL + data.logo"
              />
            </div>
            <div class="text-center font-semibold text-xl mt-3">
              {{ data.title }}
            </div>
            <div class="text-center my-1 mb-4">
              {{ data.geo.address }}
            </div>

            <div
              class="flex flex-col space-y-3 border-t border-b py-4"
              v-if="checkedServices.length > 0"
            >
              <div
                v-for="s in checkedServices"
                :key="s.id"
                class="flex flex-row justify-between items-center"
              >
                <div class="flex flex-col">
                  <div class="text-lg">
                    {{ s.title }}
                  </div>
                  <div
                    class="text-sm"
                    v-if="selectedServiceDate[s.id] && selectedServiceDates[s.id]"
                  >
                    {{ selectedServiceDate[s.id].format("DD.MM") }} в
                    {{ selectedServiceDates[s.id] }}
                  </div>
                </div>

                <div class="font-semibold w-32 text-right">{{ s.price }} руб</div>
              </div>
            </div>

            <div
              class="flex flex-row justify-between items-center mt-2"
              v-if="checkedServices.length > 0"
            >
              <div class="text-lg font-semibold">Итог</div>
              <div class="font-semibold">{{ checkedServicesPrice }} руб</div>
            </div>
          </div>

          <div class="mt-4 flex justify-around">
            <div class="flex flex-row space-x-2">
              <Button
                class="rounded-3xl font-semibold"
                label="Назад"
                @click="checkoutStep--"
                v-if="checkoutStep > 1"
              />
              <Button
                class="rounded-3xl font-semibold"
                v-if="checkoutStep < 4"
                label="Продолжить"
                @click="checkoutStep++"
              />
              <Button
                class="rounded-3xl font-semibold"
                v-else
                label="Оформить"
                @click="handleCheckout"
              />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>

    <div
      class="fixed bottom-4 right-4 bg-white rounded-lg p-4 border text-left shadow-lg"
      style="z-index: 1002"
      v-if="hasSelectedServices || hasSelectedFood"
    >
      <span
        class="flex h-4 w-4 absolute -top-1 -left-1"
        v-if="!isOrderBarDisplayed"
      >
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent opacity-75"></span>
        <span class="relative inline-flex rounded-full h-4 w-4 bg-accent"></span>
      </span>

      <div class="flex flex-row justify-between">
        <div
          @click="isOrderBarDisplayed = true"
          :class="{ 'cursor-pointer': !isOrderBarDisplayed }"
        >
          <div v-if="!isOrderBarDisplayed">
            <strong>Готовы заказать?</strong>
            <p>Нажмите, чтобы увидеть детали</p>
          </div>
          <strong v-else>Детали вашего заказа</strong>
        </div>
        <div
          @click="isOrderBarDisplayed = false"
          v-if="isOrderBarDisplayed"
        >
          <i class="pi pi-times cursor-pointer text-gray-500 hover:text-black"></i>
        </div>
      </div>

      <div
        class="flex flex-col"
        v-if="isOrderBarDisplayed"
      >
        <div
          v-if="selectedFood.length"
          class="pt-4"
        >
          <i>Еда</i>

          <div
            class="grid grid-cols-6 py-4 border-b items-center"
            v-for="i in selectedFood"
            :key="i.id"
          >
            <div class="w-16 h-16 border rounded-full overflow-hidden hidden lg:block relative">
              <img
                :src="baseURL + i.photo"
                class="object-center object-cover h-full"
              />
            </div>

            <div class="col-span-5 lg:col-span-5 ml-4">
              <div class="flex flex-row justify-between">
                <div class="flex flex-row items-center">
                  <strong class="text-lg capitalize w-3/4">{{ i.title }}</strong>
                </div>

                <div class="flex flex-row text-right relative">
                  <div class="flex flex-row items-center">
                    <span
                      class="text-right text-xl font-semibold"
                      v-if="i.added_count > 1"
                    >{{ i.added_count }} x&nbsp;</span>
                    <span class="text-right text-xl font-semibold">{{ i.price }} руб</span>
                    <i
                      class="pi cursor-pointer text-xl mb-1 pl-3"
                      :class="{ 'text-red-400 hover:text-red-600 pi-times': i.is_added }"
                      @click="i.is_added = false"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="selectedServices.length"
          class="py-4"
        >
          <i>Услуги</i>

          <div
            class="grid grid-cols-6 py-4 border-b items-center"
            v-for="i in selectedServices"
            :key="i.id"
          >
            <div class="w-16 h-16 border rounded-full overflow-hidden hidden lg:block relative">
              <img
                :src="baseURL + i.photo"
                class="object-center object-cover w-full h-full"
              />
            </div>

            <div class="col-span-5 lg:col-span-5 ml-4">
              <div class="flex flex-row justify-between">
                <div class="flex flex-row items-center">
                  <strong class="text-lg capitalize w-3/4 leading-5">{{ i.title }}</strong>
                </div>

                <div class="flex flex-row text-right relative items-center">
                  <span
                    class="text-right text-xl font-semibold"
                    v-if="i.discounted_price < i.price"
                  >{{i.discounted_price}} руб</span>
                  <span
                    class="text-right text-xl font-semibold"
                    v-else
                  >{{i.price}} руб</span>
                  <i
                    class="pi cursor-pointer text-xl mb-1 pl-3"
                    :class="{ 'text-red-400 hover:text-red-600 pi-times': i.is_added }"
                    @click="i.is_added = false"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isOrderBarDisplayed">
        <button
          class="text-white rounded-md bg-accent px-2 w-full py-3"
          @click="isPreparingOrder = true"
        >Забронировать</button>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceCard from "../../views/ui/ServiceCard";
import Comment from "../../views/ui/Comment";
import Galleria from "primevue/galleria";
import ScrollPanel from "primevue/scrollpanel";
import Sidebar from "primevue/sidebar";
// import ToggleButton from "primevue/togglebutton";
import Checkbox from "primevue/checkbox";
import ProgressBar from "primevue/progressbar";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import Message from "primevue/message";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Avatar from "primevue/avatar";
import Carousel from "primevue/carousel";
import Textarea from "primevue/textarea";
import RadioButton from "primevue/radiobutton";
import Rating from "primevue/rating";
import Dropdown from "primevue/dropdown";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab);
library.add(fas);

export default {
  components: {
    ServiceCard,
    Comment,
    Message,
    Dialog,
    Button,
    Galleria,
    Avatar,
    Carousel,
    ScrollPanel,
    Sidebar,
    // ToggleButton,
    Checkbox,
    ProgressBar,
    RadioButton,
    Rating,
    Textarea,
    InputMask,
    InputText,
    FontAwesomeIcon,
    Dropdown,
  },
  props: ["type", "id", "name", "category_id"],
  mounted() {
    if (!this.slug && this.$props.name) {
      this.slug = this.$props.name
    }

    if (!this.cat_id && this.$props.category_id) {
      this.cat_id = this.$props.category_id
    }

    this.fetchPlaceInfo();
    this.getUserFavorites();
  },
  // watch: {
  //   id: function () {
  //     this.fetchPlaceInfo();
  //     this.getUserFavorites();
  //   },
  // },
  computed: {
    baseURL() {
      return this.$http.defaults.baseURL.substr(
        0,
        this.$http.defaults.baseURL.length - 3
      );
    },
    giftedServices() {
      let gifts = [];
      let occurancies = [];

      if (this.data.services) {
        this.data.services.map((service) => {
          if (service.is_added) {
            if (service.coupons) {
              service.coupons.forEach((coupon) => {
                if (
                  coupon.is_active &&
                  coupon.is_promocode == 1 &&
                  coupon.promocode_value.toUpperCase() ==
                    this.promocode.toUpperCase()
                ) {
                  switch (coupon.discount_type) {
                    case "gift":
                      if (occurancies.indexOf(coupon.gift.id) === -1) {
                        gifts.push(coupon.gift);
                        occurancies.push(coupon.gift.id);
                      }
                      break;
                  }
                }

                if (coupon.is_active && coupon.is_promocode !== 1) {
                  switch (coupon.discount_type) {
                    case "gift":
                      if (occurancies.indexOf(coupon.gift.id) === -1) {
                        gifts.push(coupon.gift);
                        occurancies.push(coupon.gift.id);
                      }
                      break;
                  }
                }
              });
            }
          }
        });
      }

      return gifts;
    },
    checkedServices() {
      let services = [];

      if (this.data.services) {
        this.data.services.map((service) => {
          if (service.is_added) {
            if (this.promocode) {
              if (service.coupons) {
                let discount = 0;

                service.coupons.forEach((coupon) => {
                  if (
                    coupon.is_active &&
                    coupon.is_promocode == 1 &&
                    coupon.promocode_value.toUpperCase() ==
                      this.promocode.toUpperCase()
                  ) {
                    let total = service.price;

                    switch (coupon.discount_type) {
                      case "cash":
                        total -= coupon.discount_value;
                        discount += coupon.discount_value;
                        break;
                      case "percent":
                        total = (
                          total -
                          (total * coupon.discount_value) / 100
                        ).toFixed(2);
                        discount +=
                          (service.price * coupon.discount_value) / 100;
                        break;
                      case "gift":
                        service.gift_service = coupon.gift;
                        break;
                      default:
                        total = service.price;
                    }

                    service.discounted_price = total;
                  } else {
                    service.discounted_price = undefined;
                  }
                });

                console.log(discount);
                //this.total_discount += parseFloat(discount);
              }
            }

            services.push(service);
          }
        });
      }

      return services;
    },
    filteredServices() {
      let services = [];

      if (this.activeType) {
        this.data.services.map((item) => {
          if (item.type.title == this.activeType) {
            services.push(item);
          }
        });
      } else {
        services = this.data.services;
      }

      if (services && services.length) {
        services = services.map((service) => {
          let total = service.price;

          if (service.coupons) {
            service.coupons.forEach((coupon) => {
              if (!coupon.is_active) {
                return;
              }

              let discount = 0;

              if (!coupon.is_promocode) {
                switch (coupon.discount_type) {
                  case "cash":
                    total -= coupon.discount_value;
                    discount += coupon.discount_value;
                    break;
                  case "percent":
                    total = (
                      total -
                      (total * coupon.discount_value) / 100
                    ).toFixed(2);
                    discount += (service.price * coupon.discount_value) / 100;
                    break;
                  case "gift":
                    service.gift_service = coupon.gift;
                }
              }

              console.log(discount);
              //this.total_discount += parseFloat(discount);

              if (coupon.is_promocode) {
                service.hasPromocode = true;
              }
            });

            service.discounted_price = total;
          }

          return service;
        });
      }

      return services;
    },
    filteredMenu() {
      let menu = [];

      if (this.data.kitchen) {
        Object.keys(this.data.kitchen).map((kitchen) => {
          this.data.kitchen[kitchen].map((item) => {
            menu.push(item);
          });
        });
      }

      if (this.activeKitchenType) {
        menu = this.data.kitchen[this.activeKitchenType];
      }

      return menu;
    },
    hasSelectedFood() {
      if (!this.data.kitchen) {
        return false;
      }

      let menu = [];

      Object.keys(this.data.kitchen).map((kitchen) => {
        this.data.kitchen[kitchen].map((item) => {
          if (item.is_added) {
            menu.push(item);
          }
        });
      });

      return menu.length ? true : false;
    },
    hasSelectedServices() {
      let val = this.data.services.filter((service) => {
        return service.is_added;
      }).length
        ? true
        : false;

      return val;
    },
    selectedFood() {
      if (!this.data.kitchen) {
        return [];
      }

      let menu = [];

      Object.keys(this.data.kitchen).map((kitchen) => {
        this.data.kitchen[kitchen].map((item) => {
          if (item.is_added) {
            menu.push(item);
          }
        });
      });

      return menu;
    },
    selectedServices() {
      return this.data.services.filter((service) => {
        return service.is_added;
      });
    },
    isInFavorites() {
      if (this.favorites && this.favorites.length) {
        return this.favorites.indexOf(this.data.id) !== -1;
      }

      return false;
    },
    workingDaysCounted() {
      let days = [];

      days.push({
        id: 0,
        title: "ПН",
        opening: this.data.schedule.monday_start,
        closing: this.data.schedule.monday_end,
        closed:
          !this.data.schedule.monday_end ||
          this.data.schedule.monday_start == "00:00"
            ? true
            : false,
      });
      days.push({
        id: 1,
        title: "ВТ",
        opening: this.data.schedule.tuesday_start,
        closing: this.data.schedule.tuesday_end,
        closed:
          !this.data.schedule.tuesday_end ||
          this.data.schedule.tuesday_start == "00:00"
            ? true
            : false,
      });
      days.push({
        id: 2,
        title: "СР",
        opening: this.data.schedule.wednesday_start,
        closing: this.data.schedule.wednesday_end,
        closed:
          !this.data.schedule.wednesday_end ||
          this.data.schedule.wednesday_start == "00:00"
            ? true
            : false,
      });
      days.push({
        id: 3,
        title: "ЧТ",
        opening: this.data.schedule.thursday_start,
        closing: this.data.schedule.thursday_end,
        closed:
          !this.data.schedule.thursday_end ||
          this.data.schedule.thursday_start == "00:00"
            ? true
            : false,
      });
      days.push({
        id: 4,
        title: "ПТ",
        opening: this.data.schedule.friday_start,
        closing: this.data.schedule.friday_end,
        closed:
          !this.data.schedule.friday_end ||
          this.data.schedule.friday_start == "00:00"
            ? true
            : false,
      });
      days.push({
        id: 5,
        title: "СБ",
        opening: this.data.schedule.saturday_start,
        closing: this.data.schedule.saturday_end,
        closed:
          !this.data.schedule.saturday_end ||
          this.data.schedule.saturday_start == "00:00"
            ? true
            : false,
      });
      days.push({
        id: 6,
        title: "ВС",
        opening: this.data.schedule.sunday_start,
        closing: this.data.schedule.sunday_end,
        closed:
          !this.data.schedule.sunday_end ||
          this.data.schedule.sunday_start == "00:00"
            ? true
            : false,
      });

      return days;
    },
    worktime_today() {
      let dayname = moment().format("d");
      let data = null;

      if (this.data && typeof this.data.schedule !== "undefined") {
        switch (dayname) {
          case "0":
            data = this.data.schedule.sunday_start
              ? `Открыто с ${this.data.schedule.sunday_start} до ${this.data.schedule.sunday_end}`
              : "Сегодня закрыто";
            break;
          case "1":
            data = this.data.schedule.monday_start
              ? `Открыто с ${this.data.schedule.monday_start} до ${this.data.schedule.monday_end}`
              : "Сегодня закрыто";
            break;
          case "2":
            data = this.data.schedule.tuesday_start
              ? `Открыто с ${this.data.schedule.tuesday_start} до ${this.data.schedule.tuesday_end}`
              : "Сегодня закрыто";
            break;
          case "3":
            data = this.data.schedule.wednesday_start
              ? `Открыто с ${this.data.schedule.wednesday_start} до ${this.data.schedule.wednesday_end}`
              : "Сегодня закрыто";
            break;
          case "4":
            data = this.data.schedule.thursday_start
              ? `Открыто с ${this.data.schedule.thursday_start} до ${this.data.schedule.thursday_end}`
              : "Сегодня закрыто";
            break;
          case "5":
            data = this.data.schedule.friday_start
              ? `Открыто с ${this.data.schedule.friday_start} до ${this.data.schedule.friday_end}`
              : "Сегодня закрыто";
            break;
          case "6":
            data = this.data.schedule.saturday_start
              ? `Открыто с ${this.data.schedule.saturday_start} до ${this.data.schedule.saturday_end}`
              : "Сегодня закрыто";
            break;
          default:
            data = null;
            break;
        }
      }

      return data;
    },
    checkedServicesPrice() {
      let price = 0;

      if (this.checkedServices.length > 0) {
        this.checkedServices.map((service) => {
          let sprice =
            service.discounted_price < service.price
              ? service.discounted_price
              : service.price;
          price = price + parseFloat(sprice);
        });
      }

      return parseFloat(price).toFixed(2);
    },
    filteredComments() {
      let comments = [];
      let rates = [];

      this.commentFilter.map((filter) => {
        if (filter.checked) {
          rates.push(filter.rate);
        }
      });

      if (this.comments.length > 0) {
        comments = this.comments.filter((comment) => {
          return rates.indexOf(comment.rate) !== -1;
        });
      }

      return comments;
    },
    generalRating() {
      let all = this.totalCommentsCount;
      let score = 0;

      if (!all) {
        return 0;
      }

      for (let r1 = 0; r1 < this.commentsCountByRate.rate1; r1++) {
        score = score + 1;
      }
      for (let r2 = 0; r2 < this.commentsCountByRate.rate2; r2++) {
        score = score + 2;
      }
      for (let r3 = 0; r3 < this.commentsCountByRate.rate3; r3++) {
        score = score + 3;
      }
      for (let r4 = 0; r4 < this.commentsCountByRate.rate4; r4++) {
        score = score + 4;
      }
      for (let r5 = 0; r5 < this.commentsCountByRate.rate5; r5++) {
        score = score + 5;
      }

      return (score / all).toFixed(1);
    },
    serviceCategories() {
      let types = [];
      // return types;

      this.data.services.map((service) => {
        if (service.type) {
          let type = { title: service.type.title, items: [] };
          let existingType = types.filter((item) => {
            return item.title == type.title;
          });

          if (!existingType.length) {
            types.push(type);
          }

          let createdType = types.filter((item) => {
            return item.title == type.title;
          });

          if (createdType.length) {
            createdType[0].items.push(service);
          }
        }
      });

      return types;
    },
  },
  methods: {
    moment(x) {
      return moment(x);
    },
    refetch(category_id, slug) {
      this.cat_id = category_id
      this.slug = slug

      this.fetchPlaceInfo();
      this.getUserFavorites();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      this.$router.replace({
        name: 'Slug Place Details',
        params: { category_id: this.getCategorySlug(this.cat_id), name: this.slug }
      })
    },
    getCategorySlug(id) {
      let slug;

      switch (id) {
        case 5:
          slug = "cars";
          break;
        case 3:
          slug = "medicine";
          break;
        case 6:
          slug = "realty";
          break;
        case 7:
          slug = "education";
          break;
        case 1:
          slug = "food";
          break;
        case 2:
          slug = "event";
          break;
        case 4:
          slug = "beauty";
          break;
      }

      return slug;
    },
    addWorkerRelatedServiceToCart() {
      this.data.services.map((s) => {
        if (s.id == this.selectedWorkerRelatedService) {
          s.is_added = true;
        }
      });

      this.isPreparingOrder = true;
    },
    showWorkerRelatedServices(id) {
      let services = this.data.services.filter((s) => {
        if (s.workers) {
          let wc = s.workers.filter((w) => {
            return w.worker_id == id;
          });

          return wc.length ? true : false;
        }
      });

      if (services) {
        services = services.map((s) => {
          if (s.workers) {
            let wrk = s.workers.filter((w) => {
              return w.worker_id == id;
            });

            if (wrk) {
              s.worker = wrk[0];
            }
          }

          return s;
        });
      }

      this.workerRelatedServices = services;
      this.showWorkerRelatedServicePicker = true;
    },
    generateTimeslots(date, duration) {
      let slots = [];
      let day = moment(date, "DD.MM.YY");
      let boundStart, boundEnd;

      if (this.data.schedule) {
        switch (day.day()) {
          case 1:
            boundStart = moment(this.data.schedule.monday_start, "HH:mm");
            boundEnd = moment(this.data.schedule.monday_end, "HH:mm");
            break;
          case 2:
            boundStart = moment(this.data.schedule.tuesday_start, "HH:mm");
            boundEnd = moment(this.data.schedule.tuesday_end, "HH:mm");
            break;
          case 3:
            boundStart = moment(this.data.schedule.wednesday_start, "HH:mm");
            boundEnd = moment(this.data.schedule.wednesday_end, "HH:mm");
            break;
          case 4:
            boundStart = moment(this.data.schedule.thursday_start, "HH:mm");
            boundEnd = moment(this.data.schedule.thursday_end, "HH:mm");
            break;
          case 5:
            boundStart = moment(this.data.schedule.friday_start, "HH:mm");
            boundEnd = moment(this.data.schedule.friday_end, "HH:mm");
            break;
          case 6:
            boundStart = moment(this.data.schedule.saturday_start, "HH:mm");
            boundEnd = moment(this.data.schedule.saturday_end, "HH:mm");
            break;
          case 0:
            boundStart = moment(this.data.schedule.sunday_start, "HH:mm");
            boundEnd = moment(this.data.schedule.sunday_end, "HH:mm");
            break;
        }

        let diff = boundEnd.diff(boundStart, "minutes");
        let count = parseInt(diff / duration);
        let start = boundStart;

        //Add first slot
        slots.push({
          time: start.format("HH:mm"),
        });

        for (let i = 0; i < count - 1; i++) {
          slots.push({
            time: start.add(duration, "m").format("HH:mm"),
          });
        }
      }

      return slots;
    },
    getUserFavorites() {
      this.fetchUserInfo().then((r) => {
        if (!r) {
          return false;
        }

        if (r.favorites.length) {
          this.favorites = r.favorites.map((f) => {
            return f.place_id;
          });
        }
      });
    },
    fetchUserInfo() {
      return this.$http
        .get("/user")
        .then((response) => {
          return response.data;
        })
        .catch(() => {});
    },
    makeActiveKitchenType(type = null, items = null) {
      if (!type && !items) {
        this.activeKitchenType = false;
        this.activeKitchenTypeItems = this.data.services;
        return false;
      }

      this.activeKitchenType = type;
      this.activeKitchenTypeItems = items;
    },
    makeActiveType(type = null, items = null) {
      if (!type && !items) {
        this.activeType = false;
        this.activeTypeItems = this.data.services;
        return false;
      }

      this.activeType = type;
      this.activeTypeItems = items;
    },
    addWorkerFeedback() {
      this.$http
        .post("comment-worker", {
          rate: this.workerComment.rate,
          text: this.workerComment.text,
          worker_id: this.selectedWorkerData.worker.id,
        })
        .then(() => {
          this.isLeavingWorkerComment = false;
          this.fetchPlaceInfo();
        });
    },
    addFeedback() {
      this.$http
        .post("comment", {
          rate: this.placeComment.rate,
          comment: this.placeComment.comment,
          place_id: this.data.id,
        })
        .then(() => {
          this.isPlaceCommentFormDisplayed = false;
          this.fetchPlaceComments(this.data.id);
        });
    },
    handleCheckout() {
      let services = [];
      let org;

      this.checkedServices.map((service) => {
        if (service.is_added) {
          service.start_time = service.time;
          service.end_time = moment(service.time, "HH:mm")
            .add(service.duration, "m")
            .format("HH:mm");
          service.worker_id = service.worker.worker_id;
          service.service_id = service.id;
          org = service.created_by;

          if (service.addresses.length) {
            if (!service.address) {
              service.geo_id = service.addresses[0].id;
              service.address_name = service.addresses[0].clean_address;
            } else {
              service.geo_id = service.address.id;
              service.address_name = service.address.clean_address;
            }
          }

          if (!service.geo_id && this.data.geo[0]) {
            service.geo_id = this.data.geo[0].id
            service.address_name = this.data.geo[0].clean_address
          }

          services.push(service);
        }
      });

      if (services.length) {
        let gifts = this.giftedServices;

        this.$http
          .post("order", {
            organization_id: org,
            phone: this.phone,
            services,
            gifts,
          })
          .then(() => {
            this.isPreparingOrder = false;
            this.fetchPlaceInfo();
          });
      }
    },
    handleCheckout1() {
      console.log("Checkout");
      let payload = [];
      let success = 0;

      this.checkedServices.forEach((service) => {
        let data = {};

        data.service_id = service.service.id;
        data.service_type = service.category_id;
        data.phone = this.phone;

        if (this.selectedServiceDates[service.id]) {
          data.time = this.selectedServiceDates[service.id];
        }

        if (this.selectedServiceDate[service.id]) {
          data.date = this.selectedServiceDate[service.id].format("Y-MM-DD");
        }

        if (this.selectedServiceSlots[service.id]) {
          data.slot_id = this.selectedServiceSlots[service.id];
        }

        if (this.selectedWorkers[service.id]) {
          data.worker_id = this.selectedWorkers[service.id];
        }

        data.place_id = this.data.id;
        data.place_type = this.data.category_id;

        payload.push(data);
      });

      if (payload.length > 0) {
        payload.forEach((p) => {
          this.$http.post("order", p).then((response) => {
            if (response.data.order) {
              success++;

              if (success == payload.length) {
                this.isPreparingOrder = false;
              }
            }
          });
        });
      }
    },
    computeTimeslots(service) {
      let day;
      let timeslots;

      if (this.selectedServiceDate[service.id]) {
        day = this.selectedServiceDate[service.id].day();
      }

      if (service) {
        if (service.timeslot) {
          timeslots = service.timeslot.filter((t) => {
            if (!t.uses.length) {
              return t.weekday == day;
            }

            if (this.selectedServiceDate[service.id]) {
              let uses = t.uses.filter((use) => {
                return (
                  use.date ==
                  this.selectedServiceDate[service.id].format("Y-M-D")
                );
              });

              return !uses.length && t.weekday == day;
            }
          });
        }
      }

      this.availableTimeslots[service.id] = timeslots;
      return timeslots;
    },
    toggleServiceDate(serviceId, date) {
      if (this.selectedServiceDate[serviceId] == date) {
        delete this.selectedServiceDate[serviceId];
      } else {
        this.selectedServiceDate[serviceId] = date;
      }
    },
    togglePickWorker(serviceId, worker) {
      if (this.selectedWorkers[serviceId] == worker.id) {
        delete this.selectedWorkers[serviceId];
      } else {
        this.selectedWorkers[serviceId] = worker.id;
      }
    },
    percentageRating(count) {
      let all = this.totalCommentsCount;
      return (count * 100) / all;
    },
    fetchMorePlaceComments(id) {
      this.$http
        .get(
          "comments/" + id + "?page=" + (this.currentLoadedCommentsPages + 1)
        )
        .then((response) => {
          if (response.data.comments) {
            this.comments.push(...response.data.comments.data);

            this.currentLoadedCommentsPages++;
          }
        });
    },
    fetchPlaceComments(id) {
      this.$http.get("comments/" + id).then((response) => {
        if (response.data.comments) {
          this.comments = response.data.comments.data;
          this.commentsCountByRate = response.data.count;
          this.totalCommentsPages = response.data.comments.last_page;
          this.totalCommentsCount = response.data.comments.total;
          this.currentLoadedCommentsPages = 1;
        }
      });
    },
    togglePickService(service) {
      if (!service.isChecked) {
        service.isChecked = true;
      } else {
        service.isChecked = !service.isChecked;
      }

      let entry = this.checkedServices.filter((i) => {
        return i.id == service.id;
      });

      if (entry.length > 0) {
        this.checkedServices = this.checkedServices.filter((i) => {
          return i.id != service.id;
        });
      } else {
        this.checkedServices.push(service);
      }
    },
    fetchPlaceInfo() {
      let endpoint;

      if (!this.slug) {
        endpoint = "place/" + this.$props.id;
      } else {
        let category;

        switch (this.cat_id) {
          case "cars":
            category = 5;
            break;
          case "medicine":
            category = 3;
            break;
          case "realty":
            category = 6;
            break;
          case "education":
            category = 7;
            break;
          case "food":
            category = 1;
            break;
          case "event":
            category = 2;
            break;
          case "beauty":
            category = 4;
            break;
          default:
            category = this.cat_id;
        }

        endpoint = "place-title/" + category + "/" + this.slug;
      }
      // if (this.$http.defaults.headers.common["Authorization"]) {
      //   endpoint = "place/a/" + this.$props.type + "/" + this.$props.id;
      // }

      this.$http
        .get(endpoint)
        .then((response) => {
          let data = response.data;

          if (data) {
            this.data = data;

            if (data.services) {
              let services = data.services.map((service) => {
                service.date = null;
                service.time = null;
                service.worker = null;
                return service;
              });

              this.services = services;
            }

            // this.isFavorited = data.place.is_in_favorites;

            this.fetchWorkers(data.id);
            this.fetchPlaceComments(data.id);
            this.fetchOrdersCount(data.id);

            this.workers = data.workers;
            this.activeTypeItems = data.services;

            let offDays = [];
            let dates = [];

            if (this.data.schedule) {
              if (!this.data.schedule.monday_start) offDays.push(1);
              if (!this.data.schedule.tuesday_start) offDays.push(2);
              if (!this.data.schedule.wednesday_start) offDays.push(3);
              if (!this.data.schedule.thursday_start) offDays.push(4);
              if (!this.data.schedule.friday_start) offDays.push(5);
              if (!this.data.schedule.saturday_start) offDays.push(6);
              if (!this.data.schedule.sunday_start) offDays.push(0);

              for (let i = 0; i < 31; i++) {
                let d = moment().add(i, "days");

                if (offDays.indexOf(d.day()) === -1) {
                  dates.push(d);
                }
              }

              this.dates = dates;
            } else {
              this.data.schedule = {
                monday_start: '09:00',
                monday_end: '20:00',
                tuesday_start: '09:00',
                tuesday_end: '20:00',
                wednesday_start: '09:00',
                wednesday_end: '20:00',
                thursday_start: '09:00',
                thursday_end: '20:00',
                friday_start: '09:00',
                friday_end: '20:00',
                saturday_start: '09:00',
                saturday_end: '20:00',
                sunday_start: '09:00',
                sunday_end: '20:00',
              }
            }
          }
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
    fetchOrdersCount(pid) {
      this.$http.get("has-orders/" + pid).then((response) => {
        this.hasOrdersHere = response.data.orders_count;
        this.recentOrders = response.data.services;
      });
    },
    fetchWorkersForService(service) {
      let local = { ...service };
      delete local.pivot;
      delete local.isChecked;

      let workers = [];

      this.workers.map((worker) => {
        worker.services.map((srv) => {
          if (srv.service.id == service.id) {
            workers.push(worker);
          }
        });
      });

      return workers;
    },
    fetchWorkers(id) {
      this.$http.get("place/w/" + id).then((response) => {
        let data = response.data;

        if (data.workers) {
          // this.workers = data.workers;
        }
      });
    },
    showWorkerDetailsModal(data) {
      this.selectedWorkerData = data;
      this.isWorkerDetailsDisplayed = true;
    },
    toggleFavorited() {
      this.$http
        .post("favorites", {
          place_id: this.data.id,
          place_type: this.$props.type,
        })
        .then((response) => {
          this.favorites = null;
          this.getUserFavorites();

          if (typeof response.data.deleted !== "undefined") {
            this.isFavorited = false;
          }
          if (typeof response.data.created !== "undefined") {
            this.isFavorited = true;
          }
        });
    },
    toggleMap() {
      this.isMapDisplayed = !this.isMapDisplayed;

      this.$nextTick(() => {
        let center = [
          parseFloat(this.data.geo[0].lat),
          parseFloat(this.data.geo[0].lng),
        ];

        mapboxgl.accessToken =
          "pk.eyJ1IjoicmVzZXJ2YXBwIiwiYSI6ImNrbmZ4MnVkZDI1a3cydm1ydnZjNzd3ejQifQ.GitchBaltaP1uyfk5TrTtQ";

        const map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/reservapp/cknfyrj9k494y17r0elagsflz",
          center,
          zoom: 10,
        });

        map.addControl(new mapboxgl.NavigationControl());

        let features = [];

        if (this.data.geo) {
          this.data.geo.map((geo) => {
            features.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [geo.lat, geo.lng],
              },
              properties: {
                title: geo.address,
              },
            });
          });
        }

        map.on("load", () => {
          this.isMapInitialized = true;

          map.loadImage(
            "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            (error, image) => {
              if (error) throw error;
              map.addImage("custom-marker", image);
              // Add a GeoJSON source with 2 points
              map.addSource("points", {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features,
                },
              });

              // Add a symbol layer
              map.addLayer({
                id: "points",
                type: "symbol",
                source: "points",
                layout: {
                  "icon-image": "custom-marker",
                  // get the title name from the source's "title" property
                  "text-field": ["get", "title"],
                  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  "text-offset": [0, 1.25],
                  "text-anchor": "top",
                },
              });
            }
          );
        });
      });
    },
  },
  data() {
    return {
      cat_id: null,
      slug: null,
      total_discount: 0,
      promocode: null,
      promocodeForm: false,
      selectedWorkerRelatedService: null,
      workerRelatedServices: null,
      showWorkerRelatedServicePicker: false,
      isSelectingDateAndTime: false,
      isSelectingAddress: false,
      isSelectingWorker: false,
      isOrderBarDisplayed: false,
      hasOrdersHere: 0,
      socials: { vk: "", meta: "", instagram: "", twitter: "" },
      favorites: null,
      workerComment: {
        rate: 5,
        text: null,
      },
      isLeavingWorkerComment: false,
      activeKitchenType: false,
      activeKitchenTypeItems: null,
      activeTypeItems: null,
      activeType: false,
      isPlaceCommentFormDisplayed: false,
      placeComment: {
        rate: 5,
        comment: null,
      },
      phone: null,
      availableTimeslots: [],
      selectedServiceSlots: [],
      selectedServiceDates: [],
      selectedServiceDate: [],
      dates: [],
      selectedWorkers: [],
      currentLoadedCommentsPages: 0,
      totalCommentsCount: 0,
      totalCommentsPages: 1,
      checkoutStep: 1,
      activeKitchenId: 1,
      detailsSection: 1,
      isGalleryDisplayed: false,
      data: null,
      isPreparingOrder: false,
      isTimelineDisplayed: false,
      isMapInitialized: false,
      isMapDisplayed: false,
      isWorkerDetailsDisplayed: false,
      isFavorited: false,
      services: [],
      gallery: [],
      workers: [],
      comments: [],
      c1: false,
      c2: false,
      c3: false,
      commentsCountByRate: {
        rate1: 0,
        rate2: 0,
        rate3: 0,
        rate4: 0,
        rate5: 0,
      },
      commentFilter: [
        { rate: 1, checked: true, rateId: "rate1" },
        { rate: 2, checked: true, rateId: "rate2" },
        { rate: 3, checked: true, rateId: "rate3" },
        { rate: 4, checked: true, rateId: "rate4" },
        { rate: 5, checked: true, rateId: "rate5" },
      ],
      nearby: [
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 2,
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 1,
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 1,
        },
        {
          title: "Гарна дивчина",
          short_description: "Женский салон красоты",
          price_rank: 3,
        },
      ],
      workingDays: [
        { id: 1, title: "ПН", opening: "9:00", closing: "18:00" },
        { id: 2, title: "ВТ", opening: "9:00", closing: "18:00" },
        { id: 3, title: "СР", opening: "9:00", closing: "18:00" },
        { id: 4, title: "ЧТ", opening: "9:00", closing: "18:00" },
        { id: 5, title: "ПТ", opening: "9:00", closing: "18:00" },
        { id: 6, title: "СБ", opening: "9:00", closing: "16:00" },
        { id: 7, title: "ВС", closed: true },
      ],
      isWorkerDetailsModalOpen: false,
      selectedWorkerDetailsModalId: null,
      selectedWorkerData: null,
      recentOrders: null,
    };
  },
};
</script>

<style>
.leading-8\.5 {
  line-height: 2.125rem;
}
.p-dialog-content {
  height: 100%;
}
#map {
  width: 100%;
  height: 100%;
}
.gallery:hover .title-image::after {
  opacity: 0;
}
.title-image {
  position: relative;
}
.title-image::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  border-radius: 6px;
  transition: opacity 300ms ease 0s;
  top: 0;
  left: 0;
  opacity: 0.4;
}
#marker {
  background-image: url("https://docs.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
