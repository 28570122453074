<template>
  <div>
    <!-- <div class="flex flex-row-reverse mb-4">
      <Button label="Добавить" @click="isAddingNewItem = true" />
      <div></div>
    </div> -->

    <!-- <FullCalendar :options="options" :events="events" /> -->
    <WeekCalendarScale />
    <!-- <ContextMenu ref="menu" :model="items" /> -->

    <Sidebar v-model:visible="isAddingNewItem" position="full">
      <div class="mx-auto container">
        <div class="w-2/3 shadow mx-auto p-4">
          <div class="grid grid-cols-3 gap-4">
            <div>
              <Calendar class="w-full" v-model="date8" :showTime="true" :showSeconds="false" :timeOnly="true" />
            </div>
            <div class="col-span-2">
              <Dropdown class="w-full" v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Выберите услугу" />
            </div>
            <div class="col-span-2">
              <Dropdown class="w-full" v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Клиент" />
            </div>
          </div>

          <div class="grid grid-cols-3 gap-4 mt-4">
            <div>
              <Dropdown class="w-full" v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Длительность" />
            </div>
            <div class="col-span-2">
              <Dropdown class="w-full" v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Выберите работника" />
            </div>
            <div class="col-span-2">
              <Dropdown class="w-full" v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Клиент" />
            </div>
          </div>
        </div>

        <div class="w-2/3 mx-auto mt-8">
          <Textarea v-model="value" rows="5" cols="30" class="w-full" />
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script>
// import ContextMenu from "primevue/contextmenu";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Sidebar from "primevue/sidebar";
// import Button from "primevue/button";
import WeekCalendarScale from "@/components/Manager/WeekCalendar/WeekCalendarScale";

export default {
  components: {
    Sidebar,
    // Button,
    Calendar,
    Dropdown,
    Textarea,
    WeekCalendarScale
    // ContextMenu
  },
  data() {
    return {
      isAddingNewItem: false
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.$store.dispatch("fetchEvents");
      // this.$http.get("organization/orders").then(response => {
      //   let events = [];
      //
      //   if (response.data.orders) {
      //     Object.keys(response.data.orders).map(key => {
      //       response.data.orders[key].forEach(type => {
      //         type.start = type.date + "T" + type.time;
      //         type.end =
      //           type.date +
      //           "T" +
      //           moment(type.time, "HH:mm")
      //             .add(type.service.timeslots[0].duration, "minutes")
      //             .format("HH:mm");
      //         type.title = type.place.place.title;
      //         events.push(type);
      //       });
      //     });
      //   }
      //
      //   this.events = events;
      // });
    }
  }
};
</script>

<style type="scss" scoped>
.day-dark {
  background: #fafafa;
}

::v-deep .vc-container.vc-is-expanded {
  border: 0;
}
</style>
