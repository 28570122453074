<template>
  <div>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6 flex space-x-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          <InputText
            type="text"
            v-model="formData.title"
            :class="{ 'p-invalid': errors.title }"
            placeholder="Название организации"
          />
        </h3>

        <Dropdown
          @change="attachDistrict"
          v-model="formData.city_id"
          v-if="cities && 1==2"
          :options="cities"
          optionLabel="name"
          placeholder="Город"
          :filter="true"
          filterPlaceholder="Найти город"
        />

        <Dropdown
          v-if="districts.length"
          v-model="formData.district_id"
          :options="districts"
          optionLabel="name"
          placeholder="Район"
          :filter="true"
          filterPlaceholder="Найти район"
        />
      </div>
      <div class="border-t border-gray-200">
        <dl>
          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Описание
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <Editor
                v-model="formData.description"
                :class="{ 'p-invalid': errors.description }"
              />
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <dt class="text-sm font-medium text-gray-500">
              Адрес
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <InputText
                type="text"
                v-model="formData.address"
                :class="{ 'p-invalid': errors.address }"
                placeholder="ул. Советская, 1, пом. 4"
              />
            </dd>
          </div>
          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <dt class="text-sm font-medium text-gray-500">
              Телефон
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <InputMask
                v-model="formData.phone"
                :class="{ 'p-invalid': errors.phone }"
                placeholder="Номер телефона"
                mask="+375 (99) 999-99-99"
              />
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <dt class="text-sm font-medium text-gray-500">
              Электронная почта
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <InputText
                type="text"
                v-model="formData.email"
                :class="{ 'p-invalid': errors.email }"
                placeholder="Почта"
              />
            </dd>
          </div>
          <div
            class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center"
            v-if="1==2"
          >
            <dt class="text-sm font-medium text-gray-500">
              Категория
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <Dropdown
                v-model="formData.category_id"
                :options="categories"
                optionLabel="name"
                placeholder="Категория"
                :filter="true"
                filterPlaceholder="Найти категорию"
              />
            </dd>
          </div>
          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Социальные сети
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-col space-y-3">
              <div class="flex space-x-3 items-center">
                <img
                  src="/img/social/instagram.png"
                  class="w-6 h-6"
                />
                <InputText
                  type="text"
                  v-model="formData.social.instagram"
                  placeholder="Instagram"
                />
              </div>
              <div class="flex space-x-3 items-center">
                <img
                  src="/img/social/facebook.png"
                  class="w-6 h-6"
                />
                <InputText
                  type="text"
                  v-model="formData.social.facebook"
                  placeholder="Facebook"
                />
              </div>
              <div class="flex space-x-3 items-center">
                <img
                  src="/img/social/twitter.png"
                  class="w-6 h-6"
                />
                <InputText
                  type="text"
                  v-model="formData.social.twitter"
                  placeholder="Twitter"
                />
              </div>
              <div class="flex space-x-3 items-center">
                <img
                  src="/img/social/vk.png"
                  class="w-6 h-6"
                />
                <InputText
                  type="text"
                  v-model="formData.social.vk"
                  placeholder="VK"
                />
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="flex space-x-2 mt-4">
      <Button
        @click="saveOrgData"
        label="Сохранить"
        class="p-button-success"
      />
    </div>
  </div>
</template>

<script>
import Editor from "primevue/editor";
import InputMask from "primevue/inputmask";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

export default {
  components: {
    Editor,
    InputMask,
    Dropdown,
    InputText,
    Button,
  },
  mounted() {
    this.$http
      .get("/organizations/last")
      .then((response) => {
        let data = response.data;
        if (data) {
          this.formData = data.organization;

          if (data.organization.social) {
            let social = JSON.parse(data.organization.social);

            this.formData.social = social;
          }

          this.formData.country_id = 1;
        }
        console.log(data);
      })
      .catch(() => {});

    this.$http.get("/lib/cities").then((response) => {
      if (!response.data) {
        return false;
      }

      let cities = [];

      response.data.library.forEach((item) => {
        cities.push({ name: item.title, id: item.id });
      });

      this.cities = cities;
      this.citiesRaw = response.data.library;
    });

    this.$http.get("/lib/categories").then((response) => {
      if (!response.data) {
        return false;
      }

      response.data.library.map((item) => {
        item.name = item.title;
      });

      this.categories = response.data.library;
    });
  },
  methods: {
    saveOrgData() {
      this.formData.country_id = 1;

      if (this.vk) {
        this.formData.social.vk = this.vk;
      }
      if (this.facebook) {
        this.formData.social.facebook = this.facebook;
      }
      if (this.instagram) {
        this.formData.social.instagram = this.instagram;
      }
      if (this.twitter) {
        this.formData.social.twitter = this.twitter;
      }
      if (this.formData.category_id) {
        this.formData.category_id = this.formData.category_id.id;
      }
      if (this.formData.city_id) {
        this.formData.city_id = this.formData.city_id.id;
      }
      if (this.formData.district_id) {
        this.formData.district_id = this.formData.district_id.id;
      }

      this.formData.social = JSON.stringify(this.formData.social);

      this.$http
        .post("/organizations", this.formData)
        .then((response) => {
          console.log(response);

          this.$toast.add({
            severity: "success",
            summary: "Информация обновлена",
            detail:
              "Администратор проверит ваши данные, после чего они будут опубликованы",
            life: 4000,
          });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          this.$toast.add({
            severity: "error",
            summary: "Ошибка",
            detail: "Данные не сохранены. Проверьте заполненные данные",
            life: 4000,
          });
        });
    },
    attachDistrict(ev) {
      let districts = [];

      this.citiesRaw.forEach((city) => {
        if (ev.value.id == city.id && city.districts) {
          city.districts.map((district) => {
            district.name = district.title;

            districts.push(district);
          });
        }
      });

      this.districts = districts;
    },
  },
  data() {
    return {
      phone: "",
      twitter: "",
      facebook: "",
      vk: "",
      instagram: "",
      category: "",
      district: "",
      city: "",
      address: "",
      citiesRaw: null,
      cities: [],
      districts: [],
      categories: [],
      errors: [],
      formData: {
        address: null,
        category_id: null,
        city_id: null,
        country_id: 1,
        description: null,
        district_id: null,
        email: null,
        owner_id: null,
        phone: null,
        social: {
          vk: null,
          facebook: null,
          instagram: null,
          twitter: null,
        },
        subcategory_id: null,
        title: null,
      },
    };
  },
};
</script>
